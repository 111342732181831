/*
 * routes.util.ts.ts
 * Little Phil
 *
 * Created on 16/10/19
 * Copyright © 2018 Little Phil. All rights reserved.
 */

import { ActivatedRouteSnapshot } from '@angular/router';

/**
 * Removes a prefix from the start of a route path
 * e.g. ("/give/success", "/give") -> "/success"
 *
 * @param path
 * @param prefix
 */
export const removePathPrefix = (path: string, prefix: string) => {

    if (!path.startsWith(prefix)) {
        throw new Error(`${path} does not start with ${prefix}`);
    }

    return path.substring(prefix.length);

};

export const getAbsoluteUrlFromActivatedRouteSnapshot = (route: ActivatedRouteSnapshot): string => {
    const url = route.url
        .map(_url => _url.path)
        .join('/');

    if (route.parent) {
        return getAbsoluteUrlFromActivatedRouteSnapshot(route.parent) + '/' + url;
    } else {
        return url;
    }
};
