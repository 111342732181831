/*
 * share-link-generator.class.ts
 * Little Phil
 *
 * Created on 17/11/20
 * Copyright © 2018 Little Phil. All rights reserved.
 */

import { CAUSE_TYPE } from '@little-phil/js/lib/common/enums';
import { ROUTE_PATHS } from '@little-phil/js/lib/common/constants';

import { ApiService } from '../_services/api.service';
import { LoaderService } from '../_services/loader.service';
import { LoggerService } from '../_services/logger.service';
import { SentryService } from '../_services/sentry.service';
import { LocalStorageService } from '../_services/local-storage.service';
import { EnvironmentService } from '../_services/environment.service';
import { LOCAL_STORAGE_EMAIL_KEY } from '../_utils/constants.util';

export abstract class ShareLinkGenerator {

    protected causeType: CAUSE_TYPE;
    protected causeId: string;
    protected readonly webBaseUrl;

    protected constructor(
        protected apiService: ApiService,
        protected loaderService: LoaderService,
        protected logger: LoggerService,
        protected sentry: SentryService,
        protected localStorage: LocalStorageService,
        protected environmentService: EnvironmentService,
    ) {
        this.webBaseUrl = environmentService.webUrl;
    }

    /**
     * Generates a share link
     * @private
     * @return {Promise<IShareLinkCreateResponse>}
     */
    protected async generateShareLink(): Promise<string | null> {

        // Check that correct data exists before generating a new ShareLink
        if (!this.causeType || !this.causeId) {
            return null;
        }

        this.loaderService.showLoader();

        const shareLink = await this.apiService.shareLink.create({
            causeType: this.causeType,
            causeId: this.causeId,
            link: this.targetURL,
            guestEmail: this.localStorage.getItem(LOCAL_STORAGE_EMAIL_KEY) || undefined, // we cannot send null
        }).catch((err) => {
            this.sentry.trackIssue(err);
            return { url: null };
        }).finally(() => this.loaderService.hideLoader());

        return shareLink?.url;
    }

    /**
     * Returns a URL to the campaign or charity page
     */
    protected get targetURL() {
        if (!this.causeId) {
            this.sentry.trackIssue('targetURL missing causeId.', {
                causeType: this.causeType,
            });
            return this.webBaseUrl;
        }

        switch (this.causeType) {
            case CAUSE_TYPE.CAMPAIGN:
                return this.webBaseUrl + ROUTE_PATHS.web.campaign.details(this.causeId);
            case CAUSE_TYPE.CHARITY:
                return this.webBaseUrl + ROUTE_PATHS.web.charity.details(this.causeId);
            default:
                this.sentry.trackIssue('targetURL invalid causeType.', {
                    causeType: this.causeType,
                });
                return this.webBaseUrl;
        }
    }

}
