/*
 * payment.model.ts
 * Little Phil
 *
 * Created on 16/5/20
 * Copyright © 2018 Little Phil. All rights reserved.
 */
import { BaseModel } from './_base.model';
import { Campaign } from './campaign.model';
import { Charity } from './charity.model';
import { PAYMENT_STATUS } from '@little-phil/js/lib/common/enums';

export class Payment extends BaseModel {

    public static endpoint = 'payment';

    // Properties
    public readonly status: PAYMENT_STATUS = undefined;
    public readonly amount: number = undefined;
    public readonly chargedAmount: number = undefined;
    public readonly receiverAmount: number = undefined;
    public readonly creditUsed: number = undefined;
    public readonly currency: string = undefined;
    public readonly cardBrand: string = undefined;
    public readonly cardLast4: string = undefined;
    public readonly type: string = undefined;
    public campaign: Campaign = undefined;
    public charity: Charity = undefined;
    public readonly auBecsDebit?: {
        bsbNumber?: string;
        last4?: string;
    } = undefined;

    /**
     * Constructor
     * @param {object} json
     */
    constructor(json: object = {}) {
        super();
        super.init(json);
        this.sortCampaignUpdate();
    }

    protected deserialise() {
        if (typeof this.campaign === 'object' && !(this.campaign instanceof Campaign)) {
            this.campaign = new Campaign(this.campaign);
        }

        if (typeof this.charity === 'object' && !(this.charity instanceof Charity)) {
            this.charity = new Charity(this.charity);
        }
    }

    // TODO - fix this because we need to manually sort in Payment model
    // This will be replaced in PROD-326
    // https://littlephil.atlassian.net/browse/PROD-326
    private sortCampaignUpdate() {
        if (!this.campaign || !this.campaign.updates) {
            return;
        }

        this.campaign.updates = this.campaign.updates.sort((a, b) => a.createdAt > b.createdAt ? -1 : 1);
    }

    /**
     * Returns the background colour for the status badge
     */
    public static getStatusBadgeBackgroundColour(status: PAYMENT_STATUS) {
        switch (status) {
            case PAYMENT_STATUS.SUCCESS:
                return '#7DE7B5';
            case PAYMENT_STATUS.PENDING:
            case PAYMENT_STATUS.PROCESSING:
                return '#FDE580';
            case PAYMENT_STATUS.REFUNDED:
                return '#48B2FF';
            case PAYMENT_STATUS.FAILED:
                return '#F87982';
        }
    }

    /**
     * Returns the background colour for the status badge
     */
    public static getStatusBadgeTextColour(status: PAYMENT_STATUS) {
        switch (status) {
            case PAYMENT_STATUS.SUCCESS:
                return '#203B4C';
            case PAYMENT_STATUS.PENDING:
            case PAYMENT_STATUS.PROCESSING:
                return '#203B4C';
            case PAYMENT_STATUS.REFUNDED:
                return '#FFFFFF';
            case PAYMENT_STATUS.FAILED:
                return '#203B4C';
        }
    }

}
