import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { DashboardContextService } from '../dashboard/contexts/dashboard-context.service';
import { ROUTE_PATHS } from '@little-phil/js/lib/common/constants';
import { USER_ROLE } from '@little-phil/js/lib/common/enums';
import {
    CHARITY_ACTIVATE_PROFILE_QUERY_PARAM,
} from '../dashboard/charity/activate-profile/dashboard-charity-activate-profile-page.component';
import { redirectToCharityActivateProfile } from '../_utils/router.util';
import { getAbsoluteUrlFromActivatedRouteSnapshot } from '../_utils/routes.util';

export function charityDashboardAuthGuard(options?: {
    requireProfile?: boolean
}): CanActivateFn {
    const {
        requireProfile = true,
    } = options || {};

    return async (route) => {
        const dashboardContext = inject(DashboardContextService);

        if (dashboardContext.userRole !== USER_ROLE.CHARITY) {
            return true;
        }

        if (requireProfile) {
            const hasProfile = await checkCharityHasProfile(route);
            if (!hasProfile) {
                return false;
            }
        }

        return true;
    };
}

const checkCharityHasProfile = async (route: ActivatedRouteSnapshot) => {
    const dashboardContext = inject(DashboardContextService);
    const router = inject(Router);

    await dashboardContext.charityPromise;

    const selectedCharity = dashboardContext.selectedCharity;
    if (!selectedCharity) {
        router.navigateByUrl(
            ROUTE_PATHS.web.dashboard.route,
        ).catch(console.error);
        return false;
    }

    const hasValidProfile = (
        !!selectedCharity.displayName &&
        !!selectedCharity.imageUrl
    );
    if (!hasValidProfile) {
        const redirectUrl = getAbsoluteUrlFromActivatedRouteSnapshot(route);
        redirectToCharityActivateProfile(router, true, redirectUrl).catch(console.error);
        return false;
    }

    return true;
};
