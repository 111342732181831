<div class="widget-loader">
    <div
        class="animate-spin-slow aspect-square border-solid border-t-primary border-r-primary border-b-primary border-l-transparent rounded-full"
        [class.h-[16px]]="iconSize === 16"
        [class.h-[24px]]="iconSize === 24"
        [class.h-[44px]]="iconSize === 44"
        [class.h-[88px]]="iconSize === 88"
        [class.border-[1px]]="iconSize === 16"
        [class.border-[2px]]="iconSize === 24"
        [class.border-[4px]]="iconSize === 44"
        [class.border-[6px]]="iconSize === 88"
    ></div>
</div>
