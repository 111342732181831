/*
 * errors.util.ts
 * Little Phil
 *
 * Created on 21/9/20
 * Copyright © 2018 Little Phil. All rights reserved.
 */

import { HttpErrorResponse } from '@angular/common/http';
import { ZodError } from 'zod';

/**
 * Extracts a string from an error which can be used for logging
 * @param error
 */
export const extractErrorMessageFromError = (error: any): string | null => {
    // Try to unwrap zone.js error.
    // https://github.com/angular/angular/blob/master/packages/core/src/util/errors.ts
    if (error && error.ngOriginalError) {
        error = error.ngOriginalError;
    }

    if (typeof error === 'string') {
        return error;
    }

    if (error instanceof HttpErrorResponse) {
        return error.error?.message;
    }

    if (error instanceof ZodError) {
        return error.errors?.[0]?.message || error.message;
    }

    if (error instanceof Error) {
        return error.message;
    }

    return null;
};
