/*
 * colours.js
 * Little Phil
 *
 * Created on 28/10/20
 * Copyright © 2018 Little Phil. All rights reserved.
 */

exports.RELIABLE_BLUE = '#203b4c';
exports.RELIABLE_BLUE_5 = '#f4f5f6';
exports.RELIABLE_BLUE_10 = '#e9ebed';
exports.RELIABLE_BLUE_20 = '#c8ced1';
exports.RELIABLE_BLUE_50 = '#8F9DA6';

exports.OCCASIONAL_ORANGE = '#ff8150';

exports.WHITE = '#ffffff';
