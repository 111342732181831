/*
 * user.model.ts
 * Little Phil
 *
 * Created on 16/5/20
 * Copyright © 2018 Little Phil. All rights reserved.
 */
import { BaseModel } from './_base.model';
import { USER_ROLE } from '@little-phil/js/lib/common/enums';
import { ROUTE_PATHS } from '@little-phil/js/lib/common/constants';

/**
 * Interface describing a linked social login account
 */
export interface ILinkedSocialLoginAccount {
    _id: string;
    provider: string;
    userId: string;
    email: string;
}

export class User extends BaseModel {

    public static endpoint = 'user';

    // Properties
    public readonly email: string = undefined;
    public readonly creditBalance: number = undefined;
    public readonly firstName: string = undefined;
    public readonly intercomHash: string = undefined;
    public readonly hasSetPassword: boolean = undefined;
    public readonly lastName: string = undefined;
    public readonly linkedSocialLoginAccounts: ILinkedSocialLoginAccount[] = undefined;
    public readonly managedCharity: string = undefined;
    public readonly managedCompany: string = undefined;
    public readonly role: USER_ROLE = undefined;
    public readonly sex: string = undefined;
    public readonly phoneNumber: string = undefined;
    public readonly addressLine1: string = undefined;
    public readonly addressLine2: string = undefined;
    public readonly suburb: string = undefined;
    public readonly state: string = undefined;
    public readonly postCode: number = undefined;
    public readonly imageUrl: string = undefined;

    /**
     * Constructor
     * @param {object} json
     */
    constructor(json: object = {}) {
        super();
        if (json['sub']) { // workaround for when User is populated from access token cookie
            json['id'] = json['sub'];
        }
        super.init(json);
    }

    /**
     * Whether the user has email and intercomHash
     */
    public hasIntercomData() {
        return this.id && this.email && this.intercomHash;
    }

    /**
     * Construct the user name to be used by Intercom
     */
    public getIntercomName() {
        if (this.firstName) {
            if (this.lastName) {
                return `${this.firstName} ${this.lastName}`;
            } else {
                return this.firstName;
            }
        } else {
            return undefined;
        }
    }

    public getHomePageUrl() {
        switch (this.role) {
            case USER_ROLE.ADMIN:
            case USER_ROLE.CHARITY:
            case USER_ROLE.COMPANY_MANAGER:
                return ROUTE_PATHS.web.dashboard.route;
            case USER_ROLE.USER:
                return ROUTE_PATHS.web.impact;
            default:
                return ROUTE_PATHS.web.explore;
        }
    }

    /**
     * Getter for checking if the current user is an admin
     */
    public get isAdmin() {
        return this.role === USER_ROLE.ADMIN;
    }

    /**
     * Getter for checking if the current user is a charity manager
     */
    public get isCharity() {
        return this.isAdmin || this.role === USER_ROLE.CHARITY; // TODO remove isAdmin and add hasCharityPermissions
    }

    /**
     * Getter for checking if the current user is a company manager
     */
    public get isCompanyManager() {
        return this.role === USER_ROLE.COMPANY_MANAGER;
    }

}
