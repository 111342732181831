import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { DashboardFormControlComponent } from '../dashboard-form-control/dashboard-form-control.component';

@Component({
    selector: 'app-dashboard-form-select',
    standalone: true,
    imports: [
        CommonModule,
        DashboardFormControlComponent,
        ReactiveFormsModule,
        NgSelectModule,
    ],
    templateUrl: './dashboard-form-select.component.html',
    styleUrls: ['./dashboard-form-select.component.scss'],
})
export class DashboardFormSelectComponent implements OnInit {

    // Required
    @Input() control: FormControl; // The control inside the form
    @Input() name: string;
    @Input() items: any[]; // Array of items to display

    // Optionals
    @Input() placeholder = 'Please select'; // Override parent component to provide default
    @Input() bindLabel: string; // Property to display in label
    @Input() bindValue: string; // Property to emit on change
    @Input() label: string; // The label text that appears above the field
    @Input() labelFontSize: 'xs';
    @Input() details: string;
    @Input() required: boolean;
    @Input() searchable: boolean; // Property to enable/disable searching
    @Input() clearable: boolean; // Property to show/hide the clear/x icon

    ngOnInit() {
        console.assert(this.control, 'DashboardFormSelectComponent control is missing');
        console.assert(this.name, 'DashboardFormSelectComponent name is missing');
        console.assert(this.items, 'DashboardFormSelectComponent items is missing');
    }

}
