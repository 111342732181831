<div
    class="group bg-white rounded overflow-hidden"
    [ngClass]="{
        'shadow': displayShadow,
        'rounded-3': roundCorners,
        'expand-on-hover': expandOnHover,
        'cursor-pointer': causeLink,
    }"
    [routerLink]="causeLink">

    <!-- Cover image -->
    <div class="aspect-hero bg-reliable-blue-5">
        <ng-content select="[heroImageContent]"/>
    </div>

    <div class="py-4 px-3">

        <!-- Name -->
        <h4 class="mb-3 cause-name truncate">
            {{title}}
        </h4>

        <div class="mb-4 d-flex align-items-center justify-content-between">

            <div class="cause-details pe-3">

                <!-- Category -->
                <div
                    *ngIf="categoryName && categoryColour"
                    class="d-flex align-items-end mb-2 min-w-0">
                    <div
                        class="h-[16px] w-[16px] rounded-full me-2"
                        [style.background-color]="categoryColour">
                    </div>
                    <span class="font-size-xs truncate">
                        {{categoryName}}
                    </span>
                </div>

                <!-- Location -->
                <div
                    *ngIf="location"
                    class="d-flex align-items-end mb-2 min-w-0">
                    <i class="icon-16 icon-location-hollow-16 me-2"></i>
                    <span class="font-size-xs truncate">
                        {{location}}
                    </span>
                </div>

                <!-- DGR Info -->
                <div
                    *ngIf="isDGRRegistered && isCountryDGRSupported; else nonDGR"
                    class="d-flex align-items-end cause-details-line">
                    <span class="w-[16px] h-[16px] font-size-xs me-2">
                        {{countryFlag}}
                    </span>
                    <span class="font-size-xs truncate">
                        {{taxDeductibleMessage}}
                    </span>
                </div>

                <!-- Non DGR Template -->
                <ng-template #nonDGR>
                    <div class="d-flex align-items-end cause-details-line">
                        <i class="icon-16 icon-close-hollow-16 me-2"></i>
                        <span class="font-size-xs truncate">
                            Non tax deductible
                        </span>
                    </div>
                </ng-template>

            </div>

            <!-- Charity logo -->
            <div class="relative aspect-square h-[75px] bg-reliable-blue-5 rounded overflow-hidden">
                <ng-content select="[imageContent]"/>
            </div>

        </div>

        <!-- Giving stats -->
        <div class="d-flex justify-content-around">
            <div class="text-center flex-grow-1">
                <h3 class="pb-1">{{amountRaised || 0 | centsToPrettyDollarsCurrency}}</h3>
                <span class="font-size-xs">RAISED</span>
            </div>

            <div class="separator mx-3"></div>

            <div class="text-center flex-grow-1">
                <h3 class="pb-1">{{givers}}</h3>
                <span class="font-size-xs">GIVERS</span>
            </div>
        </div>

    </div>

</div>
