import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LpFormModule } from '../../../_modules/lp-form/lp-form.module';
import { DashboardFormControlComponent } from '../dashboard-form-control/dashboard-form-control.component';
import { FormControl } from '@angular/forms';
import { ALIGN } from 'src/app/_utils/constants.util';

@Component({
    selector: 'app-dashboard-form-input',
    standalone: true,
    imports: [CommonModule, LpFormModule, DashboardFormControlComponent],
    templateUrl: './dashboard-form-input.component.html',
    styleUrls: ['./dashboard-form-input.component.scss'],
})
export class DashboardFormInputComponent implements OnInit {

    // Required
    @Input() control: FormControl; // Instance of the control
    @Input() name: string; // Name used for error messages

    // Optionals
    @Input() placeholder: string; // Placeholder text inside the field
    @Input() label: string; // The label text that appears above the field
    @Input() labelFontSize: 'xs';
    @Input() details: string;
    @Input() type: 'email' | 'text' | 'number' = 'text';
    @Input() required: boolean;
    @Input() autocomplete: string;
    @Input() textAlign: ALIGN;
    @Input() gapX: 0 | 1 | 2;
    @Input() paddingX: 0 | 2;

    public isFocused = false;

    protected readonly ALIGN = ALIGN;

    ngOnInit() {
        console.assert(this.control, 'DashboardFormInputComponent control is missing');
        console.assert(this.control, 'DashboardFormInputComponent name is missing');
    }

    public handleFocus() {
        this.isFocused = true;
    }

    public handleBlur() {
        this.isFocused = false;
    }
}
