/*
 * timezone-interceptor.util.ts
 * Little Phil
 *
 * Created on 1/9/20
 * Copyright © 2018 Little Phil. All rights reserved.
 */

import { Injectable, NgModule } from '@angular/core';
import { Observable } from 'rxjs';
import { HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { localTimezone } from '@little-phil/js/lib/utils/local-timezone';

@Injectable()
export class TimezoneInterceptor implements HttpInterceptor {

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!localTimezone) {
            return next.handle(request);
        }

        return next.handle(request.clone({
            setHeaders: { 'X-Timezone': localTimezone },
        }));
    }
}

/**
 * Module is required to avoid TransferHttpCacheModule from overriding provider in AppModule
 */
@NgModule({
    providers: [{
        provide: HTTP_INTERCEPTORS,
        useClass: TimezoneInterceptor,
        multi: true,
    }],
})
export class TimezoneInterceptorModule {}
