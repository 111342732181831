/*
 * subscription.model.ts
 * Little Phil
 *
 * Created on 16/5/20
 * Copyright © 2018 Little Phil. All rights reserved.
 */
import { BaseModel } from './_base.model';
import { Charity } from './charity.model';

/**
 * Interface describing a subscription plan
 */
interface IPlan {
    id: string;
    currency: string;
    amount: number;
    nickname: string;
    interval: string;
    intervalCount: number;
}

export class Subscription extends BaseModel {

    public static endpoint = 'subscription';

    // Properties
    public readonly nextDue: number = undefined;
    public readonly startDate: number = undefined;
    public readonly customer: string = undefined;
    public readonly plan: IPlan = undefined;
    public readonly amount: number = undefined;
    public readonly paymentMethod: string = undefined;
    public readonly clientSecret: string = undefined;
    public readonly interval: 'week' | 'month' = undefined;
    public readonly intervalCount: number = undefined;

    public charity: Charity = undefined;

    /**
     * Constructor
     * @param {object} json
     */
    constructor(json: object = {}) {
        super();
        super.init(json);
    }

    protected deserialise() {
        if (typeof this.charity === 'object' && !(this.charity instanceof Charity)) {
            this.charity = new Charity(this.charity);
        }
    }

}
