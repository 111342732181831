import { Component, ElementRef, ViewChild, ViewContainerRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../_services/auth.service';
import { DynamicComponentService } from '../../../_services/dynamic-component.service';
import { LoggerService } from '../../../_services/logger.service';
import { ScrollService } from '../../../_services/scroll.service';
import { IntercomService } from '../../../_services/intercom.service';
import { DashboardContextService } from '../../../dashboard/contexts/dashboard-context.service';
import { EnvironmentService } from '../../../_services/environment.service';
import { ROUTE_PATHS } from '@little-phil/js/lib/common/constants';

@Component({
    selector: 'app-dashboard-search-bar',
    standalone: true,
    imports: [CommonModule, FormsModule],
    templateUrl: './dashboard-search-bar.component.html',
    styles: [
        `
            :host {
                display: block;
            }
        `,
    ],
})
export class DashboardSearchBarComponent {

    @ViewChild('searchInput') searchInput: ElementRef<HTMLInputElement>;

    public searchQuery: string;

    constructor(
        private router: Router,
        private logger: LoggerService,
    ) {}

    /**
     * Callback to handle search completion events
     */
    onSearchPressed() {
        if (!this.searchQuery) {
            return;
        }

        this.searchInput?.nativeElement?.blur();

        this.router
            .navigate([ROUTE_PATHS.web.dashboardSearch], {
                queryParams: {
                    query: this.searchQuery,
                },
            })
            .then(() => this.searchQuery = '')
            .catch(this.logger.error);
    }

}
