/*
 * redeemable-credit.model.ts
 * Little Phil
 *
 * Created on 12/1/21
 * Copyright © 2018 Little Phil. All rights reserved.
 */
import { BaseModel } from './_base.model';
import { REDEEMABLE_CREDIT_STATUS } from '@little-phil/js/lib/common/enums';
import { User } from './user.model';

export class RedeemableCredit extends BaseModel {

    public static endpoint = 'credit';

    // Properties
    public readonly code: string = undefined;
    public readonly amount: number = undefined;
    public readonly status: REDEEMABLE_CREDIT_STATUS = undefined;
    public readonly redemptionDate?: Date = undefined;
    public readonly redeemer?: User = undefined;
    public readonly recipient: string = undefined;
    public readonly reason: string = undefined;

    /**
     * Constructor
     * @param {object} json
     */
    constructor(json: object = {}) {
        super();
        super.init(json);
    }

}
