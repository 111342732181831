/*
 * image.util.ts
 * Little Phil
 *
 * Created on 26/10/23
 * Copyright © 2018 Little Phil. All rights reserved.
 */

const type = 'image/jpeg';
const quality = 1;

export const resizeImage = (
    blob: Blob,
    width: number,
    height: number,
): Promise<{ blob: Blob, dataUrl: string }> => {
    if (!document) {
        throw new Error('Image resizing is only available in the browser.');
    } else if (!blob) {
        throw new Error('Unable to resize image blob datUrl is missing.');
    } else if (!width || width <= 0) {
        throw new Error('Unable to resize image as width must be greater than 0.');
    } else if (!height || height <= 0) {
        throw new Error('Unable to resize image as height must be greater than 0.');
    }

    return new Promise((resolve, reject) => {
        createImageBitmap(blob)
            .then(imageBitmap => {
                const canvas = document.createElement('canvas');
                canvas.width = width;
                canvas.height = height;

                const ctx = canvas.getContext('2d');

                // Fill the background with white for transparent images
                // as we convert to JPEG which has no alpha channel and would appear black.
                ctx.fillStyle = '#FFFFFF';
                ctx.fillRect(-1, -1, width + 2, height + 2);

                ctx.drawImage(imageBitmap, -1, -1, width + 2, height + 2);

                canvas.toBlob((data) => {
                    resolve({
                        blob: data,
                        dataUrl: canvas.toDataURL(type, quality),
                    });
                }, type, quality);
            })
            .catch((e) => reject(e));
    });
};
