/*
 * cents-to-dollars-amount.pipe.ts
 * Little Phil
 *
 * Created on 30/6/21
 * Copyright © 2018 Little Phil. All rights reserved.
 */

import { Pipe, PipeTransform } from '@angular/core';
import { DollarAmountPipe } from './dollar-amount.pipe';

@Pipe({
    name: 'centsToDollarsAmount',
})
export class CentsToDollarsAmountPipe extends DollarAmountPipe implements PipeTransform {

    /**
     * Transforms a cent amount into a dollar amount
     */
    transform(
        value: number | string | null | undefined,
        digitsInfo?: string,
        locale?: string,
    ): null;
    transform(
        value: number | string | null | undefined,
        digitsInfo?: string,
        locale?: string,
    ): string | null {
        if (typeof value !== 'number') {
            return null;
        }

        const dollars = value / 100;
        return super.transform(dollars, digitsInfo, locale);
    }

}
