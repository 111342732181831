<div class="container text-center py-md-8 py-7 ">

    <h1 class="mb-7">{{title}}</h1>

    <img class="d-block m-auto fadein oops-image" alt="oops image"
         lazyLoad="https://little-phil-web.s3-ap-southeast-2.amazonaws.com/prod/not-found/LP-FlatCap-Exp-Wow.png,
                       https://little-phil-web.s3-ap-southeast-2.amazonaws.com/prod/not-found/LP-FlatCap-Exp-Wow@2x.png 2x,
                       https://little-phil-web.s3-ap-southeast-2.amazonaws.com/prod/not-found/LP-FlatCap-Exp-Wow@3x.png 3x"
         [useSrcset]="true" lazy-image>

    <p class="mt-7 mb-6" [innerHtml]="text"></p>

    <div class="mb-7 mb-md-8">
        <button class="me-2 rounded-pill btn btn-secondary btn-oops" (click)="backClicked()"
                *ngIf="primaryButtonType === primaryButtonTypeEnum.BACK">Back
        </button>
        <button class="me-2 rounded-pill btn btn-secondary btn-oops" (click)="emailSupportClicked()"
                *ngIf="primaryButtonType === primaryButtonTypeEnum.EMAIL_SUPPORT">Email
        </button>
        <button class="ms-2 rounded-pill btn btn-outline-primary btn-oops" routerLink="">Home</button>
    </div>

    <p class="error-code" *ngIf="errorCode">ERROR CODE: {{errorCode}}</p>
</div>

