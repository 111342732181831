/*
 * base-component.class.ts
 * Little Phil
 *
 * Created on 16/12/20
 * Copyright © 2018 Little Phil. All rights reserved.
 */

import { ROUTE_PATHS } from '@little-phil/js/lib/common/constants';

export abstract class BaseComponent {
    public readonly routePaths = ROUTE_PATHS.web;
}
