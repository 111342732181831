import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-modal-title',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './modal-title.component.html',
    styles: [
        `
            :host {
                display: block;
            }
        `,
    ],
})
export class ModalTitleComponent {}
