import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-modal-header',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './modal-header.component.html',
    styles: [
        `
            :host {
                display: block;
                margin-bottom: 30px;
            }
        `,
    ],
})
export class ModalHeaderComponent {}
