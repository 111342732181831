import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalService } from '../../../_services/modal.service';

@Component({
    selector: 'app-modal-trigger',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './modal-trigger.component.html',
    styles: [
        `
            :host {
                display: block;
            }
        `,
    ],
})
export class ModalTriggerComponent {
    constructor(protected modalService: ModalService) {}
}
