import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SentryService } from './sentry.service';
import { environment } from '../../environments/environment';
import { ENVIRONMENT } from '../_utils/env.util';

// Use a lower timeout for tests to avoid tests from timing out due to an outstanding async request
// TODO PROD-1697 replace this with a MockLoaderService
const MAX_TIMEOUT = environment.env !== ENVIRONMENT.test
    ? 30000
    : 100;

@Injectable({
    providedIn: 'root',
})
export class LoaderService {

    private counter = 0; // stores the number of current show requests
    private timeout;

    public loaderDisplayed = new BehaviorSubject<boolean>(false);


    constructor(private sentryService: SentryService) {}

    /**
     * Displays the loader if there is no show requests
     */
    public showLoader() {
        this.counter++;
        if (this.counter !== 1) {
            return;
        }

        this.timeout = setTimeout(() => {
            this.sentryService.trackIssue(new Error('LoaderService has reached timeout of ' + MAX_TIMEOUT));
            this.counter = 0; // force counter to 0 to ensure loader will be hidden
            this.hideLoader();
        }, MAX_TIMEOUT);

        // This timeout is needed to get Angular and RxJS in sync
        setTimeout(() => {
            this.loaderDisplayed.next(true);
        }, 0);
    }

    /**
     * Hides the loader if the last show request has completed
     */
    public hideLoader() {
        this.counter = Math.max(0, this.counter - 1); // ensure that counter is never lower than 0

        if (this.counter === 0) {
            // Timeout is longer required as all requests have been completed
            clearTimeout(this.timeout);
            this.timeout = null;

            // This timeout is needed to get Angular and RxJS in sync
            setTimeout(() => {
                this.loaderDisplayed.next(false);
            }, 0);
        }

    }

}
