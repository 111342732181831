import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgIconComponent } from '../../svg-icon/svg-icon.component';
import { CROSS_24_SVG } from '../../../_utils/icons-brand.util';
import { ModalService } from '../../../_services/modal.service';

@Component({
    selector: 'app-modal-close',
    standalone: true,
    imports: [CommonModule, SvgIconComponent],
    templateUrl: './modal-close.component.html',
    styles: [
        `
            :host {
                display: block;
            }
        `,
    ],
})
export class ModalCloseComponent {
    constructor(protected modalService: ModalService) {}
}
