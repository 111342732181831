import { Injectable } from '@angular/core';
import { BrowserService } from './browser.service';
import { Intercom } from 'ng-intercom';
import { LoggerService } from './logger.service';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';


@Injectable({
    providedIn: 'root',
})
export class IntercomService {

    private hasBooted: boolean;

    constructor(
        private authService: AuthService,
        private browserService: BrowserService,
        private logger: LoggerService,
        public client: Intercom,
    ) {
        // NOTE: this will first on app launch
        this.authService.isLoggedInObservable.subscribe(this.boot.bind(this));
    }

    public boot() {

        this.logger.verbose('Booting Intercom...');

        // Don't boot if using SSR as Intercom won't be defined globally via the import script
        this.browserService.render(() => {
            if (this.hasBooted) {
                this.logger.verbose('Intercom was already booted - shutting down for a reboot...');
                this.client.shutdown();
            }

            const user = this.authService.getUser();

            if (user && user.hasIntercomData()) {
                this.client.boot({
                    app_id: environment.intercomAppId,
                    user_id: user.id,
                    name: user.getIntercomName(),
                    email: user.email,
                    user_hash: user.intercomHash,
                    web_base_url: environment.webBaseUrl,
                });
            } else {
                this.client.boot({
                    app_id: environment.intercomAppId,
                });
            }

            this.hasBooted = true;
            this.logger.verbose('Intercom boot complete.');
        });

    }
}
