/*
 * social.model.ts.ts
 * Little Phil
 *
 * Created by Shippy on 2/11/18
 * Copyright © 2018 Little Phil. All rights reserved.
 */
export class Social {
    public static readonly data = [
        {
            'icon': 'fa-instagram',
            'url': 'https://www.instagram.com/littlephilorg',
        },
        {
            'icon': 'fa-facebook-f',
            'url': 'https://www.facebook.com/littlephilorg',
        },
        {
            'icon': 'fa-twitter',
            'url': 'https://twitter.com/littlephilorg',
        },
        {
            'icon': 'fa-medium-m',
            'url': 'https://medium.com/littlephilnews',
        },
        {
            'icon': 'fa-youtube',
            'url': 'https://www.youtube.com/littlephil',
        },
        {
            'icon': 'fa-linkedin',
            'url': 'https://www.linkedin.com/company/littlephil',
        },
    ];
}
