<div class="flex flex-col gap-1 items-start max-w-[70%]">
    <div class="bg-reliable-blue-5 rounded-2">
        <pre
            *ngIf="message && !isLoading"
            class="p-2 m-0 [&>a]:underline [&>li]:list-inside [&>ol>li]:list-decimal [&>ul>li]:list-disc [&>ol]:m-0 [&>ul]:m-0"
            [innerHTML]="message"></pre>

        <div class="flex px-2 pb-2 text-reliable-blue-75" *ngIf="isLoading">
            <h1 class="leading-[30px] h-[30px] min-h-[30px] animate-jump">
                .
            </h1>
            <h1 class="leading-[30px] h-[30px] min-h-[30px] animate-jump animation-delay-[125ms]">
                .
            </h1>
            <h1 class="leading-[30px] h-[30px] min-h-[30px] animate-jump animation-delay-[250ms]">
                .
            </h1>
        </div>
    </div>

    <div class="flex items-center gap-1">
        <img
            class="inline-block h-[40px] aspect-square rounded-full bg-reliable-blue-5"
            src="../../../../assets/img/little-phil-icon-yellow.png"
            alt="Little Phil Logo">

        <p
            *ngIf="rating && rating !== 'dislike'"
            class="text-reliable-blue-75">
            Thanks for your feedback!
        </p>

        <p
            *ngIf="rating && rating === 'dislike'"
            class="text-reliable-blue-75 underline cursor-pointer"
            (click)="openIntercom()">
            Chat to a human?
        </p>

        <p
            *ngIf="!rating && message !== 'Loading...'"
            class="text-reliable-blue-75">
            Was this helpful?
        </p>

        <p
            *ngIf="!rating && message !== 'Loading...'"
            class="cursor-pointer"
            (click)="onRatingClick('like')">
            👍
        </p>

        <p
            *ngIf="!rating && message !== 'Loading...'"
            class="cursor-pointer"
            (click)="onRatingClick('dislike')">
            👎
        </p>
    </div>
</div>
