<app-modal-popup #popup id="confirmPopup" (popupClosed)="destroy()">

    <div class="px-4">

        <h2 class="mb-4">{{title}}</h2>

        <p class="body-font" [innerHTML]="message"></p>

        <div class="d-flex gap-2 mt-7">
            <button [class]="positiveActionClasses" (click)="confirm()">{{ positiveActionText }}</button>
            <button class="btn btn-outline-primary" (click)="cancel()">{{ negativeActionText }}</button>
        </div>

    </div>

</app-modal-popup>
