/*
 * share-event.model.ts
 * Little Phil
 *
 * Created on 28/6/23
 * Copyright © 2018 Little Phil. All rights reserved.
 */

/*
 * share-link.model.ts
 * Little Phil
 *
 * Created on 26/6/23
 * Copyright © 2018 Little Phil. All rights reserved.
 */

import { CAUSE_TYPE, SHARE_EVENT_TYPE, SHARE_LINK_TYPE } from '@little-phil/js/lib/common/enums';

import { BaseModel } from './_base.model';
import { Charity } from './charity.model';
import { Campaign } from './campaign.model';
import { environment } from '../../environments/environment';

export class ShareEvent extends BaseModel {

    public static endpoint = 'share-event';

    public readonly email: string = undefined;
    public readonly eventType: SHARE_EVENT_TYPE = undefined;
    public readonly timezone: string = undefined;


    /**
     * Constructor
     * @param {object} json
     */
    constructor(json: object = {}) {
        super();
        super.init(json);
    }

    /**
     * Returns the background colour for the status badge
     */
    public getTypeBadgeText() {
        switch (this.eventType) {
            case SHARE_EVENT_TYPE.DONATION:
                return 'Donation';
            case SHARE_EVENT_TYPE.SIGN_IN:
                return 'Sign in';
            case SHARE_EVENT_TYPE.REGISTER:
                return 'Registered';
            case SHARE_EVENT_TYPE.VIEW:
                return 'View';
        }
    }

    /**
     * Returns the background colour for the status badge
     */
    public getTypeBadgeBackgroundColour() {
        switch (this.eventType) {
            case SHARE_EVENT_TYPE.DONATION:
                return '#7DE7B5';
            case SHARE_EVENT_TYPE.SIGN_IN:
                return '#B9ECFD';
            case SHARE_EVENT_TYPE.REGISTER:
                return '#F8AD6D';
            case SHARE_EVENT_TYPE.VIEW:
                return '#FDE580';
        }
    }

    /**
     * Returns the background colour for the status badge
     */
    public getTypeBadgeTextColour() {
        switch (this.eventType) {
            default:
                return '#203B4C';
        }
    }

}
