/*
 * form.util.ts
 * Little Phil
 *
 * Created on 29/6/21
 * Copyright © 2018 Little Phil. All rights reserved.
 */
import { AbstractControl, AbstractControlOptions, UntypedFormControl, ValidatorFn } from '@angular/forms';

import { isValidABN, isValidTaxId } from '@little-phil/js/lib/utils/business-tax-id';
import { passwordPattern, websitePattern } from '@little-phil/js/lib/utils/regex';
import { CAMPAIGN_STATUS } from '@little-phil/js/lib/common/enums';

import { Campaign } from '../_models/campaign.model';

/**
 * Return a conditional validator for a Form Control
 * https://medium.com/ngx/3-ways-to-implement-conditional-validation-of-reactive-forms-c59ed6fc3325
 */
export const conditionalValidator = (predicate, validator): ValidatorFn => {
    return ((formControl) => {
        if (!formControl.parent) {
            return null;
        }
        if (predicate()) {
            return validator(formControl);
        }
        return null;
    });
};

/**
 * Validates the given control's value to check if it's a valid business tax ID
 */
export const taxIdValidator: ValidatorFn = (control) => {

    // Empty values are valid as the field may not always be required
    // To prevent empty values, Validators.required should also be provided as a validator
    if (!control.value) {
        return null;
    }

    return isValidTaxId(control.value) ? null : {
        taxId: {
            valid: false,
        },
    };

};

/**
 * Validates the given control's value to check if it's a valid ABN
 */
export const abnValidator: ValidatorFn = (control) => {

    // Empty values are valid as the field may not always be required
    // To prevent empty values, Validators.required should also be provided as a validator
    if (!control.value) {
        return null;
    }

    return isValidABN(control.value) ? null : {
        abn: {
            valid: false,
        },
    };

};

/**
 * Validates the given control's value to check if it's a valid password
 */
export const passwordValidator: ValidatorFn = (control) => {

    // Empty values are valid as the field may not always be required
    // To prevent empty values, Validators.required should also be provided as a validator
    if (!control.value) {
        return null;
    }

    return passwordPattern.test(control.value) ? null : {
        custom: 'Please include a minimum of 8 characters, 1 capital and 1 number.',
    };

};

type MatchedControlValueValidator = (controlToMatch: AbstractControl, message: string) => ValidatorFn;

/**
 * Validates the control's value matches the value of the given control
 * @param {AbstractControl} controlToMatch - The control to check the value against
 * @param {string} message - The message to display if the value don't match
 */
export const matchedControlValueValidator: MatchedControlValueValidator = (controlToMatch, message) => (control) => {

    // Empty values are valid as the field may not always be required
    // To prevent empty values, Validators.required should also be provided as a validator
    if (!control.value) {
        return null;
    }

    return control.value === controlToMatch.value ? null : {
        custom: message,
    };

};

/**
 * Validates the given control's value to check it does not contain a url
 */
export const noURLValidator: ValidatorFn = (control) => {

    // Empty values are valid as the field may not always be required
    // To prevent empty values, Validators.required should also be provided as a validator
    if (!control.value) {
        return null;
    }

    return websitePattern.test(control.value) ? { custom: 'This field should not include links.' } : null;

};


type FeaturedCampaignControlValueValidator = (campaign: Campaign) => ValidatorFn;

/**
 * Validates the given control's value to check it does not contain a url
 */
export const featuredCampaignValidator: FeaturedCampaignControlValueValidator = (campaign: Campaign) => (control) => {

    // Empty values are valid as the field may not always be required, and falsy values are always valid
    // To prevent empty values, Validators.required should also be provided as a validator
    if (!control.value || typeof campaign === 'undefined') { // if the campaign is not defined we don't want to return an error
        return null;
    }

    return campaign.status !== CAMPAIGN_STATUS.IN_PROGRESS || !!campaign.active
        ? { custom: 'Only active in progress campaigns can be featured.' }
        : null;

};

/**
 * Array of options (label, value) to pass to lp-form-select components for a Yes/No question with boolean value
 */
export const yesOrNoSelectOptions = [
    {
        label: 'Yes',
        value: true,
    },
    {
        label: 'No',
        value: false,
    },
];

/**
 * Interface describing the structure of a valid formState object
 */
interface IFormStateObject {
    value: any;
    disabled: boolean;
}

/**
 * Wrapper for the creation a new FormControl instance.
 * The use of this wrapper allows us to set an initial value of undefined
 *
 * @param {string | number | boolean | Array<any> | IFormStateObject} formState - Initializes the control with an initial value,
 *        or an object that defines the initial value and disabled state.
 * @param {ValidatorFn | ValidatorFn[] | AbstractControlOptions | null} validatorOrOpts - A synchronous validator function, or an array of
 *        such functions, or an AbstractControlOptions object that contains validation functions and a validation trigger.
 */
export const createFormControl = (formState?: string | number | boolean | Array<any> | IFormStateObject,
                                  validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null) => {
    if (typeof formState === 'object') {
        return new UntypedFormControl(formState, validatorOrOpts);
    } else {
        // Passing the value as part of the formState object allows us to initialise the form control to undefined
        return new UntypedFormControl({ value: formState, disabled: false }, validatorOrOpts);
    }
};
