<app-dashboard-form-control
    [control]="control"
    [name]="name"
    [label]="label"
    [labelFontSize]="labelFontSize"
    [details]="details"
    [required]="required">

    <div
        class="d-flex align-items-center border-[1px] rounded overflow-hidden transition-colors"
        [class.border-reliable-blue]="isFocused"
        [class.border-reliable-blue-25]="!isFocused"
        [class.px-2]="paddingX === 2"
        [class.gap-x-1]="gapX === 1"
        [class.gap-x-2]="gapX === 2">
        <ng-content select="[prefix]"/>
        <input
            (focus)="handleFocus()"
            (blur)="handleBlur()"
            class="py-[14px] px-0 border-0 outline-0 flex-grow-1 bg-transparent disabled:cursor-not-allowed disabled:text-reliable-blue-75"
            [class.text-center]="textAlign === ALIGN.CENTER"
            [class.text-left]="textAlign === ALIGN.LEFT"
            [class.text-right]="textAlign === ALIGN.RIGHT"
            [class.px-2]="gapX !== 0"
            [type]="type"
            [formControl]="control"
            [placeholder]="placeholder || ''"
            [autocomplete]="autocomplete">
        <ng-content select="[suffix]"/>
    </div>

</app-dashboard-form-control>
