/*
 * full-name.pipe.ts
 * Little Phil
 *
 * Created on 24/3/21
 * Copyright © 2018 Little Phil. All rights reserved.
 */

import { Pipe, PipeTransform } from '@angular/core';

import { capitalise, CAPITALISATION_TYPE } from '@little-phil/js/lib/utils/string';
import { User } from '../../../_models/user.model';

@Pipe({
    name: 'fullName',
})
export class FullNamePipe implements PipeTransform {

    /**
     * Return the capitalised full name for the user passed as parameter
     * @param user - The user whose full name to return
     */
    transform(user: User): String {
        const fullName = [user?.firstName, user?.lastName].join(' ').trim();
        return capitalise(fullName, CAPITALISATION_TYPE.WORDS);
    }

}
