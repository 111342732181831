import { Component, Input, OnInit } from '@angular/core';
import { CollapseService } from '../../services/collapse.service';

@Component({
    selector: 'app-collapse-provider',
    templateUrl: './collapse-provider.component.html',
    styleUrls: ['./collapse-provider.component.scss'],
    providers: [CollapseService],
})
export class CollapseProviderComponent implements OnInit {

    @Input() defaultCollapsed = true;

    constructor(private service: CollapseService) {}

    ngOnInit() {
        this.service.collapsed = this.defaultCollapsed;
    }

}
