/*
 * meta.service.ts
 * Little Phil
 *
 * Created on 29/6/21
 * Copyright © 2018 Little Phil. All rights reserved.
 */

import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Environment, EnvironmentService } from './environment.service';
import { Campaign } from '../_models/campaign.model';
import { Charity } from '../_models/charity.model';

@Injectable({
    providedIn: 'root',
})
export class MetaService {

    // TODO - make generic content for whitelabel solution
    constructor(private meta: Meta, private titleService: Title, private environment: EnvironmentService) {
        if (!Environment.isProd) {
            meta.updateTag({ property: 'robots', content: 'noindex' });
        }
    }

    public setup() {
        this.meta.updateTag({ property: 'og:title', content: this.titleService.getTitle() });
        this.meta.updateTag({ property: 'og:url', content: this.environment.webUrl });
        this.meta.updateTag({ property: 'og:type', content: 'website' });
        this.meta.updateTag({ property: 'og:site_name', content: this.titleService.getTitle() });
        this.meta.updateTag({
            property: 'og:description',
            content: `${Environment.isWhitelabel ? 'A' : 'Little Phil is the'} neat new way to give charitably`,
        });
        this.meta.updateTag({
            property: 'og:image',
            content: Environment.isWhitelabel
                ? `https://little-phil-whitelabel.s3.amazonaws.com/${Environment.whitelabelId}/share.png`
                : 'https://little-phil-web.s3-ap-southeast-2.amazonaws.com/prod/littlephil-icon.png',
        });
        this.meta.updateTag({ property: 'twitter:card', content: 'summary' });
    }

    public addMetaData(model: Campaign | Charity) {
        if (model instanceof Campaign) {
            const campaign: Campaign = model;

            this.meta.updateTag({ property: 'og:title', content: `${this.titleService.getTitle()} - ${campaign.name}` });
            this.meta.updateTag({ property: 'og:image', content: campaign.imageUrl });
            this.meta.updateTag({ property: 'og:url', content: `${this.environment.webUrl}/campaign/details/${campaign.id}` });
            this.meta.updateTag({ property: 'twitter:card', content: 'summary_large_image' });

            if (campaign.description) {
                const campaignDesc = campaign.description;
                this.meta.updateTag({
                    property: 'og:description',
                    content: campaignDesc.length > 253 ? campaignDesc.substring(0, 253) + '...' : campaignDesc,
                });
            }
        } else if (model instanceof Charity) {
            const charity: Charity = model;

            this.meta.updateTag({ property: 'og:title', content: `${this.titleService.getTitle()} - ${charity.businessName}` });
            this.meta.updateTag({ property: 'og:image', content: charity.imageUrl });
            this.meta.updateTag({ property: 'og:url', content: `${this.environment.webUrl}/charity/${charity.id}` });
            this.meta.updateTag({ property: 'twitter:card', content: 'summary_large_image' });

            if (charity.description) {
                const charityDesc = charity.description;
                this.meta.updateTag({
                    property: 'og:description',
                    content: charityDesc.length > 253 ? charityDesc.substring(0, 253) + '...' : charityDesc,
                });
            }
        }
    }
}
