<div
    class="form-item__wrapper" [ngClass]="{
    'has-success': isValid(),
    'has-danger': isInvalid(),
    'slim': slim,
    'slimTop': slimTop,
    'form-floating': floatingLabel
}">

    <ng-content></ng-content>

    <label *ngIf="label && !slim" class="d-inline-flex align-items-center" [for]="controlId">
        {{label}}
        <span class="required-star" *ngIf="required"> *</span>
        <i
            appTooltip
            *ngIf="tooltipText" class="ms-1 icon-16 icon-info-orange"
            [text]="tooltipText">
        </i>
    </label>

</div>

<div class="min-line-height" *ngIf="!slim">
    <span
        *ngIf="details && !hasErrors()"
        class="font-size-xs"
        data-sentry-unmask>
        {{details}}
    </span>

    <span
        *ngIf="control && hasErrors()"
        class="font-size-xs text-danger">
        {{getErrorMessage()}}
    </span>
</div>
