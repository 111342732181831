<app-form-item
    [formGroup]="formGroup"
    [control]="control"
    [controlId]="controlId"
    [controlName]="controlName"
    [placeholder]="placeholder"
    [label]="label"
    [details]="details"
    [required]="required"
    [slim]="slim"
    [slimTop]="slimTop">

    <div class="position-relative cursor-pointer" [style.max-width]="maxWidth ? maxWidth + 'px' : '100vw'">
        <div
            class="form-image" (click)="displayUploader()"
            [ngStyle]="{ aspectRatio }"
            [style.background-image]="imageUrl && 'url(' + imageUrl + ')'"
        ></div>

        <p class="add-button text-center" *ngIf="!imageUrl">
            {{placeholder || 'Tap or drop to add an image'}}
            <span *ngIf="addButtonDetails"><br>{{addButtonDetails}}</span>
        </p>

        <div class="absolute top-0 right-0 p-2 z-10" (click)="removeImage($event)">
            <i class="icon-24 icon-close-light" *ngIf="imageUrl && imageUrl !== defaultDisplayImageUrl"></i>
        </div>

        <div
            #overlay
            class="form-image-overlay"
            (click)="displayUploader()"
            (drop)="handleChange($event)"
        ></div>

        <input
            #input
            type="file"
            hidden
            [accept]="accept"
            (change)="handleChange($event)">
    </div>

</app-form-item>

<app-modal-popup
    *ngIf="showModal"
    [dismissOnBackdropClick]="false"
    title="Crop image to best fit"
    size="lg"
    [primaryButton]="{ title: 'Continue', type: 'button' }"
    [dismissButton]="{ title: 'Cancel' }"
    [isLoading]="isResizing"
    (primaryAction)="handleCrop()"
    (popupClosed)="showModal = false">
    <div>
        <img
            #cropper
            class="max-w-full min-h-[200px] max-h-[60vh]"
            [src]="rawImageUrl"
        />
    </div>
</app-modal-popup>
