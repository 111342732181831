/*
 * auth-v2.guard.ts
 * Little Phil
 *
 * Created on 15/5/24
 * Copyright © 2018 Little Phil. All rights reserved.
 */

import { inject, Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateFn } from '@angular/router';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie';

import { USER_ROLE } from '@little-phil/js/lib/common/enums';

import { redirectToLogin } from '../_utils/router.util';
import { AuthService } from '../_services/auth.service';
import { COOKIE_LAST_USER_ROLE_KEY } from '../_utils/constants.util';
import { EnvironmentService } from '../_services/environment.service';
import { DashboardContextService } from '../dashboard/contexts/dashboard-context.service';
import { ROUTE_PATHS } from '@little-phil/js/lib/common/constants';

export function authGuard(options: {
    role?: USER_ROLE,
} = {}): CanActivateFn {
    const {
        role,
    } = options;

    return async (route, state) => {
        const authService = inject(AuthService);
        const cookieService = inject(CookieService);
        const router = inject(Router);

        const user = authService.getUser();
        const isLoggedIn = !!user;
        if (!isLoggedIn) {
            if (role) {
                cookieService.put(COOKIE_LAST_USER_ROLE_KEY, role);
            }
            redirectToLogin(router, state.url);
        }

        return isLoggedIn;
    };
}
