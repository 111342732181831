import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

import { LpTooltipModule } from '../lp-tooltip/lp-tooltip.module';

import { FormCardComponent } from './components/form-card/form-card.component';
import { FormConfirmComponent } from './components/form-confirm/form-confirm.component';
import { FormDateComponent } from './components/form-date/form-date.component';
import { FormFileComponent } from './components/form-file/form-file.component';
import { FormImageComponent } from './components/form-image/form-image.component';
import { FormItemComponent } from './components/form-item/form-item.component';
import { FormInputComponent } from './components/form-input/form-input.component';
import { FormNumberInputComponent } from './components/form-number-input/form-number-input.component';
import { FormSelectComponent } from './components/form-select/form-select.component';
import { FormTextAreaComponent } from './components/form-text-area/form-text-area.component';
import { FormTextAreaCollapsibleComponent } from './components/form-text-area-collapsible/form-text-area-collapsible.component';
import { LpModalModule } from '../lp-modal/lp-modal.module';


@NgModule({
    declarations: [
        FormCardComponent,
        FormConfirmComponent,
        FormDateComponent,
        FormFileComponent,
        FormImageComponent,
        FormItemComponent,
        FormInputComponent,
        FormNumberInputComponent,
        FormSelectComponent,
        FormTextAreaComponent,
        FormTextAreaCollapsibleComponent,
    ],
    imports: [
        CommonModule,
        NgSelectModule,
        FormsModule,
        ReactiveFormsModule,
        LpTooltipModule,
        LpModalModule,
    ],
    exports: [
        FormCardComponent,
        FormConfirmComponent,
        FormDateComponent,
        FormFileComponent,
        FormImageComponent,
        FormItemComponent,
        FormInputComponent,
        FormNumberInputComponent,
        FormSelectComponent,
        FormTextAreaComponent,
        FormTextAreaCollapsibleComponent,
        FormsModule,
        ReactiveFormsModule,
    ],
})
export class LpFormModule {}
