import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormsModule } from '@angular/forms';

@Component({
    selector: 'app-dashboard-form-control',
    standalone: true,
    imports: [CommonModule, FormsModule],
    templateUrl: './dashboard-form-control.component.html',
    styleUrls: ['./dashboard-form-control.component.scss'],
})
export class DashboardFormControlComponent implements OnInit {

    // Required
    @Input() control: FormControl; // Instance of the control
    @Input() name: string; // Name used for error messages

    // Optionals
    @Input() label: string; // The label text that appears above the field
    @Input() labelFontSize: 'xs';
    @Input() details: string;
    @Input() required: boolean;

    ngOnInit() {
        console.assert(this.control, 'DashboardFormInputComponent control is missing');
        console.assert(this.control, 'DashboardFormInputComponent name is missing');
    }

    /**
     * Whether this field is valid
     */
    public isValid() {
        return this.control && this.control.valid && (this.control.dirty || this.control.touched);
    }

    /**
     * Whether this field is invalid
     */
    public isInvalid() {
        return this.control && this.control.invalid && (this.control.dirty || this.control.touched);
    }

    /**
     * Whether this field has errors
     */
    public hasErrors() {
        return this.control && this.control.errors && (this.control.dirty || this.control.touched);
    }

    /**
     * Returns a single error message from the current control's errors
     */
    public getErrorMessage() {
        const errors = this.control.errors;

        if (!errors) {
            return;
        }

        if (errors.required) {
            return `${this.name} is required.`;
        } else if (errors.pattern || errors.taxId) {
            return `${this.name} is invalid.`;
        } else if (errors.min) {
            return `${this.name} must be at least ${errors.min.min}.`;
        } else if (errors.max) {
            return `${this.name} must be at most ${errors.max.max}.`;
        } else if (errors.minlength) {
            return `${this.name} must be at least ${errors.minlength.requiredLength} characters long.`;
        } else if (errors.maxlength) {
            return `${this.name} must be at most ${errors.maxlength.requiredLength} characters long.`;
        } else if (errors.custom) {
            return errors.custom;
        } else {
            return `${this.name} is invalid.`;
        }
    }

}
