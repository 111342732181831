import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { extractErrorMessageFromError } from '../../../_utils/errors.util';
import { IntercomService } from '../../../_services/intercom.service';

@Component({
    selector: 'app-assistant-chat-bot-message',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './assistant-chat-bot-message.component.html',
    styles: [
        `
            :host {
                display: block;
            }
        `,
    ],
})
export class AssistantChatBotMessageComponent {
    @Input() thread: string;

    @Input() set message(message: string) {
        let formattedMessage = message;

        // Parse markdown bold syntax into strong elements
        // e.g. **bold**
        // https://stackoverflow.com/a/73023711
        formattedMessage = formattedMessage.replace(
            /\*\*(.+?)\*\*(?!\*)/g,
            '<strong>$1</strong>',
        );

        // Parse markdown link syntax into link elements
        // e.g. [Create a campaign](https://app.littlephil.org/dashboard/campaign/create)
        // https://davidwells.io/snippets/regex-match-markdown-links
        formattedMessage = formattedMessage.replace(
            /(?:\[(.*?)\])\((.*?)\)/g,
            '<a href="$2" target="_blank">$1</a>',
        );

        // formattedMessage = formattedMessage.replace(
        //     /((http:|https:)[^\s]+[\w])/g,
        //     '<a href="$1" target="_blank">$1</a>',
        // );

        this._message = formattedMessage;
    }

    public rating: 'super-like' | 'like' | 'dislike';

    private _message: string;

    constructor(
        private http: HttpClient,
        private toastr: ToastrService,
        private intercom: IntercomService,
    ) {}

    get message() {
        return this._message;
    }

    get isLoading() {
        return this._message === 'Loading...'
    }

    onRatingClick(rating: 'super-like' | 'like' | 'dislike') {
        this.rating = rating;

        const baseUrl = environment.apiUrl;
        this.http
            .post(`${baseUrl}/v1/assistants/react`, {
                thread: this.thread,
                rating,
                message: this._message,
            })
            .toPromise()
            .catch((err) => {
                this.toastr.error(extractErrorMessageFromError(err));
            });
    }

    openIntercom() {
        this.intercom.client.showNewMessage();
    }
}
