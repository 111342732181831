<div class="row" [formGroup]="form">
    <div class="col-md-6 mt-7">
        <h2 class="mb-6">Public profile</h2>

        <div class="grid md:grid-cols-2 grid-cols-1 gap-x-6 gap-y-7">
            <app-dashboard-form-input
                [control]="form.controls.displayName"
                class="md:col-span-2"
                name="Display name"
                label="Display name"
                placeholder="Little Phil Australia"
                [required]="true"
            />

            <app-dashboard-form-select
                [control]="form.controls.category"
                [items]="categories"
                name="Category"
                label="Category"
                bindLabel="name"
                [required]="true"
            />

            <app-dashboard-form-input
                [control]="form.controls.youtubeUrl"
                name="YouTube URL"
                label="YouTube URL"
                placeholder="youtu.be/VKkKX4yoO0w"
            />

            <app-dashboard-form-input
                [control]="form.controls.slug"
                [gapX]="0"
                [paddingX]="2"
                class="md:col-span-2"
                name="Custom URL"
                label="Custom URL"
                [placeholder]="slugPlaceholder"
            >
                <p prefix>
                    littlephil.org/charity/
                </p>
            </app-dashboard-form-input>

            <app-dashboard-form-text-area
                [control]="form.controls.description"
                class="md:col-span-2"
                name="Description"
                label="Description"
                placeholder="A short explaining how the funds will be used. We recommended length 200-400 characters."
                required="true"
            />

            <div class="md:col-span-2">
                <app-dashboard-form-control
                    [control]="form.controls.displayLocation"
                    name="Location"
                    label="Location"
                    [required]="true">

                    <app-dashboard-form-google-maps
                        [defaultLocation]="initialLocation"
                        (onLocationChange)="handleLocationChange($event)"
                    />

                </app-dashboard-form-control>
            </div>
        </div>

        <h2 class="mt-7 mb-6" *ngIf="isAdmin">Admin</h2>

        <div class="grid md:grid-cols-2 grid-cols-1 gap-x-6 gap-y-7" *ngIf="isAdmin">
            <app-dashboard-form-select
                [control]="form.controls.automaticPayoutsDisabled"
                name="Disable automatic payouts"
                label="Disable automatic payouts"
                [items]="yesOrNoSelectOptions"
                bindValue="value"
                bindLabel="label"
            />

            <app-dashboard-form-select
                [control]="form.controls.isDGRRegisteredOverridden"
                name="Override Charity DGR status"
                label="Override Charity DGR status?"
                [items]="yesOrNoSelectOptions"
                bindValue="value"
                bindLabel="label"
            />

            <app-dashboard-form-select
                *ngIf="!!form.controls.isDGRRegisteredOverridden.value"
                [formGroup]="form"
                [control]="form.controls.isDGRRegistered"
                name="DGR status"
                label="Is the Charity DGR registered?"
                [items]="yesOrNoSelectOptions"
                bindValue="value"
                bindLabel="label"
            />
        </div>

    </div>

    <div class="offset-md-1 col-md-5 mt-7">
        <h2 class="mb-6">Preview</h2>

        <app-charity-card
            [title]="form.controls.displayName.value || 'Campaign title'"
            [location]="form.controls.displayLocation.value || 'Location'"
            [amountRaised]="charity.amountGiven || 0"
            [givers]="charity.uniqueGivers || 0"
            [charityName]="charityName"
            [charityLogoImageUrl]="charityLogoUrl"
            [categoryName]="form.controls.category.value?.name || 'Category'"
            [categoryColour]="form.controls.category.value?.colour || '#e9ebed'"
            [countryCode]="charity.countryCode"
            [isDGRRegistered]="form.controls.isDGRRegistered.value"
            [displayShadow]="true"
            [roundCorners]="true"
        >
            <app-form-image
                heroImageContent
                [formGroup]="form"
                [control]="form.controls.heroImage"
                controlName="Hero image"
                [slim]="true"
                [imageUrl]="heroImageUrl"
                [image]="heroImage"
                [croppedHeight]="720"
                [croppedWidth]="1080"
                (imageChange)="onImageChange($event, 'heroImage')">
            </app-form-image>

            <app-form-image
                imageContent
                [formGroup]="form"
                [control]="form.controls.image"
                controlName="Logo image"
                [slim]="true"
                [imageUrl]="imageUrl"
                [image]="image"
                [croppedHeight]="500"
                [croppedWidth]="500"
                placeholder="Your logo"
                (imageChange)="onImageChange($event, 'image')">
            </app-form-image>
        </app-charity-card>
    </div>

    <div class="mt-7">
        <button
            class="btn btn-secondary d-flex align-items-center gap-2"
            (click)="handleSubmit()">
            <span>{{buttonTitle}}</span>
            <app-svg-icon [svg]="ARROW_RIGHT_SVG"/>
        </button>
    </div>
</div>
