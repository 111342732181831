import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { FormComponent } from '../../../_classes/form-component.class';
import { LoggerService } from '../../../_services/logger.service';
import { ChatMessage } from '../assistant-chat-modal/assistant-chat-modal.component';

@Component({
    selector: 'app-assistant-chat-input',
    standalone: true,
    imports: [CommonModule, ReactiveFormsModule],
    templateUrl: './assistant-chat-input.component.html',
    styles: [
        `
            :host {
                display: block;
            }
        `,
    ],
})
export class AssistantChatInputComponent extends FormComponent implements OnInit {

    @Input() messages: ChatMessage[];

    @Output() newMessage = new EventEmitter<string>();

    public suggestedReplies = [
        'Yes please',
        'No thanks',
        'Explain more',
    ];

    public form: FormGroup<{
        message: FormControl<string>,
    }>;

    constructor(
        protected logger: LoggerService,
        private fb: FormBuilder,
    ) {
        super(logger);
    }

    ngOnInit() {
        this.createForm();
    }

    protected createForm() {
        this.form = this.fb.group({
            message: this.fb.control<string>(null, [Validators.required]),
        });
    }

    handleSubmit() {
        if (!this.validateForm(this.form)) {
            return;
        }

        this.newMessage.emit(this.form.controls.message.value);
        this.form.reset();
    }

    get hideSuggestedReplies() {
        if (this.messages.length < 1) {
            return true;
        }

        let lastBotMessage: ChatMessage = null;
        for (let i = this.messages.length - 1; i > 0; i--) {
            const message = this.messages[i];
            if (message.role === 'system') {
                lastBotMessage = message;
                break;
            }
        }

        if (!lastBotMessage) {
            return true;
        }

        return lastBotMessage.loading;
    }

}
