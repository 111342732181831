<div class="dashboard-container">
    <h1>Profile</h1>

    <div *ngIf="company && form else loader">
        <div class="row" [formGroup]="form" (submit)="handleSubmit()">
            <div class="col-lg-6 mt-7">
                <div class="flex flex-col gap-y-7">
                    <app-dashboard-form-input
                        [control]="form.controls.businessName"
                        name="Company name"
                        label="Company name"
                        placeholder="Little Phil Australia LTD"
                        [required]="true"
                    />

                    <app-dashboard-form-input
                        [control]="form.controls.displayName"
                        name="Display name"
                        label="Display name"
                        placeholder="Little Phil Australia"
                    />

                    <app-dashboard-form-select
                        [control]="form.controls.industry"
                        name="Industry"
                        label="Industry"
                        placeholder="Select your industry"
                        [searchable]="true"
                        [items]="industryItems"
                    />

                    <app-dashboard-form-control
                        [control]="form.controls.displayLocation"
                        name="Location"
                        label="Location">
                        <app-dashboard-form-google-maps
                            [defaultLocation]="initialLocation"
                            (onLocationChange)="handleLocationChange($event)"
                        />
                    </app-dashboard-form-control>

                </div>
            </div>

            <div class="offset-lg-1 col-lg-5 mt-7">
                <h2 class="mb-6">Preview</h2>

                <div class="rounded-lg overflow-hidden shadow">
                    <app-form-image
                        class="aspect-hero"
                        [formGroup]="form"
                        [control]="form.controls.heroImage"
                        controlName="Hero image"
                        [slim]="true"
                        [croppedHeight]="720"
                        [croppedWidth]="1080"
                        [imageUrl]="heroImageUrl"
                        (imageChange)="onImageChange($event, 'heroImage')">
                    </app-form-image>

                    <div class="py-4 px-3 space-y-3">
                        <h3 class="truncate">{{form.controls.displayName.value || 'Display name'}}</h3>

                        <div class="flex items-center justify-between gap-2">
                            <div class="min-w-0 space-y-2">
                                <div class="flex items-center gap-2">
                                    <app-svg-icon [svg]="COMPANIES_SVG"/>
                                    <span class="text-sm truncate">{{form.controls.industry.value || 'Industry'}}</span>
                                </div>

                                <div class="flex items-center gap-2">
                                    <i class="icon-16 icon-location-hollow-16"></i>
                                    <span class="text-sm truncate">{{form.controls.displayLocation.value || 'Location'}}</span>
                                </div>
                            </div>

                            <app-form-image
                                class="w-[75px] rounded overflow-hidden shrink-0"
                                [formGroup]="form"
                                [control]="form.controls.image"
                                controlName="Logo image"
                                [slim]="true"
                                [croppedHeight]="500"
                                [croppedWidth]="500"
                                [imageUrl]="imageUrl"
                                placeholder="Your logo"
                                (imageChange)="onImageChange($event, 'image')">
                            </app-form-image>
                        </div>

                        <div class="grid grid-cols-2">
                            <div class="flex flex-col items-center">
                                <h3>{{company.numberOfEmployees}}</h3>
                                <span class="text-xs uppercase mt-1">Employees</span>
                            </div>

                            <div class="flex flex-col items-center">
                                <h3>${{lpcToPrettyAUD(company.lpcImpact || 0, 0)}}</h3>
                                <span class="text-xs uppercase mt-1">Impact</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="mt-7">
            <button
                class="btn btn-secondary d-flex align-items-center gap-2"
                (click)="handleSubmit()">
                <span>Save</span>
                <app-svg-icon [svg]="ARROW_RIGHT_SVG"/>
            </button>
        </div>
    </div>
</div>

<ng-template #loader>
    <div class="relative min-h-[500px]">
        <app-widget-loader [iconSize]="88"/>
    </div>
</ng-template>
