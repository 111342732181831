import { Injectable } from '@angular/core';
import { BrowserService } from './browser.service';

@Injectable({
    providedIn: 'root',
})
export class SessionStorageService {

    // Object that contains a local version of requested local storage data
    private readonly cache: object;
    private readonly isBrowser: boolean;

    constructor(private browserService: BrowserService) {
        this.cache = {};
        this.isBrowser = browserService.isBrowserPlatform();
    }

    /**
     * Gets the data for the requested key from the cache or from session storage
     * @param {string} key
     * @returns {string}
     */
    public getItem(key: string): string {
        if (!this.isBrowser) {
            return;
        }

        if (!this.cache[key]) {
            this.cache[key] = sessionStorage.getItem(key);
        }

        return this.cache[key];
    }

    /**
     * Updated the cached object and saves the new data to the session storage
     * @param {string} key
     * @param data
     */
    public setItem(key: string, data: any) {
        this.browserService.render(() => {
            this.cache[key] = data;
            sessionStorage.setItem(key, data);
        });
    }

    /**
     * Removes an item from session storage
     * @param key
     */
    public removeItem(key: string) {
        this.browserService.render(() => {
            delete this.cache[key];
            sessionStorage.removeItem(key);
        });
    }

}
