/*
 * card.model.ts
 * Little Phil
 *
 * Created on 16/5/20
 * Copyright © 2018 Little Phil. All rights reserved.
 */
import { BaseModel } from './_base.model';

export class Card extends BaseModel {

    public static endpoint = 'stripe/card';

    // Properties
    public readonly token: string = undefined;
    public readonly brand: string = undefined;
    public readonly last4: string = undefined;

    // Additional properties
    public isSelected: boolean = undefined;

    /**
     * Constructor
     * @param {object} json
     */
    constructor(json: object = {}) {
        super();
        super.init(json);
    }

}
