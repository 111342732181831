import { Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormControl, FormGroup, FormsModule, Validators } from '@angular/forms';
import { LpFormModule } from '../../_modules/lp-form/lp-form.module';
import { FormComponent } from '../../_classes/form-component.class';
import { LoggerService } from '../../_services/logger.service';
import { DashboardFormSelectComponent } from '../../_components/forms/dashboard-form-select/dashboard-form-select.component';
import { DashboardFormInputComponent } from '../../_components/forms/dashboard-form-input/dashboard-form-input.component';
import { CharityCardComponent } from '../../_components/charity-card/charity-card.component';
import { DashboardFormControlComponent } from '../../_components/forms/dashboard-form-control/dashboard-form-control.component';
import {
    DashboardFormGoogleMapsComponent,
    GoogleMapsLocation,
} from '../../_components/forms/dashboard-form-google-maps/dashboard-form-google-maps.component';
import { ARROW_RIGHT_SVG } from '../../_utils/icons-brand.util';
import { SvgIconComponent } from '../../_components/svg-icon/svg-icon.component';
import { COMPANIES_SVG } from '../../_utils/icons-nav.util';
import { Company } from '../../_models/company.model';
import {
    CharityCrudFormComponent,
} from '../_components/charity-crud-form/charity-crud-form.component';
import { WidgetLoaderComponent } from '../../_components/widget-loader/widget-loader.component';
import { ApiService } from '../../_services/api.service';
import { DashboardContextService } from '../contexts/dashboard-context.service';
import { LoaderService } from '../../_services/loader.service';
import { extractErrorMessageFromError } from '../../_utils/errors.util';
import { ToastrService } from 'ngx-toastr';
import { lpcToPrettyAUD } from '@little-phil/js/lib/utils/lp-credit';
import { LPFormData } from '../../_classes/form-data.class';
import { LPFile } from '../../_classes/lp-file.class';
import { z } from 'zod';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-company-profile',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        LpFormModule,
        DashboardFormSelectComponent,
        DashboardFormInputComponent,
        CharityCardComponent,
        DashboardFormControlComponent,
        DashboardFormGoogleMapsComponent,
        SvgIconComponent,
        CharityCrudFormComponent,
        WidgetLoaderComponent,
    ],
    templateUrl: './company-profile.component.html',
    styles: [
        `
            :host {
                display: block;
            }
        `,
    ],
})
export class CompanyProfileComponent extends FormComponent implements OnInit {

    public isSubmitting = false;

    public form: FormGroup<{
        businessName: FormControl<string>,
        displayName: FormControl<string>,
        industry: FormControl<string>,
        displayLocation: FormControl<string>,
        latitude: FormControl<number>,
        longitude: FormControl<number>,
        image: FormControl<string>,
        heroImage: FormControl<string>,
    }>;

    public initialLocation: GoogleMapsLocation;
    public company: Company;
    public imageUrl: string;
    public image: Blob;
    public heroImageUrl: string;
    public heroImage: Blob;

    private api = inject(ApiService);
    private loader = inject(LoaderService);
    private toastr = inject(ToastrService);
    private fb = inject(FormBuilder);
    private context = inject(DashboardContextService);
    private route = inject(ActivatedRoute);
    private router = inject(Router);

    protected readonly ARROW_RIGHT_SVG = ARROW_RIGHT_SVG;
    protected readonly COMPANIES_SVG = COMPANIES_SVG;
    protected readonly lpcToPrettyAUD = lpcToPrettyAUD;
    public readonly industryItems = industryItems;

    constructor(protected logger: LoggerService) {
        super(logger);
    }

    ngOnInit() {
        this.context.companyPromise.then((company) => {
            this.company = company;
            this.createForm();

            this.imageUrl = company.imageUrl;
            this.heroImageUrl = company.heroImageUrl;

            if (company.displayLocation) {
                this.initialLocation = {
                    location: company.displayLocation,
                    lat: company.latitude,
                    lng: company.longitude,
                };
            }
        });
    }

    protected createForm() {
        this.form = this.fb.group({
            businessName: this.fb.control<string>(
                this.company.businessName,
                Validators.required,
            ),
            displayName: this.fb.control<string>(this.company.displayName),
            industry: this.fb.control<string>(
                this.company.industry,
            ),
            displayLocation: this.fb.control<string>(
                this.company.displayLocation,
            ),
            latitude: this.fb.control<number>(
                this.company.latitude,
            ),
            longitude: this.fb.control<number>(
                this.company.longitude,
            ),
            image: this.fb.control<string>(this.company.imageUrl),
            heroImage: this.fb.control<string>(this.company.heroImageUrl),
        });
    }

    public async handleSubmit() {
        try {
            if (this.isSubmitting) {
                return;
            }
            this.isSubmitting = true;

            if (!this.validateForm()) {
                return;
            }

            const formData = LPFormData.from(formSchema.parse(this.form.value), [
                new LPFile(this.image, 'image'),
                new LPFile(this.heroImage, 'heroImage'),
            ]);

            this.loader.showLoader();
            await this.api.company.update(this.company.id, formData);
            await this.context.refreshSelectedCompany();

            const redirectUrl = this.route.snapshot.queryParams[COMPANY_PROFILE_QUERY_PARAM.REDIRECT_URL];
            if (redirectUrl) {
                await this.router.navigateByUrl(redirectUrl);
            }

            this.toastr.success('Your profile has been updated.');
        } catch (err) {
            this.toastr.error(extractErrorMessageFromError(err));
        } finally {
            this.isSubmitting = false;
            this.loader.hideLoader();
        }
    }

    public handleLocationChange(location: GoogleMapsLocation) {
        this.form.controls.displayLocation.setValue(location.location);
        this.form.controls.latitude.setValue(location.lat);
        this.form.controls.longitude.setValue(location.lng);
    }

}

const falseyToNull = (value: unknown) => {
    return !value
        ? null
        : value;
};

const formSchema = z.object({
    businessName: z
        .string({
            required_error: 'Business name is required.',
            invalid_type_error: 'Business name is an invalid type.',
        })
        .min(1, 'Business name must contain at least 1 characters.')
        .max(64, 'Business name must contain at most 64 characters.'),

    displayName: z.preprocess(
        falseyToNull,
        z
            .string({
                required_error: 'Display name is required.',
                invalid_type_error: 'Display name is an invalid type.',
            })
            .min(1, 'Display name must contain at least 1 characters.')
            .max(64, 'Display name must contain at most 64 characters.')
            .nullish(),
    ),

    industry: z.preprocess(
        falseyToNull,
        z
            .string({
                required_error: 'Industry is required.',
                invalid_type_error: 'Industry is an invalid type.',
            })
            .nullish(),
    ),

    displayLocation: z.string({
        required_error: 'Location is required.',
        invalid_type_error: 'Location is an invalid type.',
    }),

    latitude: z.number({
        required_error: 'Latitude is required.',
        invalid_type_error: 'Latitude is an invalid type.',
    }),

    longitude: z.number({
        required_error: 'Longitude is required.',
        invalid_type_error: 'Longitude is an invalid type.',
    }),
});

// Taken from https://support.stripe.com/questions/setting-an-industry-group-when-creating-a-stripe-account
const industryItems = Array.from(
    // Remove any dupes
    new Set([
        // 'Autos (Sales and Service)',
        'Boat Sales',
        'Car Washes',
        'Fuel Dispensers',
        'Towing Services',
        'Truck Stop',

        // 'Building Services',
        'A C And Heating Contractors',
        'Carpentry Contractors',
        'Electrical Contractors',
        'General Contractors',
        'Other Building Services',
        'Special Trade Contractors',
        'Telecom Equipment',
        'Telecom Services',

        // 'Digital Products',
        'Apps',
        'Blogs And Written Content',
        'Books',
        'Games',
        'Music Or Other Media',
        'Other Digital Goods',
        'Software As A Service',

        // 'Education',
        'Business And Secretarial Schools',
        'Child Care Services',
        'Colleges Or Universities',
        'Elementary Or Secondary Schools',
        'Other Educational Services',
        'Vocational Schools And Trade Schools',

        // 'Entertainment And Recreation',
        'Amusement Parks, Carnivals, Or Circuses',
        'Betting Or Fantasy Sports',
        'Event Ticketing',
        'Fortune Tellers',
        'Lotteries',
        'Movie Theaters',
        'Musicians, Bands, Or Orchestras',
        'Online Gambling',
        'Other Entertainment And Recreation',
        'Recreational Camps',
        'Sports Forecasting Or Prediction Services',
        'Tourist Attractions',

        // 'Financial Services',
        'Check Cashing',
        'Collections Agencies',
        'Cryptocurrencies',
        'Currency Exchanges',
        'Digital Wallets',
        'Financial Information And Research',
        'Insurance',
        'Investment Services',
        'Loans Or Lending',
        'Money Orders',
        'Money Services Or Transmission',
        'Other Financial Institutions',
        'Personal Fundraising Or Crowdfunding',
        'Security Brokers Or Dealers',
        'Virtual Currencies',
        'Wire Transfers',

        // 'Food And Drink',
        'Bars And Nightclubs',
        'Caterers',
        'Fast Food Restaurants',
        'Grocery Stores',
        'Other Food And Dining',
        'Restaurants And Nightlife',

        // 'Medical Services',
        'Assisted Living',
        'Chiropractors',
        'Dentists And Orthodontists',
        'Doctors And Physicians',
        'Health And Wellness Coaching',
        'Hospitals',
        'Medical Devices',
        'Medical Laboratories',
        'Medical Organizations',
        'Mental Health Services',
        'Nursing Or Personal Care Facilities',
        'Opticians And Eyeglasses',
        'Optometrists and Ophthalmologists',
        'Osteopaths',
        'Other Medical Services',
        'Personal Fundraising Or Crowdfunding',
        'Podiatrists and Chiropodists',
        'Telemedicine And Telehealth',
        'Veterinary Services',

        // 'Membership Organizations',
        'Charities Or Social Service Organizations',
        'Civic, Fraternal, Or Social Associations',
        'Country Clubs',
        'Other Membership Organizations',
        'Political Organizations',
        'Religious Organizations',

        // 'Personal Services',
        'Counseling Services',
        'Dating Services',
        'Funeral Services',
        'Health And Beauty Spas',
        'Health And Wellness Coaching',
        'Landscaping Services',
        'Laundry Or Cleaning Services',
        'Massage Parlors',
        'Other Personal Services',
        'Photography Studios',
        'Salons Or Barbers',

        // 'Professional Services',
        'Accounting, Auditing, Or Tax Prep',
        'Attorneys And Lawyers',
        'Auto Services',
        'Bail Bonds',
        'Bankruptcy Services',
        'Car RentalsCar Sales',
        'Computer Repair',
        'Consulting',
        'Credit Counseling Or Credit Repair',
        'Debt Reduction Services',
        'Digital Marketing',
        'Employment Agencies',
        'Government Services',
        'Lead Generation',
        'Mortgage Consulting Services',
        'Other Business Services',
        'Other Marketing Services',
        'Printing and Publishing',
        'Protective Or Security Services',
        'Software As A Service',
        'Telemarketing',
        'Testing Laboratories',
        'Utilities',
        'Warranty Services',

        // 'Regulated And Age-Restricted Products',
        // 'Accessories For Tobacco And Marijuana',
        // 'Adult Content Or Services',
        // 'Alcohol',
        // 'Marijuana Dispensaries',
        // 'Marijuana-related Products',
        // 'Pharmacies Or Pharmaceuticals',
        // 'Supplements Or Nutraceuticals',
        // 'Tobacco Or Cigars',
        // 'Vapes, E-cigarettes, E-juice Or Related Products',
        // 'Weapons Or Munitions',

        // 'Retail',
        'Accessories',
        'Antiques',
        'Auto Parts And Accessories',
        'Beauty Products',
        'Clothing And Accessories',
        'Convenience Stores',
        'Designer Products',
        'Flowers',
        'Hardware Stores',
        'Home Electronics',
        'Home Goods And Furniture',
        'Other Merchandise',
        'Shoes',
        'Software',

        // 'Transportation',
        'Airlines And Air Carriers',
        'Commuter Transportation',
        'Courier Services',
        'Cruise Lines',
        'Freight Forwarders',
        'Other Transportation Services',
        'Parking Lots',
        'Ridesharing',
        'Shipping Or Forwarding',
        'Taxis And Limos',
        'Travel Agencies',

        // 'Travel And Lodging',
        'Hotels, Inns, Or Motels',
        'Other Travel And Lodging',
        'Property Rentals',
        'Timeshares',
        'Trailer Parks and Campgrounds',

        // Custom
        'Agriculture',
        'Retail',
    ]),
).sort();

export enum COMPANY_PROFILE_QUERY_PARAM {
    REDIRECT_URL = 'redirectUrl',
}
