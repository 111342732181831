import { Component } from '@angular/core';
import {
    animate,
    keyframes,
    state,
    style,
    transition,
    trigger,
} from '@angular/animations';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';

import { SentryService } from '../../../../_services/sentry.service';

@Component({
    selector: 'app-toastr',
    templateUrl: './toastr.component.html',
    styleUrls: ['./toastr.component.scss'],
    animations: [
        trigger('flyInOut', [
            state('inactive', style({
                opacity: 0,
            })),
            state('removed', style({
                opacity: 0,
            })),
            transition('inactive => active', animate('200ms ease-out', keyframes([
                style({
                    transform: 'translate3d(-100%, 0, 0)',
                    opacity: 0,
                }),
                style({
                    transform: 'none',
                    opacity: 1,
                }),
            ]))),
            transition('active => removed', animate('200ms ease-out', keyframes([
                style({
                    transform: 'translate3d(-100%, 0, 0)',
                    opacity: 0,
                }),
            ]))),
        ]),
    ],
})
export class ToastrComponent extends Toast {

    public readonly iconClass: string;

    constructor(
        private sentry: SentryService,
        protected toastrService: ToastrService,
        public toastPackage: ToastPackage,
    ) {
        super(toastrService, toastPackage);

        switch (toastPackage.toastType) {
            case 'lp-toast-error':
                this.iconClass = 'icon-error-toast';
                this.title = this.title ?? 'Oops! Something went wrong.';
                break;
            case 'lp-toast-info':
                this.iconClass = 'icon-info-toast';
                this.title = this.title ?? 'By the way...';
                break;
            case 'lp-toast-success':
                this.iconClass = 'icon-success-toast';
                this.title = this.title ?? 'Success!';
                break;
            case 'lp-toast-warning':
                this.iconClass = 'icon-warning-toast';
                this.title = this.title ?? 'Just a heads up...';
                break;
            default:
                sentry.trackIssue('Invalid "toastType" provided.', {
                    type: toastPackage.toastType,
                    title: toastPackage.title,
                    message: toastPackage.message,
                });
                break;
        }
    }

}
