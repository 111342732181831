/*
 * retry-after-interceptor.util.ts
 * Little Phil
 *
 * Created on 29/7/20
 * Copyright © 2018 Little Phil. All rights reserved.
 */
import { Injectable, NgModule } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, delay } from 'rxjs/operators';
import {
    HTTP_INTERCEPTORS,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest, HttpResponseBase,
} from '@angular/common/http';

@Injectable()
/**
 * Interceptor to catch 'Retry-After' response header and retry the request after the time specified by the remote server
 */
export class RetryAfterInterceptor implements HttpInterceptor {

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(error => {
            if (error instanceof HttpResponseBase) {
                if (error.headers.has('Retry-After')) {
                    const retryAfterHeaderValue = error.headers.get('Retry-After');
                    const retryDurationInSeconds = parseInt(retryAfterHeaderValue, 10);
                    if (!isNaN(retryDurationInSeconds)) {
                        const retryRequest = request.clone({
                            setHeaders: { 'X-Retried-After': retryAfterHeaderValue },
                        });
                        return next.handle(retryRequest).pipe(delay(retryDurationInSeconds));
                    }
                }
            }
            return throwError(error);
        }));
    }

}

/**
 * Module is required to avoid TransferHttpCacheModule from overriding provider in AppModule
 */
@NgModule({
    providers: [{
        provide: HTTP_INTERCEPTORS,
        useClass: RetryAfterInterceptor,
        multi: true,
    }],
})
export class RetryAfterInterceptorModule {}
