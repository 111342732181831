import { Injectable } from '@angular/core';
import {
    addBreadcrumb,
    captureException,
    Event,
    init,
    Integrations,
    Replay,
    showReportDialog,
} from '@sentry/browser';
import { Integrations as TracingIntegrations } from '@sentry/tracing';
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations';
import { HttpErrorResponse } from '@angular/common/http';

import { BrowserService } from './browser.service';
import { LoggerService } from './logger.service';
import { ENVIRONMENT } from '../_utils/env.util';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';
import { ISentryService } from '../_interfaces/sentry.interface';

@Injectable({
    providedIn: 'root',
})
export class SentryService implements ISentryService {

    public readonly showReportDialog; // required for SentryErrorHandler

    constructor(
        private browserService: BrowserService,
        private logger: LoggerService,
        private authService: AuthService,
    ) {

        init({
            dsn: 'https://c5b595e125344e86850d49f0474541ba@o414693.ingest.sentry.io/5304631',
            debug: environment.env !== ENVIRONMENT.production,
            environment: environment.env,
            release: environment.env,
            normalizeDepth: 10,
            replaysOnErrorSampleRate: 1.0,
            replaysSessionSampleRate: 0.0,

            integrations: environment.env === ENVIRONMENT.local || environment.env === ENVIRONMENT.test ? [] : [
                // TryCatch has to be configured to disable XMLHttpRequest wrapping, as we are going to handle
                // http module exceptions manually in Angular's ErrorHandler and we don't want it to capture the same error twice.
                new Integrations.TryCatch({
                    XMLHttpRequest: false,
                }),

                new CaptureConsoleIntegration({
                    // array of methods that should be captured
                    // defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']
                    levels: ['error'],
                }),

                // https://docs.sentry.io/platforms/javascript/session-replay/
                new Replay(),

                new TracingIntegrations.BrowserTracing(),
            ],

            /**
             * trace 100% of the collected transactions for non-local and non-test environments
             * Note: this value should be reduce once site traffic increases
             */
            tracesSampler: () => {
                return true;
            },

            /**
             * Intercept events and ignore certain browsers
             * @param event
             */
            beforeSend(event: Event): PromiseLike<Event | null> | Event | null {
                if (navigator?.userAgent?.includes('HeadlessChrome')) {
                    return null;
                }

                return event;
            },

            ignoreErrors: [
                'ResizeObserver loop limit exceeded', // https://littlephil.atlassian.net/browse/PROD-1073?focusedCommentId=10628
                /^ReferenceError/, // ignore ReferenceErrors as they can be thrown outside our application via browser console
            ],
        });

        this.showReportDialog = showReportDialog;

    }

    /**
     * Manually log/track an issue in Sentry
     * @param exception - Exception An exception-like object
     * @param [extras] - Optional additional information for providing further details
     */
    public trackIssue(exception: string | Error, extras?: Record<string, any>) {

        let error = this.extractError(exception) || 'Handled unknown error';

        if (typeof error === 'string') {
            error = new Error(error);
        }

        if (environment.env !== ENVIRONMENT.local && environment.env !== ENVIRONMENT.test) {
            captureException(error, this.generateSentryExtraData(extras));
        } else {
            this.logger.verbose('Skipping Sentry tracking due to environment');
            this.logger.error(exception, extras);
        }
    }

    /**
     * Extracts a string from an error which will be used for logging
     * @param error - The input error
     */
    public extractError(error: any) {
        // Try to unwrap zone.js error.
        // https://github.com/angular/angular/blob/master/packages/core/src/util/errors.ts
        if (error?.ngOriginalError) {
            error = error.ngOriginalError;
        }

        // We can handle messages and Error objects directly.
        if (typeof error === 'string' || error instanceof Error) {
            return error;
        }

        // If it's http module error, extract as much information from it as we can.
        if (error instanceof HttpErrorResponse) {
            // The `error` property of http exception can be either an `Error` object, which we can use directly...
            if (error.error instanceof Error) {
                return error.error;
            }

            // ... or an `Object`, which can provide us with the message but no stack...
            if (error.error instanceof Object && error.error.message) {
                return error.error.message;
            }

            // ...or the request body itself, which we can use as a message instead.
            if (typeof error.error === 'string') {
                return `Server returned code ${error.status} with body "${error.error}"`;
            }

            // If we don't have any detailed information, fallback to the request message itself.
            return error.message;
        }

        if (typeof error === 'object' && 'message' in error) {
            return error.message;
        }

        // Skip if there's no error, and let user decide what to do with it.
        return null;
    }

    /**
     * Generate extra data to pass to Sentry
     *
     * @private
     * @param [extras] - Optional additional information for providing further details
     */
    private generateSentryExtraData(extras?: Record<string, any>) {

        const data: Record<string, any> = {
            ssrMode: this.browserService.isServerPlatform(),
        };

        if (extras) {
            data.extras = extras;
        }

        if (this.authService.isLoggedIn) {
            data.user = this.authService.getUser();
        }

        return data;

    }

    /**
     * Adds a manual breadcrumb to tracked Sentry errors
     * @param category
     * @param message
     */
    public addBreadcrumb(category, message) {
        addBreadcrumb({
            category,
            message,
            level: 'info',
        });
    }

}
