<nav class="navbar navbar-expand py-5"
     [class.navbar-light]="!environment.darkModeNav"
     [class.navbar-dark]="environment.darkModeNav"
     [style.background-color]="backgroundColour">
    <div class="container">
        <a class="navbar-brand" routerLink="/">
            <img src="../../../assets/img/little-phil-logo.svg" alt="Main logo" *ngIf="!isWhitelabel">
            <img src="../../../assets/img/navbar.png" alt="Main logo" *ngIf="isWhitelabel"
                 onerror="this.src = '../../../assets/img/little-phil-logo.svg'">
        </a>

        <div class="flex-grow-1"></div>

        <button
            *ngIf="showButton"
            [class.btn-outline-light]="environment.darkModeNav"
            [class.btn-outline-dark]="!environment.darkModeNav"
            class="btn btn-outline-primary rounded-pill ms-auto"
            (click)="buttonClicked.emit()">
            {{buttonText}}
        </button>

        <div *ngIf="isLoggedIn" class="me-3">
            <app-credit-balance [balance]="user?.creditBalance"></app-credit-balance>
        </div>

        <div class="d-flex align-items-center" *ngIf="isLoggedIn">
            <app-user-avatar/>
        </div>
    </div>
</nav>
