<div
    #background
    class="fixed inset-0 flex flex-wrap overflow-y-auto overflow-x-hidden items-center justify-center px-3 py-6 bg-[#00000080] opacity-0 transition-opacity z-modal overscroll-contain"
    [class.cursor-pointer]="dismissOnBackdropClick"
    [class.opacity-1]="isOpen"
    [class.opacity-0]="!isOpen"
    (click)="dismissPopup($event)">

    <div
        class="relative p-md-6 p-3 overflow-scroll w-full rounded-3 cursor-auto"
        [class.max-w-[400px]]="size === 'sm'"
        [class.max-w-[600px]]="size === 'md'"
        [class.max-w-[800px]]="size === 'lg'"
        [class.max-w-[1000px]]="size === 'xl'"
        [ngStyle]="{'background-color': modalContentBackgroundColor}">

        <div *ngIf="isLoading" class="absolute inset-0 bg-white z-50">
            <app-widget-loader [iconSize]="88"/>
        </div>

        <button
            #closeButton
            class="absolute top-6 right-6"
            type="button"
            (click)="dismissPopup($event)">
            <app-svg-icon #closeButtonIcon [svg]="CROSS_24_SVG"/>
        </button>

        <h2 class="mb-md-6 mb-6">{{title}}</h2>

        <ng-content></ng-content>

        <div class="mt-md-6 mt-3" *ngIf="primaryButton || dismissButton">
            <div class="flex justify-end gap-2">
                <button
                    #dismissButtonElement
                    *ngIf="dismissButton"
                    class="btn btn-outline-primary"
                    type="button"
                    (click)="dismissPopup($event)">
                    {{dismissButton.title}}
                </button>

                <button
                    *ngIf="primaryButton"
                    class="btn btn-secondary"
                    [type]="primaryButton.type"
                    (click)="primaryAction.emit()">
                    {{primaryButton.title}}
                </button>
            </div>
        </div>

    </div>
</div>
