import { Component, Input, OnDestroy } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-form-item',
    templateUrl: './form-item.component.html',
    styleUrls: ['./form-item.component.scss'],
})
export class FormItemComponent implements OnDestroy {

    // Required
    @Input() control: AbstractControl; // The control inside the form
    @Input() controlId: string; // The unique ID of the control
    @Input() controlName: string; // The name of the field in sentence case eg. First name

    /**
     * Watches the form group state to call the reset method when the form group is reset
     *
     * @param {FormGroup} formGroup - The form item form group
     */
    @Input() set formGroup(formGroup: UntypedFormGroup) { // The form which this control belongs to
        this._formGroup = formGroup;

        if (this.formGroup) {
            this.formGroupSubscription = this.formGroup.statusChanges.subscribe(() => {
                // reset our form item if the form was reset
                setTimeout(() => {
                    if (this.formGroup?.pristine) {
                        this.reset();
                    }
                }, 0);
            });
        } else {
            this.formGroupSubscription?.unsubscribe();
        }
    }

    // Optionals
    @Input() placeholder: string; // Placeholder text inside the field
    @Input() label: string; // The label text that appears above the field
    @Input() floatingLabel: boolean; // Boolean for enabling Bootstrap's floating labels
    @Input() disabled: boolean; // Boolean for disabling the field
    @Input() required: boolean; // Boolean for adding a star next to the label
    @Input() details: string; // Text that sits below the input
    @Input() slim: boolean; // Hides the label, details and error elements when true
    @Input() slimTop: boolean; // Hides the label, details but not the error elements when true
    @Input() tooltipText: string; // Displays a tooltip next to the label with this text
    @Input() tooltipClass: string; // Classes to be passed to the tooltip
    @Input() tabIndex: number; // Tab index of the input field

    private _formGroup: UntypedFormGroup;
    private formGroupSubscription?: Subscription;

    ngOnDestroy() {
        this.formGroupSubscription?.unsubscribe();
    }


    /**
     * Returns the form group
     */
    get formGroup() {
        return this._formGroup;
    }

    /**
     * Whether this field is valid
     */
    public isValid() {
        return this.control && this.control.valid && (this.control.dirty || this.control.touched);
    }

    /**
     * Whether this field is invalid
     */
    public isInvalid() {
        return this.control && this.control.invalid && (this.control.dirty || this.control.touched);
    }

    /**
     * Whether this field has errors
     */
    public hasErrors() {
        return this.control && this.control.errors && (this.control.dirty || this.control.touched);
    }

    /**
     * Returns a single error message from the current control's errors
     */
    public getErrorMessage() {
        const errors = this.control.errors;

        if (!errors) {
            return;
        }

        if (errors.required) {
            return `${this.controlName} is required.`;
        } else if (errors.pattern || errors.taxId) {
            return `${this.controlName} must be valid.`;
        } else if (errors.min) {
            return `${this.controlName} must be at least ${errors.min.min}.`;
        } else if (errors.max) {
            return `${this.controlName} must be at most ${errors.max.max}.`;
        } else if (errors.minlength) {
            return `${this.controlName} must be at least ${errors.minlength.requiredLength} characters long.`;
        } else if (errors.maxlength) {
            return `${this.controlName} must be at most ${errors.maxlength.requiredLength} characters long.`;
        } else if (errors.custom) {
            return errors.custom;
        } else {
            return `${this.controlName} is invalid.`;
        }
    }

    /**
     * Reset the input value
     * Need to be extended by sub classes
     */
    public reset() {}
}
