import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    CharityCrudFormComponent,
    CharityCrudFormData,
    CharityEditAdminFormData,
} from '../../_components/charity-crud-form/charity-crud-form.component';
import { WidgetLoaderComponent } from '../../../_components/widget-loader/widget-loader.component';
import { Charity } from '../../../_models/charity.model';
import { Category } from '../../../_models/category.model';
import { LoggerService } from '../../../_services/logger.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../../_services/api.service';
import { LoaderService } from '../../../_services/loader.service';
import { ToastrService } from 'ngx-toastr';
import { SentryService } from '../../../_services/sentry.service';
import { ROUTE_PATHS } from '@little-phil/js/lib/common/constants';
import { extractErrorMessageFromError } from '../../../_utils/errors.util';
import { LPFormData } from '../../../_classes/form-data.class';
import { LPFile } from '../../../_classes/lp-file.class';
import { DashboardContextService } from '../../contexts/dashboard-context.service';
import { LpModalModule } from '../../../_modules/lp-modal/lp-modal.module';
import { ModalPopupComponent } from '../../../_modules/lp-modal/components/modal-popup/modal-popup.component';
import { ARROW_RIGHT_SVG, COPY_16_SVG, EXTERNAL_16_ARROW } from '../../../_utils/icons-brand.util';
import { SvgIconComponent } from '../../../_components/svg-icon/svg-icon.component';
import { environment } from '../../../../environments/environment';
import { ClipboardService } from '../../../_services/clipboard.service';
import { ModalComponent } from '../../../_components/modal/modal/modal.component';
import { ModalContentComponent } from '../../../_components/modal/modal-content/modal-content.component';
import { ModalTriggerComponent } from '../../../_components/modal/modal-trigger/modal-trigger.component';
import { ModalHeaderComponent } from '../../../_components/modal/modal-header/modal-header.component';
import { ModalTitleComponent } from '../../../_components/modal/modal-title/modal-title.component';
import { ModalFooterComponent } from '../../../_components/modal/modal-footer/modal-footer.component';
import { AuthService } from '../../../_services/auth.service';

@Component({
    selector: 'app-dashboard-charity-activate-profile-page',
    standalone: true,
    imports: [
        CommonModule,
        CharityCrudFormComponent,
        WidgetLoaderComponent,
        LpModalModule,
        SvgIconComponent,
        ModalComponent,
        ModalContentComponent,
        ModalTriggerComponent,
        ModalHeaderComponent,
        ModalTitleComponent,
        ModalFooterComponent,
    ],
    templateUrl: './dashboard-charity-activate-profile-page.component.html',
    styles: [
        `
            :host {
                display: block;
            }
        `,
    ],
})
export class DashboardCharityActivateProfilePageComponent implements OnInit {

    @ViewChild('activateModal') activateModal: ModalPopupComponent;
    @ViewChild('continueModal') continueModal: ModalPopupComponent;

    public charity: Charity;
    public categories: Category[];

    public imageUrl: string;
    public image: File;
    public isAdmin: boolean;
    public profileUrl: string;
    public showActivateModal = false;
    public showContinueModal = false;

    private id: string;

    protected readonly ARROW_RIGHT_SVG = ARROW_RIGHT_SVG;
    protected readonly COPY_16_SVG = COPY_16_SVG;
    protected readonly EXTERNAL_16_ARROW = EXTERNAL_16_ARROW;

    constructor(
        public dashboardContext: DashboardContextService,
        protected logger: LoggerService,
        private router: Router,
        private apiService: ApiService,
        private authService: AuthService,
        private loaderService: LoaderService,
        private route: ActivatedRoute,
        private toastr: ToastrService,
        private sentry: SentryService,
        private clipboard: ClipboardService,
    ) {
        this.id = this.route.snapshot.params.id;
    }

    ngOnInit() {
        this.showActivateModal = this.route.snapshot.queryParams[CHARITY_ACTIVATE_PROFILE_QUERY_PARAM.ACTIVATE_MODAL] === 'true';

        Promise.all([
            this.loadCharity(),
            this.loadCategories(),
        ])
            .catch((err) => {
                this.logger.error(err);
                this.router.navigateByUrl(ROUTE_PATHS.web.dashboard.route).catch(this.logger.error);
            });
    }

    private async loadCharity() {
        try {
            await this.dashboardContext.charityPromise;
            this.id = this.dashboardContext.selectedCharity.id;
            this.charity = await this.apiService.charity.retrieve(this.id);
            this.imageUrl = this.charity?.imageUrl;
        } catch (error) {
            this.toastr.error(extractErrorMessageFromError(error));
            this.sentry.trackIssue(error);
        }
    }

    private async loadCategories() {
        try {
            this.categories = await this.apiService.category.list();
        } catch (error) {
            this.toastr.error(extractErrorMessageFromError(error));
            this.sentry.trackIssue(error);
        }
    }

    public handleSubmit(data: {
        data: CharityCrudFormData | CharityEditAdminFormData,
        image: Blob,
        heroImage: Blob,
    }) {
        if (!data.image && !this.charity.imageUrl) {
            this.toastr.error('A logo image is required for your public profile.');
            return;
        } else if (!data.heroImage && !this.charity.heroImageUrl) {
            this.toastr.error('A hero image is required for your public profile.');
            return;
        }

        const formData = LPFormData.from(data.data, [
            new LPFile(data.image, 'image'),
            new LPFile(data.heroImage, 'heroImage'),
        ]);

        this.loaderService.showLoader();
        this.apiService.charity.update(this.id, formData)
            .then(() => {
                this.toastr.success('Your profile has been successfully activated.');
                return this.dashboardContext.refreshSelectedCharity();
            })
            .then(() => {
                const identifier = this.dashboardContext.selectedCharity?.slug || this.dashboardContext.selectedCharity?.id;
                this.profileUrl = environment.webBaseUrl + ROUTE_PATHS.web.charity.details(identifier);
                this.showContinueModal = true;
            })
            .catch((error) => {
                this.toastr.error(extractErrorMessageFromError(error));
                this.sentry.trackIssue(error);
            })
            .finally(() => this.loaderService.hideLoader());
    }

    public navigateToKyc() {
        if (!this.id) {
            return;
        }

        // force-refresh the token because we're going out directly to the backend, and our token could be expired at this point in time
        // if that was the case, the browser would display error JSON
        this.loaderService.showLoader();
        this.authService.refreshAccessToken().subscribe(() => {
            this.apiService.charity.kyc(
                this.id,
                environment.webBaseUrl + ROUTE_PATHS.web.dashboard.route,
            );

            // manually remove loader after set time as there is no way to monitor if the rediret to Stripe was successful
            setTimeout(() => {
                this.loaderService.hideLoader();
                this.toastr.error('Sorry, we were unable to redirect you to Stripe. Please try again.');
            }, 5000);
        });
    }

    public navigationToProfile() {
        window.open(this.profileUrl, '_blank');
    }

    public copyProfileLink() {
        this.clipboard.copyToClipboard(this.profileUrl).then(() => {
            this.toastr.success('Copied to clipboard');
        }).catch((err) => {
            this.logger.error(err);
            this.toastr.error('The clipboard is not accessible with your current browser, please manually copy the text.');
        });
    }

}

export enum CHARITY_ACTIVATE_PROFILE_QUERY_PARAM {
    ACTIVATE_MODAL = 'activateModal',
    REDIRECT_URL = 'redirectUrl',
}
