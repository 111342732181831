import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AssistantChatBotMessageComponent } from '../assistant-chat-bot-message/assistant-chat-bot-message.component';
import { AssistantChatUserMessageComponent } from '../assistant-chat-user-message/assistant-chat-user-message.component';
import { ChatMessage } from '../assistant-chat-modal/assistant-chat-modal.component';
import { threadId } from 'worker_threads';

@Component({
    selector: 'app-assistant-chat-history',
    standalone: true,
    imports: [CommonModule, AssistantChatBotMessageComponent, AssistantChatUserMessageComponent],
    templateUrl: './assistant-chat-history.component.html',
    styles: [
        `
            :host {
                display: block;
            }
        `,
    ],
})
export class AssistantChatHistoryComponent {

    @ViewChild('chatContainer') private chatContainer: ElementRef;

    @Input() thread: string;

    @Input() set messages(messages: ChatMessage[]) {
        this._messages = messages;
        this.scrollToBottom();
    }

    @Output() samplePromptClicked = new EventEmitter<string>();

    public samplePrompts = [
        'How do I receive payouts?',
        'Create Facebook content',
        'Create blog content',
        'How do I launch a campaign?',
        'Best ways to promote my fundraising campaign?',
        'How do I get DGR status?',
        'Help me fundraise',
        'How often do I need to report to the ACNC?',
    ];

    private _messages: ChatMessage[] = [];

    private scrollToBottom(): void {
        try {
            // allow render to occur before attempting to scroll
            queueMicrotask(() => {
                this.chatContainer.nativeElement.scrollTop = this.chatContainer.nativeElement.scrollHeight;
            });
        } catch (err: any) { }
    }

    get messages() {
        return this._messages;
    }
}
