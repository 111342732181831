/*
 * guest.guard.ts
 * Little Phil
 *
 * Created on 2/7/21
 * Copyright © 2018 Little Phil. All rights reserved.
 */

import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ROUTE_PATHS } from '@little-phil/js/lib/common/constants';
import { AuthService } from '../_services/auth.service';


@Injectable({
    providedIn: 'root',
})
export class GuestGuard implements CanActivate {

    constructor(private authService: AuthService, private router: Router) {}

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (this.authService.isLoggedIn) {
            this.router.navigateByUrl(ROUTE_PATHS.web.impact).catch(console.error);
            return false;
        }

        return true;
    }

}
