/*
 * log-level.util.ts
 * Little Phil
 *
 * Created on 26/8/20
 * Copyright © 2018 Little Phil. All rights reserved.
 */

/**
 * Enum of possible log levels for the parent module
 */
export enum LOG_LEVEL {
    error = 1,
    info = 2,
    http = 3,
    verbose = 4,
}
