import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { OCCASIONAL_ORANGE } from '@little-phil/frontend/js/common/colours';
import { DisableSSR } from '../../../../_decorators/disable-ssr.decorator';
import { BrowserService } from '../../../../_services/browser.service';

@Component({
    selector: 'app-progress-bar',
    templateUrl: './progress-bar.component.html',
    styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent implements OnChanges {

    // Required
    @Input() min: number;
    @Input() max: number;
    @Input() now: number;

    // Optional
    @Input() animated: boolean;
    @Input() barColour: string = OCCASIONAL_ORANGE;
    @Input() rounded: boolean;

    public width = 0;

    constructor(public browserService: BrowserService) {}

    ngOnChanges(changes: SimpleChanges) {
        if (
            typeof this.min === 'number' &&
            typeof this.max === 'number' &&
            typeof this.now === 'number'
        ) {
            const fraction = (this.now - this.min) / (this.max - this.min);
            let percent = Math.floor(fraction * 100);

            if (percent > 100) {
                percent = 100;
            } else if (percent < 0) {
                percent = 0;
            }

            if (this.width !== percent) {
                this.width = percent;
            }
        }
    }

}
