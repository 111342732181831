/*
 * admin.guard.ts
 * Little Phil
 *
 * Created on 2/7/21
 * Copyright © 2018 Little Phil. All rights reserved.
 */

import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie';

import { USER_ROLE } from '@little-phil/js/lib/common/enums';

import { redirectToLogin } from '../_utils/router.util';
import { AuthService } from '../_services/auth.service';
import { COOKIE_LAST_USER_ROLE_KEY } from '../_utils/constants.util';
import { EnvironmentService } from '../_services/environment.service';


@Injectable({
    providedIn: 'root',
})
export class AdminGuard implements CanActivate {

    constructor(
        private authService: AuthService,
        private cookieService: CookieService,
        protected router: Router,
    ) {}

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        const user = this.authService.getUser();

        if (!user || !user.isAdmin) {
            // set last user role before redirecting to login so correct state is loaded
            this.cookieService.put(COOKIE_LAST_USER_ROLE_KEY, USER_ROLE.ADMIN, {
                path: '/',
                domain: EnvironmentService.cookieDomain,
            });

            redirectToLogin(this.router, state.url);
            return false;
        }

        return true;
    }

}
