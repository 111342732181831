/*
 * in-out.animation.ts
 * Little Phil
 *
 * Created on 8/9/20
 * Copyright © 2018 Little Phil. All rights reserved.
 */
import { animate, style, transition, trigger } from '@angular/animations';

export const ANIMATION_DURATION = 300;
const outStyles = style({
    opacity: 0,
});
const inStyles = style({
    opacity: 1,
});

/**
 * Opacity transition
 */
export const fadeInOutAnimation = trigger(
    'fadeInOutAnimation',
    [
        transition(
            ':enter',
            [
                outStyles,
                animate(`${ANIMATION_DURATION}ms ease-out`, inStyles),
            ],
        ),
        transition(
            ':leave',
            [
                inStyles,
                animate(`${ANIMATION_DURATION}ms ease-in`, outStyles),
            ],
        ),
    ],
);
