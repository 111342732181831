import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-toggle',
    templateUrl: './toggle.component.html',
    styleUrls: ['./toggle.component.scss'],
})
export class ToggleComponent {

    @Input() isChecked: boolean;
    @Output() toggle = new EventEmitter<boolean>();

    // unique ID for each toggle
    public id = 'tgl-' + Math.random().toString(36).substring(2, 5); // String will always start with 0. so start from index 2;

}
