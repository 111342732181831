import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { SvgIconComponent } from '../../../../_components/svg-icon/svg-icon.component';

@Component({
    selector: 'app-nav-bar-menu-item',
    templateUrl: './nav-bar-menu-item.component.html',
    styleUrls: ['./nav-bar-menu-item.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        RouterLink,
        SvgIconComponent,
    ],
})
export class NavBarMenuItemComponent {

    @Input() active: boolean;
    @Input() title: string;
    @Input() iconSvg: string;
    @Input() link: string;
    @Input() slim = false;

    constructor(public router: Router) {}

}
