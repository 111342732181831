<div class="flex items-center gap-2 border-[1px] border-reliable-blue-50 rounded-full cursor-pointer">
    <div class="pl-2">
        <img
            class="h-[30px] aspect-square rounded-full bg-reliable-blue-5"
            src="../../../../assets/img/little-phil-icon-yellow.png"
            alt="Little Phil Logo">
    </div>

    <p class="grow leading-[50px] h-[50px] text-reliable-blue-75 overflow-hidden text-nowrap">
        {{text}}
    </p>
</div>
