"use strict";
/*
 * lp-credit.ts
 * Little Phil
 *
 * NOTE: LP Credit in I/O is always expressed in LPC.
 *  Some functions will convert to cents internally to make calculus easier, but will never accept or return cents as parameters.
 *
 * Created on 10/2/21
 * Copyright © 2018 Little Phil. All rights reserved.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.audToLPC = exports.audCentsToLPC = exports.lpcToPrettyAUD = exports.lpcToAUD = exports.lpcToAUDCents = void 0;
const decimal_js_1 = require("decimal.js");
const { amountToPrettyAmount } = require('./number');
// noinspection SuspiciousTypeOfGuard We may call this function from JS
/**
 * Converts LPC to AUD cents
 * @param lpc - Amount in LPC (=AUD.1)
 */
const lpcToAUDCents = (lpc) => typeof lpc === 'number' && !isNaN(lpc) ? new decimal_js_1.Decimal(lpc).times(10).toNumber() : 0;
exports.lpcToAUDCents = lpcToAUDCents;
// noinspection SuspiciousTypeOfGuard We may call this function from JS
/**
 * Converts LPC to AUD
 * @param lpc - Amount in LPC (=AUD.1)
 */
const lpcToAUD = (lpc) => typeof lpc === 'number' && !isNaN(lpc) ? new decimal_js_1.Decimal(lpc).dividedBy(10).toNumber() : 0;
exports.lpcToAUD = lpcToAUD;
/**
 * Converts LPC to AUD and formats the result for display
 * @param {number} lpc - Amount in LPC (=AUD.1)
 * @param [number] decimalPlaces - The number of decimal places to display
 */
const lpcToPrettyAUD = (lpc, decimalPlaces = undefined) => {
    let numberToFormat = (0, exports.lpcToAUD)(lpc);
    return amountToPrettyAmount(numberToFormat, decimalPlaces);
};
exports.lpcToPrettyAUD = lpcToPrettyAUD;
// noinspection SuspiciousTypeOfGuard We may call this function from JS
/**
 * Converts AUD cents to LPC (=AUD.1)
 * @param audCents - Amount in AUD cents
 */
const audCentsToLPC = (audCents) => typeof audCents === 'number' && !isNaN(audCents) ? new decimal_js_1.Decimal(audCents).dividedBy(10).toNumber() : 0;
exports.audCentsToLPC = audCentsToLPC;
// noinspection SuspiciousTypeOfGuard We may call this function from JS
/**
 * Converts AUD to LPC (=AUD.1)
 * @param aud - Amount in AUD
 */
const audToLPC = (aud) => typeof aud === 'number' && !isNaN(aud) ? new decimal_js_1.Decimal(aud).times(10).toNumber() : 0;
exports.audToLPC = audToLPC;
