<div
    #chatContainer
    class="h-full py-7 px-md-7 px-3 overflow-y-auto overscroll-x-none scroll-smooth">


    <div *ngIf="messages?.length else emptyState" class="flex flex-col min-h-0 gap-3">
        <ng-container *ngFor="let message of messages">
            <app-assistant-chat-bot-message
                *ngIf="message.role === 'system'"
                [thread]="thread"
                [message]="message.content"
            />

            <app-assistant-chat-user-message
                *ngIf="message.role === 'user'"
                [message]="message.content"
            />
        </ng-container>
    </div>
    <ng-template #emptyState>
        <div class="flex flex-col items-center justify-center min-h-full overflow-y-scroll">
            <div class="flex items-center gap-5">
                <img
                    src="../../../../assets/img/illustrations/phil-phone-ping.png"
                    alt="Phil"
                    height="120"
                    width="85">

                <div>
                    <h1 class="max-w-[420px]">Hey, I’m Little Phil your Charity Assistant</h1>
                    <p class="mt-2">Ask me anything to do with your charity.</p>
                </div>
            </div>

            <div class="flex items-center justify-center flex-wrap gap-2 mt-4">
                <ng-container *ngFor="let samplePrompt of samplePrompts">
                <span
                    class="p-3 border-[1px] border-reliable-blue-20 rounded hover:bg-reliable-blue-5 cursor-pointer text-sm"
                    (click)="samplePromptClicked.emit(samplePrompt)">
                    {{samplePrompt}}
                </span>
                </ng-container>
            </div>
        </div>
    </ng-template>
</div>
