/*
 * csrf-initialiser.util.ts
 * Little Phil
 *
 * Created on 26/2/20
 * Copyright © 2018 Little Phil. All rights reserved.
 */
import { HttpClient } from '@angular/common/http';
import { Environment } from '../../_services/environment.service';
import { BrowserService } from '../../_services/browser.service';

/**
 * Setup CSRF tokens on app initialisation
 * @param http
 * @param browserService
 */
export function csrfInitialiser(http: HttpClient, browserService: BrowserService) {
    if (browserService.isBrowserPlatform() && Environment.isLocal) {
        // Get CSRF token on client-side app launch when using ng serve
        return () => http.get(Environment.authUrl).toPromise().catch(console.error);
    } else {
        // Don't fire CSRF token request on server-side
        // Don't fire CSRF token request on client-side as the token is provided with the initial request
        return () => Promise.resolve();
    }
}
