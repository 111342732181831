<div class="connect-section" *ngIf="!isWhitelabel">
    <div class="container align-self-center">

        <div class="row flex-sm-row" *ngIf="socials">

            <div class="col-md-4 d-none d-md-block d-lg-block d-xl-block">
                <img src="../../../assets/img/little-phil-logo.svg" alt="Little Phil Logo" class="h-[50px]">
            </div>

            <div class="col-md-2 d-none d-md-block d-lg-block d-xl-block">
            </div>

            <div class="col-md-1 col-2 align-self-center text-md-end text-center" *ngFor="let social of socials">
                <a href="{{social.url}}" target="_blank">
                    <i *ngIf=social.icon class="fab {{social.icon}} social-icon"></i>
                </a>
            </div>

        </div>

    </div>
</div>
