<div class="d-flex justify-content-around">

    <div class="text-center flex-grow-1">
        <h3 class="pb-1">{{amountRaised || 0 | centsToPrettyDollarsCurrency}}</h3>
        <span class="stat-name">RAISED</span>
    </div>

    <div class="separator mx-3"></div>

    <div *ngIf="goal" class="text-center flex-grow-1">
        <h3 class="pb-1">{{goal | centsToPrettyDollarsCurrency}}</h3>
        <span class="stat-name">GOAL</span>
    </div>

    <div *ngIf="!goal" class="text-center flex-grow-1">
        <h3 class="pb-1">{{uniqueGivers || 0}}</h3>
        <span class="stat-name">GIVERS</span>
    </div>

</div>
