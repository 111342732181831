import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie';
import * as jwtDecode from 'jwt-decode';

import { IAuthCreateAccountParams, IAuthLoginParams, IAuthTokenResponse } from '../_interfaces/auth.interface';
import { LocalStorageService } from './local-storage.service';
import { BrowserService } from './browser.service';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '../_models/user.model';
import { environment } from '../../environments/environment';
import {
    ACCESS_TOKEN_KEY,
    COOKIE_LAST_USER_ROLE_KEY,
    LOCAL_STORAGE_CREDIT_BALANCE_KEY,
    LOCAL_STORAGE_EMAIL_KEY,
    LOCAL_STORAGE_FIRST_NAME_KEY,
    LOCAL_STORAGE_IMAGE_URL_KEY,
    LOCAL_STORAGE_INTERCOM_HASH_KEY,
    LOCAL_STORAGE_LAST_NAME_KEY,
    LOCAL_STORAGE_MANAGED_CHARITY_KEY,
    LOCAL_STORAGE_MANAGED_COMPANY_KEY,
    LOCAL_STORAGE_POSTCODE_KEY,
    LOCAL_STORAGE_ROLE_KEY,
    LOCAL_STORAGE_SEX_KEY,
    LOCAL_STORAGE_USER_ID_KEY,
    LOCAL_STORAGE_USER_KEY,
    LOCAL_STORAGE_LAST_USER_EMAIL_KEY,
    REFRESH_TOKEN_KEY,
} from '../_utils/constants.util';
import { LoggerService } from './logger.service';
import { EnvironmentService } from './environment.service';

export const REFRESH_ENDPOINT = '/jwt/refresh';

const authBaseUrl = environment.authUrl;

/**
 * Map of UserModel property => localstorage key
 */
const UserLocalStorageKeyMap = {
    id: LOCAL_STORAGE_USER_ID_KEY,
    email: LOCAL_STORAGE_EMAIL_KEY,
    firstName: LOCAL_STORAGE_FIRST_NAME_KEY,
    lastName: LOCAL_STORAGE_LAST_NAME_KEY,
    intercomHash: LOCAL_STORAGE_INTERCOM_HASH_KEY,
    sex: LOCAL_STORAGE_SEX_KEY,
    postCode: LOCAL_STORAGE_POSTCODE_KEY,
    managedCharity: LOCAL_STORAGE_MANAGED_CHARITY_KEY,
    managedCompany: LOCAL_STORAGE_MANAGED_COMPANY_KEY,
    role: LOCAL_STORAGE_ROLE_KEY,
    creditBalance: LOCAL_STORAGE_CREDIT_BALANCE_KEY,
    imageUrl: LOCAL_STORAGE_IMAGE_URL_KEY,
};

@Injectable({
    providedIn: 'root',
})
export class AuthService {

    public isLoggedInObservable = new BehaviorSubject<boolean>(null);
    public userObservable = new BehaviorSubject<{ newValue: User, oldValue: User }>({ newValue: null, oldValue: null });

    public readonly routes = {
        forgot: `${authBaseUrl}/v1/user/forgot`,
        login: `${authBaseUrl}/v1/user/login`,
        logout: `${authBaseUrl}/v1/user/logout`,
        refresh: `${authBaseUrl}/v1${REFRESH_ENDPOINT}`,
        register: `${authBaseUrl}/v1/user`,
        resendWelcome: `${authBaseUrl}/v1/user/resend-welcome`,
        reset: `${authBaseUrl}/v1/user/reset`,
        resetTokenIsValid: `${authBaseUrl}/v1/user/reset-token/is-valid`,
        updatePassword: `${authBaseUrl}/v1/user/update-password`,
        unlinkAccount: `${authBaseUrl}/v1/user/unlink-account`,
        retrieveUser: (id) => `${authBaseUrl}/v1/user/${id}`,
        updateUser: (id) => `${authBaseUrl}/v1/user/${id}`,
        emailExists: `${authBaseUrl}/v1/user/exists?email=`,
    };

    constructor(
        private http: HttpClient,
        private cookieService: CookieService,
        private localStorageService: LocalStorageService,
        private browserService: BrowserService,
        private route: ActivatedRoute,
        private router: Router,
        private logger: LoggerService,
    ) {
        this.refreshAuthenticationState();
        this.refreshUserState(this.getUser());
    }

    public readonly user = {

        /**
         * Function to create a new user with the given params
         * @param {IAuthCreateAccountParams} body
         * @param {string} redirectUrl
         * @returns {Promise<IAuthTokenResponse>}
         */
        create: (body: IAuthCreateAccountParams, redirectUrl?: string): Promise<IAuthTokenResponse> => {
            return this.http.post<IAuthTokenResponse>(this.routes.register, body)
                .toPromise()
                .then(this.authenticationCallback(redirectUrl));
        },

        /**
         * Retrieving the user record with the given id
         * @param id
         */
        retrieve: (id: string) => {
            return this.http.get<User>(this.routes.retrieveUser(id))
                .toPromise()
                .then((json: User) => new User(json));
        },

        /**
         * Updates the user with the given id and with the given data
         * @param {string} id
         * @param {object} data
         */
        update: (id: string, data: object) => {
            return this.http.patch<User>(this.routes.updateUser(id), data).toPromise()
                .then((res) => {
                    const user = new User(res);

                    /**
                     * NOTE: The order of these functions is very important
                     * The logged in state must be updated before intercom is rebooted
                     */
                    this.saveUser(user);
                    this.refreshAuthenticationState();
                    this.refreshUserState(user);

                    return res;
                });
        },

    };

    /**
     * Send a set password email to the given address
     * @param {string} email
     * @param {string} [redirectUrl]
     * @returns {Promise<Object>}
     */
    public forgotPassword(email: string, redirectUrl?: string): Promise<Object> {
        const body = { email, redirectUrl };
        return this.http.post(this.routes.forgot, body).toPromise();
    }

    /**
     * Resets the user's password if there is a valid token
     * @param {string} password
     * @param {string} token
     * @param {string} redirectUrl
     * @returns {Promise<Object>}
     */
    public resetPassword(password: string, token: string, redirectUrl?: string): Promise<Object> {
        const body = { password, token };
        return this.http.post(this.routes.reset, body).toPromise().then(this.authenticationCallback(redirectUrl));
    }

    /**
     * Updates or sets the user's password
     * @param {string} newPassword - New password to set to the user
     * @param {string} [currentPassword] - Current password of the user, if it's previously been set by the user
     */
    public updatePassword(newPassword: string, currentPassword?: string): Promise<Object> {
        const body = { newPassword, currentPassword };
        return this.http.post(this.routes.updatePassword, body).toPromise();
    }

    /**
     * Unlink a social login account from the user's connected accounts
     * @param {string} linkedSocialLoginAccountId - ID of the linkedSocialLoginAccount from the user's linkedSocialLoginAccounts
     */
    public unlinkAccount(linkedSocialLoginAccountId): Promise<Object> {
        return this.http.get(this.routes.unlinkAccount + '/' + linkedSocialLoginAccountId).toPromise();
    }

    /**
     * Checks whether a reset token is valid
     * @param token The token to validate
     */
    public isResetTokenValid(token: string): Promise<Object> {
        return this.http.get(`${this.routes.resetTokenIsValid}?token=${token}`).toPromise();
    }

    /**
     * Resend a welcome email to the specified email address
     * @param email Address to send Welcome email to
     */
    public resendWelcomeEmail(email: string): Promise<Object> {
        const body = { email };
        return this.http.post(this.routes.resendWelcome, body).toPromise();
    }

    /**
     * Requests a new access token cookie
     * NOTE: Return an Observable instead of a promise for the RefreshTokenInterceptor
     */
    public refreshAccessToken(): Observable<any> {
        return this.http.get(this.routes.refresh);
    }

    /**
     * Logs the current user out
     * @param redirectUrl - URL to override module authLogoutUrl for the current function call
     * @param options
     * @returns {Promise<Object>}
     */
    public logout(redirectUrl?: string, options?: { disableRedirect?: boolean }): Promise<Object> {
        // The API will return a set-cookie header which will clear all the stored cookies
        return this.http.get(this.routes.logout).toPromise()
            .finally(() => {
                /**
                 * NOTE: The order of these functions is very important.
                 * The cookies must be removed to allow for the logged in state to be updated.
                 * The logged in state must be updated before intercom is rebooted.
                 */
                this.clearCookies();
                this.removeUserFromLocalStorage();
                this.refreshAuthenticationState();
                this.refreshUserState(null);

                if (options?.disableRedirect) {
                    return;
                }

                /**
                 * Navigate to the logout URL provided by the function caller
                 * or
                 * Reload the page as the user might be on a page that requires being authorised.
                 */
                const url = redirectUrl || this.router.routerState.snapshot.url;
                return this.router.navigateByUrl(url);
            });
    }

    /**
     * Function to authenticate user with the given email and password
     * @param body
     * @param redirectUrl
     * @returns {Promise<IAuthTokenResponse>}
     */
    public authenticate(body: IAuthLoginParams, redirectUrl?: string): Promise<IAuthTokenResponse> {
        return this.http.post<IAuthTokenResponse>(this.routes.login, body)
            .toPromise()
            .then(this.authenticationCallback(redirectUrl));
    }

    /**
     * Checks if there is a user with the provided email exists in the database
     * @param email
     */
    public checkEmailExists(email: string) {
        return this.http.get<{ exists: boolean }>(`${this.routes.emailExists}${encodeURIComponent(email)}`).toPromise();
    }

    /**
     * Callback for authentication and register
     * @param redirectUrl
     */
    private authenticationCallback(redirectUrl?: string): (res: IAuthTokenResponse) => IAuthTokenResponse {
        return (res: IAuthTokenResponse): IAuthTokenResponse => {
            const user = new User(res);

            /**
             * NOTE: The order of these functions is very important
             * The logged in state must be updated before intercom is rebooted
             */
            this.saveUser(user);
            this.refreshAuthenticationState();
            this.refreshUserState(user);
            this.redirectAfterLogin(redirectUrl);

            return res;
        };
    }

    /**
     * Redirects the user to the provided url
     */
    private redirectAfterLogin(redirectUrl?: string) {
        if (redirectUrl) {
            this.router.navigateByUrl(redirectUrl).catch(console.error);
        }
    }

    /**
     * Removes the authentication cookies
     */
    private clearCookies() {
        this.cookieService.remove(ACCESS_TOKEN_KEY);
        this.cookieService.remove(REFRESH_TOKEN_KEY);
    }


    /***************************************************************************************************************************************
     ** User state functions
     **************************************************************************************************************************************/

    /**
     * Returns the current authenticated user
     * @returns {User}
     */
    public getUser(): User | null {

        /**
         * Force authentication refresh if the auth state is out of sync
         * Logging out on one application will make the other out of sync
         */
        if (this.isLoggedIn !== this.cookieService.hasKey(ACCESS_TOKEN_KEY)) {
            this.refreshAuthenticationState();
        }

        if (!this.isLoggedIn) {
            return null;
        }

        // read user data from local storage
        let localStorageUser;

        const localStorageUserString = this.localStorageService.getItem(LOCAL_STORAGE_USER_KEY);
        if (localStorageUserString) {
            localStorageUser = JSON.parse(localStorageUserString);
        }

        // local storage data may be outdated so decode the access token to get the most up to date data
        const accessToken = this.cookieService.get(ACCESS_TOKEN_KEY);
        const accessTokenUser = jwtDecode(accessToken);

        // Note: localStorageUser can be null when using legacy data (using local storage userId instead of user)
        const data: Record<string, any> = localStorageUser || {};

        // save the user if an authentication state changed occurred or if there is no user in local storage
        let saveUser = false;
        if (accessTokenUser?.sub !== data._id) {
            if (this.browserService.isBrowserPlatform()) {
                this.logger.info('Authentication state change');
            }
            saveUser = true;
        }

        Object.assign(data, accessTokenUser);
        const user = new User(data);

        if (saveUser) {
            this.saveUser(user);
        }

        return user;

    }

    /**
     * Set the local storage values of the user
     */
    public saveUser(user: User) {
        this.localStorageService.setItem(LOCAL_STORAGE_USER_KEY, JSON.stringify(user));
        this.localStorageService.setItem(LOCAL_STORAGE_LAST_USER_EMAIL_KEY, user.email);
        this.cookieService.put(COOKIE_LAST_USER_ROLE_KEY, user.role, {
            path: '/',
            domain: EnvironmentService.cookieDomain,
        });
    }

    /**
     * Refreshes the user by updating caches and emitting new values
     */
    public async refreshUser() {
        if (!this.isLoggedIn) {
            return;
        }

        const oldUser = this.userObservable.getValue().newValue;
        const newUser = await this.user.retrieve(oldUser.id);

        this.saveUser(newUser);
        this.refreshUserState(newUser);
    }

    /**
     * Removes the local storage values of the user
     */
    public removeUserFromLocalStorage() {
        this.localStorageService.removeItem(LOCAL_STORAGE_USER_KEY);
        // remove legacy data
        Object.entries(UserLocalStorageKeyMap).forEach(([_, value]) => {
            this.localStorageService.removeItem(value);
        });
    }

    /**
     * Helper for cleaner usage of isLoggedInObservable
     * @return {boolean}
     */
    public get isLoggedIn() {
        return this.isLoggedInObservable.getValue();
    }

    /**
     * Private function for checking the users authentication state whenever there is a modification made
     */
    private refreshAuthenticationState() {
        this.isLoggedInObservable.next(this.cookieService.hasKey(ACCESS_TOKEN_KEY));
    }

    /**
     * Private function to update the user observable with a new value
     * @param newUser
     * @private
     */
    private refreshUserState(newUser: User) {
        const user = this.userObservable.getValue().newValue;
        this.userObservable.next({ newValue: newUser, oldValue: user });
    }

}
