/*
 * _base.model.ts
 * Little Phil
 *
 * Created on 14/5/20
 * Copyright © 2018 Little Phil. All rights reserved.
 */

export abstract class BaseModel {

    // Backwards compatibility for id and _id
    private _id: string = undefined;
    public get id(): string {
        return this._id;
    }

    // Properties
    public createdAt: Date = undefined;
    public updatedAt: Date = undefined;

    /**
     * Constructor
     */
    constructor() {
        this.checkEndpoint();
    }

    /**
     * Setups up the initial values for the model properties
     *
     * NOTE: This needs to be called from the subclasses so that the extended properties exist during merge
     */
    protected init(json: object) {
        this.merge(json);
        this.cleanProperties();
        this.deserialise();
    }

    /**
     * Check that endpoint is overridden and throw it hasn't been
     */
    private checkEndpoint() {
        // Constructor is a function so TS isn't happy about accessing a property
        if (!Reflect.has(this.constructor, 'endpoint')) {
            throw new Error('All subclasses of BaseModel must override the endpoint getter.');
        }
    }

    /**
     * Merges a given json object into the current instance
     */
    private merge(json: object) {
        if (json) {
            const keys = Object.keys(this);
            const jsonKeys = Object.keys(json);

            // Merge json into this
            jsonKeys.forEach(key => {
                if (key === 'id' || key === '_id' && !this._id) {
                    this._id = json[key];
                } else if (keys.includes(key)) {
                    this[key] = json[key];
                }
            });

            if (typeof this.createdAt === 'string') {
                this.createdAt = new Date(this.createdAt);

                if (isNaN(this.createdAt.getTime())) {
                    this.createdAt = null;
                    console.error('BaseModel: Failed to parse date.'); // logger is not accessible here because this isn't an Angular class
                }
            }

            if (typeof this.updatedAt === 'string') {
                this.updatedAt = new Date(this.updatedAt);

                if (isNaN(this.updatedAt.getTime())) {
                    this.updatedAt = null;
                    console.error('BaseModel: Failed to parse date.'); // logger is not accessible here because this isn't an Angular class
                }
            }
        }
    }

    /**
     * Removes all undefined properties after init
     */
    private cleanProperties() {
        const keys = Object.keys(this);

        keys.forEach(key => {
            if (this[key] === undefined) {
                delete this[key];
            }
        });
    }

    /**
     * Coverts the current model's nested objects into model instances
     */
    protected deserialise() {}
}
