"use strict";
/*
 * local-timezone.ts
 * Little Phil
 *
 * Created on 29/7/22
 * Copyright © 2018 Little Phil. All rights reserved.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.localTimezone = void 0;
/**
 * Get the timezone of the machine using this code
 */
exports.localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
