import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';

import { DynamicComponentDirective } from '../../../../_classes/dynamic-component.class';
import { IDynamicComponentOptions } from '../../../../_interfaces/options.interface';
import { ModalPopupComponent } from '../modal-popup/modal-popup.component';

@Component({
    selector: 'app-confirmation-dialog',
    templateUrl: './confirmation-dialog.component.html',
    styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent extends DynamicComponentDirective {

    /**
     * The confirmation dialog title
     */
    public title = 'Are you sure?';

    /**
     * The confirmation dialog positive action text
     * Default to OK
     */
    public positiveActionText = 'OK';

    /**
     * The confirmation dialog positive action button classes
     * Default to secondary CTA
     */
    public positiveActionClasses = 'btn btn-secondary';

    /**
     * The confirmation dialog positive action text
     * Default to Cancel
     */
    public negativeActionText = 'Cancel';

    /**
     * The confirmation dialog text
     */
    public message: string;

    /**
     * The confirmation dialog callback,
     * which will be executed when the user selects an action
     */
    public callback: (confirm: boolean) => void;

    @ViewChild('popup', { static: true }) popup: ModalPopupComponent;

    /**
     * Cancel the action
     */
    public cancel() {
        this.callback(false);
        this.destroy();
    }

    /**
     * Confirm the action
     */
    public confirm() {
        this.callback(true);
        this.destroy();
    }

    onOptionsChanged(options: IDynamicComponentOptions<IConfirmationDialogData>) {
        if (!options || !options.data) {
            return;
        }

        /**
         * Sets up the dialog with the options passed in
         */
        if (options.data) {
            this.title = options.data.title || this.title;
            this.message = options.data.message;
            this.positiveActionText = options.data.positiveActionText || this.positiveActionText;
            this.positiveActionClasses = options.data.positiveActionClasses || this.positiveActionClasses;
            this.negativeActionText = options.data.negativeActionText || this.negativeActionText;
        }

        this.callback = options.confirmCallback;
    }
}

export interface IConfirmationDialogData {
    title?: string;
    message?: string;
    positiveActionText?: string;
    positiveActionClasses?: string;
    negativeActionText?: string;
}
