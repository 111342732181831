import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalComponent } from '../../modal/modal/modal.component';
import { ModalContentComponent } from '../../modal/modal-content/modal-content.component';
import { ModalFooterComponent } from '../../modal/modal-footer/modal-footer.component';
import { ModalHeaderComponent } from '../../modal/modal-header/modal-header.component';
import { ModalLoaderComponent } from '../../modal/modal-loader/modal-loader.component';
import { ModalTitleComponent } from '../../modal/modal-title/modal-title.component';
import { ModalTriggerComponent } from '../../modal/modal-trigger/modal-trigger.component';
import { AssistantChatBotMessageComponent } from '../assistant-chat-bot-message/assistant-chat-bot-message.component';
import { AssistantChatUserMessageComponent } from '../assistant-chat-user-message/assistant-chat-user-message.component';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { AssistantChatHistoryComponent } from '../assistant-chat-history/assistant-chat-history.component';
import { AssistantChatInputComponent } from '../assistant-chat-input/assistant-chat-input.component';

@Component({
    selector: 'app-assistant-chat-modal',
    standalone: true,
    imports: [
        CommonModule,
        ModalComponent,
        ModalContentComponent,
        ModalFooterComponent,
        ModalHeaderComponent,
        ModalLoaderComponent,
        ModalTitleComponent,
        ModalTriggerComponent,
        AssistantChatBotMessageComponent,
        AssistantChatUserMessageComponent,
        AssistantChatHistoryComponent,
        AssistantChatInputComponent,
    ],
    templateUrl: './assistant-chat-modal.component.html',
    styles: [
        `
            :host {
                display: block;
            }
        `,
    ],
})
export class AssistantChatModalComponent {

    public thread: string;
    messages: ChatMessage[] = [];

    constructor(private http: HttpClient) {}

    handleNewMessage(message: string) {
        const responseMessage: ChatMessage = {
            content: 'Loading...',
            loading: true,
            role: 'system',
        };

        this.messages = [...this.messages, {
            role: 'user',
            content: message,
        }, responseMessage];

        this.http
            .post(`${environment.apiUrl}/v1/assistants/chat`, {
                message,
                thread: this.thread,
            })
            .toPromise()
            .then((response: { message: string, thread: string }) => {
                responseMessage.content = response.message;
                responseMessage.loading = false;
                this.messages = [...this.messages];
                this.thread = response.thread;
            })
            .catch((err) => {
                let content = 'Oops, it looks like something went wrong. Please try again.\n\nIf you continue to see this error please contact us.';
                if (err instanceof HttpErrorResponse) {
                    // rate limited
                    if (err.status === 429 && err.error?.message) {
                        content = err.error.message;
                    }
                }

                responseMessage.content = content;
                responseMessage.loading = false;
                this.messages = [...this.messages];
            });
    }

}

export interface ChatMessage {
    content: string;
    role: 'system' | 'user';
    loading?: boolean;
}
