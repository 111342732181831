/*
 * lpc-amount.pipe.ts
 * Little Phil
 *
 * Created on 25/3/21
 * Copyright © 2018 Little Phil. All rights reserved.
 */

import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({
    name: 'lpcAmount',
})
export class LPCAmountPipe extends DecimalPipe implements PipeTransform {

    /**
     * Transforms a number into an a LPC string amount ready to be displayed
     *
     * @param value {number} - Number to transform
     * @param digitsInfo {string} - Decimal representation options
     * @param locale {string} - A locale code for the locale format rules to use
     *
     * Eg.  15 => 15 LPC
     *      15.5 => ~15 LPC
     */
    transform(
        value: number | string | null | undefined,
        digitsInfo?: string,
        locale?: string,
    ): null;
    transform(
        value: number | string | null | undefined,
        digitsInfo?: string,
        locale?: string,
    ): string | null {
        if (typeof value !== 'number') {
            return null;
        }

        let result = super.transform(value, digitsInfo || '1.0-0') + ' LPC';

        if (!Number.isInteger(value)) {
            result = `~${result}`;
        }

        return result;
    }

}
