import { Component } from '@angular/core';

@Component({
    selector: 'app-tooltip',
    templateUrl: './tooltip.component.html',
    styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent {
    // Take from https://accesto.com/blog/how-to-create-angular-tooltip-directive/
    public text: string;
    public html: string;
    public position: 'above' | 'below' | 'right' | 'left' = 'above';
    public left = 0;
    public top = 0;
    public visible = true;
}
