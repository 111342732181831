/*
 * form-component.class.ts
 * Little Phil
 *
 * Created on 29/6/21
 * Copyright © 2018 Little Phil. All rights reserved.
 */
import {
    AbstractControl,
    FormArray,
    FormControl,
    FormGroup,
    UntypedFormArray,
    UntypedFormControl,
    UntypedFormGroup,
    ValidationErrors,
} from '@angular/forms';
import { DynamicComponentDirective } from './dynamic-component.class';
import { LoggerService } from '../_services/logger.service';
import { IImageChangeEvent } from '../_modules/lp-form/components/form-image/form-image.component';
import { Directive, inject } from '@angular/core';

@Directive()
export abstract class FormComponent extends DynamicComponentDirective {

    protected abstract form: UntypedFormGroup;

    protected abstract createForm(): void;

    // protected logger = inject(LoggerService); // TODO: add this to clean up inheritence

    protected constructor(protected logger: LoggerService) {
        super();
    }

    /**
     * Iterates over all the fields in the form  and validates them
     * @param [form] - Form to validate
     * @returns {Boolean}
     */
    protected validateForm(form: UntypedFormGroup = this.form): Boolean {
        Object.keys(form.controls).forEach((name) => {
            const control = form.get(name);
            this.validateFormControl(control);
        });

        return form.valid;
    }

    /**
     * Iterates over all the fields in the form array and validates them
     * @param [formArray] - FormArray to validate
     * @returns {Boolean}
     */
    protected validateFormArray(formArray: UntypedFormArray): Boolean {
        formArray.controls.forEach((control) => {
            this.validateFormControl(control);
        });

        return formArray.valid;
    }

    /**
     * Marks a control as touched so that it can be evaluated to valid or invalid
     * @param [control] - Control to validate
     * @returns {Boolean}
     */
    protected validateFormControl(control: AbstractControl): Boolean {
        if (control instanceof FormGroup) {
            this.validateForm(control);
        } else if (control instanceof FormArray) {
            this.validateFormArray(control);
        }

        control.markAsTouched({ onlySelf: true }); // mark fields as touched to display errors for fields that may not have a value
        control.updateValueAndValidity(); // update validity in case a conditional validator's value has changes

        if (control.invalid) {
            this.logger.verbose('Invalid FormControl', control);
        }

        return control.valid;
    }

    /**
     * Iterates over all the fields to determine whether the form has dirty values
     *
     * @param [form] - Form to validate
     * @returns {Boolean} Whether the form has dirty values
     * @protected
     */
    protected hasDirtyValues(form: UntypedFormGroup = this.form): Boolean {
        // loop through the form controls and return true if any is found dirty
        for (const key of Object.keys(form.controls)) {
            const control = this.form.controls[key];
            if (control.dirty) {
                return true;
            }
        }

        // no dirty form control found
        return false;
    }

    public getFirstControlError(control: FormGroup | FormArray | FormControl, name: string): {
        error: ValidationErrors,
        name: string
        nameStack: string[]
    } | null {
        if (control instanceof FormControl && control.errors) {
            return {
                error: control.errors,
                name,
                nameStack: [name],
            };
        }

        if (control instanceof FormGroup) {
            for (const key of Object.keys(control.controls)) {
                const result = this.getFirstControlError(control.controls[key] as FormControl, key);
                if (result) {
                    return {
                        error: result.error,
                        name: `${name}.${result.name}`,
                        nameStack: [name, ...result.nameStack],
                    };
                }
            }
        }

        if (control instanceof FormArray) {
            for (const [i, v] of control.controls.entries()) {
                const result = this.getFirstControlError(v as FormControl, i.toString());
                if (result) {
                    return {
                        error: result.error,
                        name: `${name}.${result.name}`,
                        nameStack: [name, ...result.nameStack],
                    };
                }
            }
        }

        return null;
    }

    /**
     * Returns a single error message from the current control's errors
     */
    public getMessageFromControlErrors(errors: ValidationErrors | null, name: string) {
        if (!errors) {
            return;
        }

        if (errors.required) {
            return `${name} is required.`;
        } else if (errors.pattern || errors.taxId) {
            return `${name} must be valid.`;
        } else if (errors.min) {
            return `${name} must be at least ${errors.min.min}.`;
        } else if (errors.max) {
            return `${name} must be at most ${errors.max.max}.`;
        } else if (errors.minlength) {
            return `${name} must be at least ${errors.minlength.requiredLength} characters long.`;
        } else if (errors.maxlength) {
            return `${name} must be at most ${errors.maxlength.requiredLength} characters long.`;
        } else if (errors.custom) {
            return errors.custom;
        } else {
            return `${name} is invalid.`;
        }
    }

    /**
     * Helper for returning undefined instead of null from an upset field value
     * @param {FormControl} field
     * @returns {undefined}
     */
    public getFieldValue(field: UntypedFormControl) {
        return field?.value;
    }

    /**
     * Helper for returning the field value as a number or 0 if the value is NaN
     * @param {FormControl} field - The field to get the value for
     * @returns {number} The numeric field value, or 0
     */
    public getNumericFieldValueOrZero(field: UntypedFormControl) {
        return isNaN(field?.value) ? 0 : Number(field?.value);
    }

    /**
     * Whether the requested field is valid
     * @param field
     */
    public isValid(field: UntypedFormControl) {
        return field && field.valid && (field.dirty || field.touched);
    }

    /**
     * Whether the requested field is invalid
     * @param field
     */
    public isInvalid(field: UntypedFormControl) {
        return field && field.invalid && (field.dirty || field.touched);
    }

    /**
     * Whether the requested field has errors
     * @param field
     */
    public hasErrors(field: UntypedFormControl) {
        return field && field.errors && (field.dirty || field.touched);
    }

    /**
     * Event fired when an lp-form-image selects a new image
     *
     * @param {IImageChangeEvent} event - The image change event
     * @param {string} field - The field associated with the image
     */
    public onImageChange(event: IImageChangeEvent, field: string) {
        this[field] = event.image;
    }

}
