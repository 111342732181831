import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class ScrollService {

    public readonly windowScrollPositionObservable: BehaviorSubject<number>;

    private isScrollLocked: boolean;
    private lockedScrollPosition: number;

    constructor() {
        this.windowScrollPositionObservable = new BehaviorSubject<number>(0);
    }

    /**
     * Static function for scrolling to the top of the page
     */
    static scrollToTop() {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    }

    /**
     * Smoothly scrolls to the element with provided ID, if any
     * @param elementId - ID of a DOM element (excluding the #)
     * @param offset - Additional scroll distance from element
     */
    static smoothScrollToElement(elementId: string, offset = 0): void {
        const element = document.getElementById(elementId);
        if (element) {
            const top = element.getBoundingClientRect().top + offset;
            window.scrollBy({ top, behavior: 'smooth' });
        }
    }

    /**
     * Checks if there is a fragment in the current route, if so, smooth scroll to the fragment
     * e.g. "/campaign/12345#updates" would check that an element with the "updates" ID exists and scrolls to it
     * @param route - Current active route
     */
    static smoothScrollToRouteFragment(route: ActivatedRoute) {
        const fragment = route.snapshot.fragment;
        if (fragment && document.getElementById(fragment)) {
            route.snapshot.fragment = undefined;
            ScrollService.smoothScrollToElement(fragment);
        }
    }

    /**
     * Updates the new y offset position of the window object
     */
    public requestPositionUpdate() {
        this.windowScrollPositionObservable.next(window.pageYOffset);
    }


    /**
     * Disables scrolling on the body and positions the body fixed to disable iOS scrolling
     * https://markus.oberlehner.net/blog/simple-solution-to-prevent-body-scrolling-on-ios/#:~:text=The%20most%20straightforward%20way%20for,%2Futils%2Fscroll%2Dlock.
     * @param preventScrollToTop - If true (default), the body element will get a top style so the user doesn't see it scrolling back to the top
     */
    public disableBodyOverflowY(preventScrollToTop: boolean = true) {
        if (this.isScrollLocked) { // prevent the incorrect scroll position being stored if already locked
            return;
        }

        this.isScrollLocked = true;
        const body = document.querySelector('body');
        this.lockedScrollPosition = window.pageYOffset;
        body.style.overflow = 'hidden';
        body.style.position = 'fixed';
        body.style.width = '100%';
        if (preventScrollToTop) {
            body.style.top = `-${this.lockedScrollPosition}px`;
        }
    }

    /**
     * Restores scrolling and positioning of the body element
     * https://markus.oberlehner.net/blog/simple-solution-to-prevent-body-scrolling-on-ios/#:~:text=The%20most%20straightforward%20way%20for,%2Futils%2Fscroll%2Dlock.
     */
    public restoreBodyOverflowY() {
        if (!this.isScrollLocked) { // prevent the old scroll position being stored if not locked
            return;
        }

        const body = document.querySelector('body');
        body.style.removeProperty('overflow');
        body.style.removeProperty('position');
        body.style.removeProperty('top');
        body.style.removeProperty('width');
        window.scrollTo(0, this.lockedScrollPosition);
        this.isScrollLocked = false;
    }
}
