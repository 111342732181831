<app-dashboard-form-control
    [control]="control"
    [name]="name"
    [label]="label"
    [labelFontSize]="labelFontSize"
    [details]="details"
    [required]="required">

    <ng-select
        [formControl]="control"
        [items]="items"
        [bindLabel]="bindLabel"
        [bindValue]="bindValue"
        [placeholder]="placeholder"
        [searchable]="searchable"
        [clearable]="clearable"
    />

</app-dashboard-form-control>
