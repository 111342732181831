<div class="bg-primary" *ngIf="creditAmountPerClaim">
    <div class="container py-3">

        <div class="d-flex align-items-center justify-content-center">
            <p class="text-white me-3">Register or sign in to claim your free ${{lpcToAUD(creditAmountPerClaim) | dollarAmount}} giving credit 🎉</p>
            <button id="site-banner__claim-button" class="btn btn-secondary" (click)="claimButtonClicked()">Claim</button>
        </div>

    </div>
</div>
