/*
 * theme.util.ts.ts
 * Little Phil
 *
 * This class allows for easy changes to the environment theme
 *
 * Created on 18/10/19
 * Copyright © 2018 Little Phil. All rights reserved.
 */
import { environment } from '../../environments/environment';
import { IStylesInterface, IThemeInterface } from '../_interfaces/theme.interface';

// Don't use EnvironmentService as it will cause circular dependency
const isWhitelabel = environment.env === 'whitelabel';

// @ts-ignore - LP environments aren't required to provide a theme as they use default values
const envTheme = environment.theme || { styling: null };

/**
 * Default styling values
 */
const defaultStyling: IStylesInterface = {
    primaryColour: '#203b4c',
    secondaryColour: '#faa634',
    textColour: '#203b4c',
    bannerColour: '#f7e49f',
    navbarColour: 'white',
    navbarDarkMode: false,
    font: 'DM Sans',
};

/**
 * Default theme values merged with environment theme
 */
const _Theme: IThemeInterface = {
    /**
     * Default base level
     */
    title: 'Little Phil',
    shareText: isWhitelabel
        ? 'Share to your networks and increase your impact.'
        : 'Share your Little Phil Good Moment and increase your impact.',

    /**
     * Merge environment theme
     */
    ...envTheme,

    /**
     * Merge styling
     */
    styling: { ...defaultStyling, ...envTheme.styling },
};

// LP styling doesn't use primary as the banner colour so this need to be handled for white-labels
if (isWhitelabel) {
    _Theme.styling.bannerColour = _Theme.styling.primaryColour;
}

export const Theme = _Theme;
