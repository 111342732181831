<div class="d-flex content cursor-pointer">
    <i [ngClass]="iconClass" class="icon-24"></i>

    <div class="flex-grow-1 ms-2 align-self-center">
        <p *ngIf="title" data-sentry-unmask>{{title}}</p>
        <p *ngIf="message" class="font-size-xs" data-sentry-unmask>{{message}}</p>
    </div>
</div>

<div *ngIf="options.progressBar">
    <div class="toast-progress" [style.width]="width + '%'"></div>
</div>

<div class="close-button cursor-pointer" (click)="remove()"></div>
