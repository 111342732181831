/*
 * cents-to-dollars-currency.pipe.ts
 * Little Phil
 *
 * Created on 30/6/21
 * Copyright © 2018 Little Phil. All rights reserved.
 */

import { Pipe, PipeTransform } from '@angular/core';
import { WholeCurrency } from './whole-currency.pipe';

@Pipe({
    name: 'centsToDollarsCurrency',
})
export class CentsToDollarsCurrencyPipe extends WholeCurrency implements PipeTransform {

    /**
     * Transforms a cents amount into a formatted currency string in dollars
     */
    transform(
        value: number | string | null | undefined,
        currencyCode?: string,
        display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean,
        digitsInfo?: string,
        locale?: string,
    ): null;
    transform(
        value: number | string | null | undefined,
        currencyCode?: string,
        display: 'code' | 'symbol' | 'symbol-narrow' | string | boolean = 'symbol',
        digitsInfo?: string,
        locale?: string,
    ): string | null {

        if (typeof value !== 'number') {
            return null;
        }

        const dollars = value / 100;

        return super.transform(dollars, currencyCode, display, digitsInfo);
    }

}
