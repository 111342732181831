"use strict";
/*
 * business-tax-id.ts
 * Little Phil
 *
 * Created on 7/9/20
 * Copyright © 2018 Little Phil. All rights reserved.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.isValidTaxId = exports.isValidTFN = exports.isValidACN = exports.isValidABN = void 0;
/**
 * Mapping of ABN digit position to Weighting factor
 * https://abr.business.gov.au/Help/AbnFormat#:~:text=The%20Australian%20Business%20Number%20(ABN,with%20two%20leading%20check%20digits
 * @type {{}}
 */
const abnWeightingFactor = {
    0: 10,
    1: 1,
    2: 3,
    3: 5,
    4: 7,
    5: 9,
    6: 11,
    7: 13,
    8: 15,
    9: 17,
    10: 19,
};
/**
 * Check if a string is a valid ABN
 * https://abr.business.gov.au/Help/AbnFormat#:~:text=The%20Australian%20Business%20Number%20(ABN,with%20two%20leading%20check%20digits
 * To verify an ABN:
 * Subtract 1 from the first (left-most) digit of the ABN to give a new 11 digit number
 * Multiply each of the digits in this new number by the corresponding "weighting factor" based on its position
 * Sum the resulting 11 products
 * Divide the sum total by 89, noting the remainder
 * If the remainder is zero the number is a valid ABN
 *
 * @param abn - Text to test
 * @return boolean - Whether the input is a valid ABN
 */
const isValidABN = (abn) => {
    // noinspection SuspiciousTypeOfGuard
    if (!abn || typeof abn !== 'string')
        return false;
    const spaceless = abn.replace(/\s/g, '');
    const digitsOnly = spaceless.replace(/\D/g, '');
    if (spaceless.length !== digitsOnly.length)
        return false;
    else if (digitsOnly.length !== 11)
        return false;
    const digits = digitsOnly.split('').map(digit => parseInt(digit, 10));
    // Subtract 1 from the first (left-most) digit of the ABN to give a new 11 digit number
    digits[0]--;
    // Multiply each of the digits in this new number by the corresponding "weighting factor" based on its position
    // Sum the resulting 11 products
    let sum = 0;
    for (let i = 0; i < digits.length; i++) {
        sum += digits[i] * abnWeightingFactor[i];
    }
    // Divide the sum total by 89, noting the remainder
    // if the remainder is zero the number is a valid ABN
    return sum % 89 === 0;
};
exports.isValidABN = isValidABN;
/**
 * Mapping of ACN digit position to Weighting factor
 * https://asic.gov.au/for-business/registering-a-company/steps-to-register-a-company/australian-company-numbers/australian-company-number-digit-check/#:~:text=The%20Australian%20Company%20Number%20(ACN,block%20separated%20by%20a%20blank
 * @type {{}}
 */
const acnWeightingFactor = {
    0: 8,
    1: 7,
    2: 6,
    3: 5,
    4: 4,
    5: 3,
    6: 2,
    7: 1,
    8: 0,
};
/**
 * Check if a string is a valid ACN
 * https://asic.gov.au/for-business/registering-a-company/steps-to-register-a-company/australian-company-numbers/australian-company-number-digit-check/#:~:text=The%20Australian%20Company%20Number%20(ACN,block%20separated%20by%20a%20blank
 * To verify an ACN:
 * Step 1 - Apply weighting to digits 1 to 8.
 * Step 2 - Sum the products
 * Step 3 - Divide by 10 to obtain remainder
 * Step 4 - Complement the remainder to 10
 * If the complement equals 10, set it to 0
 * If the calculated check digit equals the check digit, the ACN is valid
 *
 * @param acn - Text to test
 * @return boolean - Whether the input is a valid ACN
 */
const isValidACN = (acn) => {
    // noinspection SuspiciousTypeOfGuard
    if (!acn || typeof acn !== 'string')
        return false;
    const spaceless = acn.replace(/\s/g, '');
    const digitsOnly = spaceless.replace(/\D/g, '');
    if (spaceless.length !== digitsOnly.length)
        return false;
    else if (digitsOnly.length !== 9)
        return false;
    const digits = digitsOnly.split('').map(digit => parseInt(digit, 10));
    // Step 1 - Apply weighting to digits 1 to 8.
    // Step 2 - Sum the products
    let sum = 0;
    for (let i = 0; i < digits.length - 1; i++) {
        sum += digits[i] * acnWeightingFactor[i];
    }
    //  Step 3 - Divide by 10 to obtain remainder
    const remainder = sum % 10;
    // Step 4 - Complement the remainder to 10
    // If the complement equals 10, set it to 0.
    let complement = remainder === 0 ? 0 : 10 - remainder;
    // If the calculated check digit equals the check digit, the ACN is valid
    return complement === digits[8];
};
exports.isValidACN = isValidACN;
/**
 * Mapping of TFN digit position to Weighting factor
 * https://en.wikipedia.org/wiki/Tax_file_number#Check_digit
 * @type {{}}
 */
const tfnWeightingFactor = {
    0: 1,
    1: 4,
    2: 3,
    3: 7,
    4: 5,
    5: 8,
    6: 6,
    7: 9,
    8: 10,
};
/**
 * Check if a string is a valid TFN
 * https://asic.gov.au/for-business/registering-a-company/steps-to-register-a-company/australian-company-numbers/australian-company-number-digit-check/#:~:text=The%20Australian%20Company%20Number%20(ACN,block%20separated%20by%20a%20blank
 * The TFN itself was either 8 or 9 digits, with a check digit. Individuals received a 9 digit TFN and non-individuals received an 8 digit TFN.
 * However, as the 8 digit TFNs were becoming exhausted, all new taxpayers are issued 9 digit TFNs.
 * The numbers now do not have any embedded meaning.
 * As is the case with many identification numbers, the TFN includes a check digit for detecting erroneous numbers.
 * The algorithm is based on simple modulo 11 arithmetic per many other digit checksum schemes.
 * @param tfn - Text to test
 * @return boolean - Whether the input is a valid TFN
 */
const isValidTFN = (tfn) => {
    // noinspection SuspiciousTypeOfGuard
    if (!tfn || typeof tfn !== 'string')
        return false;
    const spaceless = tfn.replace(/\s/g, '');
    const digitsOnly = spaceless.replace(/\D/g, '');
    if (spaceless.length !== digitsOnly.length)
        return false;
    else if (digitsOnly.length !== 8 && digitsOnly.length !== 9)
        return false;
    const digits = digitsOnly.split('').map(digit => parseInt(digit, 10));
    // Apply weighting to all digits and Sum the products
    let sum = 0;
    for (let i = 0; i < digits.length; i++) {
        sum += digits[i] * tfnWeightingFactor[i];
    }
    // the TFN is valid if the sum is a multiple of 11
    return sum % 11 === 0;
};
exports.isValidTFN = isValidTFN;
/**
 * Check if a string is a valid Tax ID
 * It can be either a valid ABN, ACN or TFN
 * @param taxId Text to test
 * @returns Whether the input is a valid Tax ID
 */
const isValidTaxId = (taxId) => {
    return (0, exports.isValidABN)(taxId) || (0, exports.isValidACN)(taxId) || (0, exports.isValidTFN)(taxId);
};
exports.isValidTaxId = isValidTaxId;
