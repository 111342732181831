/*
 * fingerprint-initaliser.utils.ts
 * Little Phil
 *
 * Created on 1/11/22
 * Copyright © 2018 Little Phil. All rights reserved.
 */
import * as Fingerprint2 from 'fingerprintjs2';
import { CookieService } from 'ngx-cookie';
import { EnvironmentService } from '../../_services/environment.service';
import { BrowserService } from '../../_services/browser.service';
import { COOKIE_FINGERPRINT_KEY } from '../constants.util';
import { LoggerService } from '../../_services/logger.service';

/**
 * Setup browser fingerprint on app initialisation
 * @param browserService
 * @param cookieService
 * @param loggerService
 */
export function fingerprintInitialiser(
    browserService: BrowserService,
    cookieService: CookieService,
    loggerService: LoggerService,
) {
    if (browserService.isServerPlatform()) {
        // Don't get fingerprint on server-side
        return () => {};
    }

    // Get fingerprint on client-side
    return () => new Promise(async (resolve, reject) => {

        if (cookieService.hasKey(COOKIE_FINGERPRINT_KEY)) {
            return resolve(null);
        }

        /**
         * Callback to get fingerprint after browser is idle
         */
        const callback = () => {
            setBrowserFingerprint(cookieService, loggerService)
                .then(resolve)
                .catch(reject);
        };

        /**
         * Note: Must use requestIdleCallback.
         *  You should not run fingerprinting directly on or after page load.
         *  Rather, delay it for a few milliseconds with setTimeout or requestIdleCallback to ensure consistent fingerprints.
         *  See #307, #254, and others.
         *  https://www.npmjs.com/package/fingerprintjs2#usage
         */
        if (window.requestIdleCallback) {
            requestIdleCallback(callback);
        } else {
            setTimeout(callback, 500);
        }

    });
}

/**
 * Stores the new browser fingerprint in a cookie
 * @param cookieService
 * @param logger
 */
const setBrowserFingerprint = async (cookieService: CookieService, logger: LoggerService) => {

    // Get array of unique properties about the current browsers eg. user agent, fonts, ip, etc
    const components = await Fingerprint2.getPromise();

    // Reduce component values into a single string and then hash the string
    const values = components.reduce((prev, component) => prev + component.value, '');
    const fingerprint = Fingerprint2.x64hash128(values, 31);

    logger.verbose('Storing browser fingerprint:', fingerprint);

    // Store fingerprint as a cookie
    const expires = new Date();
    expires.setFullYear(expires.getFullYear() + 1);
    cookieService.put(COOKIE_FINGERPRINT_KEY, fingerprint, {
        expires,
        path: '/',
        domain: EnvironmentService.cookieDomain,
    });

};
