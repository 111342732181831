/*
 * admin.guard.ts
 * Little Phil
 *
 * Created on 2/7/21
 * Copyright © 2018 Little Phil. All rights reserved.
 */

import { inject, Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateFn } from '@angular/router';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie';

import { USER_ROLE } from '@little-phil/js/lib/common/enums';

import { redirectToLogin } from '../_utils/router.util';
import { AuthService } from '../_services/auth.service';
import { COOKIE_LAST_USER_ROLE_KEY } from '../_utils/constants.util';
import { EnvironmentService } from '../_services/environment.service';
import { DashboardContextService } from '../dashboard/contexts/dashboard-context.service';

export function userRoleGuard(options: {
    acceptedRoles: USER_ROLE[],
}): CanActivateFn {
    const {
        acceptedRoles,
    } = options;

    return async () => {
        const authService = inject(AuthService);
        const router = inject(Router);

        // TODO: use Dashboard context?
        const user = authService.getUser();
        const allowed = acceptedRoles.includes(user.role);

        if (!allowed) {
            await router.navigateByUrl(user.getHomePageUrl());
            return false;
        }

        return true;
    };
}
