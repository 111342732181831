<app-dashboard-form-control
    [control]="control"
    [name]="name"
    [label]="label"
    [labelFontSize]="labelFontSize"
    [details]="details"
    [required]="required">

    <textarea
        class="block px-2 py-[14px] w-full border-[1px] border-reliable-blue-25 focus:border-reliable-blue outline-0 rounded transition-colors"
        type="text"
        [formControl]="control"
        [placeholder]="placeholder || ''"
        [rows]="rows">
    </textarea>

</app-dashboard-form-control>
