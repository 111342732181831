<div class="d-flex align-items-center">
    <h2 class="flex-grow-1" [innerText]="title"></h2>
    <ng-content></ng-content>
    <a
        *ngIf="buttonTitle && buttonLink"
        class="btn btn-outline-primary"
        [routerLink]="buttonLink"
        [innerText]="buttonTitle">
    </a>
</div>
