import { Component, ElementRef, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-svg-icon',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './svg-icon.component.html',
    styleUrls: ['./svg-icon.component.scss'],
})
export class SvgIconComponent {

    @Input()
    set svg(content: string) {
        if (typeof content === 'string') {
            this.element.nativeElement.innerHTML = content;
        }
    }

    constructor(private element: ElementRef) {}

}
