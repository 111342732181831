import { Component, Input } from '@angular/core';
import { OCCASIONAL_ORANGE } from '@little-phil/frontend/js/common/colours';
import { Campaign } from '../../../../_models/campaign.model';
import { Category } from '../../../../_models/category.model';

@Component({
    selector: 'app-campaign-progress-bar',
    templateUrl: './campaign-progress-bar.component.html',
    styleUrls: ['./campaign-progress-bar.component.scss'],
})
export class CampaignProgressBarComponent {

    @Input() set campaign(campaign: Campaign) {

        if (!campaign) {
            return;
        }

        this.goal = campaign.amount;
        this.amountGiven = campaign.amountGiven;

        if (campaign.category instanceof Category) {
            this.progressBarColour = campaign.category.colour;
        }

    }

    @Input() rounded: boolean;

    public progressBarColour: string;
    public goal: number;
    public amountGiven: number;

}
