/*
 * auth-interceptor.util.ts
 * Little Phil
 *
 * Created on 19/5/20
 * Copyright © 2018 Little Phil. All rights reserved.
 */
import { Injectable, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Add cookies for sessions
        request = request.clone({
            withCredentials: true
        });

        return next.handle(request);
    }

}

/**
 * Module is required to avoid TransferHttpCacheModule from overriding provider in AppModule
 */
@NgModule({
    providers: [{
        provide: HTTP_INTERCEPTORS,
        useClass: AuthInterceptor,
        multi: true
    }]
})
export class AuthInterceptorModule {}
