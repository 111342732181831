<div
    class="flex items-center gap-1 mb-2"
    [class.opacity-0]="hideSuggestedReplies">
    <div
        *ngFor="let suggestedReply of suggestedReplies"
        class="border-[1px] border-reliable-blue-50 hover:bg-reliable-blue-5 rounded py-1 px-2 cursor-pointer"
        (click)="newMessage.emit(suggestedReply)">
        {{suggestedReply}}
    </div>
</div>

<form
    [formGroup]="form"
    class="flex items-center gap-2 border-[1px] border-reliable-blue-50 rounded-full"
    (submit)="handleSubmit()">
    <div class="pl-2">
        <img
            class="h-[30px] aspect-square rounded-full bg-reliable-blue-5"
            src="../../../../assets/img/little-phil-icon-yellow.png"
            alt="Little Phil Logo">
    </div>

    <input
        [formControl]="form.controls.message"
        class="grow h-[50px] outline-none shadow-none appearance-none"
        type="text"
        placeholder="Ask me anything charity related...">

    <div class="pr-1">
        <button
            class="flex items-center justify-center gap-1 h-[40px] px-3 bg-secondary text-white rounded-full text-base hover:opacity-90 disabled:opacity-80"
            [disabled]="!form.valid"
        >
            Ask
        </button>
    </div>
</form>
