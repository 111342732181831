import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PaymentMethod } from '@stripe/stripe-js';

import { localTimezone } from '@little-phil/js/lib/utils/local-timezone';
import { COMPANY_EMPLOYEE_UPLOAD_MODE } from '@little-phil/js/lib/common/enums';
import { appendQuery } from '@little-phil/js/lib/utils/append-query';

import { timeout } from 'rxjs/operators';
import { BaseModel } from '../_models/_base.model';
import { Campaign } from '../_models/campaign.model';
import { CampaignUpdate } from '../_models/campaign-update.model';
import { Charity } from '../_models/charity.model';
import { Category } from '../_models/category.model';
import { Payment } from '../_models/payment.model';
import { Subscription } from '../_models/subscription.model';
import { Card } from '../_models/card.model';
import { IFeeRequest, IFeeResponse } from '../_interfaces/fees.interface';
import { IPaymentIntentRequest, IPaymentIntentResponse } from '../_interfaces/payment-intent.interface';
import { ICharityBalanceResponse, ICharityRaisedFundsResponse } from '../_interfaces/charity.interface';
import {
    IPaymentListAdminRequestQuery,
    IPaymentListAdminResponse,
    IPaymentStatusRequest,
    IRecentPayment,
    IRecentPaymentsQuery,
} from '../_interfaces/payment.interface';
import { LPFile } from '../_classes/lp-file.class';
import { IApplyResponse } from '../_interfaces/apply.interface';
import { CharityApplication } from '../_models/charity-application.model';
import { IMediaArticle } from '../_interfaces/media.interface';
import {
    ICharityShareLinkCreateRequest,
    IShareLinkCreateRequest,
    IShareLinkCreateResponse,
} from '../_interfaces/share-link.interface';
import { IDirectImpactResponse, IHasImpactResponse, INetworkImpactResponse } from '../_interfaces/impact.interface';
import { ICreditBalanceResponse, ICreditGiveRequest } from '../_interfaces/credit.interface';
import { IWelcomeCreditPool } from '../_interfaces/credit-pool.interface';
import { RedeemableCredit } from '../_models/redeemable-credit.model';
import { CreditPool } from '../_models/credit-pool.model';
import { IOKResponse, ISuccessResponse } from '../_interfaces/ok.interface';
import { ICountryTaxStatement, IEofyPdfStatementRequest } from '../_interfaces/tax-statement.interface';
import { environment } from '../../environments/environment';
import { Company } from '../_models/company.model';
import { ICompanyGivingCalculatePricesResponse } from '../_interfaces/company-giving-calculate-prices.interface';
import { LPFormData } from '../_classes/form-data.class';
import { ICompanyEmployeesUploadResponse } from '../_interfaces/company-employee.interface';
import { ICompanyBillingResponse, ICompanyGivingCredits } from '../_interfaces/company-billing';
import { Employee } from '../_models/employee.model';
import { SearchQuery } from '../_models/search-query.model';
import { Widget } from '../_models/widget.model';
import { ShareLink } from '../_models/share-link.model';
import { ShareEvent } from '../_models/share-event.model';
import { IPayoutsResponse } from '../_interfaces/payouts.interface';
import { ICharityOnboardingResponseData } from '../_interfaces/charity-onboarding.interface';
import { ICharityBillingCurrentPlan, ICharityBillingPlan } from '../_interfaces/charity-billing.interface';
import { COMPANY_FEATURE } from '@little-phil/js/lib/utils/feature-flags';

enum HTTPMethod {
    GET,
    POST,
    PATCH,
    DELETE
}

/**
 * Options that are required by the request function to determine the request to make
 */
interface IRequestOptions {
    data?: object;
    endpoint?: string; // custom endpoint for sub-router e.g. /v1/company/:companyId/employee/:id
    id?: string;
    method: HTTPMethod;
    query?: object;
}

/**
 * The charity report type is part of the endpoint to retrieve corresponding report (/v1/charity/:id/[type]-report)
 */
export enum CHARITY_REPORT_TYPE {
    CHARITY = 'charity',
    GIVER = 'giver',
    DONATION = 'donation',
    CAMPAIGN = 'campaign',
}

const CHARITY_TENANT_HEADER = 'X-Charity-Tenant';
const COMPANY_TENANT_HEADER = 'X-Company-Tenant';
const TIMEZONE_HEADER = 'X-Timezone';

const cacheBusterHeaders = {
    'Cache-Control': 'no-store, must-revalidate',
    'Expires': '0',
    'Pragma': 'no-cache',
};

@Injectable({
    providedIn: 'root',
})
export class ApiService {

    private readonly baseUrl;

    constructor(private http: HttpClient) {
        this.baseUrl = environment.apiUrl;
    }

    /**
     * Returns the appropriate URL string for the given params
     * @param model
     * @param options
     * @returns {string}
     */
    private getUrl<T extends BaseModel>(model: new() => T, options: IRequestOptions): string {
        // Default to empty string so that undefined is not added to the url
        const id = options.id || '';
        const query = options.query && this.generateQueryParams(options.query) || '';
        const endpoint = options.endpoint || model['endpoint'];

        return `${this.baseUrl}/v1/${endpoint}/${id}${query}`;
    }

    /**
     * Creates a query string from an object
     * @param query
     */
    private generateQueryParams(query: object): string {
        const entries = Object.entries(query);

        // NOTE: Object.entries().reduce() does not compile
        return entries.reduce((queryStr, data) => {
            const [key, value] = data;

            if (value instanceof Array) {
                if (value.length === 0) {
                    return queryStr;
                }
                const prefix = `${key}=`;
                queryStr += `${prefix}${value.join('&' + prefix)}&`;
            } else {
                queryStr += `${key}=${value}&`;
            }

            return queryStr;
        }, '?').slice(0, -1);
    }

    /**
     * Creates a http request that can be piped and subscribed
     * @param model
     * @param options
     */
    private request<T extends BaseModel>(model: new() => T, options: IRequestOptions): Promise<object | object[]> {
        const url = this.getUrl(model, options);
        const body = options.data;

        switch (options.method) {
            case HTTPMethod.GET:
                return this.http.get<T>(url).toPromise();
            case HTTPMethod.POST:
                return this.http.post<T>(url, body).toPromise();
            case HTTPMethod.PATCH:
                return this.http.patch<T>(url, body).toPromise();
            case HTTPMethod.DELETE:
                return this.http.delete<T>(url).toPromise();
        }
    }

    /**
     * Private helper for transforming a json response into a model instance
     * @param model
     */
    private deserializeSingle<T extends BaseModel>(model: new(json: object) => T): (data: object) => T {
        return (json: T) => new model(json);
    }

    /**
     * Private helper for transforming a json response into an array of model instances
     * @param model
     */
    private deserializeMultiple<T extends BaseModel>(model: new(json: object) => T): (data: object[]) => T[] {
        return (json: T[]) => json.map(d => new model(d));
    }

    /**
     * Generic function for retrieving a single record with the given id
     * @param model
     * @param id
     * @param query
     */
    private getOne<T extends BaseModel>(model: new() => T, id: string, query?: object): Promise<T> {
        const options: IRequestOptions = {
            method: HTTPMethod.GET,
            id,
            query,
        };

        return this.request(model, options).then(this.deserializeSingle(model));
    }

    /**
     * Generic function for retrieving an array of records for the given model
     * @param model
     * @param query
     */
    private getAll<T extends BaseModel>(model: new() => T, query?: object): Promise<T[]> {
        const options: IRequestOptions = {
            method: HTTPMethod.GET,
            query,
        };

        return this.request(model, options).then(this.deserializeMultiple(model));
    }

    /**
     * Generic function for creating a record for the given model with the given data
     * @param model
     * @param data
     */
    private create<T extends BaseModel>(model: new() => T, data: object | FormData): Promise<T> {
        const options: IRequestOptions = {
            method: HTTPMethod.POST,
            data,
        };

        return this.request(model, options).then(this.deserializeSingle(model));
    }

    /**
     * Generic function for updating the record with the given data
     * @param model
     * @param id
     * @param data
     */
    private update<T extends BaseModel>(model: new() => T, id: string, data: object): Promise<T> {
        const options: IRequestOptions = {
            method: HTTPMethod.PATCH,
            id,
            data,
        };

        return this.request(model, options).then(this.deserializeSingle(model));
    }

    /**
     * Generic function for deleting the record with the given data
     * @param model
     * @param id
     */
    private delete<T extends BaseModel>(model: new() => T, id: string): Promise<T> {
        const options: IRequestOptions = {
            method: HTTPMethod.DELETE,
            id,
        };

        return this.request(model, options).then(this.deserializeSingle(model));
    }

    /**
     * Generic function for searching records matching the given query
     * @param model
     * @param query
     * @param skip
     * @param limit
     */
    private search<T extends BaseModel>(model: new() => T, query: string, skip: number, limit: number) {
        const searchQuery = this.generateQueryParams({ query, skip, limit });
        const searchUrl = `${this.baseUrl}/v1/${model['endpoint']}/search${searchQuery}`;

        return this.http.get<T[]>(searchUrl)
            .pipe(timeout(8000))
            .toPromise()
            .then(this.deserializeMultiple(model));
    }

    /**
     * Create all CRUD routes for an API resource
     * The routes will be created even if they don't exist in the API
     * @param model
     */
    private crudRoutes<T extends BaseModel>(model: new() => T) {
        return {
            create: (data: object | FormData) => {
                return this.create<T>(model, data);
            },
            retrieve: (id: string, query?: object) => {
                return this.getOne<T>(model, id, query);
            },
            list: (query?: object) => {
                return this.getAll<T>(model, query);
            },
            update: (id: string, data: object) => {
                return this.update<T>(model, id, data);
            },
            delete: (id: string) => {
                return this.delete<T>(model, id);
            },
        };
    }


    /******************************************************************************************************************
     ** Campaign routes
     *****************************************************************************************************************/
    public readonly campaign = {
        ...this.crudRoutes(Campaign),

        /**
         * Searches for charities that the given query matches with skip and limit for pagination
         * @param query
         * @param skip
         * @param limit
         */
        search: (query: string, skip: number, limit: number) => {
            return this.search<Campaign>(Campaign, query, skip, limit);
        },

        /**
         * Allows an admin to approve a campaign in pending status
         * @param id
         */
        approve: (id: string) => {
            return this.http.get(`${this.baseUrl}/v1/campaign/${id}/approve`).toPromise();
        },

        /**
         * Retrieves the available balance of the provided campaign
         * @param id
         */
        availableBalance: (id: string) => {
            return this.http
                .get<{
                    availableBalance: number
                }>(`${this.baseUrl}/v1/campaign/${id}/available-balance`)
                .toPromise();
        },

        /**
         * Returns the URL for the donation report endpoint
         * @param {string} id - Campaign ID
         */
        getDonationReportURL: (id: string) => {
            return appendQuery(`${this.baseUrl}/v1/campaign/${id}/donation-report`, {
                timezone: localTimezone,
            });
        },

        /**
         * Creates a payout for the provided campaign
         * @param id
         * @param amount
         */
        payout: (id: string, amount: number) => {
            return this.http
                .post(`${this.baseUrl}/v1/campaign/${id}/payout`, { amount })
                .toPromise();
        },
    };


    /******************************************************************************************************************
     ** Campaign Update routes
     *****************************************************************************************************************/
    public readonly campaignUpdate = this.crudRoutes<CampaignUpdate>(CampaignUpdate);


    /******************************************************************************************************************
     ** Category routes
     *****************************************************************************************************************/
    public readonly category = this.crudRoutes<Category>(Category);


    /******************************************************************************************************************
     ** Charity routes
     *****************************************************************************************************************/
    public readonly charity = {
        ...this.crudRoutes<Charity>(Charity),

        createLead: (data: Record<string, any>) => {
            return this.http
                .post<Charity>(`${this.baseUrl}/v1/charity/lead`, data)
                .toPromise();
        },

        /**
         * Searches for charities that the given query matches with skip and limit for pagination
         * @param query
         * @param skip
         * @param limit
         */
        search: (query: string, skip: number, limit: number) => {
            return this.search<Charity>(Charity, query, skip, limit);
        },

        /**
         * Displays the Stripe KYC process to the user
         * @param id
         * @param redirectUrl
         */
        kyc: (id: string, redirectUrl: string) => {
            window.location.href = appendQuery(`${this.baseUrl}/v1/charity/${id}/kyc`, {
                successRedirect: redirectUrl,
                failureRedirect: redirectUrl,
            });
        },

        /**
         * Updates the charities platform fee percentage
         * @param id
         * @param lpFeePercentage
         */
        updatePlatformFee: (id: string, lpFeePercentage: number) => {
            return this.http.patch(`${this.baseUrl}/v1/charity/${id}/platform-fee`, { lpFeePercentage }).toPromise();
        },

        /**
         * Sets a charity status to approved
         * @param id
         */
        approve: (id: string) => {
            return this.http.get(`${this.baseUrl}/v1/charity/${id}/approve`).toPromise();
        },

        /**
         * Retrieves the available balance of the provided charity
         * @param id
         */
        availableBalance: (id: string) => {
            return this.http
                .get<{
                    availableBalance: number
                }>(`${this.baseUrl}/v1/charity/${id}/available-balance`)
                .toPromise();
        },

        /**
         * Retrieves the balance of the provided charity
         * @param id
         */
        balance: (id: string) => {
            return this.http.get<ICharityBalanceResponse>(`${this.baseUrl}/v1/charity/${id}/balance`).toPromise();
        },

        /**
         * Retrieves the lifetime raised amount and monthly summaries for the provided charity
         * @param id
         */
        raisedFunds: (id: string) => {
            return this.http.get<ICharityRaisedFundsResponse>(`${this.baseUrl}/v1/charity/${id}/raised-funds`).toPromise();
        },

        /**
         * Return the URL to the requested report endpoint
         * @param {CHARITY_REPORT_TYPE} reportType - Type of report
         * @param {string} id - Charity ID
         * @param {Date} [from] - Start date of report data
         * @param {Date} [to] - End date of report data
         */
        getReportURL: (reportType: CHARITY_REPORT_TYPE, id: string, from?: Date, to?: Date) => {
            return appendQuery(`${this.baseUrl}/v1/charity/${id}/${reportType}-report`, {
                from: from && from.toISOString(),
                to: to && to.toISOString(),
                timezone: localTimezone,
            });
        },

        /**
         * Create an application for the provided charity
         * @param {string} id - ID of the document for which the user applies
         * @param {Charity} application
         * @param files Files to attach
         */
        apply: (id: string, application: CharityApplication, files: LPFile[]) => {
            const applyUrl = `${this.baseUrl}/v1/charity/${id}/apply`;
            return this.http.post<IApplyResponse>(applyUrl, LPFormData.from(application, files)).toPromise();
        },

        /**
         * Activate a charity
         * @param id
         */
        activate: (id: string) => {
            return this.http.get<IOKResponse>(`${this.baseUrl}/v1/charity/${id}/activate`).toPromise();
        },

        /**
         * Unlists a charity
         * @param id
         */
        unlist: (id: string) => {
            return this.http.post<IOKResponse>(`${this.baseUrl}/v1/charity/${id}/unlist`, {}).toPromise();
        },

        /**
         * Creates a payout for the provided campaign
         * @param id
         * @param amount
         */
        payout: (id: string, amount: number) => {
            return this.http
                .post(`${this.baseUrl}/v1/charity/${id}/payout`, { amount })
                .toPromise();
        },

        /**
         * Charity share link routes
         */
        shareLinks: (charityId: string) => ({
            /**
             * Creates a share link owned by the charity
             * @param data
             */
            create: (data: ICharityShareLinkCreateRequest) => {
                return this.http.post<ShareLink>(`${this.baseUrl}/v1/charity/${charityId}/share-links`, data).toPromise();
            },

            /**
             * Deletes the share link
             * @param id
             */
            delete: (id: string) => {
                return this.http
                    .delete<ISuccessResponse>(`${this.baseUrl}/v1/charity/${charityId}/share-links/${id}`)
                    .toPromise();
            },

            /**
             * Returns the charity share link
             * @param id
             */
            events: (id: string) => {
                return this.http
                    .get<ShareEvent[]>(`${this.baseUrl}/v1/charity/${charityId}/share-links/${id}/events`)
                    .toPromise()
                    .then(this.deserializeMultiple(ShareEvent));
            },

            /**
             * Get all share links for a charity
             */
            list: (query?: object) => {
                const queryParams = this.generateQueryParams(query);
                return this.http
                    .get<ShareLink[]>(`${this.baseUrl}/v1/charity/${charityId}/share-links${queryParams}`)
                    .toPromise()
                    .then(this.deserializeMultiple(ShareLink));
            },

            /**
             * Returns the charity share link
             * @param id
             */
            retrieve: (id: string) => {
                return this.http
                    .get<ShareLink>(`${this.baseUrl}/v1/charity/${charityId}/share-links/${id}`)
                    .toPromise()
                    .then(this.deserializeSingle(ShareLink));
            },

            /**
             * Updates a charity share link
             * @param id
             * @param data
             */
            update: (id: string, data: ICharityShareLinkCreateRequest) => {
                return this.http
                    .patch<ShareLink>(`${this.baseUrl}/v1/charity/${charityId}/share-links/${id}`, data)
                    .toPromise()
                    .then(this.deserializeSingle(ShareLink));
            },
        }),

        addToWaitList: (data: Record<string, any>) => {
            return this.http
                .post(`${this.baseUrl}/v1/charity/waitlist`, data)
                .toPromise();
        },

        requestOwnership: (id: string) => {
            return this.http
                .get(`${this.baseUrl}/v1/charity/${id}/request-ownership`)
                .toPromise();
        },

        onboarding: (id: string) => {
            return this.http
                .get<ICharityOnboardingResponseData>(`${this.baseUrl}/v1/charity/${id}/onboarding`)
                .toPromise();
        },

        billing: {
            availablePlans: () => {
                return this.http
                    .get<ICharityBillingPlan[]>(`${this.baseUrl}/v1/charity/billing/available-plans`)
                    .toPromise();
            },

            currentPlan: (data: {
                charityId: string
            }) => {
                return this.http.get<ICharityBillingCurrentPlan>(`${this.baseUrl}/v1/charity/billing/current-plan`, {
                    headers: {
                        'X-Charity-Tenant': data.charityId,
                    },
                }).toPromise();
            },

            subscribe: (data: {
                charityId: string,
                priceId: string,
                returnUrl: string,
            }) => {
                return this.http
                    .post<{
                        url: string
                    }>(
                        `${this.baseUrl}/v1/charity/billing/subscribe`,
                        {
                            price: data.priceId,
                            cancelUrl: data.returnUrl,
                            successUrl: data.returnUrl,
                        },
                        {
                            headers: {
                                'X-Charity-Tenant': data.charityId,
                            },
                        },
                    ).toPromise();
            },

            manage: (data: {
                charityId: string,
                returnUrl: string,
            }) => {
                return this.http
                    .post<{
                        url: string
                    }>(
                        `${this.baseUrl}/v1/charity/billing/manage`,
                        {
                            returnUrl: data.returnUrl,
                        },
                        {
                            headers: {
                                'X-Charity-Tenant': data.charityId,
                            },
                        },
                    ).toPromise();
            },
        },
    };

    /******************************************************************************************************************
     ** Company routes
     *****************************************************************************************************************/
    public readonly company = {
        ...this.crudRoutes<Company>(Company),

        onboarding: (id: string) => {
            return this.http
                .get<{
                    hasProfile: boolean
                    hasEmployees: boolean
                    hasSetupGivingCredits: boolean
                }>(`${this.baseUrl}/v1/company/onboarding`, {
                    headers: {
                        [COMPANY_TENANT_HEADER]: id,
                    },
                })
                .toPromise();
        },

        stats: (id: string) => {
            return this.http
                .get<{
                    totalImpact: number
                    monthlyImpact: number
                    employees: number
                    nextBillingDate: string | null
                }>(`${this.baseUrl}/v1/company/stats`, {
                    headers: {
                        [COMPANY_TENANT_HEADER]: id,
                    },
                })
                .toPromise();
        },

        employees: {

            /**
             * Add an employee to the company and create it on the fly if it doesn't exist
             *
             * @param {string} companyId - The company id
             * @param {Object} data - The new employee details
             */
            create: async (companyId: string, data: object | FormData) => {
                const options: IRequestOptions = {
                    endpoint: Employee.getEndpoint(companyId),
                    method: HTTPMethod.POST,
                    data,
                };

                return this.request(Employee, options).then(this.deserializeSingle(Employee));
            },

            /**
             * Remove an employee from the company
             *
             * @param {string} companyId - The company id
             * @param {string} email - The email of the employee to remove from the company
             */
            delete: async (companyId: string, email: string) => {
                const query = '?employeeEmail=' + encodeURIComponent(email);
                return this.http.delete<IOKResponse>(`${this.baseUrl}/v1/company/${companyId}/employee${query}`).toPromise();
            },

            /**
             * Returns a list of the company employees
             *
             * Note: This returns a function so as to conform to the LazyLoadedCollection load function format
             * @param {string} companyId - The company id
             */
            list: (companyId: string) => {
                return async (query?: object) => {
                    const options: IRequestOptions = {
                        endpoint: Employee.getEndpoint(companyId),
                        method: HTTPMethod.GET,
                        query,
                    };

                    const url = this.getUrl(Employee, options);
                    return this.http
                        .get(url)
                        .toPromise()
                        .then(this.deserializeMultiple(Employee));
                };
            },

            /**
             * Upload a CSV of employees to a Company
             *
             * @param {string} id - The company ID
             * @param {LPFile} csvFile - The CSV file to upload
             * @param {boolean} includesHeader - Whether the CSV file contains a header row
             * @param {COMPANY_EMPLOYEE_UPLOAD_MODE} uploadMode - The upload mode to apply
             */
            upload: (id: string, csvFile: LPFile, includesHeader: boolean, uploadMode: COMPANY_EMPLOYEE_UPLOAD_MODE) => {
                const data = LPFormData.from({ includesHeader, uploadMode }, [csvFile]);
                return this.http.post<ICompanyEmployeesUploadResponse>(
                    `${this.baseUrl}/v1/company/${id}/employee/upload`,
                    data,
                    { observe: 'response' }, // option to return the full HttpResponse
                ).toPromise();
            },
        },

        features: {
            add: (companyId: string, feature: COMPANY_FEATURE) => {
                return this.http.post<IOKResponse>(
                    this.baseUrl + `/v1/company/${companyId}/features/add`,
                    { feature },
                    {
                        headers: {
                            [COMPANY_TENANT_HEADER]: companyId,
                        },
                    },
                ).toPromise();
            },

            remove: (companyId: string, feature: COMPANY_FEATURE) => {
                return this.http.post<IOKResponse>(
                    this.baseUrl + `/v1/company/${companyId}/features/remove`,
                    { feature },
                    {
                        headers: {
                            [COMPANY_TENANT_HEADER]: companyId,
                        },
                    },
                ).toPromise();
            },
        },

        givingCredits: {
            activate: (companyId: string, amount: number, startDate: Date) => {
                return this.http.post<{
                    url: string
                }>(
                    this.baseUrl + '/v1/company/giving-credits/activate',
                    { amount, startDate: startDate.getTime() },
                    {
                        headers: {
                            [COMPANY_TENANT_HEADER]: companyId,
                            [TIMEZONE_HEADER]: localTimezone,
                        },
                    },
                ).toPromise();
            },

            retrieve: (companyId: string) => {
                return this.http.get<ICompanyGivingCredits>(this.baseUrl + '/v1/company/giving-credits', {
                    headers: {
                        [COMPANY_TENANT_HEADER]: companyId,
                    },
                }).toPromise();
            },

            resume: (companyId: string) => {
                return this.http.post<ICompanyGivingCredits>(this.baseUrl + '/v1/company/giving-credits/resume', {}, {
                    headers: {
                        [COMPANY_TENANT_HEADER]: companyId,
                    },
                }).toPromise();
            },

            pause: (companyId: string) => {
                return this.http.post<ICompanyGivingCredits>(this.baseUrl + '/v1/company/giving-credits/pause', {}, {
                    headers: {
                        [COMPANY_TENANT_HEADER]: companyId,
                    },
                }).toPromise();
            },

            manage: (companyId: string) => {
                return this.http.post<{
                    url: string
                }>(
                    this.baseUrl + '/v1/company/giving-credits/manage',
                    {},
                    {
                        headers: {
                            [COMPANY_TENANT_HEADER]: companyId,
                        },
                    },
                ).toPromise();
            },

            update: (companyId: string, update: {
                amount?: number,
                // billingDate?: Date
            }) => {
                const body: Record<string, any> = {};

                if (typeof update.amount === 'number') {
                    body.amount = update.amount;
                }

                // if (update.billingDate) {
                //     body.billingDate = update.billingDate.getTime();
                // }

                return this.http.patch<ICompanyGivingCredits>(
                    this.baseUrl + '/v1/company/giving-credits',
                    body,
                    {
                        headers: {
                            [COMPANY_TENANT_HEADER]: companyId,
                            [TIMEZONE_HEADER]: localTimezone,
                        },
                    },
                ).toPromise();
            },
        },
    };

    /******************************************************************************************************************
     ** Credit routes
     *****************************************************************************************************************/
    public readonly credit = {

        create: this.crudRoutes<RedeemableCredit>(RedeemableCredit).create,
        list: this.crudRoutes<RedeemableCredit>(RedeemableCredit).list,

        /**
         * Redeems redeemable credit
         * @param code - The redeemable credit's code
         */
        redeem: (code: string) => {
            return this.http.post(`${this.baseUrl}/v1/credit/redeem`, { code }).toPromise();
        },

        /**
         * Retrieve a user's balance
         * @param [email] - email of a different user to query balance for
         */
        retrieveBalance: (email?: string) => {
            let query = '';
            if (email) {
                query = '?email=' + encodeURIComponent(email);
            }
            return this.http.get<ICreditBalanceResponse>(`${this.baseUrl}/v1/credit/balance${query}`).toPromise();
        },

        /**
         * Creates a donations with 100% credit
         * @param data - Request body
         */
        give: (data: ICreditGiveRequest) => {
            return this.http.post<Payment>(`${this.baseUrl}/v1/credit/give`, data).toPromise().then(this.deserializeSingle(Payment));
        },

    };

    /******************************************************************************************************************
     ** Credit pool routes
     *****************************************************************************************************************/
    public readonly creditPool = {

        ...this.crudRoutes(CreditPool),

        /**
         * Claims free credit from a 'welcome' credit pool
         */
        claimWelcomeCredit: () => {
            return this.http.get<{
                message: string
            }>(`${this.baseUrl}/v1/credit-pool/claim-welcome-credit`).toPromise();
        },

        /**
         * Returns the first live welcome credit pool details
         */
        firstLiveWelcomePool: () => {
            return this.http.get<IWelcomeCreditPool>(`${this.baseUrl}/v1/credit-pool/first-live-welcome-pool`).toPromise();
        },

    };

    /******************************************************************************************************************
     ** Impact routes
     *****************************************************************************************************************/
    public readonly impact = {

        /**
         * Returns whether the user has made an impact yet
         */
        hasImpact: () => {
            return this.http.get<IHasImpactResponse>(`${this.baseUrl}/v1/impact/has-impact`).toPromise();
        },

        /**
         * Returns the areas where the user has made a direct impact
         */
        directImpact: () => {
            return this.http.get<IDirectImpactResponse>(`${this.baseUrl}/v1/impact/direct-impact`).toPromise();
        },

        /**
         * Returns the areas where the user's network has made impact
         */
        networkImpact: () => {
            return this.http.get<INetworkImpactResponse>(`${this.baseUrl}/v1/impact/network-impact`).toPromise();
        },

    };


    /******************************************************************************************************************
     ** Media routes
     *****************************************************************************************************************/
    public readonly media = {

        /**
         * Returns a list of media articles
         */
        list: () => {
            return this.http.get<IMediaArticle[]>(`${this.baseUrl}/v1/media`).toPromise();
        },

    };


    /******************************************************************************************************************
     ** Payment routes
     *****************************************************************************************************************/
    public readonly payment = {
        retrieve: (id: string) => {
            return this.getOne<Payment>(Payment, id);
        },

        retrieveAdmin: (id: string) => {
            return this.http.get<IPaymentListAdminResponse>(`${this.baseUrl}/v1/payment/admin/${id}`).toPromise();
        },

        retrieveCharity: (charityId: string) => (id: string) => {
            return this.http
                .get<IPaymentListAdminResponse>(
                    `${this.baseUrl}/v1/payment/charity/${id}`,
                    {
                        headers: {
                            [CHARITY_TENANT_HEADER]: charityId,
                        },
                    },
                )
                .toPromise();
        },

        retrieveCompany: (companyId: string) => (id: string) => {
            return this.http
                .get<IPaymentListAdminResponse>(
                    `${this.baseUrl}/v1/payment/company/${id}`,
                    {
                        headers: {
                            [COMPANY_TENANT_HEADER]: companyId,
                        },
                    },
                )
                .toPromise();
        },

        list: (query?: object) => {
            return this.getAll<Payment>(Payment, query);
        },

        listAdmin: (query?: IPaymentListAdminRequestQuery) => {
            const queryParams = this.generateQueryParams(query);
            return this.http
                .get<IPaymentListAdminResponse[]>(`${this.baseUrl}/v1/payment/admin${queryParams}`)
                .toPromise();
        },

        listCharity: (charityId: string) => (query?: IPaymentListAdminRequestQuery) => {
            const queryParams = this.generateQueryParams(query);
            return this.http
                .get<IPaymentListAdminResponse[]>(
                    `${this.baseUrl}/v1/payment/charity${queryParams}`,
                    {
                        headers: {
                            [CHARITY_TENANT_HEADER]: charityId,
                        },
                    },
                )
                .toPromise();
        },

        listCompany: (companyId: string) => (query?: IPaymentListAdminRequestQuery) => {
            const queryParams = this.generateQueryParams(query);
            return this.http
                .get<IPaymentListAdminResponse[]>(
                    `${this.baseUrl}/v1/payment/company${queryParams}`,
                    {
                        headers: {
                            [COMPANY_TENANT_HEADER]: companyId,
                        },
                    },
                )
                .toPromise();
        },

        /**
         * Returns recent donations for a charity, campaign or user
         * @param query
         */
        recent: (query: IRecentPaymentsQuery) => {
            const queryParams = this.generateQueryParams(query);
            return this.http.get<IRecentPayment[]>(`${this.baseUrl}/v1/payment/recent${queryParams}`).toPromise();
        },

        /**
         * Returns the status of a payment
         * @param query - Endpoint input parameters
         */
        status: (query: IPaymentStatusRequest) => {
            return this.http.get<Payment>(`${this.baseUrl}/v1/payment/status${this.generateQueryParams(query)}`).toPromise();
        },

        /**
         * Returns the tax statement broken down by country
         */
        eofyStatements: () => {
            return this.http.get<ICountryTaxStatement[]>(`${this.baseUrl}/v1/payment/eofy-statements`).toPromise();
        },

        /**
         * Returns the URL for an EOFY statement PDF
         * @param query
         */
        getEofyPDFStatementURL: (query: IEofyPdfStatementRequest) => {
            return appendQuery(`${this.baseUrl}/v1/payment/eofy-pdf-statement`, { ...query });
        },
    };


    /******************************************************************************************************************
     ** Payout routes
     *****************************************************************************************************************/
    public readonly payouts = {
        list: () => {
            return this.http.get<IPayoutsResponse[]>(`${this.baseUrl}/v1/payouts`).toPromise();
        },
    };


    /******************************************************************************************************************
     ** Search query routes
     *****************************************************************************************************************/
    public readonly searchQuery = {
        list: this.crudRoutes<SearchQuery>(SearchQuery).list,
    };


    /******************************************************************************************************************
     ** Share event routes
     *****************************************************************************************************************/
    public readonly shareEvent = {
        /**
         * Completes a ShareEvent by providing a browser fingerprint
         * @param guestEmail - Email of the guest, if any
         */
        complete: (guestEmail?: string) => {
            let query = '';
            if (guestEmail) {
                query = '?email=' + encodeURIComponent(guestEmail);
            }
            return this.http.get(`${this.baseUrl}/v1/share-event/complete${query}`).toPromise();
        },
    };


    /******************************************************************************************************************
     ** Share link routes
     *****************************************************************************************************************/
    public readonly shareLink = {
        /**
         * Creates a new unique ShareLink with the given data
         * @param data
         */
        create: (data: IShareLinkCreateRequest) => {
            return this.http.post<IShareLinkCreateResponse>(`${this.baseUrl}/v1/share-link`, data).toPromise();
        },

        /**
         * Returns the share link
         * @param id
         */
        retrieve: (id: string) => {
            return this.http
                .get<ShareLink>(`${this.baseUrl}/v1/share-link/${id}`)
                .toPromise()
                .then(this.deserializeSingle(ShareLink));
        },
    };

    /******************************************************************************************************************
     ** Shopify routes
     *****************************************************************************************************************/
    public readonly shopify = {
        checkStore: (url: string) => {
            const query = new URLSearchParams({
                url,
            });

            return this.http.get<{
                isShopifyStore: boolean
            }>(`${this.baseUrl}/v1/shopify/check-store?${query}`).toPromise();
        },
    };




    /******************************************************************************************************************
     ** Shop & Support routes
     *****************************************************************************************************************/
    public readonly shopAndSupport = {
        charity: {
            impact: (id: string) => {
                interface Res {
                    a: string;
                }

                return this.http.get<Res>(`${this.baseUrl}/v1/shop-and-support/charity/${id}/impact`).toPromise();
            },
        },
    };



    /******************************************************************************************************************
     ** Stripe routes
     *****************************************************************************************************************/
    public readonly stripe = {
        card: this.crudRoutes<Card>(Card),

        paymentIntent: {
            /**
             * Creates a payment intent
             * @param data
             */
            create: (data: IPaymentIntentRequest) => {
                return this.http.post<IPaymentIntentResponse>(`${this.baseUrl}/v1/stripe/payment-intent`, data).toPromise();
            },

            /**
             * Returns the given payment intent from Stripe
             * @param id
             */
            retrieve: (id: string) => {
                return this.http.get<IPaymentIntentResponse>(`${this.baseUrl}/v1/stripe/payment-intent/${id}`).toPromise();
            },
        },

        paymentMethods: {
            /**
             * Detaches a PaymentMethod from the Customer
             * @param id
             */
            delete: (id: string) => {
                return this.http.delete<{
                    success: boolean
                }>(`${this.baseUrl}/v1/stripe/payment-methods/${id}`).toPromise();
            },

            /**
             * Lists of the available PaymentMethods for the Customer
             */
            list: () => {
                return this.http.get<PaymentMethod[]>(`${this.baseUrl}/v1/stripe/payment-methods`).toPromise();
            },
        },

        /**
         * Calls out to the API to check and display the fees for the current donation
         * @param data
         */
        calculatePlatformFees: (data: IFeeRequest) => {
            return this.http.post<IFeeResponse>(`${this.baseUrl}/v1/stripe/fees`, data).toPromise();
        },
    };


    /******************************************************************************************************************
     ** Subscription routes
     *****************************************************************************************************************/
    public readonly subscription = {
        ...this.crudRoutes<Subscription>(Subscription),

        manage: (query?: {
            redirect?: string,
            t?: string
        }) => {
            const url = appendQuery(`${this.baseUrl}/v1/subscription/manage`, query);
            return this.http.get<{
                url: string
            }>(url).toPromise();
        },
    };

    /******************************************************************************************************************
     ** Widget routes
     *****************************************************************************************************************/
    public readonly widgets = this.crudRoutes<Widget>(Widget);

}
