/*
 * search-query.model.ts
 * Little Phil
 *
 * Created on 6/8/22
 * Copyright © 2018 Little Phil. All rights reserved.
 */

import { BaseModel } from './_base.model';

export class SearchQuery extends BaseModel {

    public static endpoint = 'search-query';

    // Properties
    public readonly index: string = undefined;
    public readonly query: string = undefined;
    public readonly filter: string = undefined;
    public readonly skip: number = undefined;
    public readonly limit: number = undefined;
    public readonly hits: number = undefined;

    /**
     * Constructor
     * @param {object} json
     */
    constructor(json: object = {}) {
        super();
        super.init(json);
    }

}
