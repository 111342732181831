import { AfterViewInit, Component } from '@angular/core';
import { CollapseService } from '../../services/collapse.service';

@Component({
    selector: 'app-collapse',
    templateUrl: './collapse.component.html',
    styleUrls: ['./collapse.component.scss'],
})
export class CollapseComponent implements AfterViewInit {

    public hash = Math.random().toString(36).substring(2, 5);
    public id = 'collapse-' + this.hash;

    public collapseElement: HTMLElement;

    constructor(private collapseContext: CollapseService) {}

    ngAfterViewInit() {
        this.collapseElement = document.getElementById(this.id);
    }

    toggle() {
        if (this.transitioning) {
            return;
        }

        // Start transition
        this.collapseContext.transitioning = true;
        this.setTransitionStyles(); // Set the max-height of the body for the transition

        if (!this.collapsed) {
            setTimeout(() => {
                // Clear the transition values after 0ms to allow the DOM to know the before and after height
                this.clearTransitionStyles();
            }, 0);
        }

        // After the transition, remove the styling and flip the state
        setTimeout(() => {
            this.clearTransitionStyles();
            this.collapseContext.collapsed = !this.collapsed;
            this.collapseContext.transitioning = false;
        }, 350);
    }

    private setTransitionStyles() {
        this.collapseElement.style.display = 'block';
        this.collapseElement.style.maxHeight = this.collapseElement.scrollHeight + 'px';
    }

    private clearTransitionStyles() {
        this.collapseElement.style.display = null;
        this.collapseElement.style.maxHeight = null;
    }

    get collapsed() {
        return this.collapseContext.collapsed;
    }

    get transitioning() {
        return this.collapseContext.transitioning;
    }

}
