"use strict";
/*
 * regex.ts
 * Little Phil
 *
 * NOTE: sort alphabetically to make it easier to look for something and reduce conflicts opportunities
 *
 * Created on 5/8/20
 * Copyright © 2018 Little Phil. All rights reserved.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.websitePattern = exports.sortStringPattern = exports.phoneNumberPattern = exports.passwordPattern = exports.objectIdPattern = exports.ipv4Pattern = exports.hexColourPattern = exports.emailPattern = exports.dateOfBirthPattern = exports.cardNumberPattern = exports.abnPattern = exports.businessTaxIdPattern = exports.accentedLettersWhitespaceDashAmpersandPattern = void 0;
/**
 * RegExp for testing for letters, spaces, dashes and ampersands
 */
exports.accentedLettersWhitespaceDashAmpersandPattern = /^[A-zÀ-ú\s\-&]*$/;
/**
 * RegExp for testing ABN, ACN or TFN format
 * ABN is 11 digits
 * https://abr.business.gov.au/Help/AbnFormat#:~:text=The%20Australian%20Business%20Number%20(ABN,with%20two%20leading%20check%20digits
 * ACN is 9 digits
 * https://asic.gov.au/for-business/registering-a-company/steps-to-register-a-company/australian-company-numbers/australian-company-number-
 * digit-check/#:~:text=The%20Australian%20Company%20Number%20(ACN,block%20separated%20by%20a%20blank
 * TFN is 8 digits for businesses, 9 digits for individuals https://en.wikipedia.org/wiki/Tax_file_number
 * Inspiration for the regex: https://stackoverflow.com/a/15134706
 */
exports.businessTaxIdPattern = /(^(\d *?){8}$)|(^(\d *?){9}$)|(^(\d *?){11}$)/;
/**
 * RegExp for testing ABN format
 * ABN is 11 digits
 * https://abr.business.gov.au/Help/AbnFormat#:~:text=The%20Australian%20Business%20Number%20(ABN,with%20two%20leading%20check%20digits
 * Inspiration for the regex: https://stackoverflow.com/a/15134706
 */
exports.abnPattern = /^(\d *?){11}$/;
/**
 * RegExp for testing card numbers
 */
exports.cardNumberPattern = /^[0-9]{4}$/;
/**
 * RegExp for testing dd/mm/yyyy formatted date of births
 */
exports.dateOfBirthPattern = /[0-9]{2}[/][0-9]{2}[/][0-9]{4}$/;
/**
 * RegExp for testing emails w/ support for Gmail + emails
 */
exports.emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
/**
 * RegExp for testing hexadecimal colour codes e.g. #FFFFFF
 * It matches #fff #ffffff #FFF #FFFFFF (3 and 6 digits, case insensitive)
 */
exports.hexColourPattern = /^#([0-9a-f]{3}){1,2}$/i;
/**
 * RegExp for testing IPV4 addresses
 */
exports.ipv4Pattern = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/;
/**
 * RegExp for testing MongoDB Object IDs
 * copied from `joi-objectid` library
 */
exports.objectIdPattern = /^[0-9a-fA-F]{24}$/;
/**
 * RegExp for testing passwords with at least 1 lowercase, 1 uppercase and 1 number with a length between 8 and 64
 */
exports.passwordPattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,64}$/;
/**
 * RegExp for testing 10 digit phone numbers with an optional country code
 */
exports.phoneNumberPattern = /^(?:\+?(\d{2}))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/;
/**
 * RegExp for testing sort query params e.g. name.asc,age
 */
exports.sortStringPattern = /^[\w]+(\.(asc|desc))?(,[\w]+(\.(asc|desc))?)*$/;
/**
 * RegExp for testing website URLs with optional http(s) and www
 */
exports.websitePattern = /(https?:\/\/)?(www\.)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&\/=]*)|(https?:\/\/)?(www\.)?(?!ww)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&\/=]*)/;
