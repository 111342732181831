import { Component, ElementRef, inject, Input, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgIconComponent } from '../../svg-icon/svg-icon.component';
import { WidgetLoaderComponent } from '../../widget-loader/widget-loader.component';
import { ModalCloseComponent } from '../modal-close/modal-close.component';
import { ModalService } from '../../../_services/modal.service';
import { fadeInOutFastAnimation } from '../../../_animations/fadein-in-out-fast.animation';
import { CROSS_24_SVG } from '../../../_utils/icons-brand.util';
import { scaleUpInOutAnimation } from '../../../_animations/scale-up-in-out.animation';

@Component({
    selector: 'app-modal-content',
    standalone: true,
    imports: [CommonModule, SvgIconComponent, WidgetLoaderComponent, ModalCloseComponent],
    templateUrl: './modal-content.component.html',
    styles: [
        `
            :host {
                display: block;
            }
        `,
    ],
    animations: [
        fadeInOutFastAnimation,
        scaleUpInOutAnimation,
    ],
})
export class ModalContentComponent {

    @ViewChild('background', { static: false }) backgroundDiv: ElementRef<HTMLDivElement>;

    @Input() dismissOnBackdropClick = true;
    @Input() modalContentBackgroundColor = '#fefefe';
    @Input() size: 'sm' | 'md' | 'lg' | 'xl' = 'md';
    @Input() disablePadding = false;

    protected readonly CROSS_24_SVG = CROSS_24_SVG;

    public readonly modalService = inject(ModalService);

    public closeModal({ target }) {
        if (target === this.backgroundDiv.nativeElement && this.dismissOnBackdropClick) {
            this.modalService.setOpen(false);
        }
    }

}
