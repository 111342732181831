/*
 * refresh-token-interceptor.util.ts
 * Little Phil
 *
 * Created on 19/5/20
 * Copyright © 2018 Little Phil. All rights reserved.
 */
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Injectable, NgModule } from '@angular/core';
import { Router } from '@angular/router';
import {
    HTTP_INTERCEPTORS,
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';

import { ROUTE_PATHS } from '@little-phil/js/lib/common/constants';

import { LoaderService } from '../../_services/loader.service';
import { SentryService } from '../../_services/sentry.service';
import { LoggerService } from '../../_services/logger.service';
import { AuthService, REFRESH_ENDPOINT } from '../../_services/auth.service';
import { BrowserService } from '../../_services/browser.service';

const UNAUTHORISED = 401;

/**
 * Status to describe the state of the authenticationSubject
 */
enum REFRESH_RESPONSE_STATUS {
    NONE = 'none',
    SUCCESS = 'success',
}

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {

    // Authenticated Subject tracks the authentication state.
    // If null then the state is unknown (expired)
    // If true then the user if currently authenticated
    private authenticatedSubject = new BehaviorSubject<REFRESH_RESPONSE_STATUS>(REFRESH_RESPONSE_STATUS.NONE);
    private isRefreshing = false;
    private redirectingOnFailedRefresh = false;

    constructor(
        public authService: AuthService,
        private router: Router,
        private sentry: SentryService,
        private logger: LoggerService,
        private loaderService: LoaderService,
        private browserService: BrowserService,
        private toastr: ToastrService,
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((error) => {
                if (request.url.endsWith(REFRESH_ENDPOINT)) {
                    return this.handleFailedRefresh(error, request, next);
                } else if (error instanceof HttpErrorResponse && error.status === UNAUTHORISED) {
                    return this.handle401Error(error, request, next);
                } else {
                    return throwError(error);
                }
            }),
            catchError((error) => {
                this.logger.error(error);
                this.sentry.trackIssue(error);
                return throwError(error);
            }),
        );
    }

    /**
     * Handler for when a refresh token request is triggered and fails
     * @param error
     * @param request
     * @param next
     * @private
     */
    private handleFailedRefresh(error: any, request: HttpRequest<any>, next: HttpHandler) {

        // Update state
        this.isRefreshing = false;
        const message = 'Your session has ended. Please sign back in to resume.';

        const currentRouteIsExplore = this.router.url.startsWith(ROUTE_PATHS.web.explore);

        // NOTE: This is a hack work around for the issue caused by multiple requests redirecting to login.
        //  Login page has a <- Back button that pops the browser stack. This can cause issues with going back to an auth page.
        //  It's better UX to handle this case in this way instead of changing the login back button.
        this.authService.logout(null, { disableRedirect: true })
            .catch(console.error)
            .finally(() => {
                if (currentRouteIsExplore) {
                    // NOTE: Force the redirect outside of Angular to prevent state retention issues.
                    window.location.href = `${ROUTE_PATHS.web.explore}?error=${message}`;
                } else {
                    this.toastr.error(message);
                    this.router.navigateByUrl(ROUTE_PATHS.web.explore)
                        .catch(console.error)
                        .finally(() => this.loaderService.hideLoader());
                }
            });

        return throwError(new Error(message));
    }

    /**
     * Handler for when a request is made an a 401 is returned
     * @param error
     * @param request
     * @param next
     * @private
     */
    private handle401Error(error: any, request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // redirect 401 errors to loading page for server as SSR doesn't have access the refresh token cookie
        if (this.browserService.isServerPlatform()) {
            if (!this.router.isActive(ROUTE_PATHS.web.loading, false)) {
                this.router.navigate([ROUTE_PATHS.web.loading], {
                    queryParams: { redirectUrl: this.router.url },
                }).catch(this.logger.error);
            }

            return of();
        }

        if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.authenticatedSubject.next(REFRESH_RESPONSE_STATUS.NONE);

            this.logger.verbose('Triggering token refresh from', request.url);

            return this.authService.refreshAccessToken().pipe(
                switchMap(() => {
                    this.logger.verbose('Resuming request to', request.url, 'that triggered token refresh');

                    // Update state
                    this.isRefreshing = false;
                    this.authenticatedSubject.next(REFRESH_RESPONSE_STATUS.SUCCESS);

                    // Retry failed request that triggered the refresh
                    return next.handle(request);
                }),
            );
        } else {
            this.logger.verbose('Queueing request to', request.url);
            return this.authenticatedSubject.pipe(
                filter(authenticated => authenticated !== REFRESH_RESPONSE_STATUS.NONE),
                take(1),
                switchMap(() => {
                    this.logger.verbose('Resuming request to', request.url);

                    // Try pending requests
                    return next.handle(request);
                }),
            );
        }
    }
}

/**
 * Module is required to avoid TransferHttpCacheModule from overriding provider in AppModule
 */
@NgModule({
    providers: [{
        provide: HTTP_INTERCEPTORS,
        useClass: RefreshTokenInterceptor,
        multi: true,
    }],
})
export class RefreshTokenInterceptorModule {}
