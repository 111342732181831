import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

/**
 * MODULES
 */
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { LpTooltipModule } from '../lp-tooltip/lp-tooltip.module';

const modules = [
    CommonModule,
    LazyLoadImageModule,
    LpTooltipModule,
];

/**
 * DIRECTIVES
 */
import { DynamicComponentContainerDirective } from './directives/dynamic-component-container.directive';
import { HorizontalCollapseDirective } from './directives/horizontal-collapse.directive';
import { LazyImageDirective } from './directives/lazy-image.directive';

const directives = [
    DynamicComponentContainerDirective,
    HorizontalCollapseDirective,
    LazyImageDirective,
];


/**
 * PIPES
 */
import { AsPipe } from './pipes/as.pipe';
import { CentsToDollarsAmountPipe } from './pipes/cents-to-dollars-amount.pipe';
import { CentsToDollarsCurrencyPipe } from './pipes/cents-to-dollars-currency.pipe';
import { CentsToPrettyDollarsCurrencyPipe } from './pipes/cents-to-pretty-dollars-currency.pipe';
import { DollarAmountPipe } from './pipes/dollar-amount.pipe';
import { FullNamePipe } from './pipes/full-name.pipe';
import { GivingFilterPipe } from './pipes/giving-filter.pipe';
import { GivingSortPipe } from './pipes/giving-sort.pipe';
import { LPCAmountPipe } from './pipes/lpc-amount.pipe';
import { NameFilterPipe } from './pipes/name-filter.pipe';
import { NoCommaPipe } from './pipes/no-comma.pipe';
import { ToNumberPipe } from './pipes/to-number.pipe';
import { WholeCurrency } from './pipes/whole-currency.pipe';

const pipes = [
    AsPipe,
    CentsToDollarsAmountPipe,
    CentsToDollarsCurrencyPipe,
    CentsToPrettyDollarsCurrencyPipe,
    DollarAmountPipe,
    FullNamePipe,
    GivingFilterPipe,
    GivingSortPipe,
    LPCAmountPipe,
    NameFilterPipe,
    NoCommaPipe,
    ToNumberPipe,
    WholeCurrency,
];


/**
 * COMPONENTS
 */
import { BackBreadcrumbComponent } from './components/back-breadcrumb/back-breadcrumb.component';
import { CampaignProgressBarComponent } from './components/campaign-progress-bar/campaign-progress-bar.component';
import { CauseGivingStatsComponent } from './components/cause-giving-stats/cause-giving-stats.component';
import { CollapseComponent } from './components/collapse/collapse.component';
import { CollapseProviderComponent } from './components/collapse-provider/collapse-provider.component';
import { ConnectComponent } from './components/connect/connect.component';
import { CreditBalanceComponent } from './components/credit/credit-balance/credit-balance.component';
import { ErrorPageComponent } from './components/error-page/error-page.component';
import { FooterComponent } from './components/footer/footer.component';
import { FooterLiteComponent } from './components/footer-lite/footer-lite.component';
import { FooterAppStoreComponent } from './components/footer-app-store/footer-app-store.component';
import { HrComponent } from './components/hr/hr.component';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { NavBarDashboardComponent } from './components/nav-bar-dashboard/nav-bar-dashboard.component';
import { NavBarLiteComponent } from './components/nav-bar-lite/nav-bar-lite.component';
import { PageHeadingComponent } from './components/page-heading/page-heading.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { SectionHeaderComponent } from './components/section-header/section-header.component';
import { SiteBannerComponent } from './components/site-banner/site-banner.component';
import { SocialButtonComponent } from './components/social-button/social-button.component';
import { SocialShareSheetComponent } from './components/social-share-sheet/social-share-sheet.component';
import { SocialSignOnButtonComponent } from './components/social-sign-on-button/social-sign-on-button.component';
import { RecaptchaProtectionPoliciesComponent } from './components/recaptcha-protection-policies/recaptcha-protection-policies.component';
import { ToastrComponent } from './components/toastr/toastr.component';
import { ToggleComponent } from './components/toggle/toggle.component';
import { SvgIconComponent } from '../../_components/svg-icon/svg-icon.component';
import { DashboardSearchBarComponent } from '../../_components/dashboard/dashboard-search-bar/dashboard-search-bar.component';
import { NavBarMenuItemComponent } from './components/nav-bar-menu-item/nav-bar-menu-item.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { AssistantChatModalComponent } from '../../_components/assistant-chat/assistant-chat-modal/assistant-chat-modal.component';
import { UserAvatarComponent } from '../../_components/user-avatar/user-avatar.component';
import { NavBarAssistantTriggerComponent } from '../../_components/nav-bar-assistant-trigger/nav-bar-assistant-trigger.component';

const components = [
    BackBreadcrumbComponent,
    CampaignProgressBarComponent,
    CauseGivingStatsComponent,
    CollapseComponent,
    CollapseProviderComponent,
    ConnectComponent,
    CreditBalanceComponent,
    ErrorPageComponent,
    FooterComponent,
    FooterLiteComponent,
    FooterAppStoreComponent,
    HrComponent,
    NavBarComponent,
    NavBarDashboardComponent,
    NavBarLiteComponent,
    PageHeadingComponent,
    ProgressBarComponent,
    RecaptchaProtectionPoliciesComponent,
    SiteBannerComponent,
    SocialButtonComponent,
    SocialShareSheetComponent,
    SocialSignOnButtonComponent,
    SectionHeaderComponent,
    ToastrComponent,
    ToggleComponent,
];

/**
 * ENTRY COMPONENTS (components that need to be dynamically added to the DOM)
 */
const entryComponents = [];

@NgModule({
    declarations: [
        ...components,
        ...directives,
        ...entryComponents,
        ...pipes,
        SafeHtmlPipe,
    ],
    imports: [
        ...modules,
        RouterModule, // don't export RouterModule as all modules should declare their own child routes
        FormsModule,
        SvgIconComponent,
        DashboardSearchBarComponent,
        NavBarMenuItemComponent,
        AssistantChatModalComponent,
        UserAvatarComponent,
        NavBarAssistantTriggerComponent,
    ],
    exports: [
        ...components,
        ...directives,
        ...entryComponents,
        ...pipes,
        ...modules,
        SafeHtmlPipe,
    ],
    entryComponents,
})
export class LpCommonModule {}
