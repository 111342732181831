/*
 * widget.model.ts
 * Little Phil
 *
 * Created on 8/6/23
 * Copyright © 2018 Little Phil. All rights reserved.
 */

import { WIDGET_TYPE } from '@little-phil/js/lib/common/enums';
import { BaseModel } from './_base.model';

export class Widget<Configuration = any, Theme = any> extends BaseModel {

    public static endpoint = 'widget';

    // Properties
    public readonly type: WIDGET_TYPE = undefined;
    public configuration?: Configuration = undefined;
    public theme?: Theme = undefined;

    /**
     * Constructor
     * @param {object} json
     */
    constructor(json: object = {}) {
        super();
        super.init(json);
    }

}
