/*
 * sentry-error-handler.util.ts
 * Little Phil
 *
 * Created on 1/7/20
 * Copyright © 2018 Little Phil. All rights reserved.
 */
import { ErrorHandler, Injectable } from '@angular/core';
import { ENVIRONMENT } from './env.util';
import { SentryService } from '../_services/sentry.service';
import { LoggerService } from '../_services/logger.service';
import { environment } from '../../environments/environment';

@Injectable()
export class SentryErrorHandler implements ErrorHandler {

    constructor(
        private sentryService: SentryService,
        private logger: LoggerService,
    ) {}

    /**
     * Overrides the default ErrorHandle that prints error message to the console
     * https://angular.io/api/core/ErrorHandler#description
     */
    handleError(error) {

        // Capture handled exception and send it to Sentry.
        const eventId = this.sentryService.trackIssue(error);

        // When not in production mode, log the error to console for immediate feedback.
        if (environment.env !== ENVIRONMENT.production) {
            this.logger.error(error);
        }

        // When not in production mode, show user dialog to provide details on what happened
        if (environment.env !== ENVIRONMENT.production) {
            this.sentryService.showReportDialog({ eventId });
        }

    }

}
