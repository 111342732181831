"use strict";
/*
 * constants.ts
 * Little Phil
 *
 * Created on 13/10/20
 * Copyright © 2018 Little Phil. All rights reserved.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.COUNTRY_INTERNATIONAL = exports.MAX_DAY_OF_MONTH = exports.MIN_DAY_OF_MONTH = exports.CAUSE_DETAILS_UPDATES_ELEMENT_ID = exports.JOI_LIMIT_MAX = exports.JOI_LIMIT_DEFAULT = exports.SUPPORTED_STRIPE_PAYMENT_METHOD_TYPE_LIST = exports.MIN_REGULAR_DONATION_AMOUNT = exports.MIN_ONE_OFF_DONATION_AMOUNT_LPC = exports.MIN_ONE_OFF_DONATION_AMOUNT = exports.MAX_REGULAR_DONATION_AMOUNT = exports.MAX_ONE_OFF_DONATION_AMOUNT = exports.COOKIE_SHARE_LINK_ID_KEY = exports.COOKIE_SHARE_EVENT_ID_KEY = exports.COOKIE_FINGERPRINT_KEY = exports.MAX_CAMPAIGN_UPDATE_MESSAGE_CHARACTERS = exports.CAMPAIGN_NEAR_COMPLETION_MIN_PERCENTAGE = exports.SHORTENED_DESCRIPTION_LENGTH = exports.STATES = exports.ROUTE_PATHS = exports.EMPTY_CHAR = void 0;
/* --------------------------------------------------------------- ****** --------------------------------------------------------------- */
const lp_credit_1 = require("../utils/lp-credit");
const enums_1 = require("./enums");
/**
 * This empty char can be used to have a string that looks empty but that passes `required()` validation
 */
exports.EMPTY_CHAR = '‎';
/**
 * Object enumerating route paths per project
 */
exports.ROUTE_PATHS = {
    web: {
        account: '/account',
        accountRecovery: '/account-recovery',
        admin: '/dashboard/admin',
        apply: '/apply',
        campaign: {
            create: '/dashboard/campaign/create',
            dashboard: (id) => `/dashboard/campaign/dashboard/${id}`,
            details: (id) => `/campaign/${id}`,
            edit: (id) => `/dashboard/campaign/edit/${id}`,
            list: '/dashboard/campaign/list',
        },
        category: {
            create: '/dashboard/category/create',
            edit: (id) => `/dashboard/category/edit/${id}`,
            list: '/dashboard/category/list',
        },
        charity: {
            activateProfile: '/dashboard/activate-profile',
            admin: (id) => `/dashboard/charity/${id}/admin`,
            create: '/dashboard/charity/create',
            dashboard: (id) => `/dashboard/charity/${id}/dashboard`,
            details: (id) => `/charity/${id}`,
            edit: (id) => `/dashboard/charity/${id}/edit`,
            list: '/dashboard/charity/list',
            reports: (id) => `/dashboard/charity/${id}/reports`,
            onboarding: `/charity/onboarding`,
        },
        claimWelcome: '/claim-welcome',
        company: {
            onboarding: '/company/onboarding',
            dashboard: (id) => `/dashboard/company/dashboard/${id}`,
            details: (id) => `/company/${id}`,
            edit: (id) => `/dashboard/company/edit/${id}`,
            giving: (id) => `/dashboard/company/${id}/giving`,
            profile: (id) => `/dashboard/company/${id}/profile`,
            list: '/dashboard/company/list',
            reporting: (id) => `/dashboard/company/${id}/reporting`,
        },
        companies: '/companies',
        contentAssistant: '/dashboard/content-assistant',
        creditPool: {
            create: '/dashboard/credit-pool/create',
            edit: (id) => `/dashboard/credit-pool/edit/${id}`,
            list: '/dashboard/credit-pool/list',
        },
        crypto: '/crypto',
        customerPortal: '/customer-portal',
        dashboard: {
            route: '/dashboard',
            charity: {
                actAs: (id) => `/dashboard/charity/${id}/act-as`,
            },
            company: {
                admin: (id) => `/dashboard/company/${id}/admin`,
                actAs: (id) => `/dashboard/company/${id}/act-as`,
            },
            employees: {
                route: '/dashboard/employees',
            },
            givingCredits: {
                route: '/dashboard/giving-credits',
            },
            payments: {
                route: '/dashboard/payments',
                view: {
                    route: (id) => `/dashboard/payments/${id}`,
                },
            },
            profile: {
                route: '/dashboard/profile',
            },
        },
        dashboardSearch: '/dashboard/search',
        dashboardPayments: '/dashboard/payments',
        dashboardPayouts: {
            root: '/dashboard/payouts',
            create: '/dashboard/payouts/create',
        },
        dashboardProfile: {
            route: '/dashboard/profile',
        },
        dashboardShopify: {
            root: '/dashboard/shopify',
        },
        dashboardCharityAssistant: {
            edit: '/dashboard/charity-assistants/edit',
        },
        developer: '/dashboard/developer',
        error: '/oops',
        explore: '/explore',
        give: '/give',
        giveSuccess: '/give/success',
        home: '/',
        impact: '/impact',
        inactiveLeads: '/dashboard/admin/inactive-leads',
        linkAccount: '/user/link-account',
        login: '/login',
        notFound: '/404',
        payments: {
            dashboard: (id) => `/dashboard/payments/${id}`,
        },
        redeemCredit: '/redeem-credit',
        register: '/register',
        reports: '/dashboard/reports',
        resetPassword: '/reset',
        setPassword: '/set-password',
        setPasswordSuccess: '/set-password/success',
        shareLinks: {
            create: '/dashboard/share-link/create',
            edit: (id) => `/dashboard/share-link/${id}/edit`,
            details: (id) => `/dashboard/share-link/${id}`,
            list: '/dashboard/share-link',
        },
        shareToSocialPlatform: '/share-to-social-platform',
        settings: '/dashboard/settings',
        loading: '/loading',
        welcome: '/dashboard/welcome',
        widgets: {
            donation: '/dashboard/widgets/donation',
            list: '/dashboard/widgets',
        },
    },
};
/**
 * List of states codes per country
 */
exports.STATES = {
    AU: {
        SHORT: ['NSW', 'VIC', 'QLD', 'WA', 'SA', 'TAS', 'ACT', 'NT'],
    },
};
/**
 * The default shortened description length
 *
 * @type {number}
 */
exports.SHORTENED_DESCRIPTION_LENGTH = 256;
/* ------------------------------------------------------------- CAMPAIGN --------------------------------------------------------------- */
/**
 * The minimum completion percentage which need to be met to declare a campaign as nearing completion
 *
 * @type {number}
 */
exports.CAMPAIGN_NEAR_COMPLETION_MIN_PERCENTAGE = 0.7;
/* ---------------------------------------------------------- CAMPAIGN UPDATE ----------------------------------------------------------- */
/**
 * Character cap for campaign updates message length
 */
exports.MAX_CAMPAIGN_UPDATE_MESSAGE_CHARACTERS = 1024;
/* -------------------------------------------------------------- COOKIES --------------------------------------------------------------- */
/*
 * Keys for browser cookies
 */
exports.COOKIE_FINGERPRINT_KEY = 'lp.fingerprint';
exports.COOKIE_SHARE_EVENT_ID_KEY = 'lp.share-event-id';
exports.COOKIE_SHARE_LINK_ID_KEY = 'lp.share-link-id';
/* -------------------------------------------------------------- PAYMENT --------------------------------------------------------------- */
/**
 * Maximum one-off donation amount allowed on the platform, in cents
 * Stripe's max one-off payment amount is $999,999.99
 * A $900k donation gives a $985,028.70 total with fees, on 6% platform fee
 * $913,678.96 takes us to $999,999.99 with fees but it would be a weird max amount to display to users
 */
exports.MAX_ONE_OFF_DONATION_AMOUNT = 90000000;
/**
 * Maximum regular donation amount allowed on the platform, in cents
 * Stripe's max regular payment amount is $100,000 AUD
 * Regular payments include fees
 */
exports.MAX_REGULAR_DONATION_AMOUNT = 10000000;
/**
 * Minimum one-off donation amount allowed on the platform, in cents
 * $5
 */
exports.MIN_ONE_OFF_DONATION_AMOUNT = 500;
/**
 * Minimum one-off donation amount allowed on the platform, in LPC (=AUD.1)
 */
exports.MIN_ONE_OFF_DONATION_AMOUNT_LPC = (0, lp_credit_1.audCentsToLPC)(exports.MIN_ONE_OFF_DONATION_AMOUNT);
/**
 * Minimum regular donation amount allowed on the platform, in cents
 * $5
 */
exports.MIN_REGULAR_DONATION_AMOUNT = 500;
/**
 * List of Stripe Payment Method types that Little Phil supports
 */
exports.SUPPORTED_STRIPE_PAYMENT_METHOD_TYPE_LIST = Object.values(enums_1.SUPPORTED_STRIPE_PAYMENT_METHOD_TYPE);
/* ---------------------------------------------------------------- JOI ----------------------------------------------------------------- */
/**
 * Default Joi limit value
 */
exports.JOI_LIMIT_DEFAULT = 100;
/**
 * Maximum Joi limit value
 */
exports.JOI_LIMIT_MAX = 1000;
/* ------------------------------------------------------------- ELEMENT IDS ------------------------------------------------------------ */
/**
 * ID of the DOM element for cause updates
 */
exports.CAUSE_DETAILS_UPDATES_ELEMENT_ID = 'updates';
/* ---------------------------------------------------------------- DATE ---------------------------------------------------------------- */
/**
 * Minimum day of month value
 * 1 = First day of the month
 */
exports.MIN_DAY_OF_MONTH = 1;
/**
 * Maximum day of month value
 * 31 = Thirty first day of the month
 */
exports.MAX_DAY_OF_MONTH = 31;
/* -------------------------------------------------------------- COUNTRIES ------------------------------------------------------------- */
/**
 * Value used for querying the API for non-AU campaigns and charities
 */
exports.COUNTRY_INTERNATIONAL = 'international';
