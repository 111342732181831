import { Component, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CAUSE_TYPE, SHARE_SOCIAL_PLATFORM } from '@little-phil/js/lib/common/enums';
import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';

import { Campaign } from '../../../../_models/campaign.model';
import { Charity } from '../../../../_models/charity.model';
import { LoggerService } from '../../../../_services/logger.service';
import { ApiService } from '../../../../_services/api.service';
import { LoaderService } from '../../../../_services/loader.service';
import { ShareLinkGenerator } from '../../../../_classes/share-link-generator.class';
import { SentryService } from '../../../../_services/sentry.service';
import { LocalStorageService } from '../../../../_services/local-storage.service';
import { ClipboardService } from '../../../../_services/clipboard.service';
import { EnvironmentService } from '../../../../_services/environment.service';
import { ShareLink } from '../../../../_models/share-link.model';
import { shareToEmail, shareToFacebook, shareToLinkedIn, shareToSms, shareToX } from '../../../../_utils/social-sharing';

/**
 * List of messaging platforms used in the Social Share Sheet
 */
enum MESSAGING_PLATFORM {
    SMS = 'message',
    EMAIL = 'email',
}

@Component({
    selector: 'app-social-share-sheet',
    templateUrl: './social-share-sheet.component.html',
    styleUrls: ['./social-share-sheet.component.scss'],
})
export class SocialShareSheetComponent extends ShareLinkGenerator {

    @Input() message: string;
    @Input() center: boolean;
    @Input() shareLink: ShareLink;
    @Input() displayCopyLink = true;

    /**
     * Setter for providing a campaign to share
     * @param campaign
     */
    @Input()
    set campaign(campaign: Campaign) {
        if (!campaign) {
            return;
        }
        this.causeType = CAUSE_TYPE.CAMPAIGN;
        this.causeId = campaign.id;
        this.causeName = campaign.name;
    }

    /**
     * Setter for providing a charity to share
     * @param charity
     */
    @Input()
    set charity(charity: Charity) {
        if (!charity) {
            return;
        }
        this.causeType = CAUSE_TYPE.CHARITY;
        this.causeId = charity.id;
        this.causeName = charity.displayName;
    }

    private causeName: string;
    private deviceInfo: DeviceInfo;

    constructor(
        protected apiService: ApiService,
        protected loaderService: LoaderService,
        protected logger: LoggerService,
        protected sentry: SentryService,
        protected localStorage: LocalStorageService,
        protected environmentService: EnvironmentService,
        private toastr: ToastrService,
        private deviceService: DeviceDetectorService,
        private clipboardService: ClipboardService,
    ) {
        super(
            apiService,
            loaderService,
            logger,
            sentry,
            localStorage,
            environmentService,
        );
        this.deviceInfo = deviceService.getDeviceInfo();
    }

    /* ------------------------------------------------ Social Platform buttons --------------------------------------------------------- */

    async shareButtonPressed(platform: SHARE_SOCIAL_PLATFORM | MESSAGING_PLATFORM) {
        const url = await this.getShareLinkUrl();

        if (this.message) {
            await this.clipboardService.copyToClipboard(this.message).catch((err) => {
                this.logger.error(err);
                this.toastr.error('The clipboard is not accessible in your current browser.');
            });
        }

        switch (platform) {
            case SHARE_SOCIAL_PLATFORM.FACEBOOK:
                return shareToFacebook({ url });
            case SHARE_SOCIAL_PLATFORM.X:
                return shareToX({ url });
            case SHARE_SOCIAL_PLATFORM.LINKED_IN:
                return shareToLinkedIn({ url });
            case MESSAGING_PLATFORM.EMAIL:
                return shareToEmail({ url });
            case MESSAGING_PLATFORM.SMS:
                return shareToSms({ url });
            default:
                this.sentry.trackIssue('shareButtonPressed invalid platform.', { platform });
                return null;
        }
    }

    /* ------------------------------------------------------- Share sheet button ------------------------------------------------------- */

    /**
     * Function called when a user presses the share sheet icon
     */
    async shareSheetButtonPressed() {
        const url = await this.getShareLinkUrl();

        // @ts-ignore https://developer.mozilla.org/en-US/docs/Web/API/Navigator/share
        navigator.share({
            title: this.causeName,
            url: await this.getShareLinkUrl(),
        }).catch((err: Error) => {

            this.logger.verbose('navigator.share error', err);

            if (err.name !== 'AbortError') {
                // fall back to copy link in case of error but only if the user didn't cancel
                // this AbortError could also be due to having no sharing target (https://www.w3.org/TR/web-share/#dfn-sharepromise)
                // but this is highly unlikely for a link
                this.copyToClipboard(url);
            }

        });
    }

    /* -------------------------------------------------------- Copy link button -------------------------------------------------------- */

    /**
     * Function called when a user presses the copy link icon
     */
    copyLinkButtonPressed() {

        // Take from https://wolfgangrittner.dev/how-to-use-clipboard-api-in-firefox/
        // TODO Update to TypeScript >= 4.4 and remove below ts-ignores
        // @ts-ignore
        if (typeof ClipboardItem && navigator.clipboard.write) {
            // NOTE: Safari locks down the clipboard API to only work when triggered
            //   by a direct user interaction. You can't use it async in a promise.
            //   But! You can wrap the promise in a ClipboardItem, and give that to
            //   the clipboard API.
            //   Found this on https://developer.apple.com/forums/thread/691873
            // @ts-ignore
            const text = new ClipboardItem({
                'text/plain': this.getShareData()
                    .then((url) => {
                        return new Blob([url], { type: 'text/plain' });
                    }),
            });
            // @ts-ignore
            navigator.clipboard.write([text])
                .then(() => {
                    this.toastr.success('Copied to clipboard');
                })
                .catch((err) => {
                    this.logger.error(err);
                    this.toastr.error('The clipboard is not accessible with your current browser, please select another sharing method.');
                });
        } else {
            // NOTE: Firefox has support for ClipboardItem and navigator.clipboard.write,
            //   but those are behind `dom.events.asyncClipboard.clipboardItem` preference.
            //   Good news is that other than Safari, Firefox does not care about
            //   Clipboard API being used async in a Promise.
            this.getShareData().then((data) => {
                this.copyToClipboard(data);
            });
        }

    }

    /* ------------------------------------------------------------- Helpers ------------------------------------------------------------ */

    /**
     * Copies text to the clipboard
     * @param text - The text to copy to the clipboard
     * @private
     */
    private copyToClipboard(text: string) {
        this.clipboardService.copyToClipboard(text).then(() => {
            this.toastr.success('Copied to clipboard');
        }).catch((err) => {
            this.logger.error(err);
            this.toastr.error('The clipboard is not accessible with your current browser, please select another sharing method.');
        });
    }

    /**
     * Returns the url to open based on the current context
     * @private
     */
    private async getShareLinkUrl() {
        return this.shareLink?.getUrl() || await this.generateShareLink() || this.targetURL;
    }

    /**
     * Returns the share data to open based on the current context
     * @private
     */
    private async getShareData() {
        const shareLinkUrl = await this.getShareLinkUrl();

        if (!this.message) {
            return shareLinkUrl;
        }

        return `${this.message}\n\n${shareLinkUrl}`;
    }

    /**
     * Returns an array of all the available social platforms
     */
    get socialPlatforms() {
        return Object.values(SHARE_SOCIAL_PLATFORM);
    }

    /**
     * Returns an array of all the available messaging platforms
     */
    get messagingPlatforms() {
        return Object.values(MESSAGING_PLATFORM);
    }

    /**
     * Checks to see if the current browsers can open the native share sheet
     */
    get canDisplayShareSheet() {
        // disable Safari share sheet on desktop and use copy link instead
        if (this.deviceService.isDesktop() && this.deviceInfo.browser === 'Safari') {
            return false;
        }

        // @ts-ignore https://developer.mozilla.org/en-US/docs/Web/API/Navigator/share
        return navigator.share;
    }

}
