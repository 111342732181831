import { Component, Input } from '@angular/core';
import { Campaign } from '../../../../_models/campaign.model';
import { Charity } from '../../../../_models/charity.model';

@Component({
    selector: 'app-cause-giving-stats',
    templateUrl: './cause-giving-stats.component.html',
    styleUrls: ['./cause-giving-stats.component.scss'],
})
export class CauseGivingStatsComponent {

    /**
     * Called when a new value is bound to campaign
     * @param campaign
     */
    @Input()
    set campaign(campaign: Campaign) {
        if (!campaign) {
            return;
        }
        this.amountRaised = campaign.amountGiven;
        this.goal = campaign.amount;
        this.uniqueGivers = campaign.donors;
    }

    /**
     * Called when a new value is bound to charity
     * @param charity
     */
    @Input()
    set charity(charity: Charity) {
        if (!charity) {
            return;
        }
        this.amountRaised = charity.amountGiven;
        this.uniqueGivers = charity.uniqueGivers;
        this.goal = null;
    }

    public amountRaised: number;
    public goal: number;
    public uniqueGivers: number;

}
