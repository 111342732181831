import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import Cropper from 'cropperjs';
import { FormItemComponent } from '../form-item/form-item.component';
import { setupOverlayEvents } from '../../../../_utils/overlay.util';
import { resizeImage } from '../../../../_utils/image.util';
import { ToastrService } from 'ngx-toastr';
import { SentryService } from '../../../../_services/sentry.service';

@Component({
    selector: 'app-form-image',
    templateUrl: './form-image.component.html',
    styleUrls: ['./form-image.component.scss'],
})
export class FormImageComponent extends FormItemComponent implements OnInit {

    @ViewChild('input', { static: true }) inputEle: ElementRef;
    @ViewChild('overlay', { static: true }) overlayEle: ElementRef;
    @ViewChild('cropper') cropperElement: ElementRef;

    @Input() image: Blob; // The image data
    @Input() imageUrl: string; // The url of the displayed image
    @Input() addButtonDetails: string; // This can be used for providing recommended sizes or additional information
    @Input() maxWidth: number; // max width in pixels
    @Input() accept = 'image/png,image/jpeg,image/webp'; // The file types that are accepted in CSV format
    @Input() croppedHeight: number;
    @Input() croppedWidth: number;

    @Output() imageChange = new EventEmitter<IImageChangeEvent>();

    private cropper: Cropper;

    /**
     * Used to display a default image at all times (even when no image is selected)
     * NOTE: This is not a default value for the associated form-control
     */
    public defaultDisplayImageUrl: string = null;
    public rawImageUrl: string;
    public showModal: boolean;
    public isResizing: boolean;
    public aspectRatio: number;

    constructor(
        private toastr: ToastrService,
        private sentry: SentryService,
    ) {
        super();
    }

    /**
     * Setup event listeners for the overlay
     */
    ngOnInit() {
        if (!this.croppedWidth) {
            throw new Error('FormImageComponent croppedWidth is missing');
        } else if (!this.croppedHeight) {
            throw new Error('FormImageComponent croppedWidth is missing');
        }

        this.aspectRatio = this.croppedWidth / this.croppedHeight;

        setupOverlayEvents(this.overlayEle.nativeElement);
    }

    /**
     * Clears image and emits the changes to the parent component
     */
    public removeImage(event: MouseEvent) {
        if (event) {
            event.stopPropagation();
        }
        this.imageUrl = this.defaultDisplayImageUrl;
        this.image = null;
        this.control.setValue('');
        this.emitImageChange();
    }

    /**
     * Manually click the input to display the file picker
     */
    public displayUploader() {
        this.inputEle.nativeElement.click();
    }

    /**
     * Set a default image URL, that will always be displayed even if no picture is selected
     * @param imageUrl URL of the default image
     */
    public setDefaultDisplayImageUrl(imageUrl: string) {
        this.defaultDisplayImageUrl = imageUrl;
        this.imageUrl = imageUrl;
    }

    /**
     * Emit the new image to the parent components
     */
    private emitImageChange() {
        // logger.verbose('FormImageComponent onChange:', this.image); TODO PROD-587
        this.imageChange.emit({ image: this.image, imageUrl: this.imageUrl });
    }

    public handleChange(e: any) {
        const target = e.target;
        const newFile = target.files?.[0] || e.dataTransfer?.files?.[0];
        target.value = ''; // allow same image to be selected twice

        if (newFile) {
            this.rawImageUrl = URL.createObjectURL(newFile);
            this.openModal();
        }
    }

    public handleCrop() {
        this.isResizing = true;
        this.cropper.getCroppedCanvas().toBlob((blob) => {
            try {
                resizeImage(blob, this.croppedWidth, this.croppedHeight)
                    .then((result) => {
                        this.image = result.blob;
                        this.imageUrl = result.dataUrl;

                        this.control.patchValue({
                            file: result.dataUrl,
                        });

                        this.emitImageChange();
                        this.showModal = false;
                    });
            } catch (e) {
                this.showModal = false;
                this.sentry.trackIssue(e);
                this.toastr.error('Sorry, something went wrong with cropping your image. Our team has been notified and we will be in touch soon.');
            } finally {
                this.isResizing = false;
            }
        });
    }

    private openModal() {
        this.showModal = true;

        // Wait for ngIf to update before mounting cropper
        setTimeout(() => {
            this.cropper = new Cropper(this.cropperElement.nativeElement, {
                aspectRatio: this.croppedWidth / this.croppedHeight,
                autoCropArea: 1,
                background: false,
                checkOrientation: false,
                minCropBoxHeight: 10,
                minCropBoxWidth: 10,
                viewMode: 1,
                zoomable: false,
            });
        }, 0);
    }

}

/**
 * Interface for emitter events
 */
export interface IImageChangeEvent {
    image: Blob;
    imageUrl: string;
}
