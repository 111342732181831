import { Directive, ElementRef, Input } from '@angular/core';

/**
 * 1px images that remove the error image icon while the image is being downloaded
 * generated with https://onlinepngtools.com/generate-1x1-png
 */
enum COLOUR {
    transparent =
        'data:image/png;base64,' +
        'iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=',
    lightgrey =
        'data:image/png;base64,' +
        'iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAA1JREFUGFdj+PL1238ACZ8D38SAgcIAAAAASUVORK5CYII=',
}

@Directive({
    selector: '[lazy-image]',
})
export class LazyImageDirective {

    @Input() set loadingColour(colour: 'transparent' | 'lightgrey') {
        if (colour === 'lightgrey') {
            this.elementRef.nativeElement.src = COLOUR.lightgrey;
        } else {
            this.elementRef.nativeElement.src = COLOUR.transparent;
        }
    }

    constructor(private elementRef: ElementRef) {
        this.elementRef.nativeElement.src = COLOUR.transparent;
    }

}
