/*
 * dynamic-component.class.ts
 * Little Phil
 *
 * Created on 29/6/21
 * Copyright © 2018 Little Phil. All rights reserved.
 */
import { Input, ViewContainerRef, Directive } from '@angular/core';
import { IDynamicComponentOptions } from '../_interfaces/options.interface';
import { BaseComponent } from './base-component.class';

@Directive()
// TODO make this class a generic so only specific options can be passed
export class DynamicComponentDirective extends BaseComponent {

    // Allows for passing of options to another dynamic component (login to register)
    // TODO remove this and use a private variable and onOptionsChanged
    @Input() set options(options: IDynamicComponentOptions) {
        this.onOptionsChanged(options);
    }

    private ref: any; // ComponentRef
    public viewContainer: ViewContainerRef; // Used for displaying a dynamic comp inside a dynamic comp

    public destroy() {
        if (this.ref) {
            this.ref.destroy();
        }
    }

    public applyOptions(options: IDynamicComponentOptions) {
        this.ref = options.ref;
        this.viewContainer = options.viewContainer;
        this.options = options;
    }

    /**
     * Stub for subclass to receive notifications when the options have changed
     */
    public onOptionsChanged(options: IDynamicComponentOptions) {}

}
