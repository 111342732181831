<app-modal>
    <app-modal-trigger>
        <ng-content/>
    </app-modal-trigger>

    <app-modal-content [disablePadding]="true" size="lg">
        <ng-container *ngTemplateOutlet="content"/>
    </app-modal-content>
</app-modal>

<ng-template #content>
    <div class="bg-reliable-blue-5 p-2 rounded-3">
        <div class="relative bg-white rounded-2 min-h-[200px] max-h-[800px] h-[90vh] overflow-hidden">
            <div class="absolute top-0 left-0 right-0 px-md-7 px-3 z-10 pointer-events-none">
                <div class="bg-gradient-to-b from-white to-transparent h-7"></div>
            </div>

            <app-assistant-chat-history
                class="absolute top-0 bottom-[134px] left-0 right-0"
                [messages]="messages"
                [thread]="thread"
                (samplePromptClicked)="handleNewMessage($event)"
            />

            <div class="absolute bottom-0 left-0 right-0 px-md-7 px-3 z-50">
                <div class="bg-gradient-to-t from-white to-transparent h-7 pointer-events-none"></div>
                <div class="bg-white pt-0 pb-3">
                    <app-assistant-chat-input
                        [messages]="messages"
                        (newMessage)="handleNewMessage($event)"
                    />
                    <p class="text-xs text-center mt-1">
                        Answers can be inaccurate. It’s important to double-check important information.
                    </p>
                </div>
            </div>
        </div>
    </div>
</ng-template>
