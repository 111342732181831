<div
    #background
    @fadeInOutAnimation
    *ngIf="modalService.isOpen | async"
    class="fixed inset-0 flex flex-wrap overflow-y-auto overflow-x-hidden items-center justify-center px-3 py-6 bg-[#00000080] z-modal overscroll-contain"
    (click)="closeModal($event)">
</div>

<div
    @scaleUpInOutAnimation
    *ngIf="modalService.isOpen | async"
    class="fixed inset-0 flex flex-wrap overflow-y-auto overflow-x-hidden items-center justify-center px-3 py-6 z-modal overscroll-contain">

    <div
        class="relative overflow-scroll w-full rounded-3 cursor-auto z-10"
        [class.p-md-6]="!disablePadding"
        [class.px-3]="!disablePadding"
        [class.py-6]="!disablePadding"
        [class.max-w-[400px]]="size === 'sm'"
        [class.max-w-[600px]]="size === 'md'"
        [class.max-w-[800px]]="size === 'lg'"
        [class.max-w-[1000px]]="size === 'xl'"
        [ngStyle]="{'background-color': modalContentBackgroundColor}">

        <app-modal-close class="absolute top-5 right-5 z-20">
            <button type="button" (click)="modalService.setOpen(false)">
                <app-svg-icon [svg]="CROSS_24_SVG"/>
            </button>
        </app-modal-close>

        <ng-content/>
    </div>

</div>
