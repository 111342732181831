/*
 * environment.service.ts
 * Little Phil
 *
 * Created on 29/6/21
 * Copyright © 2018 Little Phil. All rights reserved.
 */

import { Injectable, Injector } from '@angular/core';
import { REQUEST } from '@nguniversal/express-engine/tokens';

import { environment } from '../../environments/environment';
import { Theme } from '../_utils/theme.util';
import { BrowserService } from './browser.service';


@Injectable({
    providedIn: 'root',
})
export class EnvironmentService {

    constructor(private injector: Injector, private browserService: BrowserService) {}

    // Environment checks
    public static readonly isLocal = environment.env === 'local';
    public static readonly isDev = environment.env === 'development';
    public static readonly isStaging = environment.env === 'staging';
    public static readonly isProd = environment.env === 'production';
    public static readonly isWhitelabel = environment.env === 'whitelabel';

    public static readonly apiUrl = environment.apiUrl;
    public static readonly authUrl = environment.authUrl;

    public static readonly stripePublicKey = EnvironmentService.isProd || EnvironmentService.isWhitelabel
        ? 'pk_live_UXFTYy9UblHvGmRgqHVOFvOe'
        : 'pk_test_enFKkWjxH9oo3Oqj606Ub298';

    // @ts-ignore: Ignore because it's is not required for all environments and a default value is provided
    public static readonly bannerUrl = environment.bannerUrl;
    // @ts-ignore: Ignore because it's is not required for all environments and a default value is provided
    public static readonly businessName = environment.businessName || 'Little Phil';
    // @ts-ignore: Ignore because it's is not required for all environments and a default value is provided
    public static readonly charities = environment.charities || [];
    // @ts-ignore: Ignore because it's is not required for all environments and a default value is provided
    public static readonly cookieDomain = environment.cookieDomain || '';
    // @ts-ignore: Ignore because it's is not required for all environments and a default value is provided
    public static readonly establishmentYear = environment.establishmentYear || new Date().getFullYear();
    // @ts-ignore: Ignore because it's is not required for all environments and a default value is provided
    public static readonly googleTagManagerId = environment.googleTagManagerId || '';
    // @ts-ignore: Ignore because it's is not required for all environments and a default value is provided
    public static readonly intercomAppId = environment.intercomAppId;
    // @ts-ignore: Ignore because it's is not required for all environments and a default value is provided
    public static readonly recaptchaV3SiteKey = environment.recaptchaV3SiteKey || '';
    // @ts-ignore: Ignore because it's is not required for all environments and a default value is provided
    public static readonly whitelabelId = environment.whitelabelId;

    /**
     * Returns a valid URL for the current site on client and server side
     */
    public get webUrl(): string {
        if (this.browserService.isBrowserPlatform()) {
            return window.location.protocol + '//' + window.location.host;
        } else {
            const request = this.injector.get(REQUEST);
            return request.protocol + '://' + request.get('host');
        }
    }

    /**
     * Used to display dark mode nav bar for white-labels
     */
    public get darkModeNav(): boolean {
        return !!(EnvironmentService.isWhitelabel && Theme.styling.navbarDarkMode);
    }

}

export const Environment = EnvironmentService;
