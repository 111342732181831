/*
 * env.util.ts
 * Little Phil
 *
 * Created on 28/7/20
 * Copyright © 2018 Little Phil. All rights reserved.
 */

/**
 * Enum of possible running environments for the parent module
 */
export enum ENVIRONMENT {
    local = 'local',
    development = 'development',
    staging = 'staging',
    production = 'production',
    whitelabel = 'whitelabel',
    test = 'test',
}
