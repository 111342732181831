<div class="footer align-items-center">
    <div class="container">

        <div class="row align-items-center">
            <div class="col-md-4 text-start d-flex align-items-center" id="scroll-to-top">
                <div class="d-flex align-items-center">
                    <img
                        (click)="ScrollService.scrollToTop()"
                        alt="Up Arrow"
                        src="../../../assets/img/home/up-arrow@3x.png"
                        srcset="../../../assets/img/home/up-arrow.png,
                             ../../../assets/img/home/up-arrow@2x.png 2x,
                             ../../../assets/img/home/up-arrow@3x.png 3x">
                    <span (click)="ScrollService.scrollToTop()">Take me to the top</span>
                </div>
            </div>

            <div class="col-md-4 text-center">©2023 {{businessName}}</div>

            <div class="col-md-4 text-end">
                <a href="../../../assets/pdf/privacy.pdf" target="_blank">Privacy Policy</a>
                <a href="../../../assets/pdf/terms-of-use.pdf" target="_blank">Terms of Use</a>
            </div>
        </div>

    </div>
</div>
