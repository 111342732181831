import { Component, EventEmitter, HostListener, inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalService } from '../../../_services/modal.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { ScrollService } from '../../../_services/scroll.service';
import { ANIMATION_DURATION } from '../../../_animations/fade-in-out.animation';
import { BodyScrollLockComponent } from '../../body-scroll-lock/body-scroll-lock.component';

@Component({
    selector: 'app-modal',
    standalone: true,
    imports: [CommonModule, BodyScrollLockComponent],
    templateUrl: './modal.component.html',
    styles: [
        `
            :host {
                display: block;
            }
        `,
    ],
    providers: [ModalService],
})
export class ModalComponent implements OnInit, OnDestroy {

    @Input() set open(open: boolean) {
        this.updateOpen(open);
    }

    @Output() onOpenChanged = new EventEmitter<boolean>();
    @Output() onTransitionChanged = new EventEmitter<boolean>();

    private modalService = inject(ModalService);

    private openSubscription: Subscription;
    private _open: boolean;

    ngOnInit() {
        this.openSubscription = this.modalService.isOpen.subscribe((open) => {
            this.updateOpen(open);
        });
    }

    ngOnDestroy() {
        this.openSubscription?.unsubscribe();
    }

    @HostListener('document:keydown.escape', ['$event'])
    onKeydownHandler(event: KeyboardEvent) {
        if (this._open) {
            this.modalService.setOpen(false);
        }
    }

    private updateOpen(open: boolean) {
        if (open !== this._open) {
            this._open = open;
            this.modalService.setOpen(open);
            this.onOpenChanged.emit(open);

            this.onTransitionChanged.emit(true);
            setTimeout(() => {
                this.onTransitionChanged.emit(false);
            }, ANIMATION_DURATION);
        }
    }

    get open() {
        return this._open;
    }
}
