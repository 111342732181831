import {
    ApplicationRef,
    ComponentFactoryResolver,
    ComponentRef,
    Directive,
    ElementRef, EmbeddedViewRef,
    HostListener,
    Injector,
    Input, OnDestroy,
} from '@angular/core';
import { TooltipComponent } from '../component/tooltip.component';

@Directive({
    selector: '[appTooltip]',
})
export class TooltipDirective implements OnDestroy {

    // Take from https://accesto.com/blog/how-to-create-angular-tooltip-directive/

    @Input() text;
    @Input() html;
    @Input() position: 'above' | 'below' | 'right' | 'left' = 'above';

    private componentRef: ComponentRef<TooltipComponent> = null;
    private animationDurationMs = 300; // 300ms
    private hideTimeout: ReturnType<typeof setTimeout>;

    constructor(
        private elementRef: ElementRef,
        private appRef: ApplicationRef,
        private componentFactoryResolver: ComponentFactoryResolver,
        private injector: Injector,
    ) {}

    @HostListener('mouseenter')
    onMouseEnter(): void {
        if (this.componentRef !== null) {
            clearTimeout(this.hideTimeout);
            this.componentRef.instance.visible = true;
            return;
        }

        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(TooltipComponent);
        this.componentRef = componentFactory.create(this.injector);
        this.appRef.attachView(this.componentRef.hostView);

        const domElem = (this.componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
        document.body.appendChild(domElem);

        this.setTooltipComponentProperties();
    }

    @HostListener('mouseleave')
    onMouseLeave(): void {
        if (this.componentRef === null) {
            return;
        }

        this.componentRef.instance.visible = false;
        this.hideTimeout = setTimeout(this.destroyTooltip.bind(this), this.animationDurationMs);
    }

    ngOnDestroy(): void {
        if (this.componentRef === null) {
            return;
        }

        this.componentRef.instance.visible = false;
        this.hideTimeout = setTimeout(this.destroyTooltip.bind(this), this.animationDurationMs);
    }

    destroyTooltip(): void {
        if (this.componentRef === null) {
            return;
        }

        this.appRef.detachView(this.componentRef.hostView);
        this.componentRef.destroy();
        this.componentRef = null;
    }

    private setTooltipComponentProperties() {
        if (this.componentRef === null) {
            return;
        }

        this.componentRef.instance.text = this.text;
        this.componentRef.instance.html = this.html;
        this.componentRef.instance.position = this.position;
        this.componentRef.instance.visible = true;

        const bounds = this.elementRef.nativeElement.getBoundingClientRect();
        const elementWidth = bounds.right - bounds.left;
        const elementHeight = bounds.bottom - bounds.top;
        const offsetTop = this.getOffsetTopFromDocument(this.elementRef.nativeElement);
        const offsetLeft = bounds.left;

        switch (this.position) {
            case 'below': {
                this.componentRef.instance.left = Math.round((elementWidth) / 2 + offsetLeft);
                this.componentRef.instance.top = Math.round(offsetTop + elementHeight);
                break;
            }
            case 'above': {
                this.componentRef.instance.left = Math.round((elementWidth) / 2 + offsetLeft);
                this.componentRef.instance.top = Math.round(offsetTop);
                break;
            }
            case 'right': {
                this.componentRef.instance.left = Math.round(offsetLeft + elementWidth);
                this.componentRef.instance.top = Math.round(offsetTop + elementHeight / 2);
                break;
            }
            case 'left': {
                this.componentRef.instance.left = Math.round(offsetLeft);
                this.componentRef.instance.top = Math.round(offsetTop + elementHeight / 2);
                break;
            }
            default: {
                break;
            }
        }
    }

    private getOffsetTopFromDocument(elem: HTMLElement) {

        // Offset accumulator
        let offset = 0;

        // Loop up the DOM
        if (elem.offsetParent) {
            do {
                offset += elem.offsetTop;
                elem = elem.offsetParent as HTMLElement;
            } while (elem);
        }

        // Return our distance
        return offset < 0 ? 0 : offset;
    }

}
