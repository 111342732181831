/**
 * Used to retain the name of the field the file is supposed to go to
 */
export class LPFile {

    public readonly file: Blob;
    public readonly fieldName: string;

    static fromFile(file: File) {
        return new LPFile(file, file && file.name);
    }

    constructor(file: Blob, fieldName: string) {
        this.file = file;
        this.fieldName = fieldName;
    }

    hasData() {
        return this.file !== undefined && !!this.fieldName;
    }

}
