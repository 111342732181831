/*
 * to-number.pipe.ts
 * Little Phil
 *
 * Created on 6/7/21
 * Copyright © 2018 Little Phil. All rights reserved.
 */

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'toNumber',
})
export class ToNumberPipe implements PipeTransform {

    transform(value: string, ...args: unknown[]): number {
        return parseFloat(value);
    }

}
