<div
    *ngIf="platform"
    class="btn btn-outline-primary {{platform}}-btn"
    [class.active]="active === true"
    [class.btn-sm]="size === 'sm'"
    [class.btn-lg]="size === 'lg'">

    <app-svg-icon
        *ngIf="iconSvg"
        class="me-2"
        [svg]="iconSvg"
    />

    {{title}}
</div>
