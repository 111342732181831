import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';

@Injectable({
    providedIn: 'root',
})
export class BrowserService {

    constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

    /**
     * Check if the user agent contains iPhone
     */
    public static iPhone() {
        if (typeof navigator === 'object' && navigator.userAgent) {
            const userAgent = navigator.userAgent.toString();
            return userAgent.indexOf('iPhone') !== -1;
        } else {
            return false;
        }
    }

    /**
     * Check if the user agent contains iPad
     */
    public static iPad() {
        if (typeof navigator === 'object' && navigator.userAgent) {
            const userAgent = navigator.userAgent.toString();
            return userAgent.indexOf('iPad') !== -1;
        } else {
            return false;
        }
    }

    /**
     * Checks the user agent for iPhone or iPad devices
     */
    public static iOS() {
        return BrowserService.iPhone() || BrowserService.iPad();
    }

    /**
     * Service helper for rendering browser only types.
     * Browser only types are things like $, Intercom, and anything attached to the global window.
     *
     * @param browserFn
     * @param serverFn
     */
    public render(browserFn: Function, serverFn?: Function) {
        if (this.isBrowserPlatform()) {
            browserFn();
        } else if (serverFn) {
            serverFn();
        }
    }

    /**
     * Helper for knowing if the current platform is a browser
     */
    public isBrowserPlatform(): boolean {
        return isPlatformBrowser(this.platformId);
    }

    /**
     * Helper for knowing if the current platform is a server
     */
    public isServerPlatform(): boolean {
        return isPlatformServer(this.platformId);
    }

}
