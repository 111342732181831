/*
 * form-data.class.ts
 * Little Phil
 *
 * Created on 23/8/21
 * Copyright © 2018 Little Phil. All rights reserved.
 */

import { LPFile } from './lp-file.class';

export class LPFormData {

    /**
     * Creates a FormData object from the specified object and files
     *
     * @param obj - The object to create the form data from
     * @param [files] - An array of files to append to the form data
     * @returns The generated form data
     */
    static from(obj: Record<string, any>, files?: LPFile[]) {

        const formData: FormData = new FormData();

        if (files) {
            if (files.length === 1 && files[0] && files[0].hasData()) {
                formData.append('file', files[0].file, files[0].fieldName);
            } else if (files.length > 1) {
                files.forEach(file => {
                    if (file && file.hasData()) {
                        formData.append('files', file.file, file.fieldName);
                    }
                });
            }
        }

        Object.keys(obj).forEach(key => {

            if (Array.isArray(obj[key])) {

                for (const value of obj[key]) {
                    formData.append(key, value);
                }

            } else if (typeof obj[key] === 'object' && obj[key] !== null) {

                for (const [subKey, subValue] of Object.entries(obj[key]) as [string, any]) {

                    if (Array.isArray(subValue) || typeof subValue === 'object') {
                        throw new Error('Not implemented');
                    } else if (typeof subValue !== 'undefined') {
                        formData.append(`${key}[${subKey}]`, subValue);
                    }

                }

            } else if (typeof obj[key] !== 'undefined') { // (if (obj[key])) would rule out falsy values but we need them

                formData.append(key, obj[key]);

            }
        });

        return formData;
    }

}
