/*
 * icons-socials.util.ts
 * Little Phil
 *
 * Created on 29/3/23
 * Copyright © 2018 Little Phil. All rights reserved.
 */

// Ignore tslint due to long lines
/* tslint:disable */

export const BLOG_SVG = `
<svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 20.8095C18.6262 20.8095 24 16.1503 24 10.4047C24 4.65913 18.6262 0 12 0C5.37164 0 0 4.65913 0 10.4047C0 13.8509 1.93036 16.9033 4.90691 18.7986L4.16857 22.2191C4.08186 22.6208 4.38796 23 4.79889 23C4.92985 23 5.05771 22.9601 5.16546 22.8857L8.73382 20.4201C9.77127 20.6748 10.8687 20.8095 12 20.8095ZM6.47 4.152C6.00166 4.44471 5.85929 5.06166 6.152 5.53C6.44471 5.99834 7.06166 6.14071 7.53 5.848C11.1138 3.60814 15.104 4.51823 17.2929 6.70711C17.6834 7.09763 18.3166 7.09763 18.7071 6.70711C19.0976 6.31658 19.0976 5.68342 18.7071 5.29289C15.896 2.48177 10.8862 1.39186 6.47 4.152Z"
        fill="currentColor"/>
</svg>
`;

export const EMAIL_SVG = `
<svg width="24" height="17" viewBox="0 0 24 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.65517 16.5H22.3448C23.2585 16.5 24 15.7455 24 14.8158V2.18421C24 1.25368 23.2585 0.5 22.3448 0.5H1.65517C0.741517 0.5 0 1.25368 0 2.18421V14.8158C0 15.7455 0.741517 16.5 1.65517 16.5ZM3.61394 2.71065C3.17799 2.37158 2.54972 2.45011 2.21065 2.88606C1.87158 3.32201 1.95011 3.95028 2.38606 4.28935L8.9303 9.37931C10.7358 10.7836 13.2642 10.7836 15.0697 9.37931L21.6139 4.28935C22.0499 3.95028 22.1284 3.32201 21.7894 2.88606C21.4503 2.45011 20.822 2.37158 20.3861 2.71065L13.8418 7.80061C12.7585 8.6432 11.2415 8.6432 10.1582 7.80061L3.61394 2.71065Z"
        fill="currentColor"/>
</svg>
`;

export const FACEBOOK_SVG = `
<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M24 12.5733C24 5.90541 18.6274 0.5 12 0.5C5.37258 0.5 0 5.90541 0 12.5733C0 18.5995 4.38823 23.5943 10.125 24.5V16.0633H7.07812V12.5733H10.125V9.91343C10.125 6.88755 11.9165 5.21615 14.6576 5.21615C15.9705 5.21615 17.3438 5.45195 17.3438 5.45195V8.42313H15.8306C14.3399 8.42313 13.875 9.35379 13.875 10.3086V12.5733H17.2031L16.6711 16.0633H13.875V24.5C19.6118 23.5943 24 18.5995 24 12.5733Z"
        fill="currentColor"/>
</svg>
`;

export const GOOGLE_SVG = `
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M23.7666 9.6498L12 9.6V14.4H18.7818C17.7924 17.1942 15.1338 19.2 12 19.2C8.0238 19.2 4.8 15.9762 4.8 12C4.8 8.0238 8.0238 4.8 12 4.8C13.8354 4.8 15.5052 5.4924 16.7766 6.6234L20.1708 3.2292C18.0276 1.2318 15.1608 0 12 0C5.373 0 0 5.373 0 12C0 18.627 5.373 24 12 24C18.627 24 24 18.627 24 12C24 11.1954 23.9172 10.41 23.7666 9.6498Z"
        fill="#FFC107"/>
    <path
        d="M1.38379 6.4146L5.32639 9.306C6.39319 6.6648 8.97679 4.8 12.0002 4.8C13.8356 4.8 15.5054 5.4924 16.7768 6.6234L20.171 3.2292C18.0278 1.2318 15.161 0 12.0002 0C7.39099 0 3.39379 2.6022 1.38379 6.4146Z"
        fill="#FF3D00"/>
    <path
        d="M12.0004 24.0001C15.1 24.0001 17.9164 22.8139 20.0458 20.8849L16.3318 17.7421C15.127 18.6547 13.6294 19.2001 12.0004 19.2001C8.87918 19.2001 6.22898 17.2099 5.23058 14.4325L1.31738 17.4475C3.30338 21.3337 7.33658 24.0001 12.0004 24.0001Z"
        fill="#4CAF50"/>
    <path
        d="M23.7666 9.64981L12 9.60001V14.4H18.7818C18.3066 15.7422 17.4432 16.8996 16.3296 17.7426C16.3302 17.742 16.3308 17.742 16.3314 17.7414L20.0454 20.8842C19.7826 21.123 24 18 24 12C24 11.1954 23.9172 10.41 23.7666 9.64981Z"
        fill="#1976D2"/>
</svg>`;

export const LINKED_IN_SVG = `
<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 2.5C0 1.39543 0.895431 0.5 2 0.5H22C23.1046 0.5 24 1.39543 24 2.5V22.5C24 23.6046 23.1046 24.5 22 24.5H2C0.89543 24.5 0 23.6046 0 22.5V2.5ZM12.9409 9.49999H9.47572V21.05H13.0916V14.675C13.0916 13.925 13.5888 12.35 15.2762 12.35C16.9636 12.35 17.0088 14.075 17.0088 14.9V21.05H20.7V13.85C20.7 10.175 18.666 9.19999 16.4061 9.19999C14.2733 9.19999 13.4824 10.3134 13.0202 10.964C12.9927 11.0027 12.9663 11.0399 12.9409 11.075V9.49999ZM7.29116 9.50001H3.59995V21.05H7.21581L7.29116 9.50001ZM5.4 8C6.5598 8 7.5 7.0598 7.5 5.9C7.5 4.7402 6.5598 3.8 5.4 3.8C4.2402 3.8 3.3 4.7402 3.3 5.9C3.3 7.0598 4.2402 8 5.4 8Z"
        fill="currentColor"/>
</svg>
`;

export const X_SVG = `
<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M11.6468 8.96864L18.9321 0.5H17.2057L10.8799 7.8532L5.82741 0.5H0L7.6403 11.6193L0 20.5H1.72649L8.40678 12.7348L13.7425 20.5H19.5699L11.6464 8.96864H11.6468ZM9.28211 11.7173L8.50799 10.6101L2.34857 1.79968H5.00037L9.97108 8.90994L10.7452 10.0172L17.2066 19.2594H14.5548L9.28211 11.7177V11.7173Z"
        fill="currentColor"/>
</svg>
`;

