/*
 * csrf-interceptor.util.ts
 * Little Phil
 *
 * Created on 19/5/20
 * Copyright © 2018 Little Phil. All rights reserved.
 */
import { Injectable, NgModule } from '@angular/core';
import {
    HTTP_INTERCEPTORS,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpXsrfTokenExtractor
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class CsrfInterceptor implements HttpInterceptor {
    constructor(private tokenExtractor: HttpXsrfTokenExtractor) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = this.tokenExtractor.getToken() as string;

        if (!token) {
            return next.handle(request);
        }

        const csrfRequest = request.clone({
            setHeaders: { 'X-XSRF-Token': token }
        });

        return next.handle(csrfRequest);
    }
}

/**
 * Module is required to avoid TransferHttpCacheModule from overriding provider in AppModule
 */
@NgModule({
    providers: [{
        provide: HTTP_INTERCEPTORS,
        useClass: CsrfInterceptor,
        multi: true
    }]
})
export class CsrfInterceptorModule {}
