import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserAvatarComponent } from '../../user-avatar/user-avatar.component';

@Component({
    selector: 'app-assistant-chat-user-message',
    standalone: true,
    imports: [CommonModule, UserAvatarComponent],
    templateUrl: './assistant-chat-user-message.component.html',
    styles: [
        `
            :host {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
            }
        `,
    ],
})
export class AssistantChatUserMessageComponent {
    @Input() message: string;
}
