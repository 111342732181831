import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LpCommonModule } from '../../_modules/lp-common/lp-common.module';
import { FLAG_EMOJI, TAX_DEDUCTIBILITY_MESSAGE } from '@little-phil/js/lib/common/enums';
import { RouterLink } from '@angular/router';
import { isCountryDGRSupported } from '@little-phil/js/lib/utils/dgr';

@Component({
    selector: 'app-charity-card',
    standalone: true,
    imports: [CommonModule, LpCommonModule, RouterLink],
    templateUrl: './charity-card.component.html',
    styles: [
        `
            :host {
                display: block;
            }
        `,
    ],
})
export class CharityCardComponent implements OnChanges {

    // Cause inputs
    @Input() title: string;
    @Input() location: string;
    @Input() countryCode: string;
    @Input() amountRaised: number;
    @Input() givers: number;
    @Input() isDGRRegistered: boolean;

    // Charity inputs
    @Input() charityName: string;
    @Input() charityLogoImageUrl: string;

    // Category inputs
    @Input() categoryName: string;
    @Input() categoryColour: string;

    // UI inputs
    @Input() charityLink: string;
    @Input() causeLink: string;
    @Input() roundCorners: boolean;
    @Input() displayShadow: boolean;
    @Input() expandOnHover: boolean;
    @Input() wrapTitle: boolean;

    public countryFlag: string;
    public isDGRSupported: boolean; // If we don't have the DGR details for a country, it is not supported
    public taxDeductibleMessage: string; // The tax-deductible message for the country of the cause

    protected readonly isCountryDGRSupported = isCountryDGRSupported;

    ngOnChanges(changes: SimpleChanges) {
        if (changes.isDGRRegistered || this.countryCode) {
            this.updateDGRInformation(this.isDGRRegistered, this.countryCode);
        }
    }

    /**
     * Update the displayed DGR information as per the charity passed as parameter
     *
     * @param isDGRRegistered
     * @param countryCode
     * @private
     */
    private updateDGRInformation(isDGRRegistered: boolean, countryCode: string) {
        this.isDGRRegistered = isDGRRegistered;
        this.isDGRSupported = isCountryDGRSupported(countryCode);

        // No need to set the country flag and tax-deductible message if the charity is not DGR or the country not supported for DGR
        if (this.isDGRRegistered && this.isDGRSupported) {
            this.countryFlag = FLAG_EMOJI[countryCode];
            this.taxDeductibleMessage = TAX_DEDUCTIBILITY_MESSAGE[countryCode];
        }
    }

}
