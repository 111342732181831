/*
 * disable-ssr.decorator.ts
 * Little Phil
 *
 * Created on 25/7/22
 * Copyright © 2018 Little Phil. All rights reserved.
 */

import { BrowserService } from '../_services/browser.service';

/**
 * Data structure containing required fields for this decorator
 */
interface IDisableSsrDecorator {
    browserService: BrowserService;
}

/**
 * Disables a function or class method if the page is rendered with SSR
 * @param returnValue - Value to return if the function is rendered with SSR
 */
export const DisableSSR = (returnValue?: any) => {
    return (target: IDisableSsrDecorator, propertyKey: string, descriptor: PropertyDescriptor) => {

        if (descriptor.value) {
            overrideDescriptorValue(descriptor, returnValue);
        }

        if (descriptor.get) {
            overrideDescriptorGetter(descriptor, returnValue);
        }

    };
};

/**
 * Overrides the descriptors value function
 * @param descriptor
 * @param returnValue
 */
const overrideDescriptorValue = (descriptor: PropertyDescriptor, returnValue?: any) => {
    const func = descriptor.value;
    descriptor.value = function (this: IDisableSsrDecorator, ...args) {
        if (this.browserService.isServerPlatform()) {
            return returnValue;
        }

        return func.call(this, ...args);
    };
};

/**
 * Overrides the descriptors get function
 * @param descriptor
 * @param returnValue
 */
const overrideDescriptorGetter = (descriptor: PropertyDescriptor, returnValue?: any) => {
    const func = descriptor.get;
    descriptor.get = function (this: IDisableSsrDecorator) {
        if (this.browserService.isServerPlatform()) {
            return returnValue;
        }

        return func.call(this);
    };
};
