import { Injectable } from '@angular/core';
import { LOG_LEVEL } from '../_utils/log-level.util';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class LoggerService {

    private prefix = '';

    /**
     * Allows for prefix to all logs. If using this the LoggerService should be provided in the individual component.
     *
     * Example:
     * @Component({
     *     providers: [LoggerService],
     * })
     *
     * @param prefix
     */
    setPrefix(prefix: string) {
        if (!prefix) { // ensure that the prefix is a string
            prefix = '';
        }

        this.verbose('Setting LoggerService prefix to', prefix);
        this.prefix = prefix;
    }

    /**
     * Wrapper for console.error with some additional information
     * @param message
     * @param data
     */
    error(message?: any, ...data: any[]) {
        console.error(`${this.prefix}[ERROR]`, message, ...data);
    }

    /**
     * Wrapper for console.log with some additional information
     * @param message
     * @param data
     */
    info(message?: any, ...data: any[]) {
        if (environment.logLevel >= LOG_LEVEL.info) {
            console.log(`${this.prefix}[INFO]`, message, ...data);
        }
    }

    /**
     * Should be used for logging useful information pre-production
     * @param message
     * @param data
     */
    verbose(message?: any, ...data: any[]) {
        if (environment.logLevel >= LOG_LEVEL.verbose) {
            console.log(`${this.prefix}[VERBOSE]`, message, ...data);
        }
    }

    /**
     * Should be used to log out HTTP response with a HttpInterceptor
     * @param group
     * @param data
     */
    http(group?: string, ...data: any[]) {
        if (environment.logLevel >= LOG_LEVEL.http) {
            console.group(`${this.prefix}[HTTP]`, group);
            console.log(...data);
            console.groupEnd();
        }
    }

}
