/*
 * share-link.model.ts
 * Little Phil
 *
 * Created on 26/6/23
 * Copyright © 2018 Little Phil. All rights reserved.
 */

import { CAUSE_TYPE, SHARE_LINK_TYPE } from '@little-phil/js/lib/common/enums';

import { BaseModel } from './_base.model';
import { Charity } from './charity.model';
import { Campaign } from './campaign.model';
import { environment } from '../../environments/environment';

export class ShareLink extends BaseModel {

    public static endpoint = 'share-link';

    public readonly title: string = undefined;
    public readonly description: string = undefined;
    public readonly type: SHARE_LINK_TYPE = undefined;
    public readonly link: string = undefined;
    public readonly donatedAmount: number = undefined;
    public readonly referrer: string = undefined;
    public readonly owner: string = undefined;
    public readonly ownerType: 'Charity' | 'User' = undefined;
    public readonly causeType: CAUSE_TYPE = undefined;
    public readonly automated: boolean = undefined;
    public causeId: string = undefined;
    public cause: Campaign | Charity = undefined;

    // NOTE: the below properties are only available for charity share links
    public readonly donations: number = undefined;
    public readonly registers: number = undefined;
    public readonly signIns: number = undefined;
    public readonly uniqueViewers: number = undefined;
    public readonly views: number = undefined;


    /**
     * Constructor
     * @param {object} json
     */
    constructor(json: object = {}) {
        super();
        super.init(json);
    }

    protected deserialise() {
        if (
            typeof this.causeId === 'object' &&
            this.causeType === CAUSE_TYPE.CHARITY &&
            !(this.cause instanceof Charity)
        ) {
            this.cause = new Charity(this.causeId);
            this.causeId = this.cause.id; // restore dumb dumb API issue
        }

        if (
            typeof this.causeId === 'object' &&
            this.causeType === CAUSE_TYPE.CAMPAIGN &&
            !(this.cause instanceof Campaign)
        ) {
            this.cause = new Campaign(this.causeId);
            this.causeId = this.cause.id; // restore dumb dumb API issue
        }
    }

    /**
     * Returns the
     */
    public getUrl(): string {
        return `${environment.shareLinkUrl}/s/${this.id}`;
    }

    /**
     * Returns the background colour for the status badge
     */
    public getStatusBadgeText() {
        switch (this.type) {
            case SHARE_LINK_TYPE.CONTENT_ASSISTANT:
                return 'Content Assistant';
            case SHARE_LINK_TYPE.INFLUENCER:
                return 'Influencer';
            case SHARE_LINK_TYPE.FUNDRAISER:
                return 'Fundraiser';
            case SHARE_LINK_TYPE.MARKETING:
                return 'Marketing';
            default:
                return this.type;
        }
    }

    /**
     * Returns the background colour for the status badge
     */
    public getStatusBadgeBackgroundColour() {
        switch (this.type) {
            case SHARE_LINK_TYPE.CONTENT_ASSISTANT:
            case SHARE_LINK_TYPE.INFLUENCER:
                return '#FDE580';
            case SHARE_LINK_TYPE.FUNDRAISER:
                return '#7DE7B5';
            case SHARE_LINK_TYPE.MARKETING:
                return '#B9ECFD';
        }
    }

    /**
     * Returns the background colour for the status badge
     */
    public getStatusBadgeTextColour() {
        switch (this.type) {
            default:
                return '#203B4C';
        }
    }

}
