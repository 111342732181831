import { Component } from '@angular/core';

@Component({
    selector: 'app-recaptcha-protection-policies',
    templateUrl: './recaptcha-protection-policies.component.html',
    styleUrls: ['./recaptcha-protection-policies.component.scss'],
})
export class RecaptchaProtectionPoliciesComponent {
    /*
     * Note: this component is required by Google in order to hide the reCAPTCHA badge and should appear on any page that uses reCAPTCHA.
     * https://developers.google.com/recaptcha/docs/faq#id-like-to-hide-the-recaptcha-badge.-what-is-allowed
     * https://stackoverflow.com/a/44543771
     */
}
