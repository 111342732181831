<ng-container *ngIf="control">
    <div>
        <label
            class="mb-1"
            [class.font-size-xs]="labelFontSize === 'xs'"
            *ngIf="label">
            {{label}}
            <span class="required-star" *ngIf="required">*</span>
        </label>

        <ng-content/>

        <p
            *ngIf="details && !hasErrors()"
            class="font-size-xs mt-2"
            data-sentry-unmask>
            {{details}}
        </p>

        <p
            *ngIf="hasErrors()"
            class="font-size-xs mt-2 text-danger"
            data-sentry-unmask>
            {{getErrorMessage()}}
        </p>
    </div>
</ng-container>
