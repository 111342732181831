import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GoogleMaps } from '../../../_utils/google-maps.util';
import { IGoogleMapsPosition } from '../../../dashboard/partials/google-map/google-map.component';
import { ToastrService } from 'ngx-toastr';

export interface GoogleMapsLocation {
    location: string;
    lat: number;
    lng: number;
}

@Component({
    selector: 'app-dashboard-form-google-maps',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './dashboard-form-google-maps.component.html',
    styleUrls: ['./dashboard-form-google-maps.component.scss'],
})
export class DashboardFormGoogleMapsComponent implements OnInit {

    @ViewChild('map', { static: true })
    public mapElementRef: ElementRef<HTMLDivElement>;

    @ViewChild('search', { static: true })
    public searchElementRef: ElementRef<HTMLInputElement>;

    @Input()
    public defaultLocation: GoogleMapsLocation;

    @Output()
    public onLocationChange = new EventEmitter<GoogleMapsLocation>();

    private isLoadingPromise: Promise<void>;

    private map: google.maps.Map;
    private marker: google.maps.Marker;
    private autocomplete: google.maps.places.Autocomplete;

    private location: string;
    private lat: number;
    private lng: number;

    constructor(private toast: ToastrService) {}

    ngOnInit() {
        this.loadAndSetupMap();
    }

    /**
     * Creates a map at the given position
     * @private
     */
    private loadAndSetupMap() {

        if (this.isLoadingPromise) { // the promise already exists, it means we are loading or have loaded the map already
            return;
        }

        this.isLoadingPromise = new Promise((resolve, reject) => {
            /**
             * Ensure that the Google Map script is loaded
             * Setup map, marker and autocomplete
             */
            GoogleMaps.loadScript().then(() => {
                const location = this.defaultLocation || {
                    location: 'Gold Coast, Queensland, Australia',
                    lat: -28.022743689104555,
                    lng: 153.4016724609375,
                };

                this.location = location.location;
                this.lat = location.lat;
                this.lng = location.lng;

                // Create map
                this.map = this.createMap();

                // Create marker
                this.marker = this.createMarker();

                // Create autocomplete
                this.autocomplete = this.createAutocomplete();
                this.setupAutocompleteListener();

                // Emit initial location
                this.emitLocation();

                resolve();
            }).catch(e => {
                reject(e);
            });
        });

    }

    /**
     * Create the map and mount to the DOM
     * @private
     */
    private createMap() {
        return new google.maps.Map(this.mapElementRef.nativeElement, {
            center: {
                lat: this.lat,
                lng: this.lng,
            },
            draggable: false,
            zoom: 3,
            zoomControl: false,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
        });
    }

    /**
     * Create and place a draggable on the map at the given position
     */
    private createMarker() {
        return new google.maps.Marker({
            map: this.map,
            position: {
                lat: this.lat,
                lng: this.lng,
            },
            animation: google.maps.Animation.DROP,
            draggable: false,
            title: this.location,
        });
    }

    /**
     * Create an autocomplete on the search element and bind it to the map view
     */
    private createAutocomplete() {
        const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
            fields: ['address_components', 'geometry'],
        });
        autocomplete.bindTo('bounds', this.map);
        this.searchElementRef.nativeElement.value = this.location;

        return autocomplete;
    }

    /**
     * Listener for the place_changed event of the autocomplete
     */
    private setupAutocompleteListener() {
        this.autocomplete.addListener('place_changed', () => {
            this.handleAutocompletePlaceChange();
        });
    }

    /**
     * Handler for when the selected location changes
     * @private
     */
    private handleAutocompletePlaceChange() {
        const place = this.autocomplete.getPlace();

        if (!('address_components' in place && 'geometry' in place)) {
            this.toast.error('Misconfigured Google Map instance.');
            console.error('Invalid Google Map location', place);
            return;
        }

        // Update local state for new location
        this.location = formatPlaceToPrettyLocation(place);
        this.lat = place.geometry.location.lat();
        this.lng = place.geometry.location.lng();

        // Update Google elements to match mew location
        const position = {
            lat: this.lat,
            lng: this.lng,
        };
        this.map.setCenter(position);
        this.marker.setPosition(position);
        this.marker.setTitle(this.location);
        this.searchElementRef.nativeElement.value = this.location;

        this.emitLocation();
    }

    /**
     * Emits the current location to parent
     * @private
     */
    private emitLocation() {
        this.onLocationChange.emit({
            location: this.location,
            lat: this.lat,
            lng: this.lng,
        });
    }
}

/**
 * Returns a formatted location using the provided place
 * @param place
 */
const formatPlaceToPrettyLocation = (place: google.maps.places.PlaceResult) => {
    let suburb: string;
    let stateShort: string;
    let stateLong: string;
    let country: string;

    place.address_components.forEach((component) => {
        if (component.types.includes('country')) {
            country = component.long_name;
        } else if (component.types.includes('administrative_area_level_1')) {
            stateShort = component.short_name;
            stateLong = component.long_name;
        } else if (component.types.includes('locality')) {
            suburb = component.long_name;
        }
    });

    const components = [];

    if (suburb) {
        components.push(suburb, stateShort);
    } else if (stateLong) {
        components.push(stateLong);
    }

    components.push(country);

    return components
        .filter(v => !!v)
        .join(', ');
};
