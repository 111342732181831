import { ROUTE_PATHS } from '@little-phil/js/lib/common/constants';
import {
    ADMIN_CONTROLS_SVG, ASSISTANT_SVG,
    CAMPAIGNS_SVG,
    CATEGORIES_SVG,
    COMPANIES_SVG,
    CONTENT_ASSISTANT_SVG,
    DASHBOARD_SVG,
    DEV_TOOLS_SVG,
    DONATIONS_SVG,
    EXPLORE_SVG,
    IMPACT_SVG,
    NONPROFIT_SVG,
    PAYOUT_SVG,
    PROFILE_SVG,
    REGISTER_SVG,
    REPORTS_SVG,
    SETTINGS_SVG,
    SHARE_LINK_SVG,
    SHOPIFY_CART_ICON,
    SIGN_IN_SVG, TWO_COINS_SVG,
    WIDGET_SVG,
} from './icons-nav.util';

export type MenuItem =
    {
        type: 'link';
        icon: string;
        title: string;
        link: string;
    } |
    {
        type: 'header';
        title: string;
    } |
    {
        type: 'divider';
    } |
    {
        type: 'help';
    } |
    {
        type: 'feedback';
    } |
    {
        type: 'logout';
    };

const dashboardLinkItem: MenuItem = {
    type: 'link',
    icon: DASHBOARD_SVG,
    title: 'Dashboard',
    link: ROUTE_PATHS.web.dashboard.route,
};

const campaignsLinkItem: MenuItem = {
    type: 'link',
    icon: CAMPAIGNS_SVG,
    title: 'Campaigns',
    link: ROUTE_PATHS.web.campaign.list,
};

const contentAssistantLinkItem: MenuItem = {
    type: 'link',
    icon: CONTENT_ASSISTANT_SVG,
    title: 'Content Assistant',
    link: ROUTE_PATHS.web.contentAssistant,
};

const aiAssistantLinkItem: MenuItem = {
    type: 'link',
    icon: ASSISTANT_SVG,
    title: 'AI Assistant',
    link: ROUTE_PATHS.web.dashboardCharityAssistant.edit,
};

const shareLinksLinkItem: MenuItem = {
    type: 'link',
    icon: SHARE_LINK_SVG,
    title: 'Share links',
    link: ROUTE_PATHS.web.shareLinks.list,
};

const shopifyPluginLinkItem: MenuItem = {
    type: 'link',
    icon: SHOPIFY_CART_ICON,
    title: 'Shopify plugin',
    link: ROUTE_PATHS.web.dashboardShopify.root,
};

const widgetsLinkItem: MenuItem = {
    type: 'link',
    icon: WIDGET_SVG,
    title: 'Widgets',
    link: ROUTE_PATHS.web.widgets.list,
};

const companyProfileLinkItem: MenuItem = {
    type: 'link',
    title: 'Profile',
    icon: COMPANIES_SVG,
    link: ROUTE_PATHS.web.dashboard.profile.route,
};

const charityProfileLinkItem = (charityId: string): MenuItem => ({
    type: 'link',
    title: 'Profile',
    icon: NONPROFIT_SVG,
    link: ROUTE_PATHS.web.charity.edit(charityId),
});

const donationsLinkItem: MenuItem = {
    type: 'link',
    title: 'Donations',
    icon: DONATIONS_SVG,
    link: ROUTE_PATHS.web.dashboardPayments,
};

const reportsLinkItem: MenuItem = {
    type: 'link',
    title: 'Reports',
    icon: REPORTS_SVG,
    link: ROUTE_PATHS.web.reports,
};

const settingsLinkItem: MenuItem = {
    type: 'link',
    title: 'Settings',
    icon: SETTINGS_SVG,
    link: ROUTE_PATHS.web.settings,
};

const categoriesLinkItem: MenuItem = {
    type: 'link',
    title: 'Categories',
    icon: CATEGORIES_SVG,
    link: ROUTE_PATHS.web.category.list,
};

const charitiesLinkItem: MenuItem = {
    type: 'link',
    title: 'Charities',
    icon: NONPROFIT_SVG,
    link: ROUTE_PATHS.web.charity.list,
};

const companiesLinkItem: MenuItem = {
    type: 'link',
    title: 'Companies',
    icon: COMPANIES_SVG,
    link: ROUTE_PATHS.web.company.list,
};

const creditPoolsLinkItem: MenuItem = {
    type: 'link',
    title: 'Credit pools',
    icon: TWO_COINS_SVG,
    link: ROUTE_PATHS.web.creditPool.list,
};

const payoutsLinkItem: MenuItem = {
    type: 'link',
    title: 'Payouts',
    icon: PAYOUT_SVG,
    link: ROUTE_PATHS.web.dashboardPayouts.root,
};

const adminControlsLinkItem: MenuItem = {
    type: 'link',
    title: 'Admin controls',
    icon: ADMIN_CONTROLS_SVG,
    link: ROUTE_PATHS.web.admin,
};

const devToolsLinkItem: MenuItem = {
    type: 'link',
    title: 'Dev tools',
    icon: DEV_TOOLS_SVG,
    link: ROUTE_PATHS.web.developer,
};

const myImpactLinkItem: MenuItem = {
    type: 'link',
    title: 'My impact',
    icon: IMPACT_SVG,
    link: ROUTE_PATHS.web.impact,
};

const exploreLinkItem: MenuItem = {
    type: 'link',
    title: 'Explore',
    icon: EXPLORE_SVG,
    link: ROUTE_PATHS.web.explore,
};

const accountLinkItem: MenuItem = {
    type: 'link',
    title: 'Account',
    icon: PROFILE_SVG,
    link: ROUTE_PATHS.web.account,
};

const registerLinkItem: MenuItem = {
    type: 'link',
    title: 'Register',
    icon: REGISTER_SVG,
    link: ROUTE_PATHS.web.register,
};

const signInLinkItem: MenuItem = {
    type: 'link',
    title: 'Sign in',
    icon: SIGN_IN_SVG,
    link: ROUTE_PATHS.web.login,
};

const employeesLinkItem: MenuItem = {
    type: 'link',
    title: 'Employees',
    icon: PROFILE_SVG,
    link: ROUTE_PATHS.web.dashboard.employees.route,
};

const givingCreditsLinkItem: MenuItem = {
    type: 'link',
    title: 'Giving credits',
    icon: TWO_COINS_SVG,
    link: ROUTE_PATHS.web.dashboard.givingCredits.route, // TODO rename
};


const fundraisingToolsHeaderItem: MenuItem = {
    type: 'header',
    title: 'Fundraising tools',
};

const yourNonprofitHeaderItem: MenuItem = {
    type: 'header',
    title: 'Your nonprofit',
};

const yourCompanyHeaderItem: MenuItem = {
    type: 'header',
    title: 'Your company',
};

const resourcesHeaderItem: MenuItem = {
    type: 'header',
    title: 'Resources',
};

const adminHeaderItem: MenuItem = {
    type: 'header',
    title: 'Admin',
};

const toolsHeaderItem: MenuItem = {
    type: 'header',
    title: 'Tools',
};

const supportHeaderItem: MenuItem = {
    type: 'header',
    title: 'Support',
};

const littlePhilHeaderItem: MenuItem = {
    type: 'header',
    title: 'Little Phil',
};




const dividerItem: MenuItem = {
    type: 'divider',
};

const helpItem: MenuItem = {
    type: 'help',
};

const feedbackItem: MenuItem = {
    type: 'feedback',
};

const logoutItem: MenuItem = {
    type: 'logout',
};


export const userMobileItems: MenuItem[] = [
    littlePhilHeaderItem,
    myImpactLinkItem,
    exploreLinkItem,
    accountLinkItem,
    logoutItem,
];

export const userDesktopItems: MenuItem[] = [
    myImpactLinkItem,
    exploreLinkItem,
    accountLinkItem,
    dividerItem,
    logoutItem,
];

export const guestMobileItems: MenuItem[] = [
    exploreLinkItem,
    dividerItem,
    registerLinkItem,
    signInLinkItem,
];

export const guestDesktopItems: MenuItem[] = [];

export const adminDashboardDesktopItems: MenuItem[] = [
    dashboardLinkItem,
    dividerItem,
    toolsHeaderItem,
    contentAssistantLinkItem,
    aiAssistantLinkItem,
    dividerItem,
    resourcesHeaderItem,
    campaignsLinkItem,
    categoriesLinkItem,
    charitiesLinkItem,
    companiesLinkItem,
    creditPoolsLinkItem,
    donationsLinkItem,
    payoutsLinkItem,
    dividerItem,
    adminHeaderItem,
    adminControlsLinkItem,
    devToolsLinkItem,
];

export const adminMobileItems: MenuItem[] = [
    ...adminDashboardDesktopItems,
    dividerItem,
    ...userMobileItems,
];

export const adminDesktopItems: MenuItem[] = [
    dashboardLinkItem,
    ...userDesktopItems,
];

export const charityDashboardDesktopItems = (charityId: string): MenuItem[] => [
    dashboardLinkItem,
    dividerItem,
    fundraisingToolsHeaderItem,
    campaignsLinkItem,
    contentAssistantLinkItem,
    shareLinksLinkItem,
    shopifyPluginLinkItem,
    widgetsLinkItem,
    dividerItem,
    yourNonprofitHeaderItem,
    charityProfileLinkItem(charityId),
    donationsLinkItem,
    reportsLinkItem,
    settingsLinkItem,
    dividerItem,
    supportHeaderItem,
    helpItem,
    feedbackItem,
];

export const charityMobileItems = (charityId: string): MenuItem[] => [
    ...charityDashboardDesktopItems(charityId),
    dividerItem,
    ...userMobileItems,
];

export const charityDesktopItems: MenuItem[] = [
    dashboardLinkItem,
    ...userDesktopItems,
];

export const companyDashboardDesktopItems = (companyId: string): MenuItem[] => [
    dashboardLinkItem,
    dividerItem,
    yourCompanyHeaderItem,
    companyProfileLinkItem,
    employeesLinkItem,
    givingCreditsLinkItem,
    donationsLinkItem,
    dividerItem,
    supportHeaderItem,
    helpItem,
    feedbackItem,
];

export const companyMobileItems = (companyId: string): MenuItem[] => [
    ...companyDashboardDesktopItems(companyId),
    dividerItem,
    ...userMobileItems,
];

export const companyDesktopItems = (companyId: string): MenuItem[] => [
    dashboardLinkItem,
    ...userDesktopItems,
];
