/*
 * icons-brand.util.ts
 * Little Phil
 *
 * Created on 22/3/23
 * Copyright © 2018 Little Phil. All rights reserved.
 */

// Ignore tslint due to long lines
/* tslint:disable */

// TODO further refactor this to use a IconRegistry
// https://medium.com/angular-in-depth/how-to-create-an-icon-library-in-angular-4f8863d95a

export const ARROW_RIGHT_SVG = `
<svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8 17L15.2929 9.70711C15.6834 9.31658 15.6834 8.68342 15.2929 8.29289L8 1M15.5 9H0" stroke="currentColor"/>
</svg>
`;

export const BIN_16_SVG = `
<svg width="16" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13 1.5H1M8 0.5H6M5 5.25L5.25 12.25M9 5.25L8.75 12.25M3.66457 15.5H10.3354C10.8679 15.5 11.307 15.0827 11.3341 14.551L11.9464 2.55095C11.9755 1.9794 11.52 1.5 10.9477 1.5H3.05232C2.48002 1.5 2.02446 1.9794 2.05362 2.55095L2.66587 14.551C2.693 15.0827 3.13208 15.5 3.66457 15.5Z" stroke="currentColor" stroke-linecap="round"/>
</svg>
`;

export const COPY_16_SVG = `
<svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M5.5 14V3.5C5.5 2.67157 6.17157 2 7 2H9.87868C10.2765 2 10.658 2.15804 10.9393 2.43934L14.0607 5.56066C14.342 5.84196 14.5 6.2235 14.5 6.62132V14C14.5 14.8284 13.8284 15.5 13 15.5H7C6.17157 15.5 5.5 14.8284 5.5 14ZM5.5 14H2.5C1.67157 14 1 13.3284 1 12.5V2C1 1.17157 1.67157 0.5 2.5 0.5H8.5C9.32843 0.5 10 1.17157 10 2V6C10 6.55228 10.4477 7 11 7H14"
        stroke="currentColor"/>
</svg>
`;

export const CROSS_24_SVG = `
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M23 1L1 23"
        stroke="currentColor"/>
    <path
        d="M1 1L23 23"
        stroke="currentColor"/>
</svg>
`

export const EXTERNAL_12_ARROW = `
<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
        x="1.4043"
        y="9.88867"
        width="12"
        height="0.999999"
        transform="rotate(-45 1.4043 9.88867)"
        fill="currentColor"/>
    <path
        d="M10.5 9.5V2.5C10.5 1.94772 10.0523 1.5 9.5 1.5H2.5"
         stroke="currentColor"/>
</svg>
`;

export const EXTERNAL_16_ARROW = `
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M13.6562 13.6572L13.6562 3.34352C13.6562 2.79123 13.2085 2.34352 12.6562 2.34352L2.34254 2.34352"
        stroke="currentColor"/>
    <rect
        x="1.99023"
        y="13.3037"
        width="16"
        height="1"
        transform="rotate(-45 1.99023 13.3037)"
        fill="currentColor"/>
</svg>
`;

export const REFRESH_16_SVG = `
<svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M0.5 9.5C0.5 12.8137 3.18629 15.5 6.5 15.5C9.81371 15.5 12.5 12.8137 12.5 9.5C12.5 6.18629 9.81371 3.5 6.5 3.5H0.5M3.5 0.5L0.715058 3.0286C0.428315 3.28894 0.428314 3.71105 0.715057 3.9714L3.5 6.5"
        stroke="currentColor"/>
</svg>
`;

export const SAVE_16_SVG = `
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M5 11.5H11M5 13.5H11M12.6716 0.5L2.5 0.500002C1.39543 0.500002 0.500001 1.39543 0.500001 2.5L0.5 13.5C0.5 14.6046 1.39543 15.5 2.5 15.5L13.5 15.5C14.6046 15.5 15.5 14.6046 15.5 13.5V3.32843C15.5 2.798 15.2893 2.28929 14.9142 1.91422L14.0858 1.08579C13.7107 0.710714 13.202 0.5 12.6716 0.5ZM3.5 0.500002H12C12.2761 0.500002 12.5 0.72386 12.5 1V4.5C12.5 5.05229 12.0523 5.5 11.5 5.5H4.5C3.94772 5.5 3.5 5.05229 3.5 4.5V0.500002ZM10 3.5C9.72386 3.5 9.5 3.27614 9.5 3C9.5 2.72386 9.72386 2.5 10 2.5C10.2761 2.5 10.5 2.72386 10.5 3C10.5 3.27614 10.2761 3.5 10 3.5ZM12.5 15.5V10.5C12.5 9.94772 12.0523 9.5 11.5 9.5H4.5C3.94772 9.5 3.5 9.94772 3.5 10.5V15.5H12.5Z"
        stroke="currentColor"/>
</svg>
`;
