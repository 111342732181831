import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';

import { Theme } from '../../../../_utils/theme.util';
import { Environment, EnvironmentService } from '../../../../_services/environment.service';
import { User } from '../../../../_models/user.model';
import { AuthService } from '../../../../_services/auth.service';


@Component({
    selector: 'app-nav-bar-lite',
    templateUrl: './nav-bar-lite.component.html',
    styleUrls: ['./nav-bar-lite.component.scss'],
})
export class NavBarLiteComponent {

    @Input() transparent: boolean;
    @Input() buttonText: string = null;
    @Input() hideButtonWhenLoggedIn: boolean = null;
    @Output() buttonClicked = new EventEmitter<void>();

    public isLoggedIn: boolean;
    public user: User;
    public readonly isWhitelabel = Environment.isWhitelabel;


    constructor(private router: Router,
                private authService: AuthService,
                public environment: EnvironmentService,
    ) {
        this.isLoggedIn = this.authService.isLoggedInObservable.getValue();
        this.authService.isLoggedInObservable.subscribe(isLoggedIn => this.isLoggedIn = isLoggedIn);

        this.user = this.authService.getUser();
        this.authService.userObservable.subscribe(({ newValue }) => this.user = newValue);
    }

    /**
     * Getter for background colour
     */
    get backgroundColour() {
        if (this.isWhitelabel) {
            return Theme.styling.navbarColour;
        } else if (!this.transparent) {
            return 'white';
        }
    }

    /**
     * Getter for showing the nav bar button
     */
    get showButton() {
        return this.buttonText && (!this.isLoggedIn || (this.isLoggedIn && !this.hideButtonWhenLoggedIn));
    }

}
