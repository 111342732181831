import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { ROUTE_PATHS } from '@little-phil/js/lib/common/constants';

import { Environment, EnvironmentService } from '../../../../_services/environment.service';
import { BaseComponent } from '../../../../_classes/base-component.class';
import { ScrollService } from '../../../../_services/scroll.service';
import { User } from '../../../../_models/user.model';
import { AuthService } from '../../../../_services/auth.service';
import { LoggerService } from '../../../../_services/logger.service';
import {
    adminDesktopItems,
    adminMobileItems,
    charityDesktopItems,
    charityMobileItems,
    companyDesktopItems,
    companyMobileItems,
    guestDesktopItems,
    guestMobileItems,
    MenuItem,
    userDesktopItems,
    userMobileItems,
} from '../../../../_utils/nav-config.util';
import { USER_ROLE } from '@little-phil/js/lib/common/enums';
import {
    FEEDBACK_SVG,
    HELP_SVG,
    SIGN_OUT_SVG,
} from '../../../../_utils/icons-nav.util';
import { IntercomService } from '../../../../_services/intercom.service';


@Component({
    selector: 'app-nav-bar',
    templateUrl: './nav-bar.component.html',
    styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

    @Input() variant: 'donor' | 'dashboard';
    @Input() transparent: boolean;
    @ViewChild('mobileMenu') mobileMenu: ElementRef | null;

    public desktopItems: MenuItem[];
    public mobileItems: MenuItem[];

    public isLoggedIn: boolean;
    public isAdmin: boolean;
    public isCharity: boolean;
    public isCompanyManager: boolean;
    public charityId: string;
    public companyId: string;

    public isDonorVariant: boolean;
    public isDashboardVariant: boolean;

    public currenRoutePath: string;
    public user: User;
    public readonly ScrollService = ScrollService;
    public readonly isWhitelabel = Environment.isWhitelabel;
    public readonly ROUTE_PATHS = ROUTE_PATHS.web;

    protected readonly SIGN_OUT_SVG = SIGN_OUT_SVG;
    protected readonly FEEDBACK_SVG = FEEDBACK_SVG;
    protected readonly HELP_SVG = HELP_SVG;

    private routerEventSubscription?: Subscription;

    constructor(
        public environment: EnvironmentService,
        private router: Router,
        private authService: AuthService,
        private logger: LoggerService,
        private activatedRoute: ActivatedRoute,
        private scrollService: ScrollService,
        private intercom: IntercomService,
    ) {
        super();
        this.authService.isLoggedInObservable.subscribe((isLoggedIn) => this.isLoggedIn = isLoggedIn);
        this.authService.userObservable.subscribe(({ newValue, oldValue }) => {
            this.updateAuthState(newValue, oldValue);
        });
    }

    ngOnInit() {
        this.routerEventSubscription = this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                this.collapseMobileMenu();
            } else if (event instanceof NavigationEnd) {
                this.currenRoutePath = event.url.split('?')[0]; // remove query params
            }
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.variant) {
            this.isDonorVariant = this.variant === 'donor';
            this.isDashboardVariant = this.variant === 'dashboard';
        }
    }

    ngOnDestroy() {
        this.routerEventSubscription?.unsubscribe();
    }

    /**
     * Logs the user out and redirects them to the home page
     */
    logout() {
        this.authService.logout(this.routePaths.home).catch(this.logger.error);
    }

    /**
     * Update the authentication state of a user
     * @param newValue
     * @param oldValue
     * @private
     */
    private updateAuthState(newValue: User, oldValue: User) {
        this.user = newValue;
        this.isAdmin = !!this.user?.isAdmin;
        this.isCharity = !!this.user?.isCharity;
        this.charityId = this.isCharity ? this.user.managedCharity : null;
        this.isCompanyManager = !!this.user?.isCompanyManager;
        this.companyId = this.isCompanyManager ? this.user.managedCompany : null;

        if (!this.user) {
            this.desktopItems = guestDesktopItems;
            this.mobileItems = guestMobileItems;
            return;
        }

        switch (this.user.role) {
            case USER_ROLE.ADMIN:
                this.desktopItems = adminDesktopItems;
                this.mobileItems = adminMobileItems;
                break;
            case USER_ROLE.CHARITY:
                this.desktopItems = charityDesktopItems;
                this.mobileItems = charityMobileItems(this.user.managedCharity);
                break;
            case USER_ROLE.COMPANY_MANAGER:
                this.desktopItems = companyDesktopItems(this.user.managedCompany);
                this.mobileItems = companyMobileItems(this.user.managedCompany);
                break;
            case USER_ROLE.USER:
                this.desktopItems = userDesktopItems;
                this.mobileItems = userMobileItems;
        }
    }

    /**
     * Opens the Intercom article explaining how to use credits
     */
    openCreditArticle() {
        window.open('https://support.littlephil.org/en/articles/5135856-using-my-little-phil-credit', '_blank');
    }

    /**
     * Presents the login page
     */
    displayLogin() {
        // prevent navigation when already on login page to preserve query params
        if (this.router.url.startsWith(ROUTE_PATHS.web.login)) {
            return;
        }

        this.router.navigate([ROUTE_PATHS.web.login], {
            queryParams: {
                // pass the redirect URL to the login route
                // this can happen when clicking the login button in the nav bar on the register page
                redirectUrl: this.activatedRoute.snapshot.queryParams?.redirectUrl,
            },
        }).catch(this.logger.error);
    }

    onGetHelpClicked() {
        // TODO display a popup form
        this.intercom.client.showNewMessage();
    }

    onSendFeedbackClicked() {
        // TODO display a popup form
        this.intercom.client.showNewMessage();
    }

    /**
     * Expands the mobile menu
     */
    expandMobileMenu() {
        this.scrollService.disableBodyOverflowY();
        this.mobileMenu?.nativeElement.classList.add('expanded');
    }

    /**
     * Collapses the mobile menu
     */
    collapseMobileMenu() {
        this.scrollService.restoreBodyOverflowY();
        this.mobileMenu?.nativeElement.classList.add('collapsing-menu');
        setTimeout(() => {
            this.mobileMenu?.nativeElement.classList.remove('collapsing-menu');
            this.mobileMenu?.nativeElement.classList.remove('expanded');
        }, 300);
    }

}
