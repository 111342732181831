import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { capitalise } from '@little-phil/js/lib/utils/string';

import {
    BLOG_SVG,
    EMAIL_SVG,
    FACEBOOK_SVG,
    GOOGLE_SVG,
    LINKED_IN_SVG,
    X_SVG,
} from '../../../../_utils/icons-socials.util';


export enum SOCIAL_PLATFORM {
    FACEBOOK = 'facebook',
    GOOGLE = 'google',
}

@Component({
    selector: 'app-social-button',
    templateUrl: './social-button.component.html',
    styleUrls: ['./social-button.component.scss'],
})
export class SocialButtonComponent implements OnChanges {

    /**
     * Platform the button is for
     */
    @Input() platform: 'blog' | 'email' | 'facebook' | 'google' | 'linkedin' | 'x';

    /**
     * Sizes for the button
     */
    @Input() size: 'sm' | 'md' | 'lg' = 'md';

    /**
     * Text to display on the button
     */
    @Input() buttonTitle: string;

    /**
     * Flag to set the button as active and switching the button state
     */
    @Input() active: boolean;

    /**
     * SVG to render in the button
     */
    public iconSvg: string;

    ngOnChanges(changes: SimpleChanges): void {

        switch (this.platform) {
            case 'blog':
                this.iconSvg = BLOG_SVG;
                break;
            case 'email':
                this.iconSvg = EMAIL_SVG;
                break;
            case 'facebook':
                this.iconSvg = FACEBOOK_SVG;
                break;
            case 'google':
                this.iconSvg = GOOGLE_SVG;
                break;
            case 'linkedin':
                this.iconSvg = LINKED_IN_SVG;
                break;
            case 'x':
                this.iconSvg = X_SVG;
                break;
            default:
                this.iconSvg = null;
                break;
        }

    }

    /**
     * Title displayed on the button
     */
    public get title() {
        if (this.platform === 'x') {
            return 'X/Twitter';
        }
        return this.buttonTitle || capitalise(this.platform);
    }

}
