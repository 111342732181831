"use strict";
/*
 * dgr.ts
 * Little Phil
 *
 * Created on 12/5/21
 * Copyright © 2018 Little Phil. All rights reserved.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.isCountryDGRSupported = void 0;
const enums_1 = require("../common/enums");
/**
 * Takes a country code and returns whether the corresponding country is currently supported for Tax Deductions or not.
 * Whether or not this country is supported depends on whether all the necessary information has been defined for
 * the corresponding country code.
 *
 * @param {string} countryCode - The country code to check for DGR support
 */
const isCountryDGRSupported = (countryCode) => {
    return enums_1.MIN_TAX_DEDUCTIBLE_DONATION_AMOUNT[countryCode] != undefined
        && enums_1.TAX_DEDUCTIBILITY_MESSAGE[countryCode] != undefined
        && enums_1.TAX_DEDUCTIBILITY_TOOLTIP[countryCode] != undefined
        && enums_1.FLAG_EMOJI[countryCode] != undefined;
};
exports.isCountryDGRSupported = isCountryDGRSupported;
