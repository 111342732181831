/*
 * employee.model.ts
 * Little Phil
 *
 * Created on 2/9/21
 * Copyright © 2018 Little Phil. All rights reserved.
 */


import { User } from './user.model';

export class Employee extends User {

    public static getEndpoint(companyId: string) {
        return `company/${companyId}/employee`;
    }

    // Properties
    public dateAdded: Date = undefined;

    constructor(json: object = {}) {
        super(json);
        super.init(json);
    }

    protected deserialise() {
        super.deserialise();

        if (typeof this.dateAdded === 'string') {
            this.dateAdded = new Date(this.dateAdded);

            if (isNaN(this.dateAdded.getTime())) {
                this.dateAdded = null;
                console.error('Employee: Failed to parse date.'); // logger is not accessible here because this isn't an Angular class
            }
        }
    }
}
