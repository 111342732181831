import { Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
    selector: 'app-body-scroll-lock',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './body-scroll-lock.component.html',
    styles: [
        `
            :host {
                display: block;
            }
        `,
    ],
})
export class BodyScrollLockComponent implements OnInit {
    public styles: SafeHtml;

    private sanitizer = inject(DomSanitizer);

    ngOnInit() {
        // https://stackoverflow.com/a/54348516
        this.styles = this.sanitizer.bypassSecurityTrustHtml(`
            <style>
                body {
                    overflow: hidden;
                    position: fixed;
                    top: -${window.pageYOffset}px;
                    width: 100%;
                }
            </style>
        `);
    }
}
