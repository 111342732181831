import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

export enum PRIMARY_BUTTON_TYPE {
    BACK,
    EMAIL_SUPPORT
}

@Component({
    selector: 'app-error-page',
    templateUrl: './error-page.component.html',
    styleUrls: ['./error-page.component.scss'],
})
export class ErrorPageComponent {

    public title: string;
    public text: string;
    public errorCode: string;
    public primaryButtonType: PRIMARY_BUTTON_TYPE;

    constructor(private location: Location,
                private route: ActivatedRoute) {
        this.title = route.snapshot.data.title;
        this.text = route.snapshot.data.text;
        this.errorCode = route.snapshot.data.errorCode;
        this.primaryButtonType = route.snapshot.data.primaryButtonType;
    }

    /**
     * Navigate user back to previous page in browser history
     */
    backClicked() {
        this.location.back();
    }

    /**
     * Open mail client to email support
     */
    emailSupportClicked() {
        window.location.href = 'mailto:support@littlephil.org';
    }

    /**
     * Getter for the HTML to get the PRIMARY_BUTTON_TYPE enum
     */
    public get primaryButtonTypeEnum() {
        return PRIMARY_BUTTON_TYPE;
    }

}
