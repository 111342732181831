import { Directive, ViewContainerRef } from '@angular/core';

/**
 * Directive for inserting components into a template
 * https://angular.io/guide/dynamic-component-loader#the-anchor-directive
 */
@Directive({
    selector: '[dynamic-component-container]',
})
export class DynamicComponentContainerDirective {
    constructor(public viewContainerRef: ViewContainerRef) {}
}
