<!----------------->
<!-- Desktop nav -->
<!----------------->
<nav class="navbar navbar-expand py-0 h-[80px] d-lg-flex px-md-2 d-none items-center">
    <div
        class="flex items-center px-3 w-full"
        [class.container]="isDonorVariant"
    >
        <ng-container *ngTemplateOutlet="brandIcon"/>

        <div class="collapse navbar-collapse">
            <ul class="navbar-nav align-items-center">
                <li class="nav-item me-5" *ngIf="isDonorVariant">
                    <a
                        id="nav-bar__explore-link-button"
                        class="nav-link"
                        aria-current="page"
                        [routerLink]="routePaths.explore"
                        [class.active]="currenRoutePath === routePaths.explore">
                        Explore
                    </a>
                </li>

                <li class="nav-item me-5" *ngIf="isDonorVariant && isLoggedIn">
                    <a
                        class="nav-link"
                        [routerLink]="routePaths.impact"
                        [class.active]="currenRoutePath === routePaths.impact">
                        My impact
                    </a>
                </li>
            </ul>

            <div
                *ngIf="isDashboardVariant && isAdmin"
                class="w-full max-w-[400px] ms-7">
                <ng-container *ngTemplateOutlet="searchBar"/>
            </div>

            <div
                *ngIf="isDashboardVariant && isCharity && !isAdmin"
                class="w-full max-w-[600px] ms-7 me-3">
                <ng-container *ngTemplateOutlet="assistantTrigger"/>
            </div>

            <ul class="navbar-nav me-auto"></ul>

            <ul class="navbar-nav align-items-center">

                <li class="nav-item me-5" *ngIf="isLoggedIn">
                    <ng-container *ngTemplateOutlet="creditBalance"/>
                </li>

                <li class="nav-item dropdown" *ngIf="isLoggedIn">
                    <div
                        class="nav-link !cursor-default"
                        id="accountDropdown"
                        data-bs-toggle="dropdown"
                        role="button"
                        aria-haspopup="true"
                        aria-expanded="false">
                        <div class="d-flex align-items-center gap-2">
                            <app-user-avatar
                                class="text-primary"
                                [includeName]="true"
                            />
                            <i class="icon-16 icon-arrow-down-16"></i>
                        </div>
                    </div>

                    <div
                        class="dropdown-menu dropdown-menu-end py-3 rounded overflow-hidden"
                        aria-labelledby="accountDropdown">

                        <ng-container *ngFor="let item of desktopItems">
                            <div
                                *ngIf="item.type === 'header'"
                                class="py-2 px-6 mt-1">
                                <div class="d-flex align-items-center">
                                    <p class="font-size-xs">{{item.title}}</p>
                                </div>
                            </div>

                            <div
                                *ngIf="item.type === 'divider'"
                                class="px-6 mt-1">
                                <hr class="bg-primary-20">
                            </div>

                            <app-nav-bar-menu-item
                                *ngIf="item.type === 'link'"
                                class="px-3 mt-1"
                                [title]="item.title"
                                [iconSvg]="item.icon"
                                [link]="item.link"
                                [slim]="true"
                            />

                            <app-nav-bar-menu-item
                                *ngIf="item.type ==='logout'"
                                class="px-3 mt-1"
                                title="Logout"
                                [iconSvg]="SIGN_OUT_SVG"
                                (click)="logout()"
                                [slim]="true"
                            />
                        </ng-container>
                    </div>
                </li>

                <li class="nav-item me-5" *ngIf="!isLoggedIn">
                    <a class="nav-link" [routerLink]="routePaths.register">
                        Register
                    </a>
                </li>

                <li class="nav-item" *ngIf="!isLoggedIn">
                    <button class="btn btn-outline-primary" (click)="displayLogin()">
                        Sign in
                    </button>
                </li>

            </ul>
        </div>
    </div>
</nav>


<!---------------->
<!-- Mobile nav -->
<!---------------->
<nav class="navbar navbar-expand-lg navbar-light py-5 d-lg-none d-block">
    <div
        class="flex items-center px-3 w-full"
        [class.container]="isDonorVariant"
    >
        <ng-container *ngTemplateOutlet="brandIcon"/>

        <div
            *ngIf="isDashboardVariant && isCharity"
            class="w-full max-w-[400px] mx-7 md:block hidden">
            <ng-container *ngTemplateOutlet="assistantTrigger"/>
        </div>

        <div class="grow"></div>

        <ul class="navbar-nav flex-row align-items-center">
            <li class="nav-item me-3" *ngIf="isLoggedIn">
                <ng-container *ngTemplateOutlet="creditBalance"/>
            </li>

            <li class="nav-item">
                <button class="navbar-toggler border-0" (click)="expandMobileMenu()">
                    <i class="icon-24 navbar-toggler-icon"></i>
                </button>
            </li>
        </ul>
    </div>
</nav>

<!----------------->
<!-- Mobile menu -->
<!----------------->
<div class="d-lg-none mobile-menu" #mobileMenu>
    <div class="mobile-menu-backdrop" (click)="collapseMobileMenu()"></div>

    <div class="mobile-menu-items flex flex-col gap-1 py-6">

        <app-user-avatar
            *ngIf="isLoggedIn"
            class="px-6 pb-3"
            [includeName]="true"
        />

        <ng-container *ngFor="let item of mobileItems">
            <div
                *ngIf="item.type === 'header'"
                class="py-2 px-6">
                <div class="d-flex align-items-center">
                    <p class="font-size-xs">{{item.title}}</p>
                </div>
            </div>

            <div
                *ngIf="item.type === 'divider'"
                class="px-6">
                <hr class="bg-primary-20">
            </div>

            <app-nav-bar-menu-item
                *ngIf="item.type === 'link'"
                class="px-3"
                [title]="item.title"
                [iconSvg]="item.icon"
                [link]="item.link"
            />

            <app-nav-bar-menu-item
                *ngIf="item.type === 'help'"
                class="px-3"
                title="Get help"
                [iconSvg]="HELP_SVG"
                (click)="onGetHelpClicked()"
            />

            <app-nav-bar-menu-item
                *ngIf="item.type === 'feedback'"
                class="px-3"
                title="Send feedback"
                [iconSvg]="FEEDBACK_SVG"
                (click)="onSendFeedbackClicked()"
            />

            <app-nav-bar-menu-item
                *ngIf="item.type ==='logout'"
                class="px-3"
                title="Logout"
                [iconSvg]="SIGN_OUT_SVG"
                (click)="logout()"
            />
        </ng-container>
    </div>
</div>


<!--------------->
<!-- Templates -->
<!--------------->
<ng-template #brandIcon>
    <a
        *ngIf="isDonorVariant"
        class="navbar-brand me-md-7 me-0"
        [routerLink]="ROUTE_PATHS.explore">
        <img
            src="../../../assets/img/little-phil-logo.svg"
            alt="Little Phil logo">
    </a>
    <a
        *ngIf="isDashboardVariant"
        class="navbar-brand m-0 lg:w-[275px]"
        [routerLink]="ROUTE_PATHS.dashboard.route">
        <img
            class="min-h-[40px] max-h-[40px] min-w-[157px] max-w-[157px]"
            src="../../../assets/img/little-phil-logo.svg"
            alt="Little Phil logo">
    </a>
</ng-template>

<ng-template #creditBalance>
    <app-credit-balance [balance]="user?.creditBalance"/>
</ng-template>

<ng-template #searchBar>
    <app-dashboard-search-bar/>
</ng-template>

<ng-template #assistantTrigger>
    <app-assistant-chat-modal>
        <app-nav-bar-assistant-trigger/>
    </app-assistant-chat-modal>
</ng-template>
