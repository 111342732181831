/*
 * credit-pool.model.ts
 * Little Phil
 *
 * Created on 16/3/21
 * Copyright © 2018 Little Phil. All rights reserved.
 */

import { BaseModel } from './_base.model';
import { CREDIT_POOL_TYPE } from '@little-phil/js/lib/common/enums';

export class CreditPool extends BaseModel {

    public static endpoint = 'credit-pool';

    // Properties
    public readonly name: string = undefined;
    public readonly totalAmount: number = undefined;
    public readonly description: string = undefined;
    public readonly remainingAmount: number = undefined;
    public readonly creditAmountPerClaim: number = undefined;
    public readonly numberOfClaimsPerUser: number = undefined;
    public readonly daysTillRedeemExpiry: number = undefined;
    public readonly daysTillUseExpiry: number = undefined;
    public readonly daysBeforeExpiryReminderEmail: number = undefined;
    public readonly type: CREDIT_POOL_TYPE = undefined;
    public readonly allowRedeemFromNextPoolWhenEmpty: boolean = undefined;
    public startDate: Date = undefined;
    public endDate: Date = undefined;
    public readonly active: boolean = undefined;

    /**
     * Constructor
     * @param {object} json
     */
    constructor(json: object = {}) {
        super();
        super.init(json);
    }

    protected deserialise() {
        if (typeof this.startDate === 'string') {
            this.startDate = new Date(this.startDate);

            if (isNaN(this.startDate.getTime())) {
                this.startDate = null;
                console.error('CreditPool: Failed to parse date.');
            }
        }

        if (typeof this.endDate === 'string') {
            this.endDate = new Date(this.endDate);

            if (isNaN(this.endDate.getTime())) {
                this.endDate = null;
                console.error('CreditPool: Failed to parse date.');
            }
        }
    }

}
