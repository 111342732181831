import { AppRouter } from './app.routes';
import { Environment } from './_services/environment.service';
import { AppPreloader } from './_classes/app-preloader';
import { BrowserService } from './_services/browser.service';
import { LoggerService } from './_services/logger.service';

/**
 * UTILS
 */
import { RefreshTokenInterceptorModule } from './_utils/interceptors/refresh-token-interceptor.util';
import { csrfInitialiser } from './_utils/initialisers/csrf-initialiser.util';
import { AuthInterceptorModule } from './_utils/interceptors/auth-interceptor.util';
import { CookieInterceptorModule } from './_utils/interceptors/cookie-interceptor.util';
import { CsrfInterceptorModule } from './_utils/interceptors/csrf-interceptor.util';
import { LogInterceptorModule } from './_utils/interceptors/log-interceptor.util';
import { RetryAfterInterceptorModule } from './_utils/interceptors/retry-after-interceptor.util';
import { SentryErrorHandler } from './_utils/sentry-error-handler.util';

/**
 * MODULES
 */
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { PreloadAllModules, PreloadingStrategy, RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { IntercomModule } from 'ng-intercom';
import { RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { ToastrComponentlessModule } from 'ngx-toastr';
import { CookieModule, CookieService } from 'ngx-cookie';
import { LpCommonModule } from './_modules/lp-common/lp-common.module';
import { fingerprintInitialiser } from './_utils/initialisers/fingerprint-initaliser.utils';
import { TimezoneInterceptorModule } from './_utils/interceptors/timezone-interceptor.util';

const modules = [
    CommonModule,
    BrowserModule.withServerTransition({ appId: 'web' }),
    BrowserAnimationsModule,
    RouterModule.forRoot(AppRouter.routes, {
        scrollPositionRestoration: 'enabled',
        onSameUrlNavigation: 'reload',
        /*
         * Preload all modules in background to reduce delay between route changes.
         * Preloading does not block the first load.
         * https://angular.io/guide/lazy-loading-ngmodules#preloading
         */
        preloadingStrategy: PreloadAllModules,
        /**
         *  The initial navigation starts before the root component is created.
         *  The bootstrap is blocked until the initial navigation is complete
         *  This value is required for server-side rendering to work.
         */
        initialNavigation: 'enabledBlocking',
    }),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    TransferHttpCacheModule,
    RetryAfterInterceptorModule,
    LogInterceptorModule,
    AuthInterceptorModule,
    CookieInterceptorModule,
    CsrfInterceptorModule,
    RefreshTokenInterceptorModule,
    TimezoneInterceptorModule,
    IntercomModule.forRoot({
        appId: Environment.intercomAppId,
        updateOnRouterChange: true, // will automatically run `update` on router event changes. Default: `false`
    }),
    RecaptchaV3Module,
    ToastrComponentlessModule.forRoot({
        positionClass: 'toast-bottom-left',
        autoDismiss: true,
        timeOut: 10000,
        extendedTimeOut: 5000,
        maxOpened: 4,
        toastClass: 'lp-toast',
        toastComponent: ToastrComponent,
        preventDuplicates: true,
        iconClasses: {
            error: 'lp-toast-error',
            info: 'lp-toast-info',
            success: 'lp-toast-success',
            warning: 'lp-toast-warning',
        },
        progressBar: true,
    }),
    LpCommonModule,
    LpTooltipModule,
    CookieModule.forRoot(),
];

/**
 * SERVICES
 * This is only for services that do not have the { providedIn: 'root' } config, otherwise it's redundant
 */
import { DashboardContextService } from './dashboard/contexts/dashboard-context.service';

const services = [
    DashboardContextService,
];

/**
 * GUARDS
 */
import { AuthGuard } from './_guards/auth.guard';

const guards = [AuthGuard];

/**
 * OTHER PROVIDERS
 */
const providers = [
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: Environment.recaptchaV3SiteKey },
    { provide: 'googleTagManagerId', useValue: Environment.googleTagManagerId },
];


/**
 * COMPONENTS
 */
import { AppComponent } from './app.component';
import { ToastrComponent } from './_modules/lp-common/components/toastr/toastr.component';
import { LpTooltipModule } from './_modules/lp-tooltip/lp-tooltip.module';

const components = [
    AppComponent,
];


@NgModule({
    declarations: [...components],
    imports: [...modules],
    providers: [...services, ...guards, ...providers,
        {
            provide: APP_INITIALIZER,
            useFactory: csrfInitialiser,
            multi: true,
            deps: [HttpClient, BrowserService],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: fingerprintInitialiser,
            multi: true,
            deps: [BrowserService, CookieService, LoggerService],
        },
        {
            provide: ErrorHandler,
            useClass: SentryErrorHandler,
        },
        {
            provide: PreloadingStrategy,
            useClass: AppPreloader,
            deps: [LoggerService, BrowserService],
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
