<div class="d-flex flex-wrap gap-2" [class.justify-content-center]="center">

    <div
        class="flex-grow-0"
        *ngFor="let platform of socialPlatforms">
        <i class="icon-44 icon-{{platform}}" (click)="shareButtonPressed(platform)"></i>
    </div>

    <div class="flex-grow-0" *ngFor="let platform of messagingPlatforms">
        <i class="icon-44 icon-{{platform}}" (click)="shareButtonPressed(platform)"></i>
    </div>

    <div class="flex-grow-0" *ngIf="canDisplayShareSheet">
        <i class="icon-44 icon-share" (click)="shareSheetButtonPressed()"></i>
    </div>

    <div class="flex-grow-0" *ngIf="!canDisplayShareSheet && displayCopyLink">
        <i class="icon-44 icon-copy-link" (click)="copyLinkButtonPressed()"></i>
    </div>

</div>
