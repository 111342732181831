import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';

import { ROUTE_PATHS } from '@little-phil/js/lib/common/constants';

import { EnvironmentService } from '../../../../_services/environment.service';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { AuthService } from '../../../../_services/auth.service';
import { LoggerService } from '../../../../_services/logger.service';
import { User } from '../../../../_models/user.model';
import { ScrollService } from '../../../../_services/scroll.service';
import { IntercomService } from '../../../../_services/intercom.service';
import { DashboardContextService } from '../../../../dashboard/contexts/dashboard-context.service';

import { FEEDBACK_SVG, HELP_SVG } from '../../../../_utils/icons-nav.util';
import {
    adminDashboardDesktopItems,
    adminMobileItems,
    charityDashboardDesktopItems,
    companyDashboardDesktopItems,
    MenuItem,
} from '../../../../_utils/nav-config.util';
import { USER_ROLE } from '@little-phil/js/lib/common/enums';

@Component({
    selector: 'app-nav-bar-dashboard',
    templateUrl: './nav-bar-dashboard.component.html',
    styleUrls: ['./nav-bar-dashboard.component.scss'],
})
export class NavBarDashboardComponent implements OnInit, OnDestroy {

    @ViewChild('mobileMenu') mobileMenu: ElementRef | null;
    @ViewChild('searchInput') searchInput: ElementRef<HTMLInputElement>;

    public desktopItems: MenuItem[];
    public mobileItems: MenuItem[];

    public isLoggedIn: boolean;
    public isAdmin: boolean;
    public isCharity: boolean;
    public isCompanyManager: boolean;
    public charityId: string;
    public companyId: string;
    public currenRoutePath: string;
    public user: User;
    public searchQuery: string;

    private routerEventSubscription?: Subscription;

    public readonly ROUTE_PATHS = ROUTE_PATHS;

    // icons
    protected readonly FEEDBACK_SVG = FEEDBACK_SVG;
    protected readonly HELP_SVG = HELP_SVG;

    constructor(
        private router: Router,
        private authService: AuthService,
        private logger: LoggerService,
        private scrollService: ScrollService,
        private intercomService: IntercomService,
        public context: DashboardContextService,
        public environment: EnvironmentService,
    ) {
        this.authService.isLoggedInObservable.subscribe((isLoggedIn) => this.isLoggedIn = isLoggedIn);
        this.authService.userObservable.subscribe(({ newValue, oldValue }) => {
            this.updateAuthState(newValue, oldValue);
        });
    }

    ngOnInit() {
        this.routerEventSubscription = this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                this.collapseMobileMenu();
            } else if (event instanceof NavigationEnd) {
                this.currenRoutePath = event.url.split('?')[0]; // remove query params
            }
        });
    }

    ngOnDestroy() {
        this.routerEventSubscription?.unsubscribe();
    }

    /**
     * Update the authentication state of a user
     * @param newValue
     * @param oldValue
     * @private
     */
    private updateAuthState(newValue: User, oldValue: User) {
        this.user = newValue;
        this.isAdmin = !!this.user?.isAdmin;
        this.isCharity = !!this.user?.isCharity;
        this.charityId = this.isCharity ? this.user.managedCharity : null;
        this.isCompanyManager = !!this.user?.isCompanyManager;
        this.companyId = this.isCompanyManager ? this.user.managedCompany : null;

        if (!this.user) {
            this.desktopItems = [];
            this.mobileItems = [];
            return;
        }

        switch (this.user.role) {
            case USER_ROLE.ADMIN:
                this.desktopItems = adminDashboardDesktopItems;
                this.mobileItems = adminMobileItems;
                break;
            case USER_ROLE.CHARITY:
                this.desktopItems = charityDashboardDesktopItems(this.user.managedCharity);
                break;
            case USER_ROLE.COMPANY_MANAGER:
                this.desktopItems = companyDashboardDesktopItems(this.user.managedCompany);
                break;
        }

    }

    /**
     * Logs the user out and redirects them to the home page
     */
    logout() {
        this.authService.logout(ROUTE_PATHS.web.home).catch(this.logger.error);
    }

    /**
     * Expands the mobile menu
     */
    expandMobileMenu() {
        this.scrollService.disableBodyOverflowY();
        this.mobileMenu?.nativeElement.classList.add('expanded');
    }

    /**
     * Collapses the mobile menu
     */
    collapseMobileMenu() {
        this.scrollService.restoreBodyOverflowY();
        this.mobileMenu?.nativeElement.classList.add('collapsing-menu');
        setTimeout(() => {
            this.mobileMenu?.nativeElement.classList.remove('collapsing-menu');
            this.mobileMenu?.nativeElement.classList.remove('expanded');
        }, 300);
    }

    /**
     * Callback to handle search completion events
     */
    onSearchPressed() {
        if (!this.searchQuery) {
            return;
        }

        this.searchInput?.nativeElement?.blur();

        this.router.navigate([ROUTE_PATHS.web.dashboardSearch], {
            queryParams: {
                query: this.searchQuery,
            },
        }).catch(this.logger.error);

        this.searchQuery = '';
    }

    onGetHelpClicked() {
        // TODO display a popup form
        this.intercomService.client.showNewMessage();
    }

    onSendFeedbackClicked() {
        // TODO display a popup form
        this.intercomService.client.showNewMessage();
    }
}
