/*
 * cookie-interceptor.util.ts
 * Little Phil
 *
 * Created on 18/7/22
 * Copyright © 2018 Little Phil. All rights reserved.
 */

import { Inject, Injectable, NgModule, Optional } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { REQUEST } from '@nguniversal/express-engine/tokens';

@Injectable()
export class CookieInterceptor implements HttpInterceptor {

    constructor(@Optional() @Inject(REQUEST) private httpRequest) {}

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        if (!this.httpRequest?.headers?.cookie) {
            return next.handle(req);
        }

        // add the cookie to the request headers for SSR
        const cookieRequest = req.clone({
            setHeaders: { Cookie: this.httpRequest.headers.cookie },
        });

        return next.handle(cookieRequest);
    }

}

/**
 * Module is required to avoid TransferHttpCacheModule from overriding provider in AppModule
 */
@NgModule({
    providers: [{
        provide: HTTP_INTERCEPTORS,
        useClass: CookieInterceptor,
        multi: true,
    }],
})
export class CookieInterceptorModule {}
