<div class="track" [class.rounded-pill]="rounded">
    <div
        class="progress-bar__wrapper"
        [class.rounded-pill]="rounded"
        [ngStyle]="{'width': width + '%'}">
        <div
            class="progress-bar"
            [class.animated]="animated"
            [class.rounded-pill]="rounded"
            [ngStyle]="{'background-color': barColour}">
        </div>
    </div>
</div>
