/*
 * cents-to-pretty-dollars-currency.pipe.ts
 * Little Phil
 *
 * Created on 30/6/21
 * Copyright © 2018 Little Phil. All rights reserved.
 */

import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { currencyDigitsInfo } from '../../../_utils/currency.util';

const zeroDecimalInfo = '1.0-0';

@Pipe({
    name: 'centsToPrettyDollarsCurrency',
})
export class CentsToPrettyDollarsCurrencyPipe extends CurrencyPipe implements PipeTransform {

    /**
     * Transforms a number into a formatted currency string
     * NOTE: Math.floor is needed as currency pipe will change 999.99 to 1000
     */
    transform(
        value: number | string | null | undefined,
        currencyCode?: string,
        display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean,
        digitsInfo?: string,
        locale?: string,
    ): null;
    transform(
        value: number | string | null | undefined,
        currencyCode?: string,
        display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean,
        digitsInfo?: string,
        locale?: string,
    ): string | null {

        if (typeof value !== 'number') {
            return null;
        }

        // Cents to dollars
        const dollars = value / 100;

        // Between 0 and 9,999
        if (dollars >= 0 && dollars < 10000) {
            return super.transform(Math.floor(dollars), currencyCode, display, zeroDecimalInfo);
        }

        // Between 10,000 and 999,999
        if (dollars >= 10000 && dollars < 1000000) {
            const thousands = Math.floor(dollars / 1000);
            return super.transform(thousands, currencyCode, display, zeroDecimalInfo) + 'k';
        }

        // Between 1,000,000 and 9,999,999
        if (dollars >= 1000000 && dollars < 10000000) {
            const tensOfThousands = Math.floor(dollars / 10000);
            const millions = tensOfThousands / 100;
            return super.transform(millions, currencyCode, display, currencyDigitsInfo(millions, 0, 2)) + 'm';
        }

        // Between 10,000,000 and above
        if (dollars >= 10000000) {
            const millions = Math.floor(dollars / 1000000);
            return super.transform(millions, currencyCode, display, zeroDecimalInfo) + 'm';
        }

    }

}
