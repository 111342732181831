/*
 * dollar-amount.pipe.ts
 * Little Phil
 *
 * Created on 30/6/21
 * Copyright © 2018 Little Phil. All rights reserved.
 */

import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { currencyDigitsInfo } from '../../../_utils/currency.util';

@Pipe({
    name: 'dollarAmount',
})
export class DollarAmountPipe extends DecimalPipe implements PipeTransform {

    /**
     * Transforms a number into an amount that can be displayed to represent a currency amount
     */
    transform(
        value: number | string | null | undefined,
        digitsInfo?: string,
        locale?: string,
    ): null;
    transform(
        value: number | string | null | undefined,
        digitsInfo?: string,
        locale?: string,
    ): string | null {
        if (typeof value !== 'number') {
            return null;
        }

        return super.transform(value, currencyDigitsInfo(value), locale);
    }

}
