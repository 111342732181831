import {
    Component,
    ElementRef,
    EventEmitter,
    Input, OnDestroy, OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { ScrollService } from '../../../../_services/scroll.service';
import { CROSS_24_SVG } from '../../../../_utils/icons-brand.util';

@Component({
    selector: 'app-modal-popup',
    templateUrl: './modal-popup.component.html',
    styleUrls: ['./modal-popup.component.scss'],
})
export class ModalPopupComponent implements OnInit, OnDestroy {

    @ViewChild('background') backgroundDiv: ElementRef<HTMLDivElement>;
    @ViewChild('closeButton') closeButton: ElementRef<HTMLButtonElement>;
    @ViewChild('closeButtonIcon') closeButtonIcon: ElementRef<SVGElement>;
    @ViewChild('dismissButtonElement') dismissButtonElement: ElementRef<HTMLButtonElement>;

    @Input() title: string;
    @Input() primaryButton: { title: string, type: 'button' | 'submit' };
    @Input() dismissButton: { title: string };
    @Input() dismissOnBackdropClick = true;
    @Input() isLoading: boolean;
    @Input() modalContentBackgroundColor = '#fefefe';
    @Input() size: 'sm' | 'md' | 'lg' | 'xl' = 'md';

    @Output() primaryAction = new EventEmitter<void>();
    @Output() popupClosed = new EventEmitter<void>();

    private closeAnimationDuration = 300;
    public isOpen = true;

    public readonly CROSS_24_SVG = CROSS_24_SVG;

    constructor(private scrollService: ScrollService) {}

    ngOnInit() {
        this.scrollService.disableBodyOverflowY();
    }

    ngOnDestroy() {
        this.scrollService.restoreBodyOverflowY();
    }

    /**
     * Hides the popup if there is a click on the background or close button
     * @param target
     */
    public dismissPopup({ target }) {
        if (this.closeButton.nativeElement.contains(target) || this.dismissButtonElement.nativeElement.contains(target)) {
            this.closePopup();
        } else if (target === this.backgroundDiv.nativeElement && this.dismissOnBackdropClick) {
            this.closePopup();
        }
    }

    public closePopup() {
        this.isOpen = false;

        setTimeout(() => {
            this.popupClosed.emit();
        }, this.closeAnimationDuration);
    }

}
