import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { capitalise } from '@little-phil/js/lib/utils/string';
import { ROUTE_PATHS } from '@little-phil/js/lib/common/constants';

import { Environment, EnvironmentService } from '../../../../_services/environment.service';
import { SOCIAL_PLATFORM } from '../social-button/social-button.component';
import { LoaderService } from '../../../../_services/loader.service';

@Component({
    selector: 'app-social-sign-on-button',
    templateUrl: './social-sign-on-button.component.html',
    styleUrls: ['./social-sign-on-button.component.scss'],
})
export class SocialSignOnButtonComponent implements OnChanges {

    /**
     * Social Platform the button is for (e.g. facebook, google, Apple)
     */
    @Input() platform: SOCIAL_PLATFORM;

    /**
     * Route to redirect to on successful authentication (e.g. explore)
     */
    @Input() successRoute: string;

    /**
     * Route to redirect to on failed authentication (e.g. login, register)
     */
    @Input() failureRoute: string;

    /**
     * (Optional) Email address of LP account to link SSO account to
     */
    @Input() linkToAccount: string;

    /**
     * Text to display on the button
     */
    @Input() buttonTitle: string;

    /**
     * Link to navigate to upon clicking on the button
     */
    private link: string;

    constructor(
        private environment: EnvironmentService,
        private loaderService: LoaderService,
    ) {}

    ngOnChanges(changes: SimpleChanges): void {

        const successRoute = this.successRoute || ROUTE_PATHS.web.impact;
        const failureRoute = this.failureRoute || ROUTE_PATHS.web.explore;

        if (this.platform) {
            const successRedirect = successRoute.startsWith('/')
                ? successRoute
                : '/' + successRoute;

            const failureRedirect = failureRoute.startsWith('/')
                ? failureRoute
                : '/' + failureRoute;

            // e.g. http://localhost:3001/v1/auth/facebook?successRedirect=http%3A%2F%2Flocalhost%3A4200%2Fexplore&failureRedirect=http%3A%2
            //  F%2Flocalhost%3A4200%2Flogin
            let url = Environment.authUrl + '/v1/auth/' + this.platform +
                '?successRedirect=' + encodeURIComponent(this.environment.webUrl + successRedirect) +
                '&failureRedirect=' + encodeURIComponent(this.environment.webUrl + failureRedirect);

            if (this.linkToAccount) {
                url += '&linkToAccount=' + encodeURIComponent(this.linkToAccount);
            }

            this.link = url;

        }

    }

    /**
     * Title displayed on the button
     */
    public get title() {
        return this.buttonTitle || 'Continue with ' + capitalise(this.platform);
    }

    public onClick() {
        this.loaderService.showLoader();
        window.location.href = this.link;
    }

}
