/*
 * campaign-update.model.ts
 * Little Phil
 *
 * Created on 16/5/20
 * Copyright © 2018 Little Phil. All rights reserved.
 */
import { BaseModel } from './_base.model';

export class CampaignUpdate extends BaseModel {

    public static endpoint = 'campaign/update';

    // Properties
    public readonly message: string = undefined;
    public readonly imageUrl: string = undefined;
    public readonly campaign: string = undefined;

    /**
     * Constructor
     * @param {object} json
     */
    constructor(json: object = {}) {
        super();
        super.init(json);
    }

}

