<div
    class="lp-tooltip-wrapper"
    [class.above]="position === 'above'"
    [class.below]="position === 'below'"
    [class.right]="position === 'right'"
    [class.left]="position === 'left'"
    [class.visible]="visible"
    [style.left]="left + 'px'" [style.top]="top + 'px'">

    <div *ngIf="html" class="lp-tooltip" [innerHTML]="html">
        {{text}}
    </div>

    <div *ngIf="text && !html" class="lp-tooltip" [innerText]="text">
        {{text}}
    </div>

</div>
