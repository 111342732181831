/*
 * social-sharing.ts
 * Little Phil
 *
 * Created on 28/6/23
 * Copyright © 2018 Little Phil. All rights reserved.
 */

import { appendQuery } from '@little-phil/js/lib/utils/append-query';

/**
 * Share a URL to Facebook
 * @param data
 */
export const shareToFacebook = (data: { url: string }) => {
    const url = appendQuery('https://www.facebook.com/sharer/sharer.php', {
        display: 'page',
        u: data.url,
    });

    window.open(url, '_blank');
};

/**
 * Share a URL to X/Twitter
 * @param data
 */
export const shareToX = (data: { url: string }) => {
    const url = appendQuery('https://x.com/intent/tweet', {
        url: data.url,
    });

    window.open(url, '_blank');
};

/**
 * Share a URL to LinkedIn
 * @param data
 */
export const shareToLinkedIn = (data: { url: string }) => {
    const url = appendQuery('https://www.linkedin.com/sharing/share-offsite', {
        url: data.url,
    });

    window.open(url, '_blank');
};

/**
 * Share a URL to Email
 * @param data
 */
export const shareToEmail = (data: { message?: string, url: string }) => {
    const url = appendQuery('mailto:', {
        body: data.message
            ? `${data.message} ${data.url}`
            : data.url,
    });

    window.open(url, '_parent');
};

/**
 * Share a URL to Email
 * @param data
 */
export const shareToSms = (data: { message?: string, url: string }) => {

    const userAgent = navigator.userAgent.toLowerCase();
    const body = data.message
        ? `${data.message}\n${data.url}`
        : data.url;

    let url;

    if (userAgent.includes('macintosh')) {
        url = `sms:+&body=${encodeURIComponent(body)}`;
    } else if (userAgent.includes('iphone') || userAgent.includes('ipad')) {
        url = `sms:&body=${encodeURIComponent(body)}`;
    } else {
        url = `sms:?body=${encodeURIComponent(body)}`;
    }

    window.open(url, '_parent');

};
