import { Component, HostListener, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

// Import bootstrap global into app.component.ts to avoid double importing with angular.json
// noinspection ES6UnusedImports
import * as bootstrap from 'bootstrap';

import { Theme } from './_utils/theme.util';
import { MetaService } from './_services/meta.service';
import { COOKIE_FINGERPRINT_KEY, COOKIE_SHARE_EVENT_ID_KEY, LOCAL_STORAGE_EMAIL_KEY } from './_utils/constants.util';
import { LoaderService } from './_services/loader.service';
import { BrowserService } from './_services/browser.service';
import { LocalStorageService } from './_services/local-storage.service';
import { IntercomService } from './_services/intercom.service';
import { ScrollService } from './_services/scroll.service';
import { ApiService } from './_services/api.service';
import { LoggerService } from './_services/logger.service';
import { SentryService } from './_services/sentry.service';
import { AuthService } from './_services/auth.service';
import { DisableSSR } from './_decorators/disable-ssr.decorator';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

    public isLoggedIn: boolean;
    public showLoader: boolean;
    public creditAmountPerClaim: number;

    public greyPageBackground: boolean;
    public hideNavBar: boolean;
    public hideNavBarShadow: boolean;
    public hideFooter: boolean;
    public isDashboard: boolean;

    constructor(
        public router: Router,
        public titleService: Title,
        public meta: MetaService,
        public route: ActivatedRoute,
        public browserService: BrowserService,
        public loaderService: LoaderService,
        private authService: AuthService,
        private localStorageService: LocalStorageService,
        private intercomService: IntercomService,
        private scrollService: ScrollService,
        private gtmService: GoogleTagManagerService,
        private toastr: ToastrService,
        private cookieService: CookieService,
        private apiService: ApiService,
        private logger: LoggerService,
        private sentry: SentryService,
    ) {
        titleService.setTitle(Theme.title);
        meta.setup();
    }

    ngOnInit() {
        this.isLoggedIn = this.authService.isLoggedInObservable.getValue();
        this.authService.isLoggedInObservable.subscribe(isLoggedIn => {
            this.isLoggedIn = isLoggedIn;
        });

        this.loaderService.loaderDisplayed.subscribe(loaderDisplayed => {
            this.showLoader = loaderDisplayed;
        });

        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                this.logger.verbose('NavigationStart', event.url);
            }

            if (event instanceof NavigationEnd) {
                this.logger.verbose('NavigationEnd', event.url);

                if (this.browserService.isBrowserPlatform()) {
                    // track navigation events in GTM
                    const gtmTag = {
                        event: 'page',
                        pageName: event.url,
                    };

                    this.gtmService
                        .pushTag(gtmTag)
                        .catch(() => {}); // ignore error as this is most likely happening from a blocker
                }

                // update nav bar state
                const activatedRouteData = this.getLastChildFromActivatedRoute().snapshot.data;
                this.isDashboard = event.url.startsWith('/dashboard');
                this.greyPageBackground = activatedRouteData.greyPageBackground;
                this.hideNavBar = activatedRouteData.hideNavBar;
                this.hideNavBarShadow = activatedRouteData.hideNavBarShadow;
                this.hideFooter = activatedRouteData.hideFooter;
            }

            this.scrollService.restoreBodyOverflowY(); // restore the scroll position if it was locked from the previous view
        });

        // early return for servers as the below logic is not required
        if (this.browserService.isServerPlatform()) {
            return;
        }

        // ensure the cached user data is up to date
        this.authService.refreshUser().catch((err) => {
            this.logger.error(err);
            this.sentry.trackIssue(err);
        });
        this.storeBrowserFingerprintAndTrackShareLinkClick();

        this.route.queryParams.subscribe(queryParams => {

            // remove sensitive information that should never ever be in query params
            if (queryParams && queryParams.password) {
                return this.removeQueryParam('password');
            }

            // display errors in toastr and consume - this must happen on client side
            if (queryParams && queryParams.error) {
                this.toastr.error(queryParams.error, null, {
                    timeOut: 10000, // double timeout for global errors as they are more important and unexpected
                });
                return this.removeQueryParam('error');
            }

            // display messages in toastr and consume - this must happen on client side
            if (queryParams && queryParams.message) {
                this.toastr.info(queryParams.message, null, {
                    timeOut: 10000, // double timeout for global messages as they are more important and unexpected
                });
                return this.removeQueryParam('message');
            }

        });
    }

    /**
     * Removes a param from the current query params
     * @param param - Query param to clear
     * @private
     */
    private removeQueryParam(param: string) {
        const queryParams = {};
        queryParams[param] = null;

        return this.router.navigate([], {
            queryParams,
            queryParamsHandling: 'merge',
            replaceUrl: true,
        });
    }

    /**
     * Host listener for broadcasting when the page scrolls
     */
    @HostListener('window:scroll')
    private onWindowScroll() {
        this.scrollService.requestPositionUpdate();
    }

    /**
     * Store the browser fingerprint and logs a click event if there is a store link ID
     */
    @DisableSSR()
    private storeBrowserFingerprintAndTrackShareLinkClick() {
        const fingerprint = this.cookieService.get(COOKIE_FINGERPRINT_KEY);
        const shareEventId = this.cookieService.get(COOKIE_SHARE_EVENT_ID_KEY);

        // SSR won't have a fingerprint and share event is optional
        if (!fingerprint || !shareEventId) {
            return;
        }

        this.logger.verbose('Browser fingerprint:', fingerprint);
        this.logger.verbose('Share event ID:', shareEventId);

        const guestEmail = this.localStorageService.getItem(LOCAL_STORAGE_EMAIL_KEY) || undefined; // we cannot send null

        this.apiService.shareEvent.complete(guestEmail).catch((err) => {
            this.sentry.trackIssue(err, { guestEmail, shareEventId, fingerprint });
        });
    }

    /**
     * Returns the last child of this route in the router state tree
     * @param activatedRoute
     * @private
     */
    public getLastChildFromActivatedRoute(activatedRoute = this.route) {
        if (activatedRoute.firstChild === null) {
            return activatedRoute;
        }

        return this.getLastChildFromActivatedRoute(activatedRoute.firstChild);
    }

}
