<!---------------->
<!-- Mobile nav -->
<!---------------->
<div class="d-lg-none d-block">
    <app-nav-bar
        class="shadow"
        variant="dashboard"
    />
</div>

<!----------------->
<!-- Desktop nav -->
<!----------------->
<div class="d-lg-block d-none">
    <app-nav-bar
        class="fixed top-0 w-full shadow z-[2147482971]"
        variant="dashboard"
    />
    <div class="side-navigator flex flex-col">
        <div class="absolute top-0 left-0 w-full border-bottom">
            <div class="bg-reliable-blue-5 px-5 pt-3 pb-2">
                <ng-container *ngTemplateOutlet="organisationMenuitem"/>
            </div>
            <div class="bg-reliable-blue-5 h-1"></div>
        </div>

        <div class="flex flex-col gap-1 grow overflow-y-auto mt-[71px] py-3">
            <ng-container *ngFor="let item of desktopItems">
                <div
                    *ngIf="item.type === 'header'"
                    class="px-6 mb-2">
                    <div class="d-flex align-items-center">
                        <p class="font-size-xs">{{item.title}}</p>
                    </div>
                </div>

                <div
                    *ngIf="item.type === 'divider'"
                    class="px-6">
                    <hr class="bg-primary-20">
                </div>

                <app-nav-bar-menu-item
                    *ngIf="item.type === 'link'"
                    class="px-3"
                    [title]="item.title"
                    [iconSvg]="item.icon"
                    [link]="item.link"
                />

                <app-nav-bar-menu-item
                    *ngIf="item.type === 'help'"
                    class="px-3"
                    title="Get help"
                    [iconSvg]="HELP_SVG"
                    (click)="onGetHelpClicked()"
                />

                <app-nav-bar-menu-item
                    *ngIf="item.type === 'feedback'"
                    class="px-3"
                    title="Send feedback"
                    [iconSvg]="FEEDBACK_SVG"
                    (click)="onSendFeedbackClicked()"
                />
            </ng-container>
        </div>
    </div>
</div>


<div class="content">
    <ng-content/>
</div>

<ng-template #organisationMenuitem>
    <div class="flex items-center gap-2">
        <img
            *ngIf="context.displayLogo$ | async as displayLogo; else displayLogoZeroState"
            class="h-[40px] w-[40px] bg-reliable-blue-10 rounded-1 object-cover"
            alt=""
            [src]="displayLogo"
        />
        <ng-template #displayLogoZeroState>
            <div class="h-[40px] w-[40px] bg-reliable-blue-10 rounded-1 shimmer"></div>
        </ng-template>

        <div
            *ngIf="context.displayName$ | async as displayName; else displayNameZeroState"
            class="min-w-0">
            <p
                class="truncate text-primary">
                {{displayName}}
            </p>
            <ng-container *ngTemplateOutlet="organisationRole"/>
        </div>
        <ng-template #displayNameZeroState>
            <div>
                <p class="w-[150px] bg-reliable-blue-10 text-transparent rounded-1 shimmer"></p>
                <p class="w-[50px] bg-reliable-blue-10 text-transparent rounded-1 shimmer text-sm"></p>
            </div>
        </ng-template>
    </div>
</ng-template>

<ng-template #organisationRole>
    <p
        *ngIf="isAdmin"
        class="truncate text-reliable-blue-70 text-sm">
        Admin
    </p>

    <p
        *ngIf="isCharity && !isAdmin"
        class="truncate text-reliable-blue-70 text-sm">
        Charity manager
    </p>

    <p
        *ngIf="isCompanyManager && !isAdmin"
        class="truncate text-reliable-blue-70 text-sm">
        Company manager
    </p>
</ng-template>
