/**
 * Return digits info for a currency amount
 * @param value - The amount to transform
 * @param [minDecimals] - The min number of decimals for a non-whole number
 * @param [maxDecimals] - The max number of decimals for a non-whole number
 */
export const currencyDigitsInfo = (value: number, minDecimals: number = 2, maxDecimals: number = minDecimals) => {
    const currencyAmount = parseFloat(value.toFixed(2));
    return currencyAmount % 1 === 0 ? '1.0-0' : `1.${minDecimals}-${maxDecimals}`;
};

/**
 * Returns digits info to enforce 2 decimal places
 */
export const twoDecimalDigitsInfo = '0.2-2';
