<div class="cursor-pointer" (click)="toggle()">
    <ng-content select="[header]"/>
</div>

<div
    [id]="id"
    class="lp-collapse"
    [class.lp-collapse-transition]="transitioning"
    [class.show]="!collapsed">
    <ng-content select="[body]"/>
</div>
