import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-nav-bar-assistant-trigger',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './nav-bar-assistant-trigger.component.html',
    styles: [
        `
            :host {
                display: block;
            }
        `,
    ],
})
export class NavBarAssistantTriggerComponent implements OnInit {

    public text = '';
    private backDelay = 50;
    private typeDelay = 25;
    private pauseDelay = 500;

    ngOnInit() {
        this.startTypingAnimation();
    }

    startTypingAnimation() {
        setTimeout(() => {
            this.typeText(
                [
                    // Campaigns
                    ...'Ask me anything related to campaigns...'.split('') as Char[],
                    { action: 'pause' },
                    ...this.backAction(12),

                    // Marketing
                    ...'marketing...'.split('') as Char[],
                    { action: 'pause' },
                    ...this.backAction(12),

                    // Payouts
                    ...'payouts...'.split('') as Char[],
                    { action: 'pause' },
                    ...this.backAction(10),

                    // Fundraising
                    ...'fundraising...'.split('') as Char[],
                ],
                0,
            );
        }, 500);
    }

    private backAction(count: number) {
        return Array(count).fill('back');
    }

    private typeText(actions: TypingAction[], index: number) {
        if (index >= actions.length) {
            return;
        }

        const action = actions[index];
        if (typeof action === 'object') {
            switch (action.action) {
                case 'pause':
                    setTimeout(() => {
                        this.typeText(actions, index + 1);
                    }, this.pauseDelay);
                    return;
            }
        }

        if (action === 'back') {
            this.text = this.text.slice(0, -1);
            setTimeout(() => {
                this.typeText(actions, index + 1);
            }, this.backDelay);
        } else {
            this.text += actions[index];
            setTimeout(() => {
                this.typeText(actions, index + 1);
            }, this.typeDelay);
        }
    }

}

type Char = 'a' | 'b' | 'c' | 'd' | 'e' | 'f' | 'g' | 'h' | 'i' | 'j' | 'k' | 'l'
    | 'm' | 'n' | 'o' | 'p' | 'q' | 'r' | 's' | 't' | 'u' | 'v' | 'w' | 'x' | 'y'
    | 'z' | 'A' | 'B' | 'C' | 'D' | 'E' | 'F' | 'G' | 'H' | 'I' | 'J' | 'K' | 'L'
    | 'M' | 'N' | 'O' | 'P' | 'Q' | 'R' | 'S' | 'T' | 'U' | 'V' | 'W' | 'X' | 'Y'
    | 'Z' | '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '.';

type TypingAction = Char
    | 'back'
    | { action: 'pause' };
