/*
 * app-preloader.ts
 * Little Phil
 *
 * Created on 5/7/21
 * Copyright © 2018 Little Phil. All rights reserved.
 */

import { PreloadingStrategy, Route } from '@angular/router';
import { Observable, of } from 'rxjs';
import { LoggerService } from '../_services/logger.service';
import { BrowserService } from '../_services/browser.service';

/**
 * Prevents loading of certain modules depending on their preload data flag
 * E.g. we don't want the dashboard module to load for all users, so we load it on demand
 *
 * Example taken from:
 * https://coryrylan.com/blog/custom-preloading-and-lazy-loading-strategies-with-angular
 */
export class AppPreloader implements PreloadingStrategy {

    constructor(private logger: LoggerService, private browserService: BrowserService) {}

    preload(route: Route, load: Function): Observable<any> {

        const preload = !(route.data && route.data.preload === false) && this.browserService.isBrowserPlatform();
        this.logger.verbose(`"${route.path}" loaded: ${preload}`);

        return preload ? load() : of(null);
    }

}
