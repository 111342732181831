import { Pipe, PipeTransform } from '@angular/core';
import isBefore from 'date-fns/isBefore';
import isAfter from 'date-fns/isAfter';
import { Payment } from '../../../_models/payment.model';

/**
 * Giving Sort options
 */
export enum GivingSort {
    NEWEST_TO_OLDEST = 'Newest to oldest (default)',
    OLDEST_TO_NEWEST = 'Oldest to newest',
    HIGHEST_AMOUNT_TO_LOWEST = 'Highest amount to lowest',
    LOWEST_AMOUNT_TO_HIGHEST = 'Lowest amount to highest',
}

@Pipe({
    name: 'givingSort',
})
export class GivingSortPipe implements PipeTransform {

    /**
     * Sorts an array of payments according to the selected GivingSort option
     * @param payments - source array of payments
     * @param givingSort - selected sort option
     */
    transform(payments: Payment[], givingSort: GivingSort): Payment[] {

        if (!payments || !Array.isArray(payments) || payments.length <= 1) {
            return payments;
        }

        return payments.sort((p1, p2) => {

            switch (givingSort) {

                case GivingSort.NEWEST_TO_OLDEST:
                    if (isBefore(p1.createdAt, p2.createdAt)) {
                        return 1;
                    } else if (isAfter(p1.createdAt, p2.createdAt)) {
                        return -1;
                    }
                    return 0;

                case GivingSort.OLDEST_TO_NEWEST:
                    if (isBefore(p1.createdAt, p2.createdAt)) {
                        return -1;
                    } else if (isAfter(p1.createdAt, p2.createdAt)) {
                        return 1;
                    }
                    return 0;

                case GivingSort.HIGHEST_AMOUNT_TO_LOWEST:
                    return p2.amount - p1.amount;

                case GivingSort.LOWEST_AMOUNT_TO_HIGHEST:
                    return p1.amount - p2.amount;

                default:
                    return 0;

            }

        });
    }

}
