import { Component } from '@angular/core';

import { Environment } from '../../../../_services/environment.service';
import { ScrollService } from '../../../../_services/scroll.service';


@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
    public ScrollService = ScrollService;
    public businessName = Environment.businessName;
    public establishmentYear = Environment.establishmentYear;
}
