/*
 * auth.guard.ts
 * Little Phil
 *
 * Created on 29/6/21
 * Copyright © 2018 Little Phil. All rights reserved.
 */

import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { redirectToLogin } from '../_utils/router.util';
import { AuthService } from '../_services/auth.service';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate {

    constructor(private authService: AuthService, private router: Router) {}

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        const isLoggedIn: boolean = this.authService.isLoggedInObservable.getValue();

        if (!isLoggedIn) {
            redirectToLogin(this.router, state.url);
        }

        return isLoggedIn;
    }
}
