<a [routerLink]="link">
    <div
        class="item flex items-center rounded-full px-3 py-2"
        [class.active]="router.url === link || active"
        [class.w-[270px]]="!slim"
        [class.w-[190px]]="slim"
    >
        <app-svg-icon
            *ngIf="iconSvg"
            class="me-3"
            [svg]="iconSvg"
        />
        <p *ngIf="title">{{title}}</p>
        <ng-content></ng-content>
    </div>
</a>
