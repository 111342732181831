import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicComponentDirective } from '../../_classes/dynamic-component.class';

@Component({
    selector: 'app-widget-loader',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './widget-loader.component.html',
    styleUrls: ['./widget-loader.component.scss'],
})
export class WidgetLoaderComponent extends DynamicComponentDirective implements OnInit {
    @Input() iconSize: 16 | 24 | 44 | 88 = 44;

    ngOnInit() {
        if (this.options?.iconSize) {
            this.iconSize = this.options.iconSize;
        }
    }
}
