import { Injectable } from '@angular/core';
import { LoggerService } from './logger.service';

@Injectable({
    providedIn: 'root',
})
export class ClipboardService {

    constructor(private logger: LoggerService) {}

    /**
     * Copy text to the clipboard
     */
    public async copyToClipboard(text: string) {

        // check if we can use the clipboard API
        if (await ClipboardService.hasClipboardPermission()) {
            // use the clipboard API and return its promise
            return navigator.clipboard.writeText(text);
        }

        // Otherwise, default to old hacky method
        this.logger.verbose('Clipboard API is unavailable.');

        // create a hidden element in the DOM with the text to copy
        const el = document.createElement('textarea');
        el.value = text;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);

        // select the text
        el.select();

        // copy
        document.execCommand('copy');

        // remove the element
        document.body.removeChild(el);

    }

    /**
     * Checks if we have access to the clipboard API
     * @private
     */
    private static async hasClipboardPermission() {
        return await ClipboardService.hasPermission('clipboard-write') // chrome
            || await ClipboardService.hasPermission('clipboard') // firefox
            || navigator.clipboard; // safari
    }

    /**
     * Checks if we have access to a permission
     * @param permissionName - Name of the permission to check
     * @private
     */
    private static async hasPermission(permissionName: string) {
        try {
            // @ts-ignore Chrome and Firefox have different names
            //  https://developer.mozilla.org/en-US/docs/Mozilla/Add-ons/WebExtensions/Interact_with_the_clipboard
            const permission = await navigator.permissions.query({ name: permissionName, allowWithoutGesture: false });
            return (permission?.state === 'granted' || permission?.state === 'prompt');
        } catch (e) {
            return false;
        }
    }

}
