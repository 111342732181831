import { Component, Input } from '@angular/core';
import { WholeCurrency } from '../../../pipes/whole-currency.pipe';

@Component({
    selector: 'app-credit-balance',
    templateUrl: './credit-balance.component.html',
    styleUrls: ['./credit-balance.component.scss'],
    providers: [WholeCurrency],
})
export class CreditBalanceComponent {
    @Input() balance: number | string;

    /**
     * Whether to apply the right margin as defined in the html (me-2)
     * If false, set the right margin to 0
     */
    @Input() marginRight = true;

    /**
     * Opens the Intercom article explaining how to use credits
     */
    openCreditArticle() {
        window.open('https://support.littlephil.org/en/articles/5135856-using-my-little-phil-credit', '_blank');
    }
}
