"use strict";
/*
 * append-query.ts
 * Little Phil
 *
 * Created on 4/7/23
 * Copyright © 2018 Little Phil. All rights reserved.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.getQueryPartsFromUri = exports.appendQuery = void 0;
/**
 * https://github.com/lakenen/node-append-query/blob/master/index.js
 * @param uri
 * @param queryParams
 * @param options
 */
const appendQuery = (uri, queryParams, options = { encodeComponents: true }) => {
    const queryString = serialise(Object.assign(Object.assign({}, (0, exports.getQueryPartsFromUri)(uri)), queryParams), options);
    const baseUri = uri.split('?')[0];
    // Check for existing query
    if (queryString) {
        return baseUri + '?' + queryString;
    }
    else {
        return baseUri;
    }
};
exports.appendQuery = appendQuery;
/**
 * Returns a query string for the provided query params
 * @param queryParams
 * @param options
 */
const serialise = (queryParams, options = {}) => {
    const parts = [];
    Object.entries(queryParams).forEach(function ([key, value]) {
        let queryValue;
        if (value === null) {
            if (options.removeNull) {
                return;
            }
            queryValue = options.encodeComponents ? encodeURIComponent(key) : key;
        }
        else if (Array.isArray(value)) {
            queryValue = serialiseArray(value, options, key);
        }
        else {
            queryValue = options.encodeComponents ?
                encodeURIComponent(key) + '=' + encodeURIComponent(value) :
                key + '=' + value;
        }
        parts.push(queryValue);
    });
    return parts.join('&');
};
/**
 * Returns an query string for an array of values
 * @param values
 * @param options
 * @param key
 */
const serialiseArray = (values, options = {}, key) => {
    // Use array syntax
    key = key + '[]';
    // Query parts
    const parts = [];
    values.forEach((value) => {
        const queryValue = options.encodeComponents ?
            encodeURIComponent(key) + '=' + encodeURIComponent(value) :
            key + '=' + value;
        parts.push(queryValue);
    });
    return parts.join('&');
};
/**
 * Returns the query params for a given uri
 * @param uri
 */
const getQueryPartsFromUri = (uri) => {
    const parts = {};
    const existingQuery = uri.split('?')[1];
    if (existingQuery) {
        existingQuery.split('&').forEach((part) => {
            const [key, value] = part.split('=');
            parts[key] = value;
        });
    }
    return parts;
};
exports.getQueryPartsFromUri = getQueryPartsFromUri;
