/*
 * no-comma.pipe.ts
 * Little Phil
 *
 * Created on 30/6/21
 * Copyright © 2018 Little Phil. All rights reserved.
 */

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'noComma'
})
export class NoCommaPipe implements PipeTransform {

    /**
     * Remove commas from a value
     */
    transform(val: any): string {
        if (val !== undefined && val !== null) {
            // here we just remove the commas from value
            return val.toString().replace(/,/g, '');
        } else {
            return '';
        }
    }

}
