"use strict";
/*
 * number.ts
 * Little Phil
 *
 * Created on 1/2/21
 * Copyright © 2018 Little Phil. All rights reserved.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.getNumericValueOrZero = exports.roundStringAmount = exports.roundAmount = exports.countDecimals = exports.centsToPrettyAUD = exports.amountToPrettyAmount = exports.randomIntFromInterval = void 0;
/**
 * Returns a random int between 2 provided values (inclusive)
 * @param min - Minimum value
 * @param max - Maximum value
 */
const randomIntFromInterval = (min, max) => {
    if (min < 0) {
        throw new Error('Min is not an integer.');
    }
    if (max < min) {
        throw new Error('Min is above max.');
    }
    return Math.floor(Math.random() * (max - min + 1) + min);
};
exports.randomIntFromInterval = randomIntFromInterval;
/**
 * Apply comma formatting to the amount passed as parameter and return the resulting string.
 * If a number of decimals is specified, apply it and round the value, otherwise keep the original number of decimals.
 *
 * Note: This method uses exponential notation to round numbers as a way to get around floating point inaccuracies
 *  https://medium.com/swlh/how-to-round-to-a-certain-number-of-decimal-places-in-javascript-ed74c471c1b8
 *
 * @example
 * Eg: 100000 => "100,000"
 *     5689.5556 => "5,689.5556"
 * @param {number} amount - The amount to format
 * @param {number} [decimalPlaces] - The number of decimal to display
 */
const amountToPrettyAmount = (amount, decimalPlaces = undefined) => {
    // Define the number of decimals as the number passed as parameter or the number of decimals of the amount if no parameter is specified
    const decimals = typeof decimalPlaces !== 'undefined' ? decimalPlaces : (0, exports.countDecimals)(amount);
    // If a number of decimal places is specified, round the amount to this number of decimals
    if (typeof decimalPlaces !== 'undefined' && !Number.isInteger(amount)) {
        amount = (0, exports.roundAmount)(amount, decimals);
    }
    return amount
        .toFixed(decimals)
        .replace(/^[+-]?\d+/, function (int) {
        return int.replace(/(\d)(?=(\d{3})+$)/g, '$1,');
    });
};
exports.amountToPrettyAmount = amountToPrettyAmount;
/**
 * Converts AUD cents to AUD and formats the result for display
 *
 * @param {number} cents - Amount in AUD cents
 * @param {number} [decimalPlaces] - The number of decimal places to display
 */
const centsToPrettyAUD = (cents, decimalPlaces = undefined) => {
    const numberInAUD = cents / 100;
    return (0, exports.amountToPrettyAmount)(numberInAUD, decimalPlaces);
};
exports.centsToPrettyAUD = centsToPrettyAUD;
/**
 * Return the number of decimals for the value passed as parameter
 *
 * @param {number} value - The value for which to return the number of decimals
 */
const countDecimals = (value) => {
    if (Math.floor(value) === value) {
        return 0;
    }
    return value.toString().split('.')[1].length || 0;
};
exports.countDecimals = countDecimals;
/**
 * Round an amount to the specified number of decimal places,
 * or to an integer if no number of decimal places is specified
 *
 * @param {number} amount - The amount to round
 * @param {number} decimalPlaces - The number of decimal places to round to
 *
 * @returns {number} The amount, rounded to the specified number of decimal places
 */
const roundAmount = (amount, decimalPlaces = 0) => {
    const amountExpNotation = Number(amount.toString() + 'e' + decimalPlaces);
    return Number(Math.round(amountExpNotation) + 'e-' + decimalPlaces);
};
exports.roundAmount = roundAmount;
/**
 * Round an amount passed in string format to the specified number of decimal places,
 * or to an integer if no number of decimal places is specified, and return the rounded
 * number in a string format
 *
 * @param {string} stringAmount - The amount to round
 * @param {number} decimalPlaces - The number of decimal places to round to
 *
 * @returns {string} The rounded amount, as a string
 */
const roundStringAmount = (stringAmount, decimalPlaces = 0) => {
    const amount = Number(stringAmount);
    // If the string amount is not a number, return the initial value
    if (isNaN(amount)) {
        return stringAmount;
    }
    return (0, exports.roundAmount)(amount, decimalPlaces).toFixed(decimalPlaces); // toFixed is necessary to keep zeros after the decimal point
};
exports.roundStringAmount = roundStringAmount;
/**
 * Helper for converting a string to a number or to 0 if the value is not a number
 * @param {string} stringValue - The string to return as a numeric value
 * @returns {number} The string converted to numeric value, or 0 if the string was not a number
 */
const getNumericValueOrZero = (stringValue) => {
    const numericValue = Number(stringValue);
    return isNaN(numericValue) ? 0 : numericValue;
};
exports.getNumericValueOrZero = getNumericValueOrZero;
