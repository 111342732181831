import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Charity } from '../../_models/charity.model';
import { Company } from '../../_models/company.model';
import { AuthService } from '../../_services/auth.service';
import { ApiService } from '../../_services/api.service';
import { User } from '../../_models/user.model';
import { LoggerService } from '../../_services/logger.service';
import { USER_ROLE } from '@little-phil/js/lib/common/enums';
import { extractErrorMessageFromError } from '../../_utils/errors.util';
import { ToastrService } from 'ngx-toastr';
import { SessionStorageService } from '../../_services/session-storage.service';
import {
    SESSION_STORAGE_ACTING_CHARITY_TENANT,
    SESSION_STORAGE_ACTING_COMPANY_TENANT,
    SESSION_STORAGE_ACTING_USER_ROLE,
} from '../../_utils/constants.util';

@Injectable({
    providedIn: 'root',
})
export class DashboardContextService {

    private readonly _displayLogo: BehaviorSubject<string> = new BehaviorSubject(null);
    public readonly displayLogo$ = this._displayLogo.asObservable();

    private readonly _displayName: BehaviorSubject<string> = new BehaviorSubject(null);
    public readonly displayName$ = this._displayName.asObservable();

    private readonly _userRole: BehaviorSubject<USER_ROLE> = new BehaviorSubject(null);
    public readonly userRole$ = this._userRole.asObservable();

    private _charityPromise: Promise<Charity> | undefined;
    private _companyPromise: Promise<Company> | undefined;

    private selectedCharityId: string | undefined;
    private selectedCompanyId: string | undefined;
    private _selectedCharity: Charity | undefined;
    private _selectedCompany: Company | undefined;

    constructor(
        private apiService: ApiService,
        private authService: AuthService,
        private logger: LoggerService,
        private toastr: ToastrService,
        private sessionStorage: SessionStorageService,
    ) {
        authService.userObservable.subscribe((user) => {
            this.refreshUserState(user.newValue);
        });
    }

    /******************************************************************************************************************
     ** SETTERS
     *****************************************************************************************************************/
    private setSelectCharity(id: string) {
        if (this.selectedCharityId !== id) {
            this.selectedCharityId = id;
            this.refreshSelectedCharity().catch(this.logger.error);
        }
    }

    private setSelectCompany(id: string) {
        if (this.selectedCompanyId !== id) {
            this.selectedCompanyId = id;
            this.refreshSelectedCompany().catch(this.logger.error);
        }
    }

    /******************************************************************************************************************
     ** REFRESHERS
     *****************************************************************************************************************/
    private refreshUserState(user?: User) {
        this.resetState();

        if (!user) {
            return;
        }

        if (user.role === USER_ROLE.ADMIN) {
            const actingCharityTenant = this.sessionStorage.getItem(SESSION_STORAGE_ACTING_CHARITY_TENANT);
            const actingCompanyTenant = this.sessionStorage.getItem(SESSION_STORAGE_ACTING_COMPANY_TENANT);
            const actingRole = this.sessionStorage.getItem(SESSION_STORAGE_ACTING_USER_ROLE);

            if (actingRole === USER_ROLE.COMPANY_MANAGER && actingCompanyTenant) {
                // @ts-ignore
                user.role = USER_ROLE.COMPANY_MANAGER;
                // @ts-ignore
                user.managedCompany = actingCompanyTenant;
            } else if (actingRole === USER_ROLE.CHARITY && actingCharityTenant) {
                // @ts-ignore
                user.role = USER_ROLE.CHARITY;
                // @ts-ignore
                user.managedCharity = actingCharityTenant;
            }
        }

        this._userRole.next(user.role);

        switch (user.role) {
            case USER_ROLE.ADMIN:
                this._displayName.next('Little Phil');
                this._displayLogo.next('https://little-phil-dev.s3.amazonaws.com/charity/img/fc788e16-ca6f-432f-8a44-69080a662db5');
                break;
            case USER_ROLE.CHARITY:
                this.setSelectCharity(user.managedCharity);
                break;
            case USER_ROLE.COMPANY_MANAGER:
                this.setSelectCompany(user.managedCompany);
                break;
            default:
                this.resetState();
        }

    }

    public refreshSelectedCharity() {
        // admins don't have IDs
        if (!this.selectedCharityId) {
            return;
        }

        this._charityPromise = this.apiService.charity.retrieve(this.selectedCharityId)
            .then((charity) => {
                this._selectedCharity = charity;
                this._displayName.next(charity.getDisplayName());
                this._displayLogo.next(charity.imageUrl || '/assets/img/placeholders/charity-placeholder@2x.png');
                return charity;
            })
            .catch((err) => {
                this.toastr.error(extractErrorMessageFromError(err));
                throw err;
            });

        return this._charityPromise;
    }

    public refreshSelectedCompany() {
        // admins don't have IDs
        if (!this.selectedCompanyId) {
            return;
        }

        this._companyPromise = this.apiService.company.retrieve(this.selectedCompanyId)
            .then((company) => {
                this._selectedCompany = company;
                this._displayName.next(company.getDisplayName());
                this._displayLogo.next(company.imageUrl || '/assets/img/placeholders/company-placeholder@2x.png');
                return company;
            })
            .catch((err) => {
                this.toastr.error(extractErrorMessageFromError(err));
                throw err;
            });

        return this._companyPromise;
    }

    /******************************************************************************************************************
     ** GETTERS
     *****************************************************************************************************************/
    get charityPromise() {
        return this._charityPromise;
    }

    get companyPromise() {
        return this._companyPromise;
    }

    get selectedCharity(): Charity | undefined {
        return this._selectedCharity;
    }

    get selectedCompany(): Company | undefined {
        return this._selectedCompany;
    }

    get userRole(): USER_ROLE | undefined {
        return this._userRole.value;
    }

    /******************************************************************************************************************
     ** RESET
     *****************************************************************************************************************/
    private resetState() {
        this.selectedCharityId = null;
        this.selectedCompanyId = null;
        this._selectedCharity = null;
        this._selectedCompany = null;
        this._charityPromise = null;

        this._displayLogo.next(null);
        this._displayName.next(null);
        this._userRole.next(null);
    }

}
