/*
 * as.pipe.ts
 * Little Phil
 *
 * Created on 30/6/21
 * Copyright © 2018 Little Phil. All rights reserved.
 */

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'as',
    pure: true,
})
export class AsPipe implements PipeTransform {

    /**
     * Takes a value and casts it to a type
     * @param value - The value to be cast as the provided type
     * @param constructor - The class constructor in which to cast the value as
     */
    transform<T>(value: any, constructor: new (...args: any[]) => T): T {
        return value as T;
    }

}
