/*
 * constants.util.ts
 * Little Phil
 *
 * Created on 29/6/21
 * Copyright © 2018 Little Phil. All rights reserved.
 */

export const ACCESS_TOKEN_KEY = 'access_token';
export const REFRESH_TOKEN_KEY = 'refresh_token';

export const COOKIE_FIRST_VISIT = 'lp.first-visit';
export const COOKIE_FINGERPRINT_KEY = 'lp.fingerprint';
export const COOKIE_SHARE_EVENT_ID_KEY = 'lp.share-event-id';
export const COOKIE_HIDE_MY_IMPACT_TAX_STATEMENT_BANNER_ENDING_FISCAL_YEAR_KEY = 'lp.hide-mi-tax-statement-banner';
export const COOKIE_LAST_USER_ROLE_KEY = 'lp.last_user_role';

export const LOCAL_STORAGE_FIRST_NAME_KEY = 'firstName';
export const LOCAL_STORAGE_LAST_NAME_KEY = 'lastName';
export const LOCAL_STORAGE_EMAIL_KEY = 'email';
export const LOCAL_STORAGE_USER_ID_KEY = 'userId';
export const LOCAL_STORAGE_POSTCODE_KEY = 'postCode';
export const LOCAL_STORAGE_SEX_KEY = 'sex';
export const LOCAL_STORAGE_INTERCOM_HASH_KEY = 'intercomHash';
export const LOCAL_STORAGE_MANAGED_CHARITY_KEY = 'managedCharity';
export const LOCAL_STORAGE_MANAGED_COMPANY_KEY = 'managedCompany';
export const LOCAL_STORAGE_ROLE_KEY = 'role';
export const LOCAL_STORAGE_CREDIT_BALANCE_KEY = 'creditBalance';
export const LOCAL_STORAGE_IMAGE_URL_KEY = 'imageUrl';
export const LOCAL_STORAGE_USER_KEY = 'user';
export const LOCAL_STORAGE_LAST_USER_EMAIL_KEY = 'last_user_email';
export const LOCAL_STORAGE_LAST_GUEST_PAYMENT_KEY = 'last_guest_payment';

export const SESSION_STORAGE_ACTING_CHARITY_TENANT = 'charity_tenant';
export const SESSION_STORAGE_ACTING_COMPANY_TENANT = 'company_tenant';
export const SESSION_STORAGE_ACTING_USER_ROLE = 'user_role';

export const GIVING_GATEWAY_DEFAULT_ERROR_MESSAGE =
    'Sorry, it seems we\'re having issues connecting to our secure checkout system. Please refresh the page and try again.';

export enum FORM_STATE {
    CREATE = 'Create',
    EDIT = 'Edit'
}

export enum SEX {
    MALE = 'Male',
    FEMALE = 'Female',
    OTHER = 'Other'
}

export const SEX_ARRAY = [
    SEX.MALE,
    SEX.FEMALE,
    SEX.OTHER,
];

/**
 * Enum for alignment of DOM elements
 */
export enum ALIGN {
    LEFT = 'left',
    RIGHT = 'right',
    CENTER = 'center',
}

export const MONTHS = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

/**
 * Details of the Company Platform Plan tiers
 * Note: This is a temporary solution and we should have an endpoint to return this information
 */
export const tiersDetails = [
    {
        name: 'Tier 1',
        price: 19900,
        numberOfEmployees: '0-50',
    },
    {
        name: 'Tier 2',
        price: 29900,
        numberOfEmployees: '51-250',
    },
    {
        name: 'Tier 3',
        price: 39900,
        numberOfEmployees: '250+',
    },
];

/**
 * Enum of Google ReCAPTCHA actions
 *
 * Note: Actions must contain only alphanumeric characters, slashes, and underscores.
 *  Actions must not be user-specific.
 */
export enum RECAPTCHA_ACTION {
    COMPANY_ONBOARDING_LOGIN = 'company_onboarding_login',
    GIVING_GATEWAY_LOGIN = 'giving_gateway_login',
    GIVING_GATEWAY_REGULAR_GIVING = 'giving_gateway_regular_giving',
    LOGIN = 'login',
    NATIVE_PAYMENT = 'native_payment',
    PAYMENT = 'payment',
    REGISTER = 'register',
}
