/*
 * google-maps.util.ts
 * Little Phil
 *
 * Created on 6/7/21
 * Copyright © 2018 Little Phil. All rights reserved.
 */

import { environment } from '../../environments/environment';

/**
 * NOTE: if multiple scripts are needed in the future we can implement a ScriptService
 * http://www.lukasjakob.com/how-to-dynamically-load-external-scripts-in-angular/
 */
export class GoogleMaps {

    private static loadingPromise: Promise<void>;

    public static readonly loadScript = () => {
       if (GoogleMaps.loadingPromise) {
           return GoogleMaps.loadingPromise;
       }

        GoogleMaps.loadingPromise = new Promise((resolve, reject) => {
            const scriptUrl = `https://maps.googleapis.com/maps/api/js?libraries=places&key=${environment.googleMapsAPIKey}`;

            const script = document.createElement('script');
            script.src = scriptUrl;
            script.type = 'text/javascript';
            script.async = true;
            script.defer = true;
            document.getElementsByTagName('head')[0].appendChild(script);

            script.onload = () => resolve();
            script.onerror = (error: any) => {
                reject(error);
                GoogleMaps.loadingPromise = null; // clear promise so that next request will create a new promise
            };
        });

       return GoogleMaps.loadingPromise;
    }

}
