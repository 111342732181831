/*
 * router.util.ts
 * Little Phil
 *
 * Created on 2/7/21
 * Copyright © 2018 Little Phil. All rights reserved.
 */

import { Router } from '@angular/router';
import { ROUTE_PATHS } from '@little-phil/js/lib/common/constants';
import {
    CHARITY_ACTIVATE_PROFILE_QUERY_PARAM,
} from '../dashboard/charity/activate-profile/dashboard-charity-activate-profile-page.component';
import { COMPANY_PROFILE_QUERY_PARAM } from '../dashboard/profile/company-profile.component';

/**
 * Redirects the user to the login screen with a redirectUrl query so the user can come back to the requested page upon successful login
 * @param {Router} router - Caller's Router
 * @param {string} redirectUrl - Relative URL to come back to upon successful login
 */
export const redirectToLogin = (router: Router, redirectUrl: string) => {
    router.navigate([ROUTE_PATHS.web.login], {
        queryParams: {
            redirectUrl,
        },
    }).catch(console.error);
};

export const redirectToCharityActivateProfile = (
    router: Router,
    showModal: boolean,
    redirectUrl?: string,
) => {
    const queryParams: Record<string, any> = {
        [CHARITY_ACTIVATE_PROFILE_QUERY_PARAM.ACTIVATE_MODAL]: showModal,
    };

    if (redirectUrl) {
        queryParams[CHARITY_ACTIVATE_PROFILE_QUERY_PARAM.REDIRECT_URL] = redirectUrl;
    }

    return router.navigate([ROUTE_PATHS.web.charity.activateProfile], {
        queryParams,
    });
};

export const redirectToCompanyActivateProfile = (
    router: Router,
    redirectUrl?: string,
) => {
    const queryParams: Record<string, any> = {};

    if (redirectUrl) {
        queryParams[COMPANY_PROFILE_QUERY_PARAM.REDIRECT_URL] = redirectUrl;
    }

    return router.navigate([ROUTE_PATHS.web.dashboard.profile.route], {
        queryParams,
    });
};
