/*
 * category.model.ts
 * Little Phil
 *
 * Created on 16/5/20
 * Copyright © 2018 Little Phil. All rights reserved.
 */
import { BaseModel } from './_base.model';

export class Category extends BaseModel {

    public static endpoint = 'category';

    // Properties
    public readonly name: string = undefined;
    public readonly imageUrl: string = undefined;
    public readonly colour: string = undefined;

    /**
     * Constructor
     * @param {object} json
     */
    constructor(json: object = {}) {
        super();
        super.init(json);
    }

}
