import { Pipe, PipeTransform } from '@angular/core';
import { PAYMENT_TYPE } from '@little-phil/js/lib/common/enums';
import { Payment } from '../../../_models/payment.model';

/**
 * Giving Filter options
 */
export enum GivingFilter {
    SEE_ALL = 'See all (default)',
    ONE_OFF = 'One-off',
    REGULAR = 'Regular'
}

@Pipe({
    name: 'givingFilter',
})
export class GivingFilterPipe implements PipeTransform {

    /**
     * Filters an array of payments according to the selected GivingFilter filter
     * @param payments - source array of payments
     * @param givingFilter - selected filter
     */
    transform(payments: Payment[], givingFilter: GivingFilter): Payment[] {

        if (!payments || !Array.isArray(payments) || payments.length <= 1 || givingFilter === GivingFilter.SEE_ALL) {
            return payments;
        }

        return payments.filter(payment => {

            switch (givingFilter) {

                case GivingFilter.ONE_OFF:
                    return payment.type === PAYMENT_TYPE.ONE_OFF;

                case GivingFilter.REGULAR:
                    return payment.type === PAYMENT_TYPE.SUBSCRIPTION;

                default:
                    return true;

            }

        });

    }

}
