import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DashboardFormControlComponent } from '../dashboard-form-control/dashboard-form-control.component';

@Component({
    selector: 'app-dashboard-form-text-area',
    standalone: true,
    imports: [CommonModule, FormsModule, ReactiveFormsModule, DashboardFormControlComponent],
    templateUrl: './dashboard-form-text-area.component.html',
    styleUrls: ['./dashboard-form-text-area.component.scss'],
})
export class DashboardFormTextAreaComponent implements OnInit {

    // Required
    @Input() control: FormControl; // Instance of the control
    @Input() name: string; // Name used for error messages

    // Optionals
    @Input() placeholder: string; // Placeholder text inside the field
    @Input() label: string; // The label text that appears above the field
    @Input() labelFontSize: 'xs';
    @Input() details: string;
    @Input() required: boolean;
    @Input() rows = 4;

    ngOnInit() {
        console.assert(this.control, 'DashboardFormInputComponent control is missing');
        console.assert(this.control, 'DashboardFormInputComponent name is missing');
    }

}
