import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ROUTE_PATHS } from '@little-phil/js/lib/common/constants';
import { lpcToAUD } from '@little-phil/js/lib/utils/lp-credit';

import { ApiService } from '../../../../_services/api.service';
import { LoggerService } from '../../../../_services/logger.service';
import { IWelcomeCreditPool } from '../../../../_interfaces/credit-pool.interface';

@Component({
    selector: 'app-site-banner',
    templateUrl: './site-banner.component.html',
    styleUrls: ['./site-banner.component.scss'],
})
export class SiteBannerComponent implements OnInit {

    public creditAmountPerClaim: number;

    public readonly lpcToAUD = lpcToAUD;

    constructor(private router: Router,
                private logger: LoggerService,
                private apiService: ApiService) {}

    ngOnInit() {
        this.getWelcomeCreditPoolDetails();
    }

    /**
     * Fired when the claim button is clicked
     */
    claimButtonClicked() {
        this.router.navigateByUrl(ROUTE_PATHS.web.claimWelcome).catch(this.logger.error);
    }

    /**
     * Retrieves the welcome credit pool details to display redeemable credit amount in the app banner
     */
    getWelcomeCreditPoolDetails() {
        this.apiService.creditPool.firstLiveWelcomePool().then((welcomePool: IWelcomeCreditPool) => {
            this.creditAmountPerClaim = welcomePool?.creditAmountPerClaim;
        });
    }
}
