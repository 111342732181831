/*
 * whole-currency.pipe.ts
 * Little Phil
 *
 * Created on 30/6/21
 * Copyright © 2018 Little Phil. All rights reserved.
 */

import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { currencyDigitsInfo } from '../../../_utils/currency.util';

@Pipe({
    name: 'wholeCurrency',
})
export class WholeCurrency extends CurrencyPipe implements PipeTransform {

    /**
     * Transforms a number into a formatted currency string
     */
    transform(
        value: number | string | null | undefined,
        currencyCode?: string,
        display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean,
        digitsInfo?: string,
        locale?: string,
    ): null;
    transform(
        value: number | string | null | undefined,
        currencyCode?: string,
        display: 'code' | 'symbol' | 'symbol-narrow' | string | boolean = 'symbol',
        digitsInfo?: string,
        locale?: string,
    ): string | null {

        if (typeof value !== 'number') {
            return null;
        }

        return super.transform(
            value,
            currencyCode,
            display,
            digitsInfo || currencyDigitsInfo(value),
        );
    }

}
