import { NgModule } from '@angular/core';

import { LpCommonModule } from '../lp-common/lp-common.module';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { ModalPopupComponent } from './components/modal-popup/modal-popup.component';
import { VideoModalPopupComponent } from './components/video-modal-popup/video-modal-popup.component';
import { WidgetLoaderComponent } from '../../_components/widget-loader/widget-loader.component';
import { SvgIconComponent } from '../../_components/svg-icon/svg-icon.component';

const components = [
    ConfirmationDialogComponent,
    ModalPopupComponent,
];

const entryComponents = [
    VideoModalPopupComponent,
];

@NgModule({
    declarations: [...components, ...entryComponents],
    imports: [
        LpCommonModule,
        WidgetLoaderComponent,
        SvgIconComponent,
    ],
    exports: [...components, ...entryComponents],
    entryComponents,
})
export class LpModalModule {}
