"use strict";
/*
 * enums.ts
 * Little Phil
 *
 * NOTE All enums should start with the resource name and be alphabetically sorted.
 * eg. CAMPAIGN_STATUS, CHARITY_STATUS
 *
 * Created on 1/9/20
 * Copyright © 2018 Little Phil. All rights reserved.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.WIDGET_TYPE = exports.CHATGPT_FORMALITY = exports.CHATGPT_PLATFORM = exports.CHATGPT_CONTENT_TYPE = exports.INTERCOM_EVENT_NAMES = exports.INTERCOM_ATTRIBUTES = exports.MY_COMPANY_MENU_ITEM_ID = exports.MY_ACCOUNT_MENU_ITEM_ID = exports.TAX_DEDUCTIBILITY_TOOLTIP = exports.TAX_DEDUCTIBILITY_MESSAGE = exports.MIN_TAX_DEDUCTIBLE_DONATION_AMOUNT = exports.USER_SEX = exports.USER_ROLE = exports.STRIPE_BUSINESS_TYPE = exports.STRIPE_ACCOUNT_PAYOUT_INTERVAL = exports.STRIPE_ACCOUNT_DISABLED_REASON = exports.SHARE_LINK_OWNER_TYPE = exports.SHARE_LINK_TYPE = exports.SHARE_EVENT_SOURCE_PLATFORM = exports.SHARE_EVENT_TYPE = exports.SHARE_SOCIAL_PLATFORM = exports.REDEEMABLE_CREDIT_CREATION_MODE = exports.REDEEMABLE_CREDIT_STATUS = exports.REDEMPTION_AUTHORISATION_STATUS = exports.PAYMENT_TYPE = exports.PAYMENT_STATUS = exports.PAYMENT_REFUND_REASON = exports.SUPPORTED_STRIPE_PAYMENT_METHOD_TYPE = exports.PAYMENT_METHOD_TYPE = exports.CREDIT_POOL_TYPE = exports.COMPANY_EMPLOYEE_UPLOAD_MODE = exports.CHARITY_APPLICATION_STATUS = exports.CHARITY_SORT_ORDER = exports.CHARITY_STATUS_PRIORITY = exports.CHARITY_STATUS = exports.CAMPAIGN_SUGGESTION_TYPE = exports.CAMPAIGN_SORT_ORDER = exports.CAMPAIGN_STATUS = exports.SORT_ORDER = exports.AUTHENTICATION_STRATEGY = exports.FIRST_FISCAL_MONTH = exports.FLAG_EMOJI = exports.COUNTRY_CODE = exports.COUNTRIES = exports.CAUSE_TYPE = void 0;
/* --------------------------------------------------------------- ****** --------------------------------------------------------------- */
/**
 * Different types of causes in which a donor can donate to
 * @enum {string}
 */
var CAUSE_TYPE;
(function (CAUSE_TYPE) {
    CAUSE_TYPE["CAMPAIGN"] = "campaign";
    CAUSE_TYPE["CHARITY"] = "charity";
})(CAUSE_TYPE = exports.CAUSE_TYPE || (exports.CAUSE_TYPE = {}));
/**
 * Enum of country names and codes
 * Source: https://jvectormap.com/maps/world/world/
 */
exports.COUNTRIES = {
    'AF': 'Afghanistan',
    'AE': 'United Arab Emirates',
    'AL': 'Albania',
    'AM': 'Armenia',
    'AO': 'Angola',
    'AR': 'Argentina',
    'AT': 'Austria',
    'AU': 'Australia',
    'AZ': 'Azerbaijan',
    'BA': 'Bosnia and Herz.',
    'BD': 'Bangladesh',
    'BE': 'Belgium',
    'BF': 'Burkina Faso',
    'BG': 'Bulgaria',
    'BI': 'Burundi',
    'BJ': 'Benin',
    'BN': 'Brunei',
    'BO': 'Bolivia',
    'BR': 'Brazil',
    'BS': 'Bahamas',
    'BW': 'Botswana',
    'BY': 'Belarus',
    'BZ': 'Belize',
    'CA': 'Canada',
    'CD': 'Dem. Rep. Congo',
    'CF': 'Central African Rep.',
    'CG': 'Congo',
    'CH': 'Switzerland',
    'CI': 'Côte d\'Ivoire',
    'CL': 'Chile',
    'CM': 'Cameroon',
    'CN': 'China',
    'CO': 'Colombia',
    'CR': 'Costa Rica',
    'CU': 'Cuba',
    'CY': 'Cyprus',
    'CZ': 'Czech Rep.',
    'DE': 'Germany',
    'DJ': 'Djibouti',
    'DK': 'Denmark',
    'DO': 'Dominican Rep.',
    'DZ': 'Algeria',
    'EC': 'Ecuador',
    'EE': 'Estonia',
    'EG': 'Egypt',
    'EH': 'W. Sahara',
    'ER': 'Eritrea',
    'ES': 'Spain',
    'ET': 'Ethiopia',
    'FI': 'Finland',
    'FJ': 'Fiji',
    'FK': 'Falkland Is.',
    'FR': 'France',
    'GA': 'Gabon',
    'GB': 'United Kingdom',
    'GE': 'Georgia',
    'GH': 'Ghana',
    'GL': 'Greenland',
    'GM': 'Gambia',
    'GN': 'Guinea',
    'GQ': 'Eq. Guinea',
    'GR': 'Greece',
    'GT': 'Guatemala',
    'GW': 'Guinea-Bissau',
    'GY': 'Guyana',
    'HN': 'Honduras',
    'HR': 'Croatia',
    'HT': 'Haiti',
    'HU': 'Hungary',
    'ID': 'Indonesia',
    'IE': 'Ireland',
    'IL': 'Israel',
    'IN': 'India',
    'IQ': 'Iraq',
    'IR': 'Iran',
    'IS': 'Iceland',
    'IT': 'Italy',
    'JM': 'Jamaica',
    'JO': 'Jordan',
    'JP': 'Japan',
    'KE': 'Kenya',
    'KG': 'Kyrgyzstan',
    'KH': 'Cambodia',
    'KP': 'Dem. Rep. Korea',
    'KR': 'Korea',
    'KW': 'Kuwait',
    'KZ': 'Kazakhstan',
    'LA': 'Lao PDR',
    'LB': 'Lebanon',
    'LK': 'Sri Lanka',
    'LR': 'Liberia',
    'LS': 'Lesotho',
    'LT': 'Lithuania',
    'LU': 'Luxembourg',
    'LV': 'Latvia',
    'LY': 'Libya',
    'MA': 'Morocco',
    'ME': 'Montenegro',
    'MD': 'Moldova',
    'MG': 'Madagascar',
    'MK': 'Macedonia',
    'ML': 'Mali',
    'MM': 'Myanmar',
    'MN': 'Mongolia',
    'MR': 'Mauritania',
    'MW': 'Malawi',
    'MX': 'Mexico',
    'MY': 'Malaysia',
    'MZ': 'Mozambique',
    'NA': 'Namibia',
    'NC': 'New Caledonia',
    'NE': 'Niger',
    'NI': 'Nicaragua',
    'NG': 'Nigeria',
    'NL': 'Netherlands',
    'NO': 'Norway',
    'NP': 'Nepal',
    'NZ': 'New Zealand',
    'OM': 'Oman',
    'PA': 'Panama',
    'PE': 'Peru',
    'PG': 'Papua New Guinea',
    'PH': 'Philippines',
    'PK': 'Pakistan',
    'PL': 'Poland',
    'PR': 'Puerto Rico',
    'PS': 'Palestine',
    'PT': 'Portugal',
    'PY': 'Paraguay',
    'QA': 'Qatar',
    'RO': 'Romania',
    'RS': 'Serbia',
    'RU': 'Russia',
    'RW': 'Rwanda',
    'SA': 'Saudi Arabia',
    'SB': 'Solomon Is.',
    'SD': 'Sudan',
    'SE': 'Sweden',
    'SI': 'Slovenia',
    'SK': 'Slovakia',
    'SL': 'Sierra Leone',
    'SN': 'Senegal',
    'SO': 'Somalia',
    'SR': 'Suriname',
    'SS': 'S. Sudan',
    'SV': 'El Salvador',
    'SY': 'Syria',
    'SZ': 'Swaziland',
    'TD': 'Chad',
    'TF': 'Fr. S. Antarctic Lands',
    'TG': 'Togo',
    'TH': 'Thailand',
    'TJ': 'Tajikistan',
    'TL': 'Timor-Leste',
    'TM': 'Turkmenistan',
    'TN': 'Tunisia',
    'TR': 'Turkey',
    'TT': 'Trinidad and Tobago',
    'TW': 'Taiwan',
    'TZ': 'Tanzania',
    'UA': 'Ukraine',
    'UG': 'Uganda',
    'US': 'United States',
    'UY': 'Uruguay',
    'UZ': 'Uzbekistan',
    'VE': 'Venezuela',
    'VN': 'Vietnam',
    'VU': 'Vanuatu',
    'XC': 'N. Cyprus',
    'XK': 'Kosovo',
    'XS': 'Somaliland',
    'YE': 'Yemen',
    'ZA': 'South Africa',
    'ZM': 'Zambia',
    'ZW': 'Zimbabwe',
};
/**
 * Enum of country codes
 */
exports.COUNTRY_CODE = Object.keys(exports.COUNTRIES).reduce((prev, curr) => {
    // @ts-ignore
    prev[curr] = curr;
    return prev;
}, {});
/**
 * Flag emoji per country
 * @enum {string}
 */
var FLAG_EMOJI;
(function (FLAG_EMOJI) {
    FLAG_EMOJI["AU"] = "\uD83C\uDDE6\uD83C\uDDFA";
})(FLAG_EMOJI = exports.FLAG_EMOJI || (exports.FLAG_EMOJI = {}));
/**
 * First fiscal month per country, with values ranging from 0 for January to 11 for December
 * @enum {number}
 */
var FIRST_FISCAL_MONTH;
(function (FIRST_FISCAL_MONTH) {
    FIRST_FISCAL_MONTH[FIRST_FISCAL_MONTH["AU"] = 6] = "AU";
})(FIRST_FISCAL_MONTH = exports.FIRST_FISCAL_MONTH || (exports.FIRST_FISCAL_MONTH = {}));
/**
 * Authentication strategies for registering and signing in
 * @enum {string}
 */
var AUTHENTICATION_STRATEGY;
(function (AUTHENTICATION_STRATEGY) {
    AUTHENTICATION_STRATEGY["LOCAL"] = "local";
    AUTHENTICATION_STRATEGY["FACEBOOK"] = "facebook";
    AUTHENTICATION_STRATEGY["GOOGLE"] = "google";
})(AUTHENTICATION_STRATEGY = exports.AUTHENTICATION_STRATEGY || (exports.AUTHENTICATION_STRATEGY = {}));
/**
 * Different types of orders used for sorting
 * @enum {string}
 */
var SORT_ORDER;
(function (SORT_ORDER) {
    SORT_ORDER["ASC"] = "asc";
    SORT_ORDER["DESC"] = "desc";
})(SORT_ORDER = exports.SORT_ORDER || (exports.SORT_ORDER = {}));
/* -------------------------------------------------------------- CAMPAIGN -------------------------------------------------------------- */
/**
 * Campaign status
 * @enum {string}
 */
var CAMPAIGN_STATUS;
(function (CAMPAIGN_STATUS) {
    CAMPAIGN_STATUS["PENDING"] = "Pending";
    CAMPAIGN_STATUS["IN_PROGRESS"] = "In Progress";
    CAMPAIGN_STATUS["CLOSED"] = "Closed";
})(CAMPAIGN_STATUS = exports.CAMPAIGN_STATUS || (exports.CAMPAIGN_STATUS = {}));
/**
 * Campaign sort
 * @enum {string}
 */
var CAMPAIGN_SORT_ORDER;
(function (CAMPAIGN_SORT_ORDER) {
    CAMPAIGN_SORT_ORDER["NEAR_COMPLETION"] = "near_completion";
    CAMPAIGN_SORT_ORDER["CREATED"] = "created";
    CAMPAIGN_SORT_ORDER["CREATED_ASC"] = "created.asc";
    CAMPAIGN_SORT_ORDER["CREATED_DESC"] = "created.desc";
    CAMPAIGN_SORT_ORDER["DONORS"] = "donors";
    CAMPAIGN_SORT_ORDER["DONORS_ASC"] = "donors.asc";
    CAMPAIGN_SORT_ORDER["DONORS_DESC"] = "donors.desc";
    CAMPAIGN_SORT_ORDER["GOAL"] = "goal";
    CAMPAIGN_SORT_ORDER["GOAL_ASC"] = "goal.asc";
    CAMPAIGN_SORT_ORDER["GOAL_DESC"] = "goal.desc";
    CAMPAIGN_SORT_ORDER["RAISED"] = "raised";
    CAMPAIGN_SORT_ORDER["RAISED_ASC"] = "raised.asc";
    CAMPAIGN_SORT_ORDER["RAISED_DESC"] = "raised.desc";
    CAMPAIGN_SORT_ORDER["TRENDING"] = "trending";
    CAMPAIGN_SORT_ORDER["TRENDING_ASC"] = "trending.asc";
    CAMPAIGN_SORT_ORDER["TRENDING_DESC"] = "trending.desc";
})(CAMPAIGN_SORT_ORDER = exports.CAMPAIGN_SORT_ORDER || (exports.CAMPAIGN_SORT_ORDER = {}));
/**
 * Campaign suggestion type
 * (used when suggesting campaigns from SendGrid emails)
 * @enum {string}
 */
var CAMPAIGN_SUGGESTION_TYPE;
(function (CAMPAIGN_SUGGESTION_TYPE) {
    CAMPAIGN_SUGGESTION_TYPE["FEATURED"] = "featured";
    CAMPAIGN_SUGGESTION_TYPE["NEAR_COMPLETION"] = "near completion";
    CAMPAIGN_SUGGESTION_TYPE["TRENDING"] = "trending";
})(CAMPAIGN_SUGGESTION_TYPE = exports.CAMPAIGN_SUGGESTION_TYPE || (exports.CAMPAIGN_SUGGESTION_TYPE = {}));
/* -------------------------------------------------------------- CHARITY --------------------------------------------------------------- */
/**
 * Charity status
 * @enum {string}
 */
var CHARITY_STATUS;
(function (CHARITY_STATUS) {
    CHARITY_STATUS["PENDING"] = "pending";
    CHARITY_STATUS["LEAD"] = "lead";
    CHARITY_STATUS["APPROVED"] = "approved";
})(CHARITY_STATUS = exports.CHARITY_STATUS || (exports.CHARITY_STATUS = {}));
/**
 * Charity status priority
 * @enum {number}
 */
var CHARITY_STATUS_PRIORITY;
(function (CHARITY_STATUS_PRIORITY) {
    CHARITY_STATUS_PRIORITY[CHARITY_STATUS_PRIORITY["pending"] = 1] = "pending";
    CHARITY_STATUS_PRIORITY[CHARITY_STATUS_PRIORITY["lead"] = 2] = "lead";
    CHARITY_STATUS_PRIORITY[CHARITY_STATUS_PRIORITY["approved"] = 0] = "approved";
})(CHARITY_STATUS_PRIORITY = exports.CHARITY_STATUS_PRIORITY || (exports.CHARITY_STATUS_PRIORITY = {}));
/**
 * Charity sort
 * @enum {string}
 */
var CHARITY_SORT_ORDER;
(function (CHARITY_SORT_ORDER) {
    CHARITY_SORT_ORDER["CREATED"] = "created";
    CHARITY_SORT_ORDER["CREATED_ASC"] = "created.asc";
    CHARITY_SORT_ORDER["CREATED_DESC"] = "created.desc";
    CHARITY_SORT_ORDER["DONORS"] = "donors";
    CHARITY_SORT_ORDER["DONORS_ASC"] = "donors.asc";
    CHARITY_SORT_ORDER["DONORS_DESC"] = "donors.desc";
    CHARITY_SORT_ORDER["RAISED"] = "raised";
    CHARITY_SORT_ORDER["RAISED_ASC"] = "raised.asc";
    CHARITY_SORT_ORDER["RAISED_DESC"] = "raised.desc";
    CHARITY_SORT_ORDER["TRENDING"] = "trending";
    CHARITY_SORT_ORDER["TRENDING_ASC"] = "trending.asc";
    CHARITY_SORT_ORDER["TRENDING_DESC"] = "trending.desc";
})(CHARITY_SORT_ORDER = exports.CHARITY_SORT_ORDER || (exports.CHARITY_SORT_ORDER = {}));
/* ------------------------------------------------------- CHARITY APPLICATION ---------------------------------------------------------- */
/**
 * Charity application status
 * @enum {string}
 */
var CHARITY_APPLICATION_STATUS;
(function (CHARITY_APPLICATION_STATUS) {
    CHARITY_APPLICATION_STATUS["PENDING"] = "pending";
    CHARITY_APPLICATION_STATUS["APPROVED"] = "approved";
})(CHARITY_APPLICATION_STATUS = exports.CHARITY_APPLICATION_STATUS || (exports.CHARITY_APPLICATION_STATUS = {}));
/* -------------------------------------------------------------- COMPANY --------------------------------------------------------------- */
/**
 * Mode to upload company employees
 * - Flush → unlink all employees that are not in the spreadsheet
 * - Merge → simply link up new employees
 */
var COMPANY_EMPLOYEE_UPLOAD_MODE;
(function (COMPANY_EMPLOYEE_UPLOAD_MODE) {
    COMPANY_EMPLOYEE_UPLOAD_MODE["FLUSH"] = "FLUSH";
    COMPANY_EMPLOYEE_UPLOAD_MODE["MERGE"] = "MERGE";
})(COMPANY_EMPLOYEE_UPLOAD_MODE = exports.COMPANY_EMPLOYEE_UPLOAD_MODE || (exports.COMPANY_EMPLOYEE_UPLOAD_MODE = {}));
/* ------------------------------------------------------------ CREDIT POOL ------------------------------------------------------------- */
/**
 * Type of credit pool
 * https://www.notion.so/20210324-Multiple-Credit-Pools-377209e2e8964fe0ad94d433fde1ef12#66696782937748ecae62152c96335bf2
 * @enum {string}
 */
var CREDIT_POOL_TYPE;
(function (CREDIT_POOL_TYPE) {
    CREDIT_POOL_TYPE["MANUAL"] = "manual";
    CREDIT_POOL_TYPE["WELCOME"] = "welcome";
    CREDIT_POOL_TYPE["COMPANY_GIVING"] = "company giving";
})(CREDIT_POOL_TYPE = exports.CREDIT_POOL_TYPE || (exports.CREDIT_POOL_TYPE = {}));
/* -------------------------------------------------------------- PAYMENT --------------------------------------------------------------- */
/**
 * Payment method type
 * Note: ensure that this enum contains all the values in SUPPORTED_STRIPE_PAYMENT_METHOD_TYPE
 */
var PAYMENT_METHOD_TYPE;
(function (PAYMENT_METHOD_TYPE) {
    PAYMENT_METHOD_TYPE["AU_DIRECT_DEBIT"] = "au_becs_debit";
    PAYMENT_METHOD_TYPE["CARD"] = "card";
    PAYMENT_METHOD_TYPE["CREDIT_ONLY"] = "credit_only";
    PAYMENT_METHOD_TYPE["CRYPTO"] = "cryptocurrency";
})(PAYMENT_METHOD_TYPE = exports.PAYMENT_METHOD_TYPE || (exports.PAYMENT_METHOD_TYPE = {}));
/**
 * Supported Stripe payment method type
 */
var SUPPORTED_STRIPE_PAYMENT_METHOD_TYPE;
(function (SUPPORTED_STRIPE_PAYMENT_METHOD_TYPE) {
    SUPPORTED_STRIPE_PAYMENT_METHOD_TYPE["AU_DIRECT_DEBIT"] = "au_becs_debit";
    SUPPORTED_STRIPE_PAYMENT_METHOD_TYPE["CARD"] = "card";
})(SUPPORTED_STRIPE_PAYMENT_METHOD_TYPE = exports.SUPPORTED_STRIPE_PAYMENT_METHOD_TYPE || (exports.SUPPORTED_STRIPE_PAYMENT_METHOD_TYPE = {}));
/**
 * List of reasons for refunding a payment
 * @enum {string}
 */
var PAYMENT_REFUND_REASON;
(function (PAYMENT_REFUND_REASON) {
    PAYMENT_REFUND_REASON["INSUFFICIENT_CREDIT"] = "insufficient_credit";
    PAYMENT_REFUND_REASON["INTERNAL_ERROR"] = "internal_error";
})(PAYMENT_REFUND_REASON = exports.PAYMENT_REFUND_REASON || (exports.PAYMENT_REFUND_REASON = {}));
/**
 * Payment status
 * @enum {string}
 *
 * Note: These values are used to display the payment status in the payment history on My Impact,
 *  therefore any value added here should be user friendly, or alternatively a map should be
 *  created for display on the frontend
 */
var PAYMENT_STATUS;
(function (PAYMENT_STATUS) {
    PAYMENT_STATUS["SUCCESS"] = "success";
    PAYMENT_STATUS["REFUNDED"] = "refunded";
    PAYMENT_STATUS["PENDING"] = "pending";
    PAYMENT_STATUS["PROCESSING"] = "processing";
    PAYMENT_STATUS["FAILED"] = "failed";
})(PAYMENT_STATUS = exports.PAYMENT_STATUS || (exports.PAYMENT_STATUS = {}));
/**
 * Payment type
 * @enum {string}
 */
var PAYMENT_TYPE;
(function (PAYMENT_TYPE) {
    PAYMENT_TYPE["ONE_OFF"] = "one-off";
    PAYMENT_TYPE["SUBSCRIPTION"] = "subscription";
})(PAYMENT_TYPE = exports.PAYMENT_TYPE || (exports.PAYMENT_TYPE = {}));
/* --------------------------------------------------------- REDEEMABLE CREDIT ---------------------------------------------------------- */
/**
 * List of possible statuses returned when checking
 * a user can redeem from a credit pool
 * @enum {string}
 */
var REDEMPTION_AUTHORISATION_STATUS;
(function (REDEMPTION_AUTHORISATION_STATUS) {
    /**
     * Can redeem from pool
     */
    REDEMPTION_AUTHORISATION_STATUS["CAN_REDEEM"] = "CAN_REDEEM";
    /**
     * Pool is empty
     */
    REDEMPTION_AUTHORISATION_STATUS["POOL_EMPTY"] = "POOL_EMPTY";
    /**
     * User has reached their redemption limit
     */
    REDEMPTION_AUTHORISATION_STATUS["MAX_REDEEM_REACHED"] = "MAX_REDEEM_REACHED";
    /**
     * The Pool is not live
     */
    REDEMPTION_AUTHORISATION_STATUS["POOL_NOT_LIVE"] = "POOL_NOT_LIVE";
})(REDEMPTION_AUTHORISATION_STATUS = exports.REDEMPTION_AUTHORISATION_STATUS || (exports.REDEMPTION_AUTHORISATION_STATUS = {}));
/**
 * Redeemable Credit status
 * @enum {string}
 */
var REDEEMABLE_CREDIT_STATUS;
(function (REDEEMABLE_CREDIT_STATUS) {
    REDEEMABLE_CREDIT_STATUS["NOT_REDEEMED"] = "NOT_REDEEMED";
    REDEEMABLE_CREDIT_STATUS["REDEEMED"] = "REDEEMED";
    REDEEMABLE_CREDIT_STATUS["EXPIRED"] = "EXPIRED";
})(REDEEMABLE_CREDIT_STATUS = exports.REDEEMABLE_CREDIT_STATUS || (exports.REDEEMABLE_CREDIT_STATUS = {}));
/**
 * Whether the Redeemable Credit was created automatically through logic or manually by an admin through the portal
 * https://www.notion.so/20210324-Multiple-Credit-Pools-377209e2e8964fe0ad94d433fde1ef12#10fb1c7691f24b27a0d6e03c845d5cfe
 * @enum {string}
 */
var REDEEMABLE_CREDIT_CREATION_MODE;
(function (REDEEMABLE_CREDIT_CREATION_MODE) {
    REDEEMABLE_CREDIT_CREATION_MODE["AUTOMATIC"] = "automatic";
    REDEEMABLE_CREDIT_CREATION_MODE["MANUAL"] = "manual";
})(REDEEMABLE_CREDIT_CREATION_MODE = exports.REDEEMABLE_CREDIT_CREATION_MODE || (exports.REDEEMABLE_CREDIT_CREATION_MODE = {}));
/* --------------------------------------------------------------- SHARE ---------------------------------------------------------------- */
/**
 * List of social platforms a user can share a link to
 * @enum {string}
 */
var SHARE_SOCIAL_PLATFORM;
(function (SHARE_SOCIAL_PLATFORM) {
    SHARE_SOCIAL_PLATFORM["FACEBOOK"] = "facebook";
    SHARE_SOCIAL_PLATFORM["X"] = "x";
    SHARE_SOCIAL_PLATFORM["LINKED_IN"] = "linkedin";
})(SHARE_SOCIAL_PLATFORM = exports.SHARE_SOCIAL_PLATFORM || (exports.SHARE_SOCIAL_PLATFORM = {}));
/* ------------------------------------------------------------- SHARE EVENT ------------------------------------------------------------ */
/**
 * Share event types
 * @enum {string}
 */
var SHARE_EVENT_TYPE;
(function (SHARE_EVENT_TYPE) {
    SHARE_EVENT_TYPE["VIEW"] = "view";
    SHARE_EVENT_TYPE["CREDIT_CLAIMED"] = "credit_claimed";
    SHARE_EVENT_TYPE["DONATION"] = "donation";
    SHARE_EVENT_TYPE["REGISTER"] = "register";
    SHARE_EVENT_TYPE["SIGN_IN"] = "sign_in";
})(SHARE_EVENT_TYPE = exports.SHARE_EVENT_TYPE || (exports.SHARE_EVENT_TYPE = {}));
/**
 * Share event source platforms
 * @enum {string}
 */
var SHARE_EVENT_SOURCE_PLATFORM;
(function (SHARE_EVENT_SOURCE_PLATFORM) {
    SHARE_EVENT_SOURCE_PLATFORM["FACEBOOK"] = "facebook";
    SHARE_EVENT_SOURCE_PLATFORM["TWITTER"] = "twitter";
    SHARE_EVENT_SOURCE_PLATFORM["LINKED_IN"] = "linked_in";
    SHARE_EVENT_SOURCE_PLATFORM["OTHER"] = "other";
})(SHARE_EVENT_SOURCE_PLATFORM = exports.SHARE_EVENT_SOURCE_PLATFORM || (exports.SHARE_EVENT_SOURCE_PLATFORM = {}));
/* ------------------------------------------------------------- SHARE LINK ------------------------------------------------------------- */
/**
 * Share link types
 * @enum {string}
 */
var SHARE_LINK_TYPE;
(function (SHARE_LINK_TYPE) {
    SHARE_LINK_TYPE["CONTENT_ASSISTANT"] = "content-assistant";
    SHARE_LINK_TYPE["FUNDRAISER"] = "fundraiser";
    SHARE_LINK_TYPE["INFLUENCER"] = "influencer";
    SHARE_LINK_TYPE["MARKETING"] = "marketing";
})(SHARE_LINK_TYPE = exports.SHARE_LINK_TYPE || (exports.SHARE_LINK_TYPE = {}));
/**
 * Different types of resource that can own a share link
 * @enum {string}
 */
var SHARE_LINK_OWNER_TYPE;
(function (SHARE_LINK_OWNER_TYPE) {
    SHARE_LINK_OWNER_TYPE["CHARITY"] = "charity";
    SHARE_LINK_OWNER_TYPE["USER"] = "user";
})(SHARE_LINK_OWNER_TYPE = exports.SHARE_LINK_OWNER_TYPE || (exports.SHARE_LINK_OWNER_TYPE = {}));
/* --------------------------------------------------------------- STRIPE --------------------------------------------------------------- */
/**
 * Stripe Connect Account requirements disabled reason
 * https://stripe.com/docs/api/accounts/object#account_object-requirements-disabled_reason
 * @enum {string}
 */
var STRIPE_ACCOUNT_DISABLED_REASON;
(function (STRIPE_ACCOUNT_DISABLED_REASON) {
    STRIPE_ACCOUNT_DISABLED_REASON["REQUIREMENTS_PAST_DUE"] = "requirements.past_due";
    STRIPE_ACCOUNT_DISABLED_REASON["REQUIREMENTS_PENDING_VERIFICATION"] = "requirements.pending_verification";
    STRIPE_ACCOUNT_DISABLED_REASON["REJECTED_FRAUD"] = "rejected.fraud";
    STRIPE_ACCOUNT_DISABLED_REASON["REJECTED_TOS"] = "rejected.terms_of_service";
    STRIPE_ACCOUNT_DISABLED_REASON["REJECTED_LISTED"] = "rejected.listed";
    STRIPE_ACCOUNT_DISABLED_REASON["REJECTED_OTHER"] = "rejected.other";
    STRIPE_ACCOUNT_DISABLED_REASON["LISTED"] = "listed";
    STRIPE_ACCOUNT_DISABLED_REASON["UNDER_REVIEW"] = "under_review";
    STRIPE_ACCOUNT_DISABLED_REASON["OTHER"] = "other";
})(STRIPE_ACCOUNT_DISABLED_REASON = exports.STRIPE_ACCOUNT_DISABLED_REASON || (exports.STRIPE_ACCOUNT_DISABLED_REASON = {}));
/**
 * Stripe Connect Account payout schedule interval
 * https://stripe.com/docs/api/accounts/object#account_object-settings-payouts-schedule-interval
 * @enum {string}
 */
var STRIPE_ACCOUNT_PAYOUT_INTERVAL;
(function (STRIPE_ACCOUNT_PAYOUT_INTERVAL) {
    STRIPE_ACCOUNT_PAYOUT_INTERVAL["MANUAL"] = "manual";
    STRIPE_ACCOUNT_PAYOUT_INTERVAL["DAILY"] = "daily";
    STRIPE_ACCOUNT_PAYOUT_INTERVAL["WEEKLY"] = "weekly";
    STRIPE_ACCOUNT_PAYOUT_INTERVAL["MONTHLY"] = "monthly";
})(STRIPE_ACCOUNT_PAYOUT_INTERVAL = exports.STRIPE_ACCOUNT_PAYOUT_INTERVAL || (exports.STRIPE_ACCOUNT_PAYOUT_INTERVAL = {}));
/**
 * Stripe business type
 * https://stripe.com/docs/api/accounts/object#account_object-business_type
 * @enum {string}
 */
var STRIPE_BUSINESS_TYPE;
(function (STRIPE_BUSINESS_TYPE) {
    STRIPE_BUSINESS_TYPE["COMPANY"] = "company";
    STRIPE_BUSINESS_TYPE["INDIVIDUAL"] = "individual";
    STRIPE_BUSINESS_TYPE["NON_PROFIT"] = "non_profit";
})(STRIPE_BUSINESS_TYPE = exports.STRIPE_BUSINESS_TYPE || (exports.STRIPE_BUSINESS_TYPE = {}));
/* --------------------------------------------------------------- USER ----------------------------------------------------------------- */
/**
 * User role
 * @enum {string}
 */
var USER_ROLE;
(function (USER_ROLE) {
    USER_ROLE["ADMIN"] = "admin";
    USER_ROLE["CHARITY"] = "charity";
    USER_ROLE["COMPANY_MANAGER"] = "company manager";
    USER_ROLE["USER"] = "user";
})(USER_ROLE = exports.USER_ROLE || (exports.USER_ROLE = {}));
/**
 * User gender
 */
var USER_SEX;
(function (USER_SEX) {
    USER_SEX["MALE"] = "Male";
    USER_SEX["FEMALE"] = "Female";
    USER_SEX["OTHER"] = "Other";
})(USER_SEX = exports.USER_SEX || (exports.USER_SEX = {}));
/* ---------------------------------------------------------------- DGR ----------------------------------------------------------------- */
/**
 * Minimum tax deductible donation amount per country
 * @enum {number}
 */
var MIN_TAX_DEDUCTIBLE_DONATION_AMOUNT;
(function (MIN_TAX_DEDUCTIBLE_DONATION_AMOUNT) {
    MIN_TAX_DEDUCTIBLE_DONATION_AMOUNT[MIN_TAX_DEDUCTIBLE_DONATION_AMOUNT["AU"] = 200] = "AU";
})(MIN_TAX_DEDUCTIBLE_DONATION_AMOUNT = exports.MIN_TAX_DEDUCTIBLE_DONATION_AMOUNT || (exports.MIN_TAX_DEDUCTIBLE_DONATION_AMOUNT = {}));
/**
 * Tax deductibility message per country
 * @enum {string}
 */
var TAX_DEDUCTIBILITY_MESSAGE;
(function (TAX_DEDUCTIBILITY_MESSAGE) {
    TAX_DEDUCTIBILITY_MESSAGE["AU"] = "DGR \u2022 Tax deductible in Australia";
})(TAX_DEDUCTIBILITY_MESSAGE = exports.TAX_DEDUCTIBILITY_MESSAGE || (exports.TAX_DEDUCTIBILITY_MESSAGE = {}));
/**
 * Tax deductibility tooltip per country
 * @enum {string}
 */
var TAX_DEDUCTIBILITY_TOOLTIP;
(function (TAX_DEDUCTIBILITY_TOOLTIP) {
    TAX_DEDUCTIBILITY_TOOLTIP["AU"] = "Deductible Gift Recipients (DGR) are organisations which can receive tax deductible donations.<br><br>Donations above AUD $2 (apart from credit) are generally tax deductible. Please consult your tax agent for more info.";
})(TAX_DEDUCTIBILITY_TOOLTIP = exports.TAX_DEDUCTIBILITY_TOOLTIP || (exports.TAX_DEDUCTIBILITY_TOOLTIP = {}));
/* ------------------------------------------------------------ QUERY PARAMS ------------------------------------------------------------ */
/**
 * Enum of My Account menu item IDs
 * These values are used for query param to auto select items
 */
var MY_ACCOUNT_MENU_ITEM_ID;
(function (MY_ACCOUNT_MENU_ITEM_ID) {
    MY_ACCOUNT_MENU_ITEM_ID["MY_DETAILS"] = "my-details";
    MY_ACCOUNT_MENU_ITEM_ID["REGULAR_GIVING"] = "regular-giving";
    MY_ACCOUNT_MENU_ITEM_ID["PAYMENT_METHODS"] = "payment-methods";
    MY_ACCOUNT_MENU_ITEM_ID["SOCIAL_ACCOUNTS"] = "social-accounts";
    MY_ACCOUNT_MENU_ITEM_ID["TAX_STATEMENTS"] = "tax-statements";
    MY_ACCOUNT_MENU_ITEM_ID["UPDATE_PASSWORD"] = "update-password";
})(MY_ACCOUNT_MENU_ITEM_ID = exports.MY_ACCOUNT_MENU_ITEM_ID || (exports.MY_ACCOUNT_MENU_ITEM_ID = {}));
/**
 * Enum of My Company menu item IDs
 * These values are used for query param to auto select items
 */
var MY_COMPANY_MENU_ITEM_ID;
(function (MY_COMPANY_MENU_ITEM_ID) {
    MY_COMPANY_MENU_ITEM_ID["COMPANY_PROFILE"] = "company-profile";
    MY_COMPANY_MENU_ITEM_ID["GIVING_MANAGEMENT"] = "giving-management";
    MY_COMPANY_MENU_ITEM_ID["REPORTING"] = "reporting";
})(MY_COMPANY_MENU_ITEM_ID = exports.MY_COMPANY_MENU_ITEM_ID || (exports.MY_COMPANY_MENU_ITEM_ID = {}));
/* -------------------------------------------------------------- INTERCOM -------------------------------------------------------------- */
/**
 * Enum of all Intercom attributes names
 */
var INTERCOM_ATTRIBUTES;
(function (INTERCOM_ATTRIBUTES) {
    INTERCOM_ATTRIBUTES["FIRST_NAME"] = "First Name";
    INTERCOM_ATTRIBUTES["LAST_NAME"] = "Last Name";
    INTERCOM_ATTRIBUTES["EMAIL"] = "email";
    INTERCOM_ATTRIBUTES["USER_ROLE"] = "User Role";
    INTERCOM_ATTRIBUTES["CREDIT_BALANCE_CENTS"] = "Credit balance (in cents)";
    INTERCOM_ATTRIBUTES["CREDIT_BALANCE"] = "Credit balance";
    INTERCOM_ATTRIBUTES["IS_COMPANY_GIVING_EMPLOYEE"] = "Is Company Giving employee";
    INTERCOM_ATTRIBUTES["REFERRAL_CODE"] = "Referral Code";
    INTERCOM_ATTRIBUTES["REFERRER"] = "Referrer";
    INTERCOM_ATTRIBUTES["WEB_BASE_URL"] = "Web Base Url";
})(INTERCOM_ATTRIBUTES = exports.INTERCOM_ATTRIBUTES || (exports.INTERCOM_ATTRIBUTES = {}));
/**
 * Enum of all Intercom event names
 */
var INTERCOM_EVENT_NAMES;
(function (INTERCOM_EVENT_NAMES) {
    INTERCOM_EVENT_NAMES["ONE_OFF_DONATION"] = "one-off-donation";
    INTERCOM_EVENT_NAMES["REGULAR_DONATION"] = "regular-donation";
})(INTERCOM_EVENT_NAMES = exports.INTERCOM_EVENT_NAMES || (exports.INTERCOM_EVENT_NAMES = {}));
/* -------------------------------------------------------------- CHAT GPT -------------------------------------------------------------- */
/**
 * Enum of the content types that can be generated
 */
var CHATGPT_CONTENT_TYPE;
(function (CHATGPT_CONTENT_TYPE) {
    CHATGPT_CONTENT_TYPE["CAMPAIGN_DESCRIPTION"] = "campaign-description";
    CHATGPT_CONTENT_TYPE["CONTENT"] = "content";
})(CHATGPT_CONTENT_TYPE = exports.CHATGPT_CONTENT_TYPE || (exports.CHATGPT_CONTENT_TYPE = {}));
/**
 * Enum of the platforms that can be generated
 */
var CHATGPT_PLATFORM;
(function (CHATGPT_PLATFORM) {
    CHATGPT_PLATFORM["BLOG"] = "blog";
    CHATGPT_PLATFORM["EMAIL"] = "email";
    CHATGPT_PLATFORM["FACEBOOK"] = "facebook";
    CHATGPT_PLATFORM["LINKED_IN"] = "linkedin";
    CHATGPT_PLATFORM["TWITTER"] = "twitter";
})(CHATGPT_PLATFORM = exports.CHATGPT_PLATFORM || (exports.CHATGPT_PLATFORM = {}));
/**
 * Enum of the English formality used to generate content
 */
var CHATGPT_FORMALITY;
(function (CHATGPT_FORMALITY) {
    CHATGPT_FORMALITY["CASUAL"] = "casual";
    CHATGPT_FORMALITY["FORMAL"] = "formal";
})(CHATGPT_FORMALITY = exports.CHATGPT_FORMALITY || (exports.CHATGPT_FORMALITY = {}));
/* -------------------------------------------------------------- WIDGETS -------------------------------------------------------------- */
/**
 * Different types of widgets which can be created
 * @enum {string}
 */
var WIDGET_TYPE;
(function (WIDGET_TYPE) {
    WIDGET_TYPE["DONATION"] = "donation";
})(WIDGET_TYPE = exports.WIDGET_TYPE || (exports.WIDGET_TYPE = {}));
