import { Component, OnInit } from '@angular/core';
import { Social } from '../../../../_models/social.model';
import { Environment } from '../../../../_services/environment.service';

@Component({
    selector: 'app-connect',
    templateUrl: './connect.component.html',
    styleUrls: ['./connect.component.scss'],
})
export class ConnectComponent implements OnInit {

    public socials: any[];
    public isWhitelabel = Environment.isWhitelabel;

    ngOnInit() {
        this.socials = Social.data;
    }

}
