<input
    #search
    id="map-search"
    type="text"
    class="block px-2 py-[14px] w-full border-[1px] border-reliable-blue-25 focus:border-reliable-blue outline-0 rounded transition-colors"
    (keydown.enter)="$event.preventDefault()"
    autocorrect="off"
    autocapitalize="off"
    spellcheck="true"
>

<div #map class="aspect-hero rounded mt-3"></div>
