/**
 * Setup overlay's events
 * @param overlay
 */
export const setupOverlayEvents = (overlay: Element) => {
    ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
        overlay.addEventListener(eventName, preventDefaults, false);
    });

    ['dragenter', 'dragover'].forEach(eventName => {
        overlay.addEventListener(eventName, dragStart, false);
    });

    ['dragleave', 'drop'].forEach(eventName => {
        overlay.addEventListener(eventName, dragEnd, false);
    });

    function preventDefaults(e) {
        e.preventDefault();
        e.stopPropagation();
    }

    function dragStart() {
        overlay.classList.add('hover');
    }

    function dragEnd() {
        overlay.classList.remove('hover');
    }
};
