/*
 * campaign.model.ts
 * Little Phil
 *
 * Created on 14/5/20
 * Copyright © 2018 Little Phil. All rights reserved.
 */
import { BaseModel } from './_base.model';
import { Charity } from './charity.model';
import { Category } from './category.model';
import { CampaignUpdate } from './campaign-update.model';
import { ROUTE_PATHS } from '@little-phil/js/lib/common/constants';
import { CAMPAIGN_STATUS, CAUSE_TYPE } from '@little-phil/js/lib/common/enums';

export class Campaign extends BaseModel {

    public static endpoint = 'campaign';

    // Properties
    public name: string = undefined;
    public description: string = undefined;
    public imageUrl: string = undefined;
    public thumbnailUrl: string = undefined;
    public amount: number = undefined;
    public amountGiven: number = undefined;
    public endDate: Date = undefined;
    public city: string = undefined;
    public countryCode: string = undefined;
    public long: number = undefined;
    public lat: number = undefined;
    public featured: boolean = undefined;
    public tags: string[] = undefined;
    public status: CAMPAIGN_STATUS = undefined; // TODO - Replace with Status enum
    public donors: number = undefined;
    public updateMessageTemplates: string[] = undefined;
    public slug: string = undefined;
    public youtubeUrl: string = undefined;
    public active: boolean = undefined;
    public charity: Charity | string = undefined;
    public category: Category | string = undefined;
    public updates: CampaignUpdate[] = undefined;

    /**
     * Constructor
     * @param {object} json
     */
    constructor(json: object = {}) {
        super();
        super.init(json);
    }

    protected deserialise() {
        if (typeof this.charity === 'object' && !(this.charity instanceof Charity)) {
            this.charity = new Charity(this.charity);
        }

        if (typeof this.category === 'object' && !(this.category instanceof Category)) {
            this.category = new Category(this.category);
        }

        if (Array.isArray(this.updates)) {
            this.updates = this.updates.map((update) => {
                if (typeof update === 'object' && !(update instanceof CampaignUpdate)) {
                    return new CampaignUpdate(update);
                } else {
                    return update;
                }
            });
        }
    }

    /**
     * Returns the URL for the campaign
     */
    public getUrl() {
        return ROUTE_PATHS.web.campaign.details(this.slug || this.id);
    }

    /**
     * Returns the cause type
     */
    public getType() {
        return CAUSE_TYPE.CAMPAIGN;
    }

    /**
     * Returns the background colour for the status badge
     */
    public getStatusBadgeText() {
        switch (this.status) {
            case CAMPAIGN_STATUS.PENDING:
                return 'Pending';
            case CAMPAIGN_STATUS.IN_PROGRESS:
                return 'Active';
            case CAMPAIGN_STATUS.CLOSED:
                return 'Closed';
        }
    }

    /**
     * Returns the background colour for the status badge
     */
    public getStatusBadgeBackgroundColour() {
        switch (this.status) {
            case CAMPAIGN_STATUS.PENDING:
                return '#F8AD6D';
            case CAMPAIGN_STATUS.IN_PROGRESS:
                return '#7DE7B5';
            case CAMPAIGN_STATUS.CLOSED:
                return '#FCB9C5';
        }
    }

    /**
     * Returns the background colour for the status badge
     */
    public getStatusBadgeTextColour() {
        switch (this.status) {
            case CAMPAIGN_STATUS.PENDING:
                return '#203B4C';
            case CAMPAIGN_STATUS.IN_PROGRESS:
                return '#203B4C';
            case CAMPAIGN_STATUS.CLOSED:
                return '#203B4C';
        }
    }

}
