"use strict";
/*
 * string.ts
 * Little Phil
 *
 * Created on 10/9/20
 * Copyright © 2018 Little Phil. All rights reserved.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.textEndsWithWhitespaceCharacter = exports.textEndsWithPunctuationCharacter = exports.truncateText = exports.rfc1736Encode = exports.removeWhitespaces = exports.capitalise = exports.CAPITALISATION_TYPE = void 0;
/**
 * Capitalisation type to apply when capitalising a string
 */
var CAPITALISATION_TYPE;
(function (CAPITALISATION_TYPE) {
    CAPITALISATION_TYPE["FIRST_CHAR"] = "FIRST_CHAR";
    CAPITALISATION_TYPE["WORDS"] = "WORDS";
    CAPITALISATION_TYPE["SENTENCES"] = "SENTENCES";
})(CAPITALISATION_TYPE = exports.CAPITALISATION_TYPE || (exports.CAPITALISATION_TYPE = {}));
/**
 * Capitalises a string as per the capitalisation type specified.
 * If no capitalisation type is specified, only the first character will be capitalised.
 * Return the object as is if the type is not string
 * @param {string} str - text to capitalise
 * @param {CAPITALISATION_TYPE} capitalisationType - type of capitalisation to apply
 */
const capitalise = (str, capitalisationType = CAPITALISATION_TYPE.FIRST_CHAR) => {
    // noinspection SuspiciousTypeOfGuard
    if (typeof str !== 'string' || str.length <= 0) {
        return str;
    }
    switch (capitalisationType) {
        // First character only
        case CAPITALISATION_TYPE.FIRST_CHAR:
            return capitaliseString(str);
        // First character of every word
        case CAPITALISATION_TYPE.WORDS:
            return capitaliseWords(str);
        // First character of every sentence
        case CAPITALISATION_TYPE.SENTENCES:
            return capitaliseSentences(str);
    }
};
exports.capitalise = capitalise;
/**
 * Capitalise the first character of every word in the string passed as parameter
 * @param str - text to capitalise
 */
const capitaliseWords = (str) => {
    // Replace the first character of the string, or the first character after a space by the same character in upper case
    return str.replace(/(^\w{1})|(\s{1}\w{1})/g, match => match.toUpperCase());
};
/**
 * Capitalise the first character of every sentence in the string passed as parameter
 * @param str - text to capitalise
 */
const capitaliseSentences = (str) => {
    // Replace the first character of a sentence by the same character in upper case,
    // with a sentence being defined as one or more characters followed by '.', '?' or '!' and a space
    // Note: This won't capitalise a sentence which doesn't end with a punctuation mark.
    return str.replace(/.+?[.?!](\s|$)/g, function (sentence) {
        return capitaliseString(sentence);
    });
};
/**
 * Capitalise the first character of the string passed as parameter
 * @param str - text to capitalise
 */
const capitaliseString = (str) => {
    return str[0].toUpperCase() + str.substring(1);
};
/**
 * Remove whitespace characters from the string passed as parameter
 * @param {string} str - text from which to remove whitespaces from
 */
const removeWhitespaces = (str) => {
    if (!str || !str.length) {
        return str;
    }
    return str.replace(/\s/g, '');
};
exports.removeWhitespaces = removeWhitespaces;
/**
 * Encodes a string to be RFC 1736 compliant
 * Implementation inspired from
 *  https://github.com/Gamecredits-Universe/node-coinpayments-gamecredits/blob/7a61459e0cb5e1e353b90aa65848bc4716ad2632/coinpayments.js#L56
 * Added the '+' sign encoding before the 'space' encoding
 *
 * @param string - the string to encode
 */
const rfc1736Encode = (string) => {
    return string.replace(/!/g, '%21')
        .replace(/#/g, '%23')
        .replace(/\$/g, '%24')
        .replace(/&/g, '%26')
        .replace(/'/g, '%27')
        .replace(/\(/g, '%28')
        .replace(/\)/g, '%29')
        .replace(/\*/g, '%2A')
        .replace(/\+/g, '%2B')
        .replace(/ /g, '+')
        .replace(/,/g, '%2C')
        .replace(/\//g, '%2F')
        .replace(/:/g, '%3A')
        .replace(/;/g, '%3B')
        .replace(/=/g, '%3D')
        .replace(/\?/g, '%3F')
        .replace(/@/g, '%40')
        .replace(/\[/g, '%5B')
        .replace(/]/g, '%5D');
};
exports.rfc1736Encode = rfc1736Encode;
/**
 * Return the text passed as parameter, truncated to the last white space
 * before specified length and with an added ellipsis if it was truncated
 * Note: The added ellipsis is included in the length
 *
 * @param {string} text - The text to truncate
 * @param {number} length - The length to truncate the text to
 */
const truncateText = (text, length) => {
    // if the text length is already less than or equal to specified length, or the length is less than 0, return the text as is
    if (text.length <= length || length < 0) {
        return text;
    }
    // truncate the text to the desired length before applying the regex.
    // It is important not to truncate it to length-1 as we wouldn't be able to see if it ends with a dot or a space character
    const textSlicedToLength = text.slice(0, length);
    // if the sliced text ends with a punctuation or whitespace character, replace it by the ellipsis and return
    if ((0, exports.textEndsWithPunctuationCharacter)(textSlicedToLength) || (0, exports.textEndsWithWhitespaceCharacter)(textSlicedToLength)) {
        return text.slice(0, length - 1) + '…';
    }
    // split the text on whitespace characters to identify where to truncate
    const truncatedTextSplitOnWhitespaceCharacters = textSlicedToLength.split(new RegExp(/\s|\n|\r/));
    const lastItemInSplitTextArray = truncatedTextSplitOnWhitespaceCharacters[truncatedTextSplitOnWhitespaceCharacters.length - 1];
    // if the text contains whitespace characters, remove any text after the last whitespace character
    // otherwise just slice to the desired length, minus one for the ellipsis
    let truncatedText = truncatedTextSplitOnWhitespaceCharacters.length > 1
        ? text.slice(0, textSlicedToLength.lastIndexOf(lastItemInSplitTextArray) - 1)
        : text.slice(0, length - 1);
    // if the truncated text ends with a punctuation character, remove it
    // (we don't want to display a punctuation character followed by the ellipsis)
    while (typeof truncatedText === 'string' && (0, exports.textEndsWithPunctuationCharacter)(truncatedText)) {
        truncatedText = truncatedText.slice(0, -1);
    }
    // return the truncated text with an added ellipsis at the end if not undefined or empty
    return (truncatedText && truncatedText.length > 0) ? truncatedText + '…' : truncatedText;
};
exports.truncateText = truncateText;
/**
 * An array of punctuation characters
 */
const punctuationCharactersArray = [',', ':', ';', '.', '?', '!', '…'];
/**
 * Check whether the text passed as parameter ends with a punctuation character
 *
 * @param text - The text to run the check on
 */
const textEndsWithPunctuationCharacter = (text) => {
    // if the text is empty, return false
    if (!text.length) {
        return false;
    }
    // check whether the last characters is amongst the punctuation characters list
    const lastCharacter = text[text.length - 1];
    return punctuationCharactersArray.includes(lastCharacter);
};
exports.textEndsWithPunctuationCharacter = textEndsWithPunctuationCharacter;
/**
 * An array of whitespace characters
 */
const whitespaceCharactersArray = [' ', '\t', '\n', '\r'];
/**
 * Check whether the text passed as parameter ends with a whitespace character
 *
 * @param text - The text to run the check on
 */
const textEndsWithWhitespaceCharacter = (text) => {
    // if the text is empty, return false
    if (!text.length) {
        return false;
    }
    // check whether the last characters is amongst the whitespace characters list
    const lastCharacter = text[text.length - 1];
    return whitespaceCharactersArray.includes(lastCharacter);
};
exports.textEndsWithWhitespaceCharacter = textEndsWithWhitespaceCharacter;
