<div class="loader__wrapper" *ngIf="showLoader">
    <div class="loader__spinner">
        <i class="icon-88 icon-loader icon-spin"></i>
    </div>
</div>

<ng-container *ngTemplateOutlet="isDashboard ? dashboardNavigation : appNavigation"/>

<ng-template #appNavigation>
    <div class="min-vh-100" [class.grey-page-bg]="greyPageBackground">
        <app-site-banner/>
        <app-nav-bar
            *ngIf="!hideNavBar"
            variant="donor"
            [class.shadow-sm]="!hideNavBarShadow"
        />
        <router-outlet/>
    </div>

    <ng-container *ngIf="!hideFooter">
        <app-connect/>
        <app-footer/>
    </ng-container>
</ng-template>

<ng-template #dashboardNavigation>
    <div class="min-vh-100">
        <app-nav-bar-dashboard>
            <router-outlet/>
        </app-nav-bar-dashboard>
    </div>
</ng-template>
