import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ModalService {

    private readonly _isOpen: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public readonly isOpen = this._isOpen.asObservable();

    setOpen(open: boolean) {
        if (this._isOpen.value !== open) {
            this._isOpen.next(open);
        }
    }

}
