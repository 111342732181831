/*
 * log-interceptor.util.ts
 * Little Phil
 *
 * Created on 14/7/20
 * Copyright © 2018 Little Phil. All rights reserved.
 */
import { Injectable, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LoggerService } from '../../_services/logger.service';

@Injectable()
export class LogInterceptor implements HttpInterceptor {

    constructor(private logger: LoggerService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            tap((event) => {
                if (event instanceof HttpResponse) {
                    this.logger.http(event.url, event.body);
                }
            }),
        );
    }

}

/**
 * Module is required to avoid TransferHttpCacheModule from overriding provider in AppModule
 */
@NgModule({
    providers: [{
        provide: HTTP_INTERCEPTORS,
        useClass: LogInterceptor,
        multi: true,
    }],
})
export class LogInterceptorModule {}
